<template>
    <div class="c-form-inputWeekdays">
        <label>
            {{ label }}
        </label>

        <ul class="c-form-inputWeekdays__grid">
            <li v-for="(label, index) in weekdays">
                <label>
                    {{ label }}
                    <br>
                    <input
                        type="checkbox"
                        @click="()=>{select(((index+1) % 7))}"
                        :value="[((index+1) % 7)]"
                    >
                </label>
            </li>
        </ul>
    </div>
</template>

<script>
    import value from "../../../mixins/value";

    export default {
        extends: value,
        props: {
            label: {},
        },
        methods: {
            select(weekday) {
                if (this.value.includes(weekday)) {
                    this.value = this.value.splice(this.value.indexOf(weekday), 1);
                } else {
                    this.value.push(weekday);
                }
            }
        },
        data() {
            return {
                weekdays: [
                    'Montag',
                    'Dienstag',
                    'Mittwoch',
                    'Donnerstag',
                    'Freitag',
                    'Samstag',
                    'Sonntag'
                ]
            }
        }
    }
</script>

<style lang="scss">
.c-form-inputWeekdays {
    margin-bottom: 1rem;

    .c-form-inputWeekdays__grid {
        display: grid;
        grid-auto-flow: column;
        margin: 0;
        padding: 0;
        list-style: none;
    }
}
</style>
