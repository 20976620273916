<template>
    <div class="grid grid-cols-12 gap-x-4">
        <input-simple-select
            class="col-span-6"
            label="Status"
            v-model="value.status"
            :options="$store.getters.applicationSettings.model.autumnOfColorWork.status"
            :errors="errors.status"
        />

        <input-entity
            class="col-span-6"
            label="Standort"
            type="location"
            v-model="value.location"
            :errors="errors.location"
        />

        <input-text
            label="Vita"
            v-model="value.vita"
            type="textarea"
            class="col-span-12"
            :errors="errors.vita"
        />
        <input-text
            class="col-span-4"
            label="Geburtsort"
            v-model="value.cityOfBirth"
            :errors="errors.cityOfBirth"
        />
        <input-simple-select
            v-if="$store.getters.applicationSettings"
            :options="$store.getters.applicationSettings.model.autumnOfColorWork.education"
            class="col-span-4"
            label="Ausbildung"
            v-model="value.education"
            :errors="errors.education"
        />
        <input-text
            class="col-span-4"
            label="Job"
            v-model="value.job"
            :errors="errors.job"
        />

        <label class="col-span-12">
            Potrait
        </label>

        <div class="col-span-12 -mb-4">
            <div
                v-if="value.personPortraitAttachment"
                class="grid grid-flow-col items-center justify-between border border-[#cccccc] border-solid p-4 mb-4"
            >
                <div class="italic">
                    {{ value.personPortraitAttachment.name }}
                </div>
                <div>
                    <a
                        @click="$download(value.personPortraitAttachment)"
                        class="underline hover:no-underline"
                    >
                        Download
                    </a>
                </div>
            </div>
        </div>

        <div class="col-span-12">
            <hr>
        </div>

        <div class="col-span-12">
            <label>
                Kein Bild vorhanden, gegenständlichen Werke (Performance, Video, ...)
                <br>
                <input type="checkbox" v-model="value.noUploadWork"/>
            </label>
        </div>

        <label class="col-span-12">
            Werk
        </label>

        <div
            v-if="value.workAttachment"
            class="grid grid-flow-col items-center justify-between border border-[#cccccc] border-solid p-4 mb-4 col-span-12 -mt-4"
        >
            <div class="italic">
                {{ value.workAttachment.name }}
            </div>
            <div>
                <a
                    @click="$download(value.workAttachment)"
                    class="underline hover:no-underline"
                >
                    Download
                </a>
            </div>
        </div>
        <div
            v-else
            class="mb-4 text-warning"
        >
            Kein Bild vom Werk vorhanden
        </div>

        <input-text
            label="Werkbeschreibung"
            v-model="value.descriptionOfWork"
            type="textarea"
            class="col-span-9"
            :errors="errors.descriptionOfWork"
        />

        <div class="col-span-3">
            <label>
                Materielle Arbeit
                <br>
                <input type="checkbox" v-model="value.materialWork"/>
            </label>
        </div>

        <template v-if="value.materialWork">
            <input-text
                class="col-span-12"
                label="Werktitel"
                v-model="value.titleOfWork"
                :errors="errors.titleOfWork"
            />
            <input-text
                class="col-span-4"
                label="Art der Arbeit"
                v-model="value.typeOfWork"
                :errors="errors.typeOfWork"
            />
            <input-text
                type="number"
                class="col-span-2"
                label="Höhe des Werkes in cm"
                v-model.number="value.sizeOfWorkHeight"
                :errors="errors.sizeOfWorkHeight"
            />

            <input-text
                type="number"
                class="col-span-2"
                label="Breite des Werkes in cm"
                v-model.number="value.sizeOfWorkWidth"
                :errors="errors.sizeOfWorkWidth"
            />

            <input-simple-select
                v-if="$store.getters.applicationSettings"
                :options="$store.getters.applicationSettings.model.autumnOfColorWork.sellingType"
                class="col-span-3"
                label="Verkauf"
                v-model="value.sellingType"
                :errors="errors.sellingType"
            />

            <input-currency
                v-if="value.sellingType === 'forSale'"
                label="Preis"
                class="col-span-3"
                v-model.number="value.price"
                :errors="errors.price"
            />
        </template>

        <template v-else>
            <input-text
                class="col-span-6"
                type="textarea"
                label="Alternative Beschreibung der Arbeit"
                v-model="value.alternativeDescriptionOfWork"
                :errors="errors.alternativeDescriptionOfWork"
            />
            <input-text
                class="col-span-6"
                type="textarea"
                label="Alternative Beschreibung der Arbeit"
                v-model="value.transferNonMaterialWork"
                :errors="errors.transferNonMaterialWork"
            />
            <input-text
                class="col-span-6"
                type="textarea"
                label="Dauer der Performance (z.B. Minutenangabe)"
                v-model="value.durationNonMaterialWork"
                :errors="errors.durationNonMaterialWork"
            />
        </template>
    </div>
</template>

<script>
    import value from "../../../mixins/value";
    import InputCurrency from "@/components/form/InputCurrency.vue";

    export default {
        components: {InputCurrency},
        mixins: [value]
    }
</script>
