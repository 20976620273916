<template>
    <c-modal
        headline="Notizen / Kommentare"
        ref="notes"
    >
        <div class="p-4 grid gap-4">
            <note-list :notes="notes"/>

            <note-form
                v-if="showCreate"
                :resourceIri="iri"
                @update="updateNote"
            />
        </div>
    </c-modal>
</template>

<script>
    import NoteList from "@/data/Note/List.vue";
    import NoteForm from "@/data/Note/Form.vue";

    export default {
        components: {NoteForm, NoteList},
        data() {
            return {
                iri: null,
                notes: [],
            }
        },
        props: {
            showCreate: {
                default: () => true
            }
        },
        methods: {
            updateNote(note) {
                this.notes.unshift(note);
            },
            open(iri) {
                this.iri = iri;
                this.$refs.notes.open();
            }
        },
        watch: {
            iri: async function (newVal) {
                this.notes = [];
                this.notes = await this.$getAllFromApi('notes', {resourceIri: newVal});
            }
        }
    }
</script>
