<template>
    <div>
        <content-header headline="Kurs-Termine">
            <a
                    v-if="$store.getters.hasLoggedInUserCredibility('ROLE_EDUCATION_MANAGEMENT')"
                    class="button"
                    @click="$refs.dataTable.createData({})"
            >
                Kurs hinzufügen
            </a>
        </content-header>
        <content-main>
            <course-dates-table ref="dataTable"/>
        </content-main>
    </div>
</template>

<script>
    import DataTable from "../../components/DataTable";
    import CourseDatesTable from "../../data/CourseDatesTable";

    export default {
        components: {
            CourseDatesTable,
            DataTable
        },
        computed: {
            filters() {
                let _this = this;
                let filter = [
                    {
                        type: 'text',
                        model: 'course.code',
                        label: 'Kurs-Kürzel'
                    },
                    {
                        type: 'text',
                        entity: 'location',
                        model: 'course.location.name',
                        label: 'Standort',
                    },
                    {
                        type: 'text',
                        model: 'course.education.name',
                        label: 'Ausbildung / Seminar'
                    }
                ];

                return filter;
            }
        },
        data() {
            return {
                columns: {
                    date: {
                        text: 'Datum',
                        order: true
                    },
                    course: {
                        text: 'Kurs',
                        order: true
                    },
                    education: {
                        text: 'Ausbildung / Seminar'
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    },
                }
            }
        }
    }
</script>
