import Vue from 'vue';
import resource from 'vue-resource';

Vue.use(resource);

export default {
    clearAuth() {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
    },
    getTokenHeader() {
        return 'Bearer ' + localStorage.getItem('token');
    },
    setAuth(token) {
        localStorage.setItem("token", token);
    },
    getToken() {
        return localStorage.getItem('token');
    },
    setRefreshToken(refresh_token) {
        localStorage.setItem('refresh_token', refresh_token);
    },
    getRefreshToken() {
        return localStorage.getItem('refresh_token');
    },
    refresh() {
        return Vue.http.post('token/refresh', {refresh_token: this.getRefreshToken()});
    },
    checkTokenAuth() {
        let jwt = localStorage.getItem('token');

        if (typeof jwt === 'string' && jwt.length > 0) {
            return true;
        }
        return false;
    }
}
