<template>
    <data-table
        endpoint="event_course_date_applications"
        :columns="columns"
        :filters="filters"
        :filter-layout="filterLayout"
        :fixed-query="fixedQuery"
        :filter-large="true"
        :add-query-to-url="true"
        ref="dataTable"
        updateRevealSize="large"
    >
        <template slot="dataRow" slot-scope="{singleData: eventCourseDateApplication}">
            <td>

                {{ eventCourseDateApplication.eventCourseDate.code }}
                <br>
                {{ eventCourseDateApplication.eventCourseDate.date|formatDate('L') }}
            </td>
            <td>
                        <span
                            v-if="eventCourseDateApplication.status === 'acceptIntern'"
                            class="bg-green-500 px-4 py-1 rounded-full text-white"
                        >
                            Durch campus bestätigt
                        </span>
                <span
                    v-else-if="eventCourseDateApplication.status === 'acceptDocent'"
                    class="bg-green-500 px-4 py-1 rounded-full text-white"
                >
                            Lehrauftrag beidseitig bestätigt
                        </span>
                <template v-else>
                    {{
                        $dataTranslate(eventCourseDateApplication.status, 'model.eventCourseDateApplication.status')
                    }}
                </template>
            </td>
            <td>
                <router-link :to="'/erp/docents/' + eventCourseDateApplication.docent.id">
                    {{ eventCourseDateApplication.docent.displayName }}
                    <br>
                    {{ eventCourseDateApplication.docent.homeLocationName }}
                </router-link>
            </td>
            <td>
                {{ eventCourseDateApplication.lastEditedAt|formatDate('LLL') }}
            </td>
            <td>
                <a
                    @click="revert(eventCourseDateApplication)"
                    class="button bg-alert hover:bg-alert-600 small mb-0"
                    v-if="eventCourseDateApplication.status === 'acceptIntern' || eventCourseDateApplication.status === 'acceptDocent'"
                >
                    Zurückziehen
                </a>
                <a
                    @click="accept(eventCourseDateApplication)"
                    class="button small mb-0"
                    v-if="eventCourseDateApplication.status === 'pending'"
                >
                    Dozent auswählen
                </a>
            </td>
        </template>
    </data-table>
</template>


<script>
    import DataTable from "@/components/DataTable.vue";
    import UpdateForm from "@/data/Docent/UpdateForm.vue";
    import Util from "../../plugins/util";

    export default {
        components: {
            UpdateForm,
            DataTable
        },
        props:{
            fixedQuery: {
                default: () => {}
            }
        },
        methods: {
            accept(eventCourseDateApplication) {
                let _this = this;
                this.$dialog
                    .confirm('Soll der Lehraufrag an diesen Dozent wirklich vergeben werden?', {
                        okText: 'Ja',
                        cancelText: 'Abbrechen',
                    })
                    .then(function (dialog) {
                        _this.$http.put(
                            eventCourseDateApplication['@id'].substring(5),
                            {
                                status: 'acceptIntern'
                            }
                        ).then((response) => {
                            _this.$refs.dataTable.reload();
                        })
                    })
                    .catch(function () {
                    });
            },
            revert(eventCourseDateApplication) {
                let _this = this;
                this.$dialog
                    .confirm('Soll der Lehraufrag wirklich entzogen werden?', {
                        okText: 'Ja',
                        cancelText: 'Abbrechen',
                    })
                    .then(function (dialog) {
                        _this.$http.put(
                            eventCourseDateApplication['@id'].substring(5),
                            {
                                status: eventCourseDateApplication.status === 'acceptIntern' ? 'pending' : 'hardDecline',
                            }
                        ).then((response) => {
                            _this.$refs.dataTable.reload();
                        })
                    })
                    .catch(function () {
                    });

            }
        },
        computed: {
            filters() {
                return [
                    {
                        type: 'entity',
                        model: 'eventCourseDate',
                        label: 'Kurs-Termin',
                        entity: 'eventCourseDate'
                    },
                    {
                        type: 'dateRange',
                        model: 'eventCourseDate.date',
                        label: 'Datum',
                    },
                    {
                        type: 'entity',
                        entity: 'location',
                        model: 'eventCourseDate.course.location',
                        label: 'Standort',
                        callback: (item) => {
                            if(item) {
                                return 'Standort ausgewählt';
                            }
                            return '-';
                        }
                    },
                    {
                        type: 'entity',
                        model: 'eventCourseDate.course',
                        label: 'Kurs',
                        entity: 'course',
                        callback: (item) => {
                            if(item) {
                                return '1 Kurs ausgewählt';
                            }
                            return '-';
                        }
                    }
                ];
            },
            columns() {
                return {
                    'eventCourseDate.date': {
                        text: 'Kurs-Termin',
                        order: true,
                    },
                    status: {
                        text: 'Status',
                        order: true,
                    },
                    'docent.lastName': {
                        text: 'Dozent',
                        order: true,
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    }
                };
            },
        },
        data() {
            return {
                filterLayout: [
                    ['eventCourseDate'],
                    ['eventCourseDate.course'],
                    ['eventCourseDate.date'],
                    ['eventCourseDate.course.location']
                ],
            }
        }
    }
</script>
