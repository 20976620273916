<template>
	<div>
		<content-header headline="Kostenstellen FÖBG">
			<a
					class="button"
					@click="$refs.dataTable.createData({})"
			>
				Kostenstellen hinzufügen
			</a>
		</content-header>
		<content-main>
			<data-table
					endpoint="supported_cost_centers"
					:columns="columns"
					:filters="filters"
					:filter-layout="filterLayout"
					:filter-large="true"
					ref="dataTable"
					updateRevealSize="large"
					:add-query-to-url="addQueryToUrl"
					:state-key="stateKey"
			>
				<template slot="updateForm" slot-scope="{data: supportedCostCenter, errors: errors}">
					<update-form v-model="supportedCostCenter" :errors="errors"/>
				</template>
				<template slot="dataRow" slot-scope="{singleData: supportedCostCenter}">
					<td>
						{{ supportedCostCenter.name }}
					</td>
					<td>
						<template v-if="!supportedCostCenter.reportAllAppearances">
							Nur Abwesenheiten melden
						</template>
						<template v-else>
							Alle Daten melden
						</template>
					</td>
					<td>
						<template v-if="supportedCostCenter.street">{{ supportedCostCenter.street }}</template>
						<br v-if="supportedCostCenter.street && (supportedCostCenter.zip || supportedCostCenter.city)">
						<template v-if="supportedCostCenter.zip">{{ supportedCostCenter.zip }}</template>
						<template v-if="supportedCostCenter.city">{{ supportedCostCenter.city }}</template>
					</td>
					<td>
						<template v-if="supportedCostCenter.email">
							{{ supportedCostCenter.email }}
						</template>
						<template v-if="supportedCostCenter.email && supportedCostCenter.phoneNumber">
							<br>
						</template>
						<template v-if="supportedCostCenter.phoneNumber">
							{{ supportedCostCenter.phoneNumber }}
						</template>
					</td>
					<td>
						{{ supportedCostCenter.lastEditedAt|formatDate('LLL') }}
					</td>
					<td class="text-right">
						<router-link :to="'/crm/supportedCostCenters/' + supportedCostCenter.id" class="action --show"></router-link>
					</td>
				</template>
			</data-table>
		</content-main>
	</div>
</template>


<script>
    import DataTable from "../../../../components/DataTable.vue";
    import UpdateForm from "../../../../data/SupportedCostCenter/UpdateForm.vue";

    export default {
        components: {
            UpdateForm,
            DataTable
        },
        methods: {
            reload() {
                this.$refs.dataTable.reload();
            },
            createData(data) {
                this.$refs.dataTable.createData(data);
            },
        },
        computed: {
            filters() {
                return [
                    {
                        type: 'text',
                        model: 'name',
                        label: 'Name'
                    },
                    {
                        type: 'text',
                        model: 'street',
                        label: 'Straße'
                    },
                    {
                        type: 'text',
                        model: 'city',
                        label: 'Stadt'
                    },
                    {
                        type: 'text',
                        model: 'phoneNumber',
                        label: 'Telefonnummer'
                    },,
                    {
                        type: 'text',
                        model: 'email',
                        label: 'E-Mail'
                    },
                ];
            },
            columns() {
                return {
                    lastName: {
                        text: 'Name',
                        order: true,
                    },
					reportAllAppearances: {
						text: 'Reporting',
						order: true,
					},
                    city: {
                        text: 'Adresse',
                    },
                    email: {
                        text: 'Kontakt',
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    }
                };
            },
        },
        data() {
            return {
                duplicate: null,
                filterLayout: [
                    ['name'],
                    ['street', 'city'],
                    ['email', 'phoneNumber'],
                ],
            }
        }
    }
</script>
