<template>
    <div>
        <div class="row h-largeMargin-bottom">
            <div class="columns small-12">
                <h2>Allgemeines</h2>
            </div>
            <div class="columns large-4">
                <input-date
                    v-model="value.date"
                    label="Datum Erstkontakt"
                    :time="true"
                    :errors="errors.date"
                    :readonly="showOnlyTouchUniqueFields"
                />
            </div>
        </div>

        <div class="row h-largeMargin-bottom">
            <div class="columns small-12">
                <h2>Interessengebiet</h2>
            </div>
            <div class="large-6 columns">
                <input-entity
                    v-if="!showOnlyTouchUniqueFields"
                    type="education"
                    label="Ausbildungswunsch (Primär)"
                    v-model="value.primaryEducation"
                    :errors="errors.primaryEducation"
                    key="education"
                />
            </div>

            <div class="large-6 columns">
                <input-entity
                    v-if="!showOnlyTouchUniqueFields"
                    type="education"
                    label="Ausbildungswunsch (Sekundär)"
                    v-model="value.secondaryEducation"
                    :errors="errors.secondaryEducation"
                    key="education"
                />
            </div>
            <div class="columns small-4">
                <input-simple-select
                    label="Methodische Ausbildung mit Schwerpunkt"
                    v-model="value.methodicalFocusType"
                    :options="educationFocusMethodical"
                    :errors="errors.methodicalFocusType"
                    :readonly="showOnlyTouchUniqueFields"
                />
            </div>
            <div class="columns small-4">
                <input-simple-select
                    label="Ausbildung mit Schwerpunkt"
                    v-model="value.theoreticalFocusType"
                    :options="educationFocusTheoretical"
                    :errors="errors.theoreticalFocusType"
                    :readonly="showOnlyTouchUniqueFields"
                />
            </div>
            <div class="columns small-4">
                <label>
                    Seminarinteresse
                    <br>
                    <input type="checkbox" v-model="value.seminarInterest"/>
                </label>
            </div>

            <template v-if="!showOnlyTouchUniqueFields">
                <div class="large-4 columns">
                    <quarter-select v-model="value.possibleStart" label="Möglicher Start"/>
                </div>
                <div class="large-4 columns">
                    <input-entity
                        type="location"
                        label="Wunschstandort"
                        v-model="value.location"
                        :errors="errors.location"
                        key="location"
                    />
                </div>
                <div class="large-4 columns">
                    <input-location-type
                        v-model="value.locationType"
                        :errors="errors.locationType"
                        label="Wunschausbildungsart"
                    />
                </div>
                <div class="large-12 columns">
                    <hr>
                </div>
            </template>
        </div>

        <template v-if="!showOnlyTouchUniqueFields">
            <div class="h-largeMargin-bottom">
                <h2>Aktuelle Situation</h2>
                <current-situation v-model="value" :errors="errors" :show-payment="false"/>
            </div>
            <div>
                <h2>Informationen</h2>
                <marketing v-model="value" :errors="errors"/>
                <quick-actions-and-invitation v-model="value" :errors="errors"/>
                <follow-up
                    v-model="value"
                    :errors="errors"
                    :location-type="true"
                    :consultation-enabled="true"
                    :note-visible="false"
                />
            </div>
        </template>
    </div>
</template>

<script>
    import value from "../../../mixins/value";
    import InputRte from "../../components/form/InputRte";
    import CurrentSituation from "./parts/CurrentSituation";
    import Marketing from "./parts/Marketing";
    import QuickActionsAndInvitation from "./parts/QuickActionsAndInvitation";
    import FollowUp from "./parts/FollowUp";
    import QuarterSelect from "../../components/form/QuarterSelect";
    import EventLocationTypeSelect from "../../components/form/EventLocationTypeSelect";
    import InputLocationType from "../../components/form/InputLocationType";

    export default {
        components: {
            InputLocationType,
            EventLocationTypeSelect,
            QuarterSelect, FollowUp, QuickActionsAndInvitation, Marketing, CurrentSituation, InputRte
        },
        mixins: [
            value
        ],
        props: {
            showOnlyTouchUniqueFields: {
                type: Boolean,
                default: () => false
            }
        },
        computed: {
            educationFocusMethodical: function () {
                return this.$store.getters.applicationSettings.model.educationFocus.methodical;
            },
            educationFocusTheoretical: function () {
                return this.$store.getters.applicationSettings.model.educationFocus.theoretical;
            }
        }
    }
</script>
