<template>
    <div>
        <div class="panel" style="margin-bottom: 1rem;">
            <div class="panel__headline --noBorder">Auswertungsbedingungen</div>
            <div class="row">
                <div class="columns large-6">
                    <input-text
                        label="Name"
                        v-model="value.name"
                        :errors="errors.name"
                    />
                </div>
            </div>

            <div class="callout warning" v-if="value.conditions.items.length === 0">
                Es sollte mindestens eine Bedingung gepflegt werden.
            </div>

            <div
                style="margin-bottom: 1rem;"
                v-for="reportingRequestRow, i in value.conditions.items"
                :key="i"
            >
                <condition-builder
                    v-model="value.conditions.items[i]"
                    v-on:delete="deleteCondition(i)"
                />

                <div style="width: 100px; margin: 0 auto;"
                     v-if="i < (value.conditions.items.length - 1)"
                >
                    <input-simple-select
                        v-model="value.conditions.conditionConnection"
                        :required="true"
                        label="Verknüpfung"
                        :options="{'and': 'Und', 'or': 'Oder'}"
                    />
                </div>
            </div>

            <div class="text-center">
                <a
                    class="button thirdly"
                    @click="addCondition"
                >
                    Bedingung hinzufügen
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import value from "../../../mixins/value";
    import ConditionBuilder from "./ConditionBuilder";

    export default {
        components: {ConditionBuilder},
        mixins: [value],
        data() {
            return {}
        },
        mounted() {
        },
        methods: {
            deleteCondition(i) {
                console.log(i);
                this.value.conditions.items.splice(i, 1);
            },
            addCondition() {
                this.value.conditions.items.push({});
            }
        }
    }
</script>
