<template>
	<div>
		<content-header headline="Dozenten">
			<a
					class="button"
					@click="$refs.dataTable.createData({})"
			>
				Dozent hinzufügen
			</a>
		</content-header>
		<content-main>
			<data-table
					endpoint="docents"
					:columns="columns"
					:filters="filters"
					:filter-layout="filterLayout"
					:filter-large="true"
					ref="dataTable"
					updateRevealSize="large"
			>
				<template slot="updateForm" slot-scope="{data: docent, errors: errors}">
					<update-form v-model="docent" :errors="errors"/>
				</template>
				<template slot="dataRow" slot-scope="{singleData: docent}">
					<td>
						{{ docent.displayName }}
					</td>
					<td>
						{{ $dataTranslate(docent.status, 'model.docent.status') }}
					</td>
					<td>
						<template v-if="docent.email">
							{{ docent.email }}
						</template>
						<template v-if="docent.email && docent.phoneNumber">
							<br>
						</template>
						<template v-if="docent.phoneNumber">
							{{ docent.phoneNumber }}
						</template>
					</td>
                    <td>
                        <template v-if="docent.homeLocation">
                            {{ docent.homeLocation.name }}
                        </template>
                    </td>
					<td>
						{{ docent.lastEditedAt|formatDate('LLL') }}
					</td>
					<td class="text-right">
						<router-link :to="'/erp/docents/' + docent.id" class="action --show"></router-link>
					</td>
				</template>
			</data-table>
		</content-main>
	</div>
</template>


<script>
    import DataTable from "@/components/DataTable.vue";
    import UpdateForm from "@/data/Docent/UpdateForm.vue";

    export default {
        components: {
            UpdateForm,
            DataTable
        },
        methods: {
            reload() {
                this.$refs.dataTable.reload();
            },
            createData(data) {
                this.$refs.dataTable.createData(data);
            },
        },
        computed: {
            filters() {
                return [
                    {
                        type: 'text',
                        model: 'lastName',
                        label: 'Nachname'
                    },
					{
						type: 'text',
						model: 'firstName',
						label: 'Vorname'
					},
                    {
                        type: 'text',
                        model: 'phoneNumber',
                        label: 'Telefonnummer'
                    },,
                    {
                        type: 'text',
                        model: 'email',
                        label: 'E-Mail'
                    },
                ];
            },
            columns() {
                return {
                    lastName: {
                        text: 'Name',
                        order: true,
                    },
					status: {
						text: 'Status',
						order: false,
					},
                    email: {
                        text: 'Kontakt',
                    },
                    homeLocation: {
                        text: 'Standort (Heimat)',
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    }
                };
            },
        },
        data() {
            return {
                filterLayout: [
                    ['firstName', 'lastName'],
                    ['email', 'phoneNumber'],
                ],
            }
        }
    }
</script>
