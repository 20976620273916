<template>
	<div>
		<data-table
				endpoint="supported_cost_center_contact_people"
				:columns="columns"
				:filters="filters"
				:filter-layout="filterLayout"
				:filter-large="true"
				ref="dataTable"
				updateRevealSize="large"
				:add-query-to-url="addQueryToUrl"
				:state-key="stateKey"
				:fixed-query="{supportedCostCenter: supportedCostCenter['@id']}"
		>
			<template slot="updateForm" slot-scope="{data: supportedCostCenterContactPerson, errors: errors}">
				<update-form v-model="supportedCostCenterContactPerson" :errors="errors"/>
			</template>

			<template slot="nextToPagination">
				<div class="columns shrink">
					<a
							class="button"
							@click="$refs.dataTable.createData({supportedCostCenter: supportedCostCenter['@id']})"
					>
						Kontaktperson hinzufügen
					</a>
				</div>
			</template>

			<template slot="dataRow" slot-scope="{singleData: supportedCostCenterContactPerson}">
				<td>
					<template v-if="supportedCostCenterContactPerson.gender">
						<template v-if="supportedCostCenterContactPerson.gender === 'f'">Frau</template>
						<template v-if="supportedCostCenterContactPerson.gender === 'm'">Herr</template>
					</template>
					{{ supportedCostCenterContactPerson.lastName }}
					<template v-if="supportedCostCenterContactPerson.firstName">
						<br>
						{{ supportedCostCenterContactPerson.firstName }}
					</template>
				</td>
				<td>
					{{ supportedCostCenterContactPerson.email }}
					<br v-if="supportedCostCenterContactPerson.email && supportedCostCenterContactPerson.phoneNumber">
					{{ supportedCostCenterContactPerson.phoneNumber }}
				</td>
				<td>
					{{supportedCostCenterContactPerson.lastEditedAt|formatDate('LLL')}}
					<br>
					{{supportedCostCenterContactPerson.lastEditedByDisplayName}}
				</td>
				<td class="text-right">
					<a
						@click="$refs.dataTable.editData(supportedCostCenterContactPerson)"
						class="action --edit"
					/>
				</td>
			</template>
		</data-table>
	</div>
</template>


<script>
    import DataTable from "../../components/DataTable.vue";
    import UpdateForm from "../../data/SupportedCostCenterContactPerson/UpdateForm.vue";

    export default {
        components: {
            UpdateForm,
            DataTable
        },
        props: {
            disableFilter: {
                type: Array,
                default: () => []
            },
            addQueryToUrl: {
                type: Boolean,
                default: () => false
            },
            stateKey: {
                type: String | null,
                default: () => null,
            },
            supportedCostCenter: {
                required: true,
            }
        },
        methods: {
            reload() {
                this.$refs.dataTable.reload();
            },
            createData(data) {
                this.$refs.dataTable.createData(data);
            },
        },
        computed: {
            filters() {
                return [
                    {
                        type: 'text',
                        model: 'firstName',
                        label: 'Vorname'
                    },
                    {
                        type: 'text',
                        model: 'lastName',
                        label: 'Nachname'
                    },
                    {
                        type: 'text',
                        model: 'phoneNumber',
                        label: 'Telefonnummer'
                    },
                    {
                        type: 'text',
                        model: 'email',
                        label: 'E-Mail'
                    },
                ];
            },
            columns() {
                return {
                    lastName: {
                        text: 'Name',
                        order: true,
                    },
                    email: {
                        text: 'Kontakt',
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    }
                };
            },
        },
        data() {
            return {
                duplicate: null,
                filterLayout: [
                    ['firstName', 'lastName'],
                    ['email', 'phoneNumber'],
                ],
            }
        }
    }
</script>
