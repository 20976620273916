<template>
	<div class="grid gap-4">
		<ul class="list-none m-0 p-0 text-left">
			<li
					class="inline-block mr-4 mb-4 uppercase text-xl py-1 border-0 border-b-2  border-solid font-bold text-brand"
					v-for="tab, index in tabs"
					:key="tab.title"
					:class="{
						'border-brand': index === selectedIndex,
						'border-transparent cursor-pointer hover:border-brand': index !== selectedIndex
					}"
					@click="selectTab(index)"
			>
				{{ tab.title }}
			</li>
		</ul>
		<slot></slot>
	</div>
</template>

<script>
    export default {
        data() {
            return {
                selectedIndex: 0, // the index of the selected tab,
                tabs: []         // all of the tabs
            }
        },
        created() {
            this.tabs = this.$children;
        },
		mounted () {
			this.selectTab(0)
		},
		methods: {
			selectTab (i) {
				this.selectedIndex = i;
				this.tabs.forEach((tab, index) => {
					tab.isActive = (index === i);
				});
			}
		}
    }
</script>
