<template>
	<div>
		<content-header headline="Mahnwesen"/>
		<content-main>
			<data-table
					endpoint="reminders"
					:columns="columns"
					:filter-large="true"
					ref="dataTable"
					updateRevealSize="large"
					:disable-pagination="true"
					:filters="filters"
			>
				<template slot="dataRow" slot-scope="{singleData: reminder}">
					<td>
						<router-link :to="'/persons/' + reminder.person.id">
							{{ reminder.invoiceNumber }}
						</router-link>
					</td>
					<td>
						<template v-if="reminder.supported">Gefördert</template>
						<template v-if="!reminder.supported">SZ</template>
					</td>
					<td>
						{{ reminder.touchBooking.date|formatDate('LL') }}
					</td>
					<td style="text-align: right;">
						{{ reminder.expectedEarnings|price }}
					</td>
					<td style="text-align: right;">
						{{ reminder.earnings|price }}
					</td>
					<td style="text-align: right;">
						{{ reminder.delta * -1|price }}
					</td>
					<td>
						<template v-if="reminder.touchBooking.warningStepOne">
							{{ reminder.touchBooking.warningStepOne|formatDate('LL') }}
						</template>
					</td>
					<td>
						<template v-if="reminder.touchBooking.warningStepTwo">
							{{ reminder.touchBooking.warningStepTwo|formatDate('LL') }}
						</template>
					</td>
					<td>
						{{ reminder.touchBooking.warningStepThree }}
					</td>
					<td>
						<router-link :to="'/persons/' + reminder.person.id" class="action --show"/>
						<router-link :to="'/finance/reminder?invoiceNumber=' + reminder.invoiceNumber"
									 class="action --plus"/>
					</td>
				</template>
			</data-table>
		</content-main>
	</div>
</template>

<script>
    import DataTable from "../../../components/DataTable";

    export default {
        components: {DataTable},
		computed: {
			filters() {
				let _this = this;

				return [
					{
						type: 'text',
						model: 'invoiceNumber',
						label: 'Rechnungsnummer'
					},
					{
						type: 'dateRange',
						model: 'invoiceDate',
						label: 'Rechnungsdatum'
					},
					{
						type: 'select',
						model: 'bookingType',
						label: 'Buchungstyp',
						options: this.$store.getters.applicationSettings.model.touchBooking.bookingType,
						callback: function (value) {
							return _this.$store.getters.applicationSettings.model.touchBooking.bookingType[value];
						}
					},
				]
			}
		},
        data() {
            return {
                columns: {
                    invoiceNumber: {
                        text: 'Rechnungsnummer',
                        order: true,
                    },
                    supported: {
                        text: 'Gefördert / SZ'
                    },
                    invoiceDate: {
                        order: true,
                        text: 'Rechnungsdatum'
                    },
                    expectedEarnings: {
                        text: 'Erwartete Einnahmen (Zahlungsziel 4 Wochen)',
                    },
                    earnings: {
                        text: 'Einnahmen',
                    },
                    delta: {
                        text: 'Delta',
                        order: true
                    },
                    warningStepOne: {
                        text: 'Mahnung 1',
                    },
                    warningStepTwo: {
                        text: 'Mahnung 2',
                    },
                    warningStepThree: {
                        text: 'Mahnung 3',
                    }
                }
            }
        }
    }
</script>
