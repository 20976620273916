<template>
    <div>
        <content-header headline="Stundenpläne"/>
        <content-main>
            <div class="panel">
                <div class="panel__headline">
                    Einstellungen
                </div>
                <form v-on:submit.prevent="loadTimetableSuggest">
                    <div class="row">
                        <div class="large-5 columns">
                            <input-text
                                label="Rechnungsnummer"
                                v-model="timetableSuggest.touchBooking"
                                :errors="errors.touchBooking"
                            />
                        </div>
                        <div class="large-5 columns">
                            <input-date
                                label="Maßnahmenbeginn"
                                v-model="timetableSuggest.start"
                                :errors="errors.start"
                            />
                        </div>
                        <div class="large-2 columns">
                            <label>
                                Als Anhang speichern
                                <br>
                                <input type="checkbox" v-model="timetableSuggest.createAttachment">
                            </label>
                        </div>
                    </div>

                    <div class="row">
                        <div class="small-12 columns">
                            <input-weekdays
                                label="Präsenszeiten"
                                v-model="timetableSuggest.presenceDays"
                            />
                        </div>
                    </div>

                    <div class="row">
                        <div class="large-4 columns">
                            <input-text
                                type="number"
                                label="Pflicht-UE"
                                v-model.number="timetableSuggest.presenceUnits"
                            />
                        </div>
                        <div class="large-4 columns">
                            <input-text
                                type="number"
                                label="Praktikums-UE"
                                v-model.number="timetableSuggest.intershipUnits"
                            />
                        </div>
                        <div class="large-4 columns">
                            <input-text
                                type="number"
                                label="Studienarbeit-UE"
                                v-model.number="timetableSuggest.thesisUnits"
                            />
                        </div>
                    </div>

                    <div class="row">
                        <div class="small-12 columns text-center">
                            <button
                                type="submit"
                                class="button"
                            >
                                Stundenplan generieren
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="panel">
                <div class="panel__headline">
                    Stundenplan
                </div>

                <table>
                    <thead>
                    <tr>
                        <th>Tag</th>
                        <th>Wochentag</th>
                        <th>Typ</th>
                        <th>Start</th>
                        <th>Ende</th>
                        <th>UE</th>
                        <th>kum. UE</th>
                        <th>kum. Praktikum UE</th>
                        <th>kum. Studienarbeit UE</th>
                        <th>Bemerkung</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="event in timetableSuggest.events">
                        <td>{{ event.date|formatDate('LL') }}</td>
                        <td>{{ event.date|formatDate('dddd') }}</td>
                        <td>{{ event.name }}</td>
                        <td>{{ event.start }}</td>
                        <td>{{ event.end }}</td>
                        <td>{{ event.ue }}</td>
                        <td>{{ event.sumUe }}</td>
                        <td>{{ event.sumUeThesis }}</td>
                        <td>{{ event.sumUeIntership }}</td>
                        <td>{{ event.description }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </content-main>
    </div>
</template>

<script>
    import util from "../../../../plugins/util";
    import InputWeekdays from "../../../components/form/InputWeekdays";

    export default {
        components: {InputWeekdays},
        mounted() {
            if (this.$route.query.hasOwnProperty('invoiceNumber')) {
                this.timetableSuggest.touchBooking = this.$route.query.invoiceNumber;
            }
        },
        data() {
            return {
                timetableSuggest: {
                    touchBooking: '',
                    presenceDays: [],
                },
                errors: {},
            }
        },
        methods: {
            loadTimetableSuggest() {
                this.$sendToApi('timetable_suggests', this.timetableSuggest, (entity, status, data) => {
                    if (status > 299) {
                        this.errors = util.mapConstraints(data);
                    } else {
                        this.timetableSuggest = Object.assign(entity, {
                            touchBooking: this.timetableSuggest.touchBooking,
                            start: this.timetableSuggest.start,
                        });
                        delete this.timetableSuggest['@id'];
                        delete this.timetableSuggest['id'];
                    }
                });
            }
        }
    }
</script>
