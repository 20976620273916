<template>
	<div class="grid grid-cols-2 gap-8"
		 :class="{
						'grid-cols-2': ['AV', 'E'].includes(value.status),
						'grid-cols-1': !['AV', 'E'].includes(value.status)
					}"
	>
		<input-simple-select
				label="Status"
				:options="statusOptions"
				v-model="value.status"
				:errors="errors.status"
		></input-simple-select>
		<div
				v-if="['AV', 'E'].includes(value.status)"
		>
			<input-text
					v-if="value.status === 'AV'"
					type="number"
					label="Verspätung in h"
					v-model.number="value.delay"
					:step="0.25"
					:errors="errors.delay"
			/>

			<input-simple-select
					v-if="value.status === 'E'"
					label="Grund"
					v-model="value.reason"
					:options="reasonOptions"
					:errors="errors.reason"
			/>
		</div>

		<input-text
				class="col-span-2"
				type="textarea"
				v-model="value.comment"
				label="Kommentar"
		>
		</input-text>
	</div>
</template>
<script>
    import value from "../../../mixins/value";

    export default {
        mixins: [value],
        computed: {
            statusOptions: function () {
                return this.$store.getters.applicationSettings.model.appearance.status;
            },
            reasonOptions: function () {
                let options = {};
                if (!this.$store.getters.applicationSettings) {
                    return [];
                }
                Object.keys(this.$store.getters.applicationSettings.model.appearance.reason).forEach((item) => {
                    options[item] = this.$store.getters.applicationSettings.model.appearance.reason[item];
                });

                return options;
            },
        }
    }
</script>
