export default {
    data() {
        return {
            persistedEntity: null,
            editedEntity: null,
            errors: {},
        }
    },
    async mounted() {
        if (this.$route.params.id === 'create') {
            this.editedEntity = this.createEmptyEntity();
            this.editedEntity['@id'] = this.getEndpoint();
        } else {
            await this.loadData();
        }
        this.afterMounted();
    },
    watch: {
        '$route': async function (newVal) {
            await this.loadData();
        },
    },
    methods: {
        afterMounted() {
        },
        afterError(errors) {
        },
        afterUpdate(data) {
        },
        afterCreate(data) {
            this.$router.push(this.$route.path.replace('create', data.id));
        },
        getEndpoint() {
            throw new Error('Function getEndpoint has to be overwrite');
        },
        async loadData() {
            this.$getFromApi(this.getEndpoint(), {id: this.$route.params.id}, (entity) => {
                this.$set(this, 'editedEntity', this.convertLoadedEntity(entity));
                this.$set(this, 'persistedEntity', Object.assign({}, this.editedEntity));
            });
        },
        createEmptyEntity() {
            return {};
        },
        convertLoadedEntity(editedEntity) {
            return editedEntity;
        },
        convertEntityBeforeSubmit(editedEntity) {
            return editedEntity;
        },
        submitData() {
            this.errors = {};
            this.$sendToApi(this.getEndpoint(), this.convertEntityBeforeSubmit(this.editedEntity), (data, status, errors) => {
                if (status > 299) {
                    this.afterError(errors);
                    this.errors = errors;
                } else {
                    if (this.$route.params.id === 'create') {
                        this.afterCreate(data);
                    } else {
                        this.editedEntity = this.convertLoadedEntity(data);
                        this.$set(this, 'persistedEntity', Object.assign({}, this.editedEntity));
                        this.afterUpdate(data);
                    }
                }
            });
        }
    }
}
