<template>
    <div>
        <content-header headline="Kataloganfragen-Export"/>

        <content-main>
            <div class="row">
                <div class="columns small-12">
                    <div class="panel">
                        <div class="panel__headline">Export analoger Kataloganfragen (nicht versendet)</div>

                        <div class="callout info" v-if="message">
                            {{message}}
                        </div>

                        <div class="row">
                            <div class="columns ">
                                <label>
                                    Als versendet markieren
                                    <br>
                                    <input type="checkbox" v-model="request.markAsSend">
                                </label>
                            </div>
                            <div class="columns">
                                <label>
                                    Trennzeichen
                                    <select v-model="request.columnDelimiter">
                                        <option :value="null"></option>
                                        <option value=",">Komma</option>
                                        <option value=";">Semikolon</option>
                                        <option value="|">Pipe</option>
                                    </select>
                                </label>
                            </div>
                            <div class="columns shrink">
                                <a class="button thirdly" style="margin-top: 1.5rem;" @click="showExportInformation()">Zeige Export-Informationen</a>
                                <button type="submit" class="button" style="margin-top: 1.5rem;" @click="exportCsv()">CSV-Download</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </content-main>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                request: {},
                message: null,
            }
        },
        mounted() {
            this.showExportInformation();
        },
        methods: {
            exportCsv() {
                this.message = null;
                this.$http.post('exports/catalogueRequests', this.request).then(response => {
                    window.location = response.body.url;
                });
            },
            showExportInformation() {
                this.$http.post('exports/catalogueRequests', {onlyExportInformation: true}).then(response => {
                    this.message = 'Es werden ' + response.data.lines + ' Datensätze exportiert';
                });
            }
        }
    }
</script>
