<template>
    <div>
        <content-header headline="Dozentenkosten"/>
        <content-main>
            <div class="panel">
                <div class="panel__content">
                    <div class="row">
                        <div class="large-4 columns">
                            <label>
                                <InputEntity
                                    label="Standort"
                                    v-model="filter.location"
                                    type="location"
                                />
                            </label>
                        </div>
                        <div class="large-4 columns">
                            <label>
                                <InputSimpleSelect
                                    label="Jahr"
                                    :options="yearOptions"
                                    v-model.number="filter.year"
                                />
                            </label>
                        </div>
                        <div class="large-4 columns">
                            <label>
                                Kosten UE
                                <InputText
                                    v-model.number="filter.costPerUe"
                                    type="number"
                                />
                            </label>
                        </div>
                    </div>
                </div>

                <hr>

                <table>
                    <thead>
                    <tr>
                        <th rowspan="2">Kurs</th>
                        <th>Teilnehmer</th>
                        <th>{{ filter.year - 1 }}</th>
                        <th>{{ filter.year }}</th>
                        <th>{{ filter.year + 1 }}</th>
                    </tr>
                    <tr>
                        <th>Kosten:</th>
                        <th>{{getCostsByYear(filter.year - 1)}} Euro</th>
                        <th>{{getCostsByYear(filter.year)}} Euro</th>
                        <th>{{getCostsByYear(filter.year + 1)}} Euro</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="result in result.result">
                        <td>
                            {{ result.code }}
                            <template
                                v-if="result.blockMismatches">
                                <br>
                                <p
                                    :style="{'color': result.numberOfLessons > 0 ? 'red' : 'orange'}"
                                    style="margin-bottom: 0;"
                                >
                                    <template v-if="result.numberOfLessons > 0">
                                        {{result.ue}} UEs laut Kursterminen
                                        <br>
                                        {{result.numberOfLessons}} UEs laut Ausbildungsinformationen
                                    </template>
                                    <template v-else>
                                        {{result.ue}} UEs laut Kursterminen
                                        <br>
                                        Keine UEs in Ausbildungsinformationen
                                    </template>
                                </p>
                            </template>
                        </td>
                        <td>{{ result.touchBookingsCount }}</td>
                        <td>
                            <template v-if="getUesByYearAndBlocks(filter.year - 1, result.blocks) > 0">
                                {{ getUesByYearAndBlocks(filter.year - 1, result.blocks) }} UE
                                <br>
                                {{ getCostsByYearAndBlocks(filter.year - 1, result.blocks) }} Euro
                            </template>
                        </td>
                        <td>
                            <template v-if="getUesByYearAndBlocks(filter.year, result.blocks) > 0">
                                {{ getUesByYearAndBlocks(filter.year, result.blocks) }} UE
                                <br>
                                {{ getCostsByYearAndBlocks(filter.year, result.blocks) }} Euro
                            </template>
                        </td>
                        <td>
                            <template v-if="getUesByYearAndBlocks(filter.year + 1, result.blocks) > 0">
                                {{ getUesByYearAndBlocks(filter.year + 1, result.blocks) }} UE
                                <br>
                                {{ getCostsByYearAndBlocks(filter.year + 1, result.blocks) }} Euro
                            </template>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </content-main>
    </div>
</template>

<script>
    import InputEntity from "../../../components/form/InputEntity";
    import InputSimpleSelect from "../../../components/form/InputSimpleSelect";
    import moment from "moment";
    import StatusLight from "../../../data/Person/StatusLight";
    import InputText from "../../../components/form/InputText";

    export default {
        components: {StatusLight, InputEntity, InputSimpleSelect, InputText},
        data() {
            return {
                result: null,
                filter: {
                    location: '/api/locations/814db3e7-cfe1-46ef-8ab6-0f253abd4ff7',
                    year: (new Date()).getFullYear(),
                    costPerUe: 27.5,
                }
            }
        },
        computed: {
            yearOptions: function () {
                let currentYear = parseInt(moment().format('Y'));
                let years = {};
                for (let i = currentYear - 5; i <= currentYear + 3; i++) {
                    years[parseInt(i)] = parseInt(i);
                }
                return years;
            }
        },
        watch: {
            filter: {
                deep: true,
                handler: function () {
                    this.loadData();
                }
            }
        },
        methods: {
            getCostsByYearAndBlocks(year, blocks) {
                return this.getUesByYearAndBlocks(year, blocks) * this.filter.costPerUe;
            },
            getUesByYearAndBlocks(year, blocks) {
                let ue = 0;
                Object.values(blocks).forEach((block) => {
                    if (parseInt(moment(block.date).format('YYYY')) === parseInt(year)) {
                        ue += block.ue;
                    }
                });
                return ue;
            },
            getCostsByYear(year) {
                let ue = 0;
                Object.values(this.result.result).forEach((item) => {
                    item.blocks.forEach((blockItem) => {
                        if (parseInt(moment(blockItem.date).format('YYYY')) === parseInt(year)) {
                            ue += blockItem.ue;
                        }
                    });
                })

                return ue * this.filter.costPerUe;
            },
            loadData() {
                this.$http.get('reportings/coursePerformance', {params: this.filter}).then(response => {
                    this.result = response.data;
                })
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>
