<template>
    <div v-if="currentCourse.id">
        <appearance-update-modal :fixedCourse="course" ref="appearanceUpdateModal"/>

        <attendance-certification-generator-update-modal
            :course="course"
            ref="attendanceCertificationGeneratorUpdateModal"
        />

        <c-modal ref="modal" headline="Teilnehmerliste">
            <form
                class="py-8 px-4 grid gap-4"
                v-on:submit.prevent="downloadListOfParticipants"
            >
                <input-date
                    label="Start"
                    v-model="listOfParticipants.start"
                />
                <input-date
                    label="Ende"
                    v-model="listOfParticipants.end"
                />
                <div class="text-center">
                    <button type="submit" class="button --small">
                        Teilnehmerliste generieren
                    </button>
                </div>
            </form>
        </c-modal>

        <c-modal
            ref="eventCourseDateInvitationModal"
            headline="Einladung verschicken"
        >
            <form
                v-on:submit.prevent="submitEventCourseDateInvitation"
                class="grid gap-4 p-4 py-12"
            >
                <input-entity
                    v-model="eventCourseDateInvitation.eventCourseDate"
                    type="eventCourseDate"
                    label="Kurs-Termin"
                    :fixed-params="{
                        course: this.course['@id']
                    }"
                />
                <input-entity
                    v-model="eventCourseDateInvitation.person"
                    type="person"
                    label="Person"
                    :fixed-params="{
                        course: this.course.id
                    }"
                />
                <div class="text-center">
                    <button type="submit" class="small button">
                        Einladung verschicken
                    </button>
                </div>
            </form>
        </c-modal>

        <content-header :headline="headline" :subline="education.name">
            <div>
                <select
                    v-model="action"
                >
                    <option
                        :value="null"
                        disabled
                        selected
                    >
                        Aktion auswählen
                    </option>
                    <option
                        value="courseTimetable"
                    >
                        Termindatenblatt
                    </option>
                    <option
                        value="attendanceCertification"
                    >
                        Teilnehmerbescheinigung erstellen
                    </option>
                    <option
                        v-if="tab !== 'appearance'"
                        value="appearance"
                    >
                        Anwesenheiten erfassen
                    </option>
                    <option
                        value="participantList"
                    >
                        Teilnehmer-Liste
                    </option>
                    <option
                        value="eventCourseDateInvitation"
                    >
                        Einladungs-Mail
                    </option>
                </select>
            </div>
        </content-header>

        <content-main>
            <ul class="tabs">
                <li v-for="label, key in tabs">
                    <a @click="tab = key;" :class="{'--active': tab === key}">{{ label }}</a>
                </li>
            </ul>

            <div class="panel" v-if="tab === 'notes'">
                <notes :entity="course" class="mb-4"/>
            </div>

            <div class="panel" v-if="tab === 'report'">
                <report :course="currentCourse"/>
            </div>

            <div class="panel" v-if="tab === 'general'">
                <form v-on:submit.prevent="updateCourse">
                    <div class="row">
                        <div class="large-8 columns --borderRight">
                            <course-form v-model="course" :errors="formErrors"/>
                            <div class="text-right">
                                <button type="submit" class="button">Aktualisieren</button>
                            </div>
                        </div>
                        <div class="large-4 columns">
                            <div class="panel --veryLightGray">
                                <template v-if="course.isPartOf && course.isPartOf.length > 0">
                                    <h4>Ist Modul von:</h4>
                                    <ul>
                                        <li v-for="partOfCourse in course.isPartOf">
                                            <router-link
                                                :to="'/courses/' + partOfCourse.id">
                                                {{ partOfCourse.code }}
                                                <small>
                                                    ({{
                                                        partOfCourse.locationType|dataTrans('model.course.locationType')
                                                    }})
                                                </small>
                                            </router-link>
                                        </li>
                                    </ul>
                                </template>

                                <template v-if="course.contains.length > 0">
                                    <h3 class="h-smallMargin-bottom">Enthält Module</h3>
                                    <ul>
                                        <li v-for="containCourse in course.contains">
                                            <router-link :to="'/courses/' + containCourse.id">
                                                {{ containCourse.code }}
                                                <small>
                                                    ({{
                                                        containCourse.locationType|dataTrans('model.course.locationType')
                                                    }})
                                                </small>
                                            </router-link>
                                        </li>
                                    </ul>
                                </template>

                                <template v-if="course.modules.length > 0">
                                    <h3 class="h-smallMargin-bottom">Frei wählbare Module</h3>
                                    <ul>
                                        <li v-for="module in course.modules">
                                            {{ module.code }}
                                        </li>
                                    </ul>
                                </template>

                                <hr>

                                <h3 class="h-smallMargin-bottom">Allgemeine Daten</h3>
                                <p>
                                    <strong>Erstellt von:</strong> {{ currentCourse.createdByDisplayName }}
                                    <br>
                                    <strong>Erstellt am:</strong> {{ currentCourse.createdAt|formatDate('LLL') }}
                                </p>
                                <p v-if="currentCourse.lastEditedByDisplayName">
                                    <strong>Zuletzt bearbeitet von:</strong>
                                    {{ currentCourse.lastEditedByDisplayName }}
                                    <br>
                                    <strong>Zuletzt bearbeitet am:</strong>
                                    {{ currentCourse.lastEditedAt|formatDate('LLL') }}
                                </p>
                                <h3 class="h-smallMargin-bottom">Termine</h3>
                                <p>
                                    <strong>Aktuelle Teilnehmer:</strong> {{ currentCourse.touchBookingsCount }}<br>
                                    <strong>Termine:</strong> {{ currentCourse.courseDatesCount }}<br>
                                    <strong>Start:</strong> {{ currentCourse.firstDate|formatDate('LL') }}<br>
                                    <strong>Ende:</strong> {{ currentCourse.lastDate|formatDate('LL') }}
                                </p>
                            </div>

                            <div class="text-center">
                                <router-link :to="'/educations/' + education.id">Zur Ausbildung</router-link>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div v-if="tab === 'courseDates'" class="panel">
                <course-dates-table
                    :disable-filter="['course.code', 'course.education.name', 'course.location.name']"
                    :fixed-query="{'course': courseIds}"
                    :add-query-to-url="false"
                    class="h-smallMargin-bottom h-smallMargin-top"
                />
            </div>

            <div v-if="tab === 'touchAppearance'">
                <persons-table
                    :fixed-query="{'course': currentCourse['id']}"
                />
            </div>

            <div v-if="tab === 'attachments'">
                <attachments :entity-id="course['@id']"/>
            </div>
            <div v-if="tab === 'calendar'" class="panel">
                <calendar-view
                    v-if="courseDateEvents.length > 0"
                    :startingDayOfWeek="1"
                    displayPeriodUom="month"
                    :displayPeriodCount="1"
                    :events="courseDateEvents"
                    :showDate="showDate"
                    currentPeriodLabel="Heute"
                    event-top="0.5rem"
                >
                    <template slot="header" slot-scope="t">
                        <div class="row align-middle h-margin-bottom">
                            <div class="columns shrink text-left">
                                <a class="action --prev" @click="setShowDate(t.headerProps.previousPeriod)"></a>
                            </div>
                            <div class="columns text-center">
                                <h2 style="margin-bottom: 0.24rem;">{{ t.headerProps.periodLabel }}</h2>
                                <a @click="setShowDate(course.firstDate)">Zum ersten Termin wechseln</a> -
                                <a @click="setShowDate(t.currentPeriod)">Zum heutigen Datum wechseln</a> -
                                <a @click="setShowDate(course.lastDate)">Zum letzten Termin wechseln</a>
                            </div>
                            <div class="columns shrink text-right">
                                <a class="action --next" @click="setShowDate(t.headerProps.nextPeriod)"></a>
                            </div>
                        </div>
                    </template>
                </calendar-view>
            </div>

            <div v-if="tab === 'appearance'">
                <div class="panel">
                    <matrix :course="currentCourse"/>
                </div>
            </div>
        </content-main>
    </div>
</template>

<script>
    import CourseDatesTable from "../../data/CourseDatesTable";
    import CourseForm from "../../data/CourseForm";
    import {CalendarView, CalendarViewHeader} from "vue-simple-calendar";
    import TouchAppearanceTable from "../../data/Appearance/Table";
    import PersonsTable from "../../data/PersonsTable";
    import AppearanceUpdateModal from "../../data/Appearance/UpdateModal";
    import Touch from "./Touch";
    import Attachments from "../../data/Person/Attachments";
    import AttendanceCertificationGeneratorUpdateModal
        from "../../data/AttendanceCertificationGenerator/UpdateModal.vue";
    import Matrix from "../../data/Appearance/Matrix.vue";
    import Notes from "../../data/Notes.vue";
    import util from "../../../plugins/util";
    import Report from "@/data/Course/Report.vue";

    require("vue-simple-calendar/static/css/default.css");

    export default {
        components: {
            Report,
            Notes,
            Matrix,
            Attachments,
            Touch,
            AppearanceUpdateModal,
            PersonsTable,
            TouchAppearanceTable,
            CourseForm,
            CourseDatesTable,
            CalendarView,
            CalendarViewHeader,
            AttendanceCertificationGeneratorUpdateModal
        },
        data() {
            return {
                action: null,
                eventCourseDateInvitation: {
                    eventCourseDate: null,
                },
                tab: 'general',
                course: {},
                education: {},
                currentCourse: {},
                formErrors: {},
                courseDates: {},
                showDate: new Date(),
                courseDateAdd: {
                    type: 'single',
                    weekDays: []
                },
                listOfParticipants: {
                    start: null,
                    end: null,
                },
                tabs: {
                    general: 'Allgemeine Daten',
                    touchAppearance: 'Teilnehmer',
                    courseDates: 'Termine',
                    appearance: 'Anwesenheit',
                    attachments: 'Anhänge',
                    notes: 'Notizen',
                    report: 'Klassenbuch',
                    calendar: 'Kalender'
                },
                courseDataAddErrors: {},
                weekDays: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So']
            }
        },
        mounted() {
            this.loadCourse();
            this.loadCourseDates();
        },
        methods: {
            submitEventCourseDateInvitation() {
                this.$sendToApi(
                    'event_course_date_invitations',
                    this.eventCourseDateInvitation,
                    (entity, status, data) => {
                        if (status > 299) {
                            alert("Fehler beim Verschicken");
                            this.eventCourseDateInvitation = {};
                        } else {
                            this.eventCourseDateInvitation = {};
                            this.$refs.eventCourseDateInvitationModal.close();
                        }
                    }
                )
            },
            async downloadListOfParticipants() {
                this.$sendToApi(
                    'list_of_participants',
                    this.listOfParticipants,
                    (entity, status, data) => {
                        let csv = entity.people.map(
                            row => [
                                row.courseInvoiceNumber,
                                row.courseInvoiceLocation,
                                this.currentCourse.code,
                                row.lastName || '',
                                row.firstName || '',
                                row.email,
                                row.phoneNumbers,
                                row.street || '',
                                row.zip || '',
                                row.city || '',
                                (row.hasOwnProperty('bookingType') && row.bookingType === 'self_payer') ? 'Ja' : 'Nein',
                                (row.hasOwnProperty('bookingType') && row.bookingType === 'extern_payer') ? 'Ja' : 'Nein'
                            ]);
                        csv.unshift([
                            'Rechnungsnummer',
                            'Rechnungsstandort',
                            'Kurs',
                            'Nachname',
                            'Vorname',
                            'E-Mail',
                            'Telefon',
                            'Straße',
                            'PLZ',
                            'Wohnort',
                            'SZ',
                            'Gefördert'
                        ]);

                        this.$downloadCsv(csv, `Teilnehmer-${this.course.code}.csv`);
                    }
                )
            },
            loadCourse() {
                this.$getFromApi('courses', this.$route.params.id, (entity) => {
                    this.course = entity;
                    this.currentCourse = Object.assign({}, entity);
                    this.showDate = new Date(this.course.firstDate);
                    this.listOfParticipants = {
                        course: entity['@id'],
                        start: this.course.firstDate,
                        end: this.course.firstDate,
                    };
                });
            },
            updateCourse() {
                this.$sendToApi('courses', this.course, (entity, status, errors) => {
                    if (status === 200) {
                        this.course = entity;
                        this.currentCourse = Object.assign({}, entity);
                    } else {
                        this.formErrors = util.mapConstraints(errors);
                    }
                })
            },
            loadCourseDates() {
                this.$http.get('event_course_dates', {
                    params: {
                        course: this.$route.params.id,
                        itemsPerPage: 1000
                    }
                }).then(response => {
                    this.courseDates = response.data['hydra:member'];
                });
            },
            setShowDate(d) {
                console.log(d);
                this.showDate = d;
            },
        },
        computed: {
            headline: function () {
                return this.course.code + ' (' + this.$options.filters.dataTrans(this.course.locationType, 'model.course.locationType') + ')';
            },
            courseIds: function () {
                return this.currentCourse.contains.map((item) => item['@id']).concat([this.currentCourse['@id']]);
            },
            courseDateEvents: function () {
                let events = [];
                if (this.courseDates.length > 0) {
                    this.courseDates.forEach((item) => {
                        item.startDate = item.date;
                        item.endDate = item.date;
                        item.title = item.name;
                        events.unshift(item);
                    });
                }

                return events;
            }
        },
        watch: {
            action: function(newVal, oldVal) {
                switch (newVal) {
                    case 'courseTimetable':
                        this.$store.commit('incrementLoadingIndex');
                        this.$http.post(
                            'course_timetables',
                            {
                                course: this.course['@id']
                            }
                        ).then((res) => {
                            window.location.href = res.data.exportFile.url;
                            this.$store.commit('decrementLoadingIndex');
                        }, () => {
                            this.$store.commit('decrementLoadingIndex');
                        });
                        break;
                    case 'attendanceCertification':
                        this.$refs.attendanceCertificationGeneratorUpdateModal.open(this.course);
                        break;
                    case 'appearance':
                        this.tab = 'appearance';
                        break;
                    case 'participantList':
                        this.$refs.modal.open();
                        break;
                    case 'eventCourseDateInvitation':
                        this.$refs.eventCourseDateInvitationModal.open();
                        break;
                    case 'back':
                        this.$router.back();
                        break;
                }

                this.action = null;
            },
            '$route': function () {
                this.currentCourse = {};
                this.loadCourse();
                this.loadCourseDates();
            },
            'courseDateAdd.type': function (newVal) {
                this.courseDateAdd = {
                    type: newVal,
                    weekDays: []
                };
            },
            course: function (newVal) {
                this.$getFromApi('education', newVal.education, (entity) => {
                    this.education = entity;
                });
            }
        }
    }
</script>
