<template>
    <div>
        <content-header headline="Dokumente Teilnehmenden Plattform">
            <router-link
                to="/tools/customerInformationAttachments/create"
                class="button"
            >
                Erstellen
            </router-link>
        </content-header>
        <content-main>
            <data-table
                endpoint="customer_information_attachments"
                :columns="columns"
                :filters="filters"
                :filter-layout="filterLayout"
                :filter-large="true"
                ref="dataTable"
                updateRevealSize="large"
            >
                <template slot="dataRow" slot-scope="{singleData: customerInformationAttachment}">
                    <td>
                        <template v-if="customerInformationAttachment.name">
                            {{ customerInformationAttachment.name }}
                        </template>
                        <template v-else>
                            <i>Kein Name gepflegt</i>
                        </template>
                        <br>
                        <i>{{ customerInformationAttachment.attachment.name }}</i>
                    </td>
                    <td>
                        {{ customerInformationAttachment.type|dataTrans('model.customerInformationAttachment.type') }}
                    </td>
                    <td>
                        {{ customerInformationAttachment.validity|dataTrans('model.customerInformationAttachment.validity') }}
                    </td>
                    <td>
                        {{ customerInformationAttachment.status|dataTrans('model.customerInformationAttachment.status') }}
                    </td>
                    <td>
                        {{ customerInformationAttachment.educations.map((item) => item.name).join(', ') }}
                    </td>
                    <td>
                        {{customerInformationAttachment.createdByDisplayName}}
                        <br>
                        {{ customerInformationAttachment.createdAt|formatDate('LLL')}}
                    </td>
                    <td class="text-right">
                        <router-link
                            :to="'/tools/customerInformationAttachments/' + customerInformationAttachment.id"
                            class="action --show"
                        ></router-link>
                    </td>
                </template>
            </data-table>
        </content-main>
    </div>
</template>


<script>
    import DataTable from "@/components/DataTable.vue";

    export default {
        components: {
            DataTable
        },
        methods: {
            exportAutumnOfColorWorks() {
                this.$http.post('exports/autumnOfColorWorkExport').then((response) => {
                    window.location.href = response.data.url;
                })
            },
        },
        computed: {
            filters() {
                return [
                    {
                        type: 'text',
                        model: 'name',
                        label: 'Name'
                    },
                    {
                        type: 'select',
                        model: 'status',
                        label: 'Status',
                        options: this.$store.getters.applicationSettings.model.customerInformationAttachment.status,
                        callback: function (newVal) {
                            return 'Status ausgewählt';
                        }
                    },
                    {
                        type: 'select',
                        model: 'type',
                        label: 'Typ',
                        options: this.$store.getters.applicationSettings.model.customerInformationAttachment.type,
                        callback: function () {
                            return 'Typ ausgewählt';
                        }
                    },
                    {
                        type: 'select',
                        model: 'validity',
                        label: 'Validität',
                        options: this.$store.getters.applicationSettings.model.customerInformationAttachment.validity,
                        callback: function () {
                            return 'Validität ausgewählt';
                        }
                    },
                ];
            },
            columns() {
                return {
                    name: {
                        text: 'Datei',
                        order: true
                    },
                    type: {
                        text: 'Typ',
                    },
                    validity: {
                        text: 'Validität',
                        order: true,
                    },
                    status: {
                        text: 'Status',
                    },
                    educations: {
                        text: 'Ausbildungen',
                    },
                    createdAt: {
                        text: 'Eingereicht',
                        order: true,
                    },
                };
            },
        },
        data() {
            return {
                filterLayout: [
                    [
                        'name',
                    ],
                    [
                        'status',
                    ],
                    [
                        'type'
                    ],
                    [
                        'validity'
                    ]
                ],
            }
        }
    }
</script>
