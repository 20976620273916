<template>
    <div>
        <content-header headline="Ausbildungen & Seminare">
            <a
                    v-if="$store.getters.hasLoggedInUserCredibility('ROLE_EDUCATION_MANAGEMENT')"
                    class="button"
                    @click="$refs.dataTable.createData({})"
            >
                Ausbildung/Seminar hinzufügen
            </a>
        </content-header>
        <content-main>
            <education-table ref="dataTable"/>
        </content-main>
    </div>
</template>

<script>
    import EducationTable from "../../data/EducationTable";

    export default {
        components: {
            EducationTable
        },
        computed: {}
    }
</script>
