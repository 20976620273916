<template>
    <div>
        <content-header headline="Bewerbungen"/>
        <content-main>
            <data-eventCourseApplication/>
        </content-main>
    </div>
</template>


<script>
    import DataEventCourseApplication from "@/data/EventCourseApplication.vue";

    export default {
        components: {
            DataEventCourseApplication,
        }
    }
</script>
