<template>
    <div>
        <content-header headline="Duplikate bereinigen"/>
        <content-main>
            <div class="callout warning" v-if="countPages === 0">
                Keine Vorschläge<br>Neue Vorschläge werden nächtlich generiert.
            </div>

            <div class="row" v-else>
                <div class="large-12 columns text-center" v-if="resolveType === 'suggest'">
                    <h2 class="h-margin-bottom">
                        Vorschlag aufgrund von
                        <br>
                        {{ duplicatePerson.matchKey|tPersonDuplicatesMatchKeys }}
                        <br>
                        ({{ duplicatePerson.matchValue }})
                        <br>
                        <small>Vorschlag {{ page }} von {{ countPages }}</small>
                    </h2>
                </div>

                <div class="large-12 columns">
                    <div class="panel" v-if="personBase && personMatch">
                        <table>
                            <thead>
                            <tr>
                                <th>Attribut</th>
                                <th>
                                    <template v-if="!personDuplicateResolve.personBase">
                                        Datensatz A
                                    </template>
                                    <template v-else-if="personDuplicateResolve.personBase === this.personBase.id">
                                        Stammdatensatz
                                    </template>
                                    <template v-else>
                                        Duplikat
                                    </template>
                                    <br>
                                    <router-link
                                        :to="'/persons/' + this.personBase.id"
                                        target="_blank"
                                    >
                                        Profil anzeigen
                                    </router-link>
                                    <br>
                                    <small>({{ personBase.id }})</small>
                                </th>
                                <th v-if="personDuplicateResolve.personBase" class="text-center">
                                    <span style="font-size: 4rem;"
                                          v-if="this.personBase.id !== personDuplicateResolve.personBase">→</span>
                                    <span style="font-size: 4rem;" v-else>←</span>
                                </th>
                                <th>
                                    <template v-if="!personDuplicateResolve.personBase">
                                        Datensatz B
                                    </template>
                                    <template v-else-if="personDuplicateResolve.personBase === this.personMatch.id">
                                        Stammdatensatz
                                    </template>
                                    <template v-else>
                                        Duplikat
                                    </template>
                                    <br>
                                    <router-link
                                        :to="'/persons/' + this.personMatch.id"
                                        target="_blank"
                                    >
                                        Profil anzeigen
                                    </router-link>
                                    <br>
                                    <small>({{ personMatch.id }})</small>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="!personDuplicateResolve.personBase">
                                <td></td>
                                <td>
                                    <a class="button small h-noMargin-bottom"
                                       @click="setBaseAndMatchPerson(personBase.id, personMatch.id)"
                                    >
                                        Als Stammdatensatz auswählen
                                    </a>
                                </td>
                                <td v-if="personDuplicateResolve.personBase">

                                </td>
                                <td>
                                    <a class="button small h-noMargin-bottom"
                                       @click="setBaseAndMatchPerson(personMatch.id, personBase.id)"
                                    >
                                        Als Stammdatensatz auswählen
                                    </a>
                                </td>
                            </tr>

                            <tr v-for="tableCompareDatum in tableCompareData">
                                <td><strong>{{ tableCompareDatum.label }}</strong></td>
                                <td>
                                    <template v-if="typeof tableCompareDatum.render === 'function'">
                                        {{ tableCompareDatum.render(personBase[tableCompareDatum.id]) }}
                                    </template>
                                    <template v-else-if="tableCompareDatum.type === 'date'">
                                        {{ personBase[tableCompareDatum.id]|formatDate('LLL') }}
                                    </template>
                                    <template v-else-if="tableCompareDatum.type === 'dateShort'">
                                        <template v-if="personBase.hasOwnProperty(tableCompareData.id)">
                                            {{ personBase[tableCompareDatum.id]|formatDate('LL') }}
                                        </template>
                                    </template>
                                    <template v-else>
                                        {{ personBase[tableCompareDatum.id] }}
                                    </template>
                                </td>
                                <td v-if="personDuplicateResolve.personBase">
                                    <label
                                        v-if="tableCompareDatum.transferable !== false"
                                        class="h-noMargin-bottom"
                                    >
                                        <select
                                            v-model="personDuplicateResolve[tableCompareDatum.transferID]"
                                            class="small"
                                        >
                                            <option :value="null">
                                                <template v-if="tableCompareDatum.hasOwnProperty('nullText')">
                                                    {{tableCompareDatum.nullText}}
                                                </template>
                                                <template v-else>
                                                    Kein Übertrag
                                                </template>
                                            </option>
                                            <option value="override">
                                                Überschreiben / Primär setzen
                                            </option>
                                            <option
                                                v-if="tableCompareDatum.transferType === 'append'"
                                                value="append"
                                            >
                                                Anhängen
                                            </option>
                                        </select>
                                    </label>
                                </td>
                                <td>
                                    <template v-if="typeof tableCompareDatum.render === 'function'">
                                        {{ tableCompareDatum.render(personMatch[tableCompareDatum.id]) }}
                                    </template>
                                    <template v-else-if="tableCompareDatum.type === 'date'">
                                        {{ personMatch[tableCompareDatum.id]|formatDate('LLL') }}
                                    </template>
                                    <template v-else-if="tableCompareDatum.type === 'dateShort'">
                                        <template v-if="personMatch.hasOwnProperty(tableCompareData.id)">
                                            {{ personMatch[tableCompareDatum.id]|formatDate('LL') }}
                                        </template>
                                    </template>
                                    <template v-else>
                                        {{ personMatch[tableCompareDatum.id] }}
                                    </template>
                                </td>
                            </tr>

                            <tr v-if="personDuplicateResolve.personBase">
                                <td></td>
                                <td></td>
                                <td class="text-center">
                                    <a class="button h-noMargin-bottom" @click="resolve">Duplikat bereinigen</a>
                                </td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="large-4 columns text-left">
                    <a class="button thirdly" v-if="page > 1" @click="prevSuggest">Vorheriger Vorschlag</a>
                </div>
                <div class="large-4 columns text-center" v-if="resolveType === 'suggest'">
                    <a class="button thirdly" @click="noDuplicate">Kein Duplikat</a>
                </div>
                <div class="large-4 columns text-right">
                    <a class="button thirdly" v-if="page < countPages" @click="nextSuggest">Nächster Vorschlag</a>
                </div>
            </div>
        </content-main>
    </div>
</template>

<script>
    import DataTable from "../../../components/DataTable";
    import PersonForm from "../../../data/PersonForm";

    export default {
        components: {
            PersonForm,
            DataTable
        },
        data() {
            return {
                resolveType: 'suggest',
                duplicatePerson: {},
                personBase: {},
                personMatch: {},
                personDuplicateResolve: {
                    personBase: null,
                    personMatch: null,
                    address: null,
                    names: null,
                    company: null,
                    phone: null,
                    birthday: null,
                    email: null
                },
                page: 1,
                countPages: 1
            }
        },
        computed: {
            tableCompareData: function () {
                let applicationSettings = this.$store.getters.applicationSettings;
                console.log(this.$store.getters);
                return [
                    {
                        label: 'Status',
                        id: 'status',
                        transferID: 'status',
                        render: function (data) {
                            return applicationSettings.model.person.stati[data];
                        }
                    },
                    {
                        label: 'campus-Kundennummer',
                        id: 'customerNumber',
                        transferID: 'customerNumber',
                        nullText: 'Anhängen'
                    },
                    {
                        label: 'Geschlecht',
                        id: 'gender',
                        transferID: 'gender'
                    },
                    {
                        label: 'Titel',
                        id: 'title',
                        transferID: 'title'
                    },
                    {
                        label: 'Vorname',
                        id: 'firstName',
                        transferID: 'firstName'
                    },
                    {
                        label: 'Nachname',
                        id: 'lastName',
                        transferID: 'lastName'
                    },
                    {
                        label: 'Straße',
                        id: 'street',
                        transferID: 'street'
                    },
                    {
                        label: 'PLZ',
                        id: 'zip',
                        transferID: 'zip'
                    },
                    {
                        label: 'Stadt',
                        id: 'city',
                        transferID: 'city'
                    },
                    {
                        label: 'Unternehmen',
                        id: 'company',
                        transferID: 'company'
                    },
                    {
                        label: 'Geburtstag',
                        id: 'birthday',
                        transferID: 'birthday',
                        type: 'dateShort'
                    },
                    {
                        label: 'E-Mail',
                        id: 'email',
                        transferID: 'email',
                        transferType: 'append',
                    },
                    {
                        label: 'Telefonummer',
                        id: 'phoneNumbers',
                        transferID: 'phone',
                        transferType: 'append',
                    },
                    {
                        label: 'Kontakt',
                        id: 'primaryContactChannel',
                        transferID: 'primaryContactChannel',
                        render: function (data) {
                            return applicationSettings.model.person.contactChannels[data];
                        }
                    },
                    {
                        label: 'Zuletzt bearbeitet',
                        id: 'lastEditedAt',
                        type: 'date',
                        transferable: false,
                    },
                    {
                        label: 'Erstellt am',
                        id: 'createdAt',
                        type: 'date',
                        transferable: false,
                    },
                    {
                        label: 'Anzahl Touches',
                        id: 'touchCount',
                        transferable: false,
                    }
                ];
            }
        },
        mounted() {
            if (this.$route.query.basePerson && this.$route.query.matchPerson) {
                this.resolveType = 'custom';
                this.loadPersonData(this.$route.query.basePerson, this.$route.query.matchPerson);
            } else {
                this.resolveType = 'suggest';
                this.loadDuplicatePersons();
            }
        },
        methods: {
            setBaseAndMatchPerson(base, match) {
                this.personDuplicateResolve.personBase = base;
                this.personDuplicateResolve.personMatch = match;
            },
            removeBaseAndMatchPerson() {
                this.personDuplicateResolve.personBase = null;
                this.personDuplicateResolve.personMatch = null;
            },
            resolve() {
                this.$store.commit('incrementLoadingIndex');
                this.$http.post('personDuplicate/resolve', this.personDuplicateResolve).then(response => {
                    this.$notify({
                        type: 'success',
                        text: 'Duplikat bereinigt'
                    });
                    this.$store.commit('decrementLoadingIndex');
                    if (this.resolveType === 'custom') {
                        this.$router.push('/persons/' + this.personDuplicateResolve.personBase);
                    } else {
                        this.loadDuplicatePersons();
                    }
                }, response => {
                    this.$notify({
                        type: 'error',
                        text: 'Allgemeiner Fehler'
                    });
                    this.$store.commit('decrementLoadingIndex');
                });
            },
            noDuplicate() {
                this.$store.commit('incrementLoadingIndex');
                this.$http.put(
                    'person_duplicates/' + this.duplicatePerson.id,
                    {status: 'decline'}
                ).then(response => {
                    this.$store.commit('decrementLoadingIndex');
                    this.loadDuplicatePersons();
                }, response => {
                    this.$store.commit('decrementLoadingIndex');
                });
            },
            prevSuggest() {
                this.page--;
                this.loadDuplicatePersons();
            },
            nextSuggest() {
                this.page++;
                this.loadDuplicatePersons();
            },
            loadDuplicatePersons() {
                this.$store.commit('incrementLoadingIndex');
                this.$http.get(
                    'person_duplicates',
                    {
                        params: {
                            itemsPerPage: 1,
                            currentPage: this.page,
                            status: 'default'
                        }
                    }
                ).then(response => {
                    this.countPages = response.body['hydra:totalItems'];
                    this.$store.commit('decrementLoadingIndex');
                    if (this.countPages === 0) {
                        return;
                    }
                    if (!response.body['hydra:member'][0]) {
                        this.$router.push('/');
                        this.$notify({
                            'text': 'Aktuell keine Duplikatvorschläge',
                            'type': 'warning'
                        });
                    }
                    this.duplicatePerson = response.body['hydra:member'][0];
                    this.loadPersonData(this.duplicatePerson.personBase.id, this.duplicatePerson.personMatch.id);
                }, response => {
                    this.$store.commit('decrementLoadingIndex');
                });
            },
            loadPersonData(personBaseId, personMatchId) {
                this.$store.commit('incrementLoadingIndex');
                this.$http.get('people/' + personBaseId).then(response => {
                    this.personBase = response.body;
                    this.$store.commit('decrementLoadingIndex');
                }, response => {
                    this.$store.commit('decrementLoadingIndex');
                });

                this.$store.commit('incrementLoadingIndex');
                this.$http.get('people/' + personMatchId).then(response => {
                    this.personMatch = response.body;
                    this.$store.commit('decrementLoadingIndex');
                }, response => {
                    this.$store.commit('decrementLoadingIndex');
                });

                this.removeBaseAndMatchPerson();
                this.tableCompareData.forEach((item, index) => {
                    if (
                        typeof item['transferID'] !== 'undefined'
                        && this.personDuplicateResolve[item['transferID']] !== null
                    ) {
                        this.personDuplicateResolve[item['transferID']] = null;
                    }
                });
                this.$forceUpdate();
            }
        },
        watch: {
            personDuplicateResolve: {
                deep: true,
                handler: function (newVal) {
                    console.log(newVal);
                }
            }
        }
    }
</script>
