import Vue from 'vue'
import InputText from "./view/components/form/InputText";
import InputEntity from "./view/components/form/InputEntity";
import InputSelect from "./view/components/form/InputSelect";
import InputDate from "./view/components/form/InputDate";
import Reveal from "./view/components/Reveal";
import Loading from "./view/components/Loading";
import ContentHeader from "./view/components/ContentHeader";
import ContentMain from "./view/components/ContentMain";
import EducationTypeSelect from "./view/components/form/EducationTypeSelect";
import InputDateRange from "./view/components/form/InputDateRange";
import InputSimpleSelect from "./view/components/form/InputSimpleSelect";

Vue.component('reveal', Reveal);
Vue.component('loading', Loading);
Vue.component('input-entity', InputEntity);
Vue.component('input-select', InputSelect);
Vue.component('input-text', InputText);
Vue.component('input-date', InputDate);
Vue.component('input-date-range', InputDateRange);
Vue.component('input-simple-select', InputSimpleSelect);
Vue.component('input-education-type-select', EducationTypeSelect);
Vue.component('content-main', ContentMain);
Vue.component('content-header', ContentHeader);

import VModal from 'vue-js-modal/dist/ssr.nocss'
import 'vue-js-modal/dist/styles.css'

Vue.use(VModal,
  {
    dynamicDefaults: {
      draggable: false,
      resizable: false,
      height: 'auto'
    }
  }
);
