<template>
    <div>
        <content-header headline="Profil" subline="Hier können Sie Ihre persönlichen Daten ändern">
        </content-header>
        <content-main :show-loading="loadingIndex > 0">
            <div class="row">
                <div class="columns large-6 medium-8">
                    <div class="panel">
                        <div class="row">
                            <div class="small-12 columns">
                                <h1>Passwort ändern</h1>
                                <form v-on:submit.prevent="changePasswordSubmit">
                                    <input-text
                                            label="Altes Passwort"
                                            type="password"
                                            v-model="profile.oldPassword"
                                            :errors="formErrors.oldPassword"
                                    />

                                    <hr>

                                    <password-requirements/>

                                    <input-text
                                            label="Neues Passwort"
                                            type="password"
                                            v-model="profile.plainPassword"
                                            :errors="formErrors.plainPassword"
                                    />

                                    <input-text
                                            label="Neues Passwort (Wied.)"
                                            type="password"
                                            v-model="profile.plainPasswordRepeat"
                                            :errors="formErrors.plainPasswordRepeat"
                                    />
                                    <div class="text-right">
                                        <button type="submit" class="button">Passwort ändern</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </content-main>
    </div>
</template>

<script>
    import PasswordRequirements from "../../components/callout/PasswordRequirements";
    import util from "../../../plugins/util";

    export default {
        components: {
            PasswordRequirements
        },
        data() {
            return {
                profile: {},
                formErrors: {},
                loadingIndex: 0,
            }
        },
        mounted() {
        },
        methods: {
            changePasswordSubmit() {
                if (this.profile.plainPasswordRepeat !== this.profile.plainPassword) {
                    this.formErrors = {
                        plainPassword: [
                            "Die Passwörter müssen identisch sein."
                        ]
                    };
                    return;
                }

                this.formErrors = {};

                this.loadingIndex++;
                this.$http.post('actions/passwordChange', this.profile).then(response => {
                    this.loadingIndex--;
                    this.profile = {};
                    this.$router.push('/login?newPassword');
                }, response => {
                    this.loadingIndex--;
                    this.formErrors = util.mapConstraints(response.data);
                })
            }
        }
    }
</script>
