<template>
    <div>
        <div class="row">
            <div class="large-4 columns">
                <h3>Schnellaktion</h3>
                <a class="button small thirdly" disabled>Newsletter</a>
                <a class="button small thirdly" disabled>Ausbildungsinformationen</a>
                <a class="button small thirdly" disabled>Schnupper-Workshop-Anmeldung</a>
            </div>
            <div class="large-8 columns">
                <h3>Einladen zu</h3>
                <div class="row">
                    <div class="columns">
                        <label>
                            Tag der offenen Tür
                            <br>
                            <input type="checkbox" v-model="value.openDoorDayInvitation">
                        </label>
                    </div>
                    <div class="columns">
                        <label>
                            Farbenherbst
                            <br>
                            <input type="checkbox" v-model="value.autumnOfColorsInvitation">
                        </label>
                    </div>
                    <div class="columns">
                        <label>
                            Gesundheitstage
                            <br>
                            <input type="checkbox" v-model="value.healthDaysInvitation">
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import value from "../../../../mixins/value";
    import InputRte from "../../../components/form/InputRte";

    export default {
        components: {InputRte},
        mixins: [value]
    }
</script>
