<template>
    <div>
        <multiselect
            ref="multiSelect"
            v-model="value"
            :multiple="true"
            :taggable="false"
            :options="Object.keys(options)"
            :custom-label="getLabelById"
            :internal-search="true"
            @input="update"
            selectLabel="Enter Drücken zum Auswählen"
            selectGroupLabel="Enter Drücken um Gruppe auszuwählen"
            selectedLabel="Ausgewählt"
            deselectLabel="Enter Drücken zum Entfernen"
            deselectGroupLabel="Enter Drücken um Gruppe zu entfernen"
            placeholder="Eingeben und Auswählen"
            :allow-empty="true"
            :showPointer="false"
            :options-limit="300"
            :max="10"
            :maxHeight="130"
            :searchable="false"
        >
            <template slot="noResult">
            <span>
                Keine Ergebnisse gefunden.
            </span>
            </template>

            <template slot="noOptions">
                <span>Keine Ergebnisse gefunden.</span>
            </template>
        </multiselect>
    </div>
</template>

<script>
    import Multiselect from "vue-multiselect";

    export default {
        components: {
            Multiselect
        },
        data() {
            return {}
        },
        methods: {
            update(value) {
                this.$emit('input', value);
            },
            getLabelById(item) {
                return this.options[item];
            }
        },
        props: {
            value: {
                type: [Object, Array],
                required: false,
                default: () => {
                    return [];
                }
            },
            options: {
                type: [Object, Array]
            },
        }
    }
</script>
