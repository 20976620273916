<template>
    <label :class="{'--errors': hasErrors}"
        class="relative"
    >
        <template v-if="label">{{label}}</template>

        <input v-if="type === 'text' || type === 'password' || type === 'email' || type === 'number' || type === 'date'"
               v-bind:type="type"
               v-bind:placeholder="placeholder"
               :required="required"
               v-on:input="update($event.target.value)"
               v-model="value"
               v-bind="{'readonly': readonly, 'min': min, 'max': max, 'step': step}"
        >

        <textarea
                v-if="type === 'textarea'"
                v-model="value"
                :rows="rows"
                v-on:input="update($event.target.value)"
                v-bind="{'readonly': readonly}"
        >
        </textarea>

        <template v-if="errors !== null && errors.length > 0">
            <ul class="form-errors">
                <li v-for="error in errors">
                    {{error}}
                </li>
            </ul>
        </template>
    </label>
</template>

<script>
    export default {
        data() {
            return {
                showErrors: true
            }
        },
        props: {
            type: {
                type: String | Text,
                default: () => {
                    return 'text';
                }
            },
            value: {
                type: String | Number,
                default: () => {
                    return '';
                }
            },
            placeholder: {
                type: String,
                default: () => {
                    return '';
                }
            },
            label: {
                type: String,
                default: () => {
                    return '';
                }
            },
            readonly: {
                type: Boolean,
                default: () => {
                    return false;
                }
            },
            rows: {
                default: () => {
                    return 5;
                }
            },
            required: {
                type: Boolean,
                default: () => {
                    return false;
                }
            },
            errors: {
                type: Object | Array,
                default: function () {
                    return {};
                }
            },
            min: {
                type: Number,
                default: null
            },
            max: {
                type: Number,
                default: null
            },
            step: {
                type: Number,
                default: null
            }
        },
        computed: {
            hasErrors() {
                return this.errors !== null && this.errors.length > 0 && this.showErrors
            }
        },
        methods: {
            update(data) {
                this.errors = [];
                this.showErrors = false;
                if (!this.readonly) {
                    this.$emit('input', data);
                }
            }
        },
        watch: {
            'errors': function() {
                this.showErrors = true;
            }
        }
    }
</script>
