<template>
    <div>
        <div class="row h-largeMargin-bottom">
            <div class="columns small-12">
                <h2>Maßnahmen-Informationen</h2>
            </div>
            <div class="columns large-4">
                <input-text
                    v-model="value.supportNumber"
                    label="Maßnahmen-Nr."
                    :errors="errors.supportNumber"
                />
            </div>
            <div class="columns large-4">
                <input-date
                    v-model="value.supportStart"
                    label="Start Maßnahme"
                    :errors="errors.supportStart"
                />
            </div>
            <div class="columns large-4">
                <input-entity
                    type="education"
                    v-model="value.education"
                    label="Auswahl Maßnahme"
                    :fixedParams="{
                        type: 'supported'
                    }"
                />
            </div>
        </div>

        <template v-if="educationSupportInformation && educationSupportInformation.hasOwnProperty('@id')">
            <div class="row h-largeMargin-bottom">
                <div class="columns small-12">
                    <h2>Familäre Situation</h2>
                </div>

                <div class="large-6 columns">
                    <input-simple-select
                        label="Betreuung Kinder"
                        v-model="value.familiarSituationKids"
                        :options="getOptions('familiarSituationKids')"
                        :errors="errors.familiarSituationKids"
                    />
                </div>

                <div class="large-6 columns">
                    <input-simple-select
                        label="Vereinbarkeit mit fam. Situation"
                        v-model.number="value.familiarSituation"
                        :options="getOptions('familiarSituation')"
                        :errors="errors.familiarSituation"
                    />
                </div>
            </div>

            <div class="row">
                <div class="large-12 columns">
                    <div class="panel__subPanel" v-if="educationSupportInformation.artTherapy">
                        <div class="row">

                        </div>

                        <div class="row">
                            <div class="large-12 columns">
                                <h3>Kunsttherapie</h3>
                            </div>

                            <div class="large-12 columns">
                                <label>
                                    Mappe wird nachgereicht
                                    <br>
                                    <input v-model="value.artTherapyDataFollowedUp" type="checkbox"/>
                                </label>
                            </div>

                            <template
                                v-if="value.artTherapyDataFollowedUp === false || value.artTherapyDataFollowedUp === null">
                                <div class="large-3 columns">
                                    <input-text
                                        label="Anzahl Werke"
                                        type="number"
                                        v-model.number="value.artTherapyWorks"
                                        :errors="errors.artTherapyWorks"
                                    />
                                    <input-text
                                        label="Link zu Werken (optional)"
                                        v-model="value.artTherapyWorksLink"
                                        :errors="errors.artTherapyWorksLink"
                                    />
                                </div>

                                <div class="large-9 columns">
                                    <input-rte
                                        label="Verwendete Materialien"
                                        v-model="value.artTherapyWorksMaterial"
                                        :errors="errors.artTherapyWorksMaterial"
                                    />
                                </div>

                                <div class="large-12 columns">
                                    <hr/>
                                    <h3>Fazit Mappengespräch</h3>
                                </div>

                                <div class="large-3 columns">
                                    <input-simple-select
                                        label="Kunstfertigkeit"
                                        v-model.number="value.artTherapySkills"
                                        :errors="errors.artTherapySkills"
                                        :options="getOptions('artTherapySkills')"
                                    />
                                    <input-simple-select
                                        label="Ausdruck"
                                        v-model.number="value.artTherapyExpression"
                                        :errors="errors.artTherapyExpression"
                                        :options="getOptions('artTherapyExpression')"
                                    />
                                </div>

                                <div class="large-9 columns">
                                    <input-rte
                                        label="Weiteres Fazit / Notiz (optional)"
                                        v-model="value.artTherapyNote"
                                        :errors="errors.artTherapyNote"
                                    />
                                </div>
                            </template>
                        </div>
                    </div>

                    <div class="panel__subPanel">
                        <div class="row">
                            <div class="large-12 columns">
                                <h3>Eignungsfeststellung</h3>
                            </div>

                            <div class="large-3 columns">
                                <input-simple-select
                                    label="Für Gew. Ausbildung / Weiterbildung geeignet"
                                    v-model.number="value.suitability"
                                    :errors="errors.suitability"
                                    :options="getOptions('suitability')"
                                    :select-number="true"
                                />
                                <input-simple-select
                                    label="Begründung"
                                    v-model="value.suitabilityArgumentation"
                                    :errors="errors.suitabilityArgumentation"
                                    :options="getOptions('suitabilityArgumentation')"
                                    :select-number="true"
                                />
                            </div>

                            <div class="large-9 columns">
                                <input-rte
                                    label="Weiteres Eignung / Notiz (optional)"
                                    v-model="value.suitabilityNote"
                                    :errors="errors.suitabilityNote"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="large-12 columns">
                    <div class="callout warning">
                        <p>Bitte denken Sie daran den Bewerber über Lebenslauf-, Schulabschluss- und
                            Berufsabschluss-Dokumente
                            zu informieren.</p>
                    </div>
                </div>

                <div class="large-6 columns" v-if="educationSupportInformation.policeRecord">
                    <input-simple-select
                        :label="
                            educationSupportInformation.advancedPoliceRecord
                            ? 'Erweitertes Führungszeugnis'
                            : 'Polizeiliches Führungszeugnis'"
                        v-model="value.policeClearanceCertificate"
                        :options="getOptions('documentStati')"
                        :errors="errors.policeClearanceCertificate"
                    />
                </div>
                <div class="large-6 columns" v-if="educationSupportInformation.medicalCertificate">
                    <input-simple-select
                        label="Ärztliches Attest"
                        v-model="value.medicalCertificate"
                        :options="getOptions('documentStati')"
                        :errors="errors.medicalCertificate"
                    />
                </div>
                <div class="large-6 columns" v-if="educationSupportInformation.languageSkills">
                    <input-simple-select
                        label="Sprachkennntnisse"
                        v-model="value.languageSkills"
                        :options="getOptions('documentStati')"
                        :errors="errors.languageSkills"
                    />
                </div>
                <div class="large-6 columns" v-if="educationSupportInformation.minimumAge">
                    <input-simple-select
                        :label="'Mindestalter ' + educationSupportInformation.minimumAge"
                        v-model="value.minimumAge"
                        :options="getOptions('documentStati')"
                        :errors="errors.minimumAge"
                    />
                </div>
                <div class="large-12 columns">
                    <input-simple-select
                        label="Stabiles Internet, PC od. Laptop mit Webcam, Lautsprecher, Headset"
                        v-model="value.internet"
                        :options="getOptions('documentStati')"
                        :errors="errors.internet"
                    />
                </div>
            </div>
        </template>

        <template v-else>
            <div class="callout info">
                Bitte zuerst die Maßnahme auswählen
            </div>
        </template>
    </div>
</template>

<script>
    import value from "../../../mixins/value";
    import InputRte from "../../components/form/InputRte";

    export default {
        components: {InputRte},
        data() {
            return {
                educationSupportInformation: null,
            }
        },
        methods: {
            getOptions(optionName) {
                return this.$store.getters.applicationSettings.model.touchSupport[optionName];
            }
        },
        mixins: [
            value
        ],
        watch: {
            'value.education': function (newVal, oldVal) {
                this.$http.get(newVal + '/education_information');
                this.$getFromApiSearch(
                    'education_supported_informations',
                    {
                        education: newVal,
                    },
                    (entity) => {
                        this.$set(this, 'educationSupportInformation', entity);
                    }
                );

                if (newVal !== oldVal) {
                    this.value.artTherapyDataFollowedUp = false;
                }
            }
        },
        props: {},
    }
</script>
