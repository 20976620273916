<template>
    <div>
        <content-header headline="API-Verwaltung">
            <a class="button" @click="$refs.dataTable.createData({credibilitySlugs: []})">API hinzufügen</a>
        </content-header>
        <content-main>
            <data-table
                    endpoint="user_api_keys"
                    :columns="columns"
                    :filters="filters"
                    ref="dataTable"
                    updateRevealSize="large"
            >
                <template slot="updateForm" slot-scope="{data: userApiKey, errors: errors}">
                    <div class="row">
                        <div class="columns large-6">
                            <input-text
                                    v-model="userApiKey.name"
                                    label="Name"
                                    :errors="errors.name"
                            />
                        </div>
                        <div class="columns large-2">
                            <label>
                                Aktiv
                                <br>
                                <input type="checkbox" v-model="userApiKey.active">
                            </label>
                        </div>
                    </div>
                </template>

                <template slot="dataRow" slot-scope="{singleData: user}">
                    <td>
                        {{user.displayName}}
                        <template v-if="!user.active"> (gesperrt)</template>
                    </td>
                    <td>
                        {{user.apiToken}}
                    </td>
                    <td>
                        <template v-if="user.role">
                            {{user.role.name}}
                        </template>
                    </td>
                    <td>
                        <template v-for="(credibilitySlug, index) in user.credibilitySlugs">
                            {{credibilitySlug|tSecurity}}<template v-if="index < (user.credibilitySlugs.length - 1)">,</template>
                        </template>
                    </td>
                    <td>
                        {{user.lastEditedAt|formatDate('LLL')}}
                    </td>
                    <td class="text-right">
                        <a v-if="user.userCanUpdate" class="action --edit" @click="$refs.dataTable.editData(user)"></a>
                        <a v-if="user.userCanDelete" class="action --delete"></a>
                    </td>
                </template>
            </data-table>
        </content-main>
    </div>
</template>

<script>
    import DataTable from "../../../components/DataTable";
    import InputSelect from "../../../components/form/InputSelect";
    import CredibilitySelect from "../../../components/form/CredibilitySelect";

    export default {
        components: {
            CredibilitySelect,
            InputSelect,
            DataTable
        },
        data() {
            return {
                filters: [],
                columns: {
                    displayName: {
                        text: 'Name'
                    },
                    apiToken: {
                        text: 'API-Token'
                    },
                    role: {
                        text: 'Rolle',
                        order: false
                    },
                    credibilitySlugs: {
                        text: 'Berechtigungen',
                        order: false
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    },
                }
            }
        }
    }
</script>
