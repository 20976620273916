<template>
	<div v-if="supportedCostCenter">
		<content-header
				:headline="supportedCostCenter.name"
		>
			<!--<a
					class="button"
					@click="$refs.dataTable.createData({})"
			>
				Kostenstellen hinzufügen
			</a>-->
		</content-header>
		<content-main>
			<ui-tabs>
				<ui-tab title="Allgemeine Daten">
					<form
						v-on:submit.prevent="updateData"
						class="panel grid gap-4"
					>
						<div class="font-bold text-xl uppercase">Allgemeine Daten</div>
						<update-form
							v-model="supportedCostCenter"
							:errors="errors"
						/>
						<div class="text-center">
							<button type="submit" class="button">
								Aktualisieren
							</button>
						</div>
					</form>
				</ui-tab>
				<ui-tab title="Ansprechpartner">
					<supported-cost-center-contact-person-table :supportedCostCenter="supportedCostCenter" />
				</ui-tab>
			</ui-tabs>

		</content-main>
	</div>
</template>


<script>
	import UpdateForm from "../../../../data/SupportedCostCenter/UpdateForm.vue";
	import SupportedCostCenterContactPersonTable from "../../../../data/SupportedCostCenterContactPerson/Table.vue";
	import Util from "../../../../../plugins/util";

    export default {
        components: {
			SupportedCostCenterContactPersonTable,
            UpdateForm
        },
        props: {
            endpoint: {
                type: Text | String,
                default: 'people'
            },
            disableFilter: {
                type: Array,
                default: () => []
            },
            addQueryToUrl: {
                type: Boolean,
                default: () => false
            },
            stateKey: {
                type: String | null,
                default: () => null,
            }
        },
        methods: {
			updateData() {
				this.errors = {};
				this.$sendToApi(
					'supported_cost_centers',
					this.supportedCostCenter,
					(supportedCostCenter, status, errors) => {
						if(status < 300) {
							this.supportedCostCenter = Object.assign({}, supportedCostCenter);
						} else {
							this.errors = Util.mapConstraints(errors);
						}
					}
				)
			},
            loadData() {
                this.$getFromApi(
                  'supported_cost_centers',
                  this.$route.params.id,
                  (supportedCostCenter) => {
                      this.supportedCostCenter = Object.assign({}, supportedCostCenter)
                  }
                )
            }
        },
        watch: {
            'route': function (newVal) {
                this.loadData();
            }
        },
        mounted() {
            this.loadData();
        },
        data() {
            return {
                errors: {},
                supportedCostCenter: null
            }
        }
    }
</script>
