<template>
    <div>
        <content-header headline="Externe Portale">
            <router-link to="/tools/portals/create" class="button">Hinzufügen</router-link>
        </content-header>
        <content-main>
            <data-table
                endpoint="portals"
                :columns="columns"
                :filters="filters"
                ref="dataTable"
            >
                <template slot="dataRow" slot-scope="{singleData: portal}">
                    <td>
                        {{portal.name}}
                    </td>
                    <td>
                        {{portal.note}}
                    </td>
                    <td>
                        {{portal.locations.length}} Standort(e)
                    </td>
                    <td>
                        {{portal.lastUpdated|formatDate('LL')}}
                    </td>
                    <td class="text-right">
                        <router-link
                            v-if="portal.userCanUpdate"
                            class="action --edit"
                            :to="'/tools/portals/' + portal.id"
                        ></router-link>
                    </td>
                </template>
            </data-table>
        </content-main>
    </div>
</template>

<script>
    import DataTable from "../../../components/DataTable";

    export default {
        components: {
            DataTable
        },
        data() {
            return {
                filters: [
                    {
                        type: 'text',
                        model: 'name',
                        label: 'Name'
                    },
                    {
                        type: 'text',
                        model: 'note',
                        label: 'Notiz'
                    },
                    {
                        type: 'dateRange',
                        model: 'lastUpdated',
                        label: 'Zuletzt aktualisiert'
                    }
                ],
                columns: {
                    name: {
                        text: 'Name',
                        order: true,
                    },
                    note: {
                        text: 'Notiz',
                        order: true,
                    },
                    locations: {
                        text: 'Anzahl Standorte',
                        order: false,
                    },
                    lastUpdate: {
                        text: 'Letztes Update',
                        order: true,
                    },
                }
            }
        }
    }
</script>
