<template>
    <content-main>
        <data-table
            v-if="$store.getters.applicationSettings"
            endpoint="touches"
            :columns="columns"
            :filters="filters"
            ref="dataTable"
            updateRevealSize="small"
            state-key="touches"
        >
            <template slot="dataRow" slot-scope="{singleData: touch}">
                <td>
                    {{ touch.date|formatDate('dddd') }}
                    <br>
                    {{ touch.date|formatDate('LLL') }}
                </td>
                <td>
                    {{ touch['clonedType']|dataTrans('model.touch.types') }}
                    <template v-if="touch['@type'] === 'TouchAppearance'">
                        <br>
                        {{ touch.course.code }}
                    </template>
                    <template v-if="touch['@type'] === 'TouchBooking'">
                    </template>
                    <template v-if="touch['clonedType'] === 'visit'">
                        <br>
                        <template v-if="touch.event">
                            {{ touch.event.name }}
                        </template>
                    </template>
                    <template v-if="touch['clonedType'] === 'callback'">
                        <br>
                        <template v-if="touch.status">
                            {{ touch.status|dataTrans('model.touchCallback.stati') }}
                        </template>
                    </template>
                    <template v-if="touch['clonedType'] === 'registration'">
                        <br>
                        <template v-if="touch.event">
                            {{ touch.event.name }}
                        </template>
                    </template>
                    <template v-if="touch['clonedType'] === 'custom'">
                        Individueller Touch<br>{{ touch.name }}
                    </template>
                    <template v-if="touch['clonedType'] === 'consultation'">
                        <template v-if="touch.primaryEducation">
                            <br>
                            für {{ touch.primaryEducation.name }}
                        </template>
                        <template v-if="touch.location">
                            <br>
                            in {{ touch.location.name }}
                        </template>
                    </template>
                    <template
                        v-if="touch['clonedType'] === 'catalogueSend' || touch['clonedType'] === 'catalogueRequest'">
                        <br>
                        Art:
                        <template v-if="touch.digital">(digital)</template>
                        <template v-else>(Post)</template>
                    </template>
                </td>
                <td>
                    {{ touch.person.displayName }}
                    <br>
                    <router-link :to="'/persons/' + touch.person.id">Zum Profil</router-link>
                </td>
                <td>
                    <template v-if="touch.createdByDisplayName">
                        {{ touch.createdByDisplayName }}
                    </template>
                </td>
                <td>
                    {{ touch.lastEditedAt|formatDate('LLL') }}
                </td>
                <td class="text-right">
                    <router-link :to="'/persons/' + touch.person.id" class="action --show"/>
                </td>
            </template>
        </data-table>
    </content-main>
</template>

<script>
    import DataTable from "../../components/DataTable";

    export default {
        components: {
            DataTable
        },
        props: {},
        methods: {},
        computed: {
            filters() {
                let _this = this;
                return [
                    {
                        type: 'dateRange',
                        model: 'date',
                        label: 'Zeitraum'
                    },
                    {
                        type: 'select',
                        model: 'clonedType',
                        label: 'Typ',
                        options: this.$store.getters.applicationSettings.model.touch.types,
                        callback: function (value, store = null) {
                            if(store) {
                                return store.getters.applicationSettings.model.touch.types[value];
                            }
                            return '';
                        }
                    }
                ];
            }
        },
        data() {
            return {
                columns: {
                    date: {
                        text: 'Wann',
                        order: true
                    },
                    type: {
                        text: 'Typ',
                        order: false
                    },
                    person: {
                        text: 'Person'
                    },
                    createdBy: {
                        text: 'Kontaktperson',
                        order: true
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    },
                }
            }
        }
    }
</script>
