<template>
    <label :class="{'--errors': hasErrors}" class="vue-flatPickr">
        <template v-if="label">{{label}}</template>

        <div class="vue-flatPickr__wrapper">
           <flat-pickr
                    v-model="value"
                    :config="config"
                    class="form-control"
                    placeholder="Datum auswählen"
                    name="date"
                    @on-change="update"
            >
            </flat-pickr>
            <a
                    v-if="!required && value !== null && value !== ''"
                    @click="clear"
                    class="action --close vue-flatPickr__clear"
            />
        </div>

        <template v-if="errors !== null && errors.length > 0">
            <ul class="form-errors">
                <li v-for="error in errors">
                    {{error}}
                </li>
            </ul>
        </template>
    </label>
</template>

<script>
    import flatPickr from 'vue-flatpickr-component';
    import {German} from 'flatpickr/dist/l10n/de.js';

    export default {
        components: {
            flatPickr
        },
        data() {
            return {
                showErrors: true,
                config: {
                    wrap: true, // set wrap to true only when using 'input-group'
                    altFormat: this.time ? 'j. F Y H:i' : 'j. F Y',
                    altInput: true,
                    dateFormat: this.time ? 'Y-m-d H:i' : 'Y-m-d',
                    defaultDate: null,
                    locale: German,
                    enableTime: this.time,
                },
            }
        },
        props: {
            value: {
                type: String | Number,
                default: () => {
                    return '';
                }
            },
            placeholder: {
                type: String,
                default: () => {
                    return '';
                }
            },
            label: {
                type: String,
                default: () => {
                    return '';
                }
            },
            readonly: {
                type: Boolean,
                default: () => {
                    return false;
                }
            },
            required: {
                type: Boolean,
                default: () => {
                    return false;
                }
            },
            errors: {
                type: Object | Array,
                default: function () {
                    return {};
                }
            },
            time: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            hasErrors() {
                return this.errors !== null && this.errors.length > 0 && this.showErrors
            }
        },
        methods: {
            clear() {
                this.update(null, '');
            },
            update(timeString, time) {
                this.value = time;
                this.errors = [];
                this.showErrors = false;
                if (!this.readonly) {
                    this.$emit('input', time.length === 0 ? null : time);
                }
            }
        },
        watch: {
            'errors': function() {
                this.showErrors = true;
            }
        }
    }
</script>
