<template>
    <c-modal
        ref="eventCourseDateAppearanceReportModal"
        :headline="loadedEventCourseDate.course ? ('Klassenbuch für ' + loadedEventCourseDate.course.code) : 'Klassenbuch'"
    >
        <div
            class="p-4 grid gap-8"
            v-if="loadedEventCourseDate.course && loadedEventCourseDate.course.hasOwnProperty('code')"
        >
            <div class="text-center grid gap-2">
                <div class="text-lg">
                    {{ loadedEventCourseDate.course.code }} am
                    {{ loadedEventCourseDate.date|formatDate('LLL') }}
                </div>
                <div
                    v-if="loadedEventCourseDate.curriculumTopic"
                    class="text-left grid gap-2"
                >
                    <div class="text-2xl uppercase font-bold">
                        Themen für den Tag
                    </div>
                    <table>
                        <tbody>
                        <tr>
                            <th>Thema</th>
                            <td class="text-left">{{ loadedEventCourseDate.curriculumTopic.name }}</td>
                        </tr>
                        <tr>
                            <th>Inhalt</th>
                            <td
                                v-html='loadedEventCourseDate.curriculumTopic.content.replaceAll("\n", "<br>")'
                                class="text-left"
                            ></td>
                        </tr>
                        <tr>
                            <th>Anweisungen</th>
                            <td
                                v-html='loadedEventCourseDate.curriculumTopic.instructions.replaceAll("\n", "<br>")'
                                class="text-left"
                            >
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <notes :entity="loadedEventCourseDate"/>

            <div>
                <div class="text-xl uppercase font-bold">
                    Anwesenheitskontrolle
                </div>
                <table>
                    <thead>
                    <tr>
                        <th>Name des Teilnehmers</th>
                        <th>Status</th>
                        <th>Fehlzeit</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="touchBooking in touchBookings">
                        <td>
                            <strong>{{ touchBooking.person.displayName }}</strong>
                            <template
                                v-if="loadedEventCourseDate.guestTouchBookings.map((item) => item['@id']).indexOf(touchBooking['@id']) >= 0">
                                (GH)
                            </template>
                        </td>
                        <td>
                            <template v-if="appearancesByPersonId.hasOwnProperty(touchBooking.person['@id'])">
                                {{ appearancesByPersonId[touchBooking.person['@id']].status }}
                            </template>
                        </td>
                        <td>
                            <template v-if="appearancesByPersonId.hasOwnProperty(touchBooking.person['@id']) && appearancesByPersonId[touchBooking.person['@id']].delay">
                                {{ appearancesByPersonId[touchBooking.person['@id']].delay }}h
                            </template>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </c-modal>
</template>
<script>
    import Notes from "@/data/Notes.vue";

    export default {
        components: {Notes},
        data() {
            return {
                eventCourseDate: {},
                loadedEventCourseDate: {},
                touchBookings: [],
                appearances: [],
            }
        },
        computed: {
            appearancesByPersonId: function () {
                return this.appearances.reduce(function (map, obj) {
                    map[obj.person['@id']] = obj;
                    return map;
                }, {});
            }
        },
        methods: {
            open(eventCourseDate) {
                this.eventCourseDate = eventCourseDate;
            },
            loadEventCourseDate(eventCourseDate) {
                this.$getFromApi('event_course_dates', eventCourseDate, (entity) => {
                    if (entity) {
                        this.$store.commit('incrementLoadingIndex');
                        this.loadedEventCourseDate = Object.assign({}, entity);
                        this.$http.get('touch_bookings', {params: {eventCourseDate: eventCourseDate['@id']}}).then((response) => {
                            this.touchBookings = response.body['hydra:member'];
                            this.$http.get('appearances', {params: {eventCourseDate: eventCourseDate['@id']}}).then((response) => {
                                this.appearances = response.body['hydra:member'];
                                this.$store.commit('decrementLoadingIndex');
                                this.$refs.eventCourseDateAppearanceReportModal.open();
                            });
                        });
                    }
                });
            }
        },
        watch: {
            eventCourseDate: {
                handler: function (newVal) {
                    this.loadEventCourseDate(newVal);
                },
                deep: true,
            }
        }
    }
</script>
