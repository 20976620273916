<template>
    <div>
        <div class="grid gap-x-8 grid-cols-12">
            <div class="col-span-12">
                <h2>Allgemeine Daten</h2>
            </div>
            <div class="col-span-6">
                <input-text type="text"
                            label="URL"
                            v-model="value.url"
                            :errors="errors.url"
                />
            </div>
            <div class="col-span-6">
                <input-entity
                    type="word_press_page"
                    label="WordPress-Seite"
                    v-model="value.wordpressPage"
                    :errors="errors.wordpressPage"
                />
            </div>
            <div class="col-span-6">
                <input-text
                    type="textarea"
                    label="Abschluss"
                    v-model="value.degree"
                    :errors="errors.degree"
                />

                <input-simple-select
                    label="Abschlussart"
                    v-model="value.typeOfDegree"
                    :options="typesOfDegree"
                />
            </div>
            <div class="col-span-6">
                <input-text type="textarea"
                            label="Teilnahmevoraussetzungen"
                            v-model="value.participationRequirement"
                            :errors="errors.participationRequirement"
                />

                <input-simple-select
                    label="Angebot Unterrichtsform"
                    v-model="value.teachingMethod"
                    :options="teachingMethods"
                    :errors="errors.teachingMethod"
                />
            </div>
            <div class="col-span-6">
                <input-text
                    type="textarea"
                    label="Berufliche Perspektive"
                    v-model="value.professionalPerspective"
                    :errors="errors.professionalPerspective"
                />
            </div>
            <div class="col-span-6">
                <input-text
                    type="textarea"
                    label="Zielgruppe"
                    v-model="value.targetGroup"
                    :errors="errors.targetGroup"
                />
            </div>
            <div class="col-span-6">
                <input-text
                    type="textarea"
                    label="Hinweis allgemein"
                    v-model="value.noteGeneral"
                    :errors="errors.noteGeneral"
                />
            </div>
            <div class="col-span-6">
                <input-text
                    label="Fortbildungspunkte"
                    v-model.number="value.educationPoints"
                    :errors="errors.educationPoints"
                />
            </div>
            <div class="col-span-6">
                <input-text
                    type="textarea"
                    label="Unterrichtszeiten"
                    v-model="value.teachingTimes"
                    :errors="errors.teachingTimes"
                />
            </div>
        </div>
        <hr>
        <div class="grid gap-x-8 grid-cols-12">
            <div class="col-span-12">
                <h2>Inhalte & Portale</h2>
            </div>
            <div class="col-span-6">
                <input-text
                    label="Kommunizierter Titel"
                    v-model="value.communicatedTitle"
                    :errors="errors.communicatedTitle"
                />
            </div>
            <div class="col-span-6">
                <input-text
                    label="Kommunizierter Code"
                    v-model="value.communicatedCode"
                    :errors="errors.communicatedCode"
                />
            </div>
            <div class="col-span-6">
                <input-rte
                    label="Lead-Text"
                    v-model="value.leadText"
                    :errors="errors.leadText"
                />
            </div>
            <div class="col-span-6">
                <input-rte
                    label="Beschreibung"
                    v-model="value.description"
                    :errors="errors.description"
                />
            </div>
            <div class="col-span-6">
                <input-rte
                    label="Inhalt"
                    v-model="value.contents"
                    :errors="errors.contents"
                />
            </div>
            <div class="col-span-6">
                <input-text
                    label="Schlüsselwörter (max 5.)"
                    v-model="value.keywords"
                    :errors="errors.keywords"
                />
            </div>
            <div class="col-span-6">
                <label>
                    Kursfinder Kategorien
                    <input-simple-multi-select
                        :multiple="true"
                        v-model="value.kursfinderCategories"
                        :options="this.$store.getters.applicationSettings.model.educationInformation.kursfinderCategories"
                    />
                </label>
            </div>
        </div>
        <div class="grid gap-x-8 grid-cols-12">
            <div class="col-span-6">
                <input-entity
                    v-model="value.exportInPortals"
                    :multi="true"
                    type="portal"
                    label="Export in Portale"
                />
            </div>

            <div class="col-span-6">
                <label>
                    <input-entity
                        label="Möglich in"
                        type="location"
                        :multi="true"
                        v-model="value.possibleInLocations"
                    />
                </label>
            </div>
        </div>
        <hr>
        <div class="grid gap-x-8 grid-cols-12">
            <div class="col-span-12">
                <h2>Dauer und Umfang</h2>
            </div>
            <div class="col-span-6">
                <input-text
                    label="Dauer"
                    v-model="value.duration"
                    :errors="errors.duration"
                />
            </div>
            <div class="col-span-6">
                <input-text
                    label="Umfang"
                    v-model="value.amount"
                    :errors="errors.amount"
                />
            </div>
            <div class="col-span-6">
                <input-text
                    label="Unterrichtseinheiten"
                    type="number"
                    v-model.number="value.numberOfLessons"
                    :errors="errors.numberOfLessons"
                />
            </div>
        </div>
        <hr>
        <div class="grid gap-x-8 grid-cols-12">
            <div class="col-span-12">
                <h2>Kosten</h2>
            </div>

            <div class="col-span-12">
                <div class="grid gap-x-8 grid-cols-12">
                    <div class="col-span-12">
                        <h3>Gemeinsame Kosten</h3>
                        <div class="grid gap-x-8 grid-cols-12">
                            <div class="col-span-6">
                                <input-text
                                    v-model.number="value.registrationFee"
                                    type="number"
                                    step="0.01"
                                    label="Einschreibegebühr"
                                    :errors="errors.registrationFee"
                                />
                            </div>
                            <div class="col-span-6">
                                <input-text
                                    v-model.number="value.examinationFee"
                                    type="number"
                                    label="Prüfungsgebühr"
                                    step="0.01"
                                    :errors="errors.examinationFee"
                                />
                            </div>
                            <div class="col-span-6">
                                <input-text
                                    v-model.number="value.oralExaminationFee"
                                    type="number"
                                    step="0.01"
                                    label="Prüfungsgebühr (mündlich)"
                                    :errors="errors.oralExaminationFee"
                                />
                            </div>
                            <div class="col-span-6">
                                <input-text
                                    v-model.number="value.writtenExaminationFee"
                                    type="number"
                                    step="0.01"
                                    label="Prüfungsgebühr (schriftlich)"
                                    :errors="errors.writtenExaminationFee"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-span-6">
                        <h3>Einmalzahlung</h3>
                        <input-text
                            label="Einmalzahlung"
                            v-model.number="value.oneOffPayment"
                            step="0.01"
                            type="number"
                            :errors="errors.oneOffPayment"
                        />

                        <input-text
                            label="Hinweis Kursgebühr"
                            v-model="value.noteCourseFee"
                            :errors="errors.noteCourseFee"
                            type="textarea"
                        />
                    </div>
                    <div class="col-span-6">
                        <h3>Ratenzahlung</h3>

                        <input-text
                            label="Anzahlung"
                            v-model.number="value.depositRate"
                            step="0.01"
                            type="number"
                            :errors="errors.depositRate"
                        />

                        <input-text
                            label="Anzahl Raten"
                            type="number"
                            v-model.number="value.numberOfRates"
                            :errors="errors.numberOfRates"
                        />

                        <input-text
                            label="Rate"
                            v-model.number="value.amountOfRate"
                            step="0.01"
                            type="number"
                            :errors="errors.amountOfRate"
                        />

                        <input-text
                            v-model="value.noteRatePayment"
                            :errors="errors.noteRatePayment"
                            type="textarea"
                            label="Hinweis Ratenzahlung"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="grid gap-x-8 grid-cols-12">
            <div class="col-span-12">
                <h2>Daten Teilnehmerbescheinigung</h2>
            </div>

            <div class="col-span-6">
                <input-text
                    label="Titel"
                    v-model="value.certificateTitle"
                    :errors="errors.certificateTitle"
                />
            </div>

            <div class="col-span-6">
                <input-text
                    label="Kategorie"
                    v-model="value.certificateCategory"
                    :errors="errors.certificateCategory"
                />
            </div>

            <div class="col-span-6">
                <input-text
                    type="number"
                    steps="1"
                    label="UEs aus Teilnahmebescheinigung"
                    v-model.number="value.certificateDurationUnits"
                    :errors="errors.certificateDurationUnits"
                />
            </div>

            <div class="col-span-6">
                <input-text
                    label="Teilnahme-Text"
                    v-model="value.certificateAttendanceText"
                    :errors="errors.certificateAttendanceText"
                />
            </div>

            <div class="col-span-6">
                <input-text
                    label="Titel (neutral)"
                    v-model="value.certificateTitleNeutral"
                    :errors="errors.certificateTitleNeutral"
                />
            </div>

            <div class="col-span-6">
                <input-text
                    type="textarea"
                    label="Ausbildungsschwerpunkte für Teilnahmebescheinigung"
                    v-model="value.certificateContents"
                    :errors="errors.certificateContents"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import value from "../../mixins/value";
    import InputRte from "../components/form/InputRte";
    import InputSimpleMultiSelect from "../components/form/InputSimpleMultiSelect";

    export default {
        components: {InputSimpleMultiSelect, InputRte},
        mixins: [value],
        computed: {
            portals: function () {
                return this.$store.getters.applicationSettings.model.educationInformation.portals;
            },
            teachingMethods: function () {
                return this.$store.getters.applicationSettings.model.educationInformation.teachingMethods;
            },
            typesOfDegree: function () {
                return this.$store.getters.applicationSettings.model.educationInformation.typesOfDegree;
            }
        }
    }
</script>
