<template>
    <label>
        <template v-if="label">{{label}}</template>
        <currency-input
            v-model="value"
            currency="EUR"
            locale="de"
            :aria-readonly="true"
        />
        <template v-if="errors !== null && errors.length > 0">
            <ul class="form-errors">
                <li v-for="error in errors">
                    {{error}}
                </li>
            </ul>
        </template>
    </label>
</template>

<script>
    import value from "../../../mixins/value";

    export default {
        mixins: [value],
        props: {
            label: {

            },
            errors: {
                type: Object | Array,
                default: function () {
                    return {};
                }
            },
        }
    }
</script>
