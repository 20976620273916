<template>
    <div>
        <content-header headline="Mahnungsprozess">

        </content-header>
        <content-main>
            <div class="panel">
                <div class="panel__headline">Mahnung erstellen</div>
                <div class="row">
                    <div class="columns">
                        <input-text
                            v-model="invoiceNumber"
                            label="Rechnungsnummer"
                        />
                    </div>
                    <div class="columns">
                        <label>
                            Mahnungsstufe
                            <select
                                v-model="reminder.level"
                                :disabled="!touchBooking.hasOwnProperty('@id')"
                            >
                                <option :value="null" disabled>Bitte auswählen</option>
                                <option :value="1">1. Mahnung</option>
                                <option :value="2">2. Mahnung</option>
                                <option :value="null">Dokument erstellen</option>
                            </select>

                            <ul class="form-errors">
                                <li v-for="error in errors['level']">
                                    {{ error }}
                                </li>
                            </ul>
                        </label>
                    </div>
                    <div class="columns shrink">
                        <label>
                            &nbsp;
                            <br>
                            <a @click="loadInvoice" class="button">Rechnung laden</a>
                        </label>
                    </div>
                    <div class="columns shrink">
                        <label>
                            &nbsp;
                            <br>
                            <a
                                @click="createReminder"
                                class="button"
                                :disabled="(!touchBooking.hasOwnProperty('invoiceNumber') || touchBooking.invoiceNumber !== invoiceNumber)"
                            >Mahnung erstellen</a>
                        </label>
                    </div>
                </div>

                <hr>

                <finance
                    v-if="touchBooking && touchBooking.hasOwnProperty('invoiceNumber') && touchBooking.invoiceNumber === invoiceNumber"
                    :touchBooking="touchBooking"
                />
            </div>
        </content-main>
    </div>
</template>

<script>
    import Finance from "../../../data/Person/Finance";
    import util from "../../../../plugins/util";

    export default {
        components: {
            Finance
        },
        mounted() {
            if (this.$route.query.hasOwnProperty('invoiceNumber')) {
                this.invoiceNumber = this.$route.query.invoiceNumber;
                this.loadInvoice();
            }
        },
        methods: {
            createReminder() {
                this.$sendToApi(
                    'reminders',
                    {
                        touchBooking: this.touchBooking['@id'],
                        level: this.reminder.level,
                        createAttachment: true,
                    },
                    (entity, status, data) => {
                        if(status > 299) {
                            this.errors = util.mapConstraints(data);
                        } else {
                            this.touchBooking = Object.assign({}, entity);
                        }
                    }
                );
            },
            loadInvoice() {
                this.$getFromApiSearch('touch_bookings', {internNumber: this.invoiceNumber.replace('101-', '')}, (entity) => {
                    this.$set(this, 'touchBooking', Object.assign(this.touchBooking, entity));
                });
            }
        },
        data() {
            return {
                errors: {},
                reminder: {
                    level: null,
                },
                invoiceNumber: null,
                touchBooking: {
                    invoiceNumber: null,
                }
            }
        }
    }
</script>
