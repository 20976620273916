<template>
    <div>
        <input-simple-select
            :label="label"
            :errors="errors"
            :options="options"
            v-model="value"
            v-if="!multi"
        />
        <label v-else>
            {{label}}
            <input-simple-multi-select
                :errors="errors"
                :options="options"
                v-model="value"
            />
        </label>
    </div>
</template>

<script>
    import value from "../../../mixins/value";
    import InputSimpleMultiSelect from "./InputSimpleMultiSelect";

    export default {
        components: {InputSimpleMultiSelect},
        mixins: [value],
        props: {
            multi: {
                required: false,
                default: false,
            },
            label: {
                required: true,
                default: () => ''
            },
            years: {
                required: false,
                default: () => 1
            }
        },
        computed: {
            options: function () {
                let yearStart = 2019;
                let yearEnd = (new Date()).getFullYear() + this.years;
                let options = {};
                for (var i = yearEnd; i >= yearStart; i--) {
                    for (var y = 4; y > 0; y--) {
                        let value = 'Q' + y + '/' + i;
                        if(
                            (i !== 2019 && i !== 2020)
                            || y === 4
                        ) {
                            options[value] = value;
                        }
                    }
                }

                return options;
            }
        }
    }
</script>
