<template>
    <div>
        <reveal ref="contactDataReveal" headline="Kontaktmöglichkeit hinzufügen">
            <div class="row">
                <div class="columns">
                    <form v-on:submit.prevent="submitContactData">
                        <contact-data-form v-model="contactData" :errors="contactDataErrors" v-if="value.id"/>
                        <div class="text-center">
                            <button type="submit" class="button">Speichern</button>
                        </div>
                    </form>
                </div>
            </div>
        </reveal>
        <div class="row">
            <div class="large-12 columns">
                <h3>Intern</h3>
            </div>
            <div class="large-12 columns">
                <input-simple-select
                    :options="personStati"
                    v-model="value.status"
                    :errors="errors.status"
                    label="Status"
                />
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="large-12 columns">
                <h3>Stammdaten</h3>
            </div>
        </div>
        <div class="row">
            <div class="column" :class="size === 'large' ? 'large-2' : 'large-6'">
                <label>
                    Anrede
                    <select v-model="value.gender">
                        <option :value="null"></option>
                        <option value="m">Herr</option>
                        <option value="w">Frau</option>
                        <option value="d">Diverse</option>
                    </select>
                </label>
            </div>
            <div class="column" :class="size === 'large' ? 'large-2' : 'large-6'">
                <input-text v-model="value.title" :errors="errors.title" label="Titel"/>
            </div>
            <div class="column" :class="size === 'large' ? 'large-4' : 'large-6'">
                <input-text v-model="value.firstName" :errors="errors.firstName" label="Vorname"/>
            </div>
            <div class="column" :class="size === 'large' ? 'large-4' : 'large-6'">
                <input-text v-model="value.lastName" :errors="errors.lastName" label="Nachname"/>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="large-12 columns">
                <h3>Adresse</h3>
            </div>
            <div class="column" :class="size === 'large' ? 'large-6' : 'large-12'">
                <input-text v-model="value.street" :errors="errors.street" label="Straße"/>
            </div>
            <div class="column" :class="size === 'large' ? 'large-2' : 'large-4'">
                <input-text v-model="value.zip" :errors="errors.zip" label="PLZ"/>
            </div>
            <div class="column" :class="size === 'large' ? 'large-4' : 'large-8'">
                <input-text v-model="value.city" :errors="errors.city" label="Stadt"/>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="large-12 columns">
                <h3>Kontakt</h3>
            </div>
        </div>
        <div class="align-middle row">
            <div class="column" :class="size === 'large' ? 'large-4' : 'large-12'">
                <input-text v-model="value.email" :errors="errors.email" label="E-Mail" :readonly="!!value.id"/>
            </div>
            <div class="column" :class="size === 'large' ? 'large-4' : 'large-12'">
                <input-text v-model="value.phoneNumbers" :errors="errors.phoneNumbers" label="Telefon"
                            :readonly="!!value.id"/>
            </div>
            <div class="column" :class="size === 'large' ? 'large-4' : 'large-12'">
                <a class="button small thirdly" v-if="value.id" @click="addContactData">
                    Weitere Kontaktdaten hinzufügen</a>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="large-12 columns">
                <h3>Sonstiges</h3>
            </div>
            <div class="column" :class="size === 'large' ? 'large-4' : 'large-12'">
                <input-text v-model="value.company" :errors="errors.company" label="Firma / Unternehmen"/>
            </div>
            <div class="column" :class="size === 'large' ? 'large-4' : 'large-12'">
                <div class="row">
                    <div class="columns">
                        <input-date v-model="value.birthday" :errors="errors.birthday" label="Geburtstag"/>
                    </div>
                </div>
            </div>
            <div class="column" :class="size === 'large' ? 'large-4' : 'large-12'">
                <input-simple-select
                    v-model="value.primaryContactChannel"
                    :errors="errors.primaryContactChannel"
                    label="Bevorzugter Kontakt-Kanal"
                    :options="contactChannels"
                ></input-simple-select>
            </div>
            <div class="column" :class="size === 'large' ? 'large-4' : 'large-12'">
                <label>
                    Teilnehmerplattform deaktiviert
                    <br>
                    <input
                        v-model="value.customerPortalDisabled"
                        type="checkbox"
                    />
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import EducationTypeSelect from "../components/form/EducationTypeSelect";
    import value from "../../mixins/value";
    import TouchForm from "./TouchForm";
    import ContactDataForm from "./ContactDataForm";
    import util from "../../plugins/util";
    import DataTable from "../components/DataTable";

    export default {
        mixins: [value],
        props: {
            readonly: {
                type: Boolean,
                default: () => false
            },
            size: {
                type: String,
                default: () => 'large'
            }
        },
        data() {
            return {
                contactData: {},
                contactDataErrors: {},
                ageType: null,
            }
        },
        components: {
            DataTable,
            ContactDataForm,
            TouchForm,
            EducationTypeSelect
        },
        computed: {
            contactChannels: function () {
                return this.$store.getters.applicationSettings.model.person.contactChannels;
            },
            personStati: function () {
                return this.$store.getters.applicationSettings.model.person.stati;
            }
        },
        methods: {
            addContactData: function () {
                this.$refs.contactDataReveal.open();
            },
            closeContactData: function () {
                this.contactData = [];
                this.$refs.contactDataReveal.close();
            },
            submitContactData: function () {
                this.contactData.person = this.value['@id'];
                this.contactDataErrors = [];
                this.$sendToApi('contact_datas', this.contactData, (entity, status, error) => {
                    if (status > 299) {
                        this.contactDataErrors = util.mapConstraints(error);
                    } else {
                        this.closeContactData();
                        this.contactData = {};
                    }
                });
            }
        }
    }
</script>
