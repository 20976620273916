<template>
    <div>
        <div class="grid grid-cols-4 gap-4">
            <div class="col-span-3">
                <input-text v-model="value.name" :errors="errors.name" label="Name" :readonly="readonly"/>
            </div>
            <div class="">
                <label>
                    Aktiv
                    <br>
                    <input type="checkbox" v-model="value.active" :readonly="readonly">
                </label>
            </div>
            <div class="col-span-2">
                <input-text v-model="value.code" :errors="errors.code" label="Code" :readonly="readonly"/>
            </div>

            <div class="col-span-2">
                <education-type-select v-model="value.type" :errors="errors.type" label="Typ" :readonly="readonly"/>
            </div>

            <div class="col-span-4">
                <input-entity
                    label="Lernfelder"
                    v-model="value.learningField"
                    type="learningField"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import EducationTypeSelect from "../components/form/EducationTypeSelect";

    export default {
        props: {
            value: {
                type: Object,
                required: true,
            },
            errors: {
                type: Object
            },
            readonly: {
                type: Boolean,
                default: () => false
            }
        },
        components: {
            EducationTypeSelect
        },
        watch: {
            value: {
                handler(newVal) {
                    this.$emit('input', newVal);
                },
                deep: true
            }
        }
    }
</script>
