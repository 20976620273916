<template>
    <div>
        <note-modal ref="noteModal" :show-create="false"/>

        <content-header
            headline="Anwesenheiten"
        />

        <content-main>
            <data-table
                endpoint="appearances"
                ref="dataTable"
                :columns="columns"
                :filters="filters"
                :add-query-to-url="true"
                :preSetQuery="{
                    accepted: 'null'
                }"
                :fixed-query="{
                    disableCourse: true
                }"
            >
                <template slot="dataRow" slot-scope="{singleData: appearance}">
                    <td>
                        {{ appearance.date|formatDate('LL') }}
                        <br>
                        {{ appearance.durationUnits }} UE
                    </td>
                    <td>
                        <router-link :to="'/persons/' + appearance.person.id">
                            {{ appearance.person.displayName }}
                        </router-link>
                    </td>
                    <td>
                        <template v-if="appearance.location">
                            {{appearance.location.name}}
                        </template>
                    </td>
                    <td>
                        {{ appearance.type|dataTrans('model.appearance.type') }}
                    </td>
                    <td>
                        <template v-if="appearance.accepted === true">
                            Ja
                        </template>
                        <template v-else-if="appearance.accepted === false">
                            Nein
                            <template v-if="appearance.notAcceptedReason">
                                <br>
                                {{appearance.notAcceptedReason|dataTrans('model.appearance.notAcceptedReason')}}
                            </template>
                        </template>
                        <template v-else>
                            Ausstehend
                        </template>
                    </td>
                    <td>
                        <a @click="$refs.noteModal.open(appearance['@id'])">
                            Kommentare
                        </a>
                    </td>
                    <td>
                        <div
                            v-if="appearance.accepted === null"
                            class="grid gap-4"
                        >
                            <a
                                @click="setAccepted(appearance, true)"
                                class="underline hover:no-underline"
                            >
                                Anwesenheit akzeptieren
                            </a>
                            <a
                                @click="openNotAcceptedModal(appearance)"
                                class="underline hover:no-underline"
                            >
                                Anwesenheit ablehnen
                            </a>
                        </div>
                    </td>
                </template>
            </data-table>

            <modal ref="notAcceptedModal" headline="Anwesenheit ablehnen">
                <div class="py-8 grid gap-4 px-4">
                    <input-simple-select
                        v-model="editedAppearance.notAcceptedReason"
                        :options="$store.getters.applicationSettings.model.appearance.notAcceptedReason"
                        label="Grund"
                    />
                    <div class="text-center">
                        <button
                            :disabled="!editedAppearance.notAcceptedReason"
                            @click="setAccepted(editedAppearance, false)"
                            type="submit" class="button">
                            Ablehnen
                        </button>
                    </div>
                </div>
            </modal>
        </content-main>
    </div>
</template>

<script>
    import DataTable from "@/components/DataTable.vue";
    import NoteModal from "@/data/NoteModal.vue";
    import Modal from "@/components/Modal.vue";

    export default {
        components: {NoteModal, DataTable, Modal},
        props: {},
        data() {
            return {
                editedAppearance: {},
            }
        },
        methods: {
            setAccepted(appearance, status = null) {
                let _appearance = Object.assign({}, appearance);
                if(status) {
                    _appearance.accepted = status;
                }
                this.$sendToApi('appearances', _appearance, (entity, status, data) => {
                    if(status < 300) {
                        this.$refs.notAcceptedModal.hide();
                        this.$refs.dataTable.reload();
                    }
                });
            },
            openNotAcceptedModal(appearance) {
                this.editedAppearance = {...appearance};
                this.editedAppearance.accepted = false;
                this.$refs.notAcceptedModal.show();
            }
        },
        computed: {
            filters() {
                let bookingTypes = this.$store.getters.applicationSettings.model.touchBooking.bookingType;

                return [
                    {
                        type: 'dateRange',
                        model: 'date',
                        label: 'Datum'
                    },
                    {
                        type: 'entity',
                        entity: 'location',
                        model: 'connectedLocation',
                        label: 'Standort',
                        callback: function () {
                            return 'Ein Datensatz ausgewählt';
                        }
                    },
                    {
                        type: 'select',
                        model: 'accepted',
                        label: 'Akzeptiert',
                        options: {
                            'null': 'Ausstehend',
                            1: 'Akzeptiert',
                            0: 'Nicht akzeptiert',
                        },
                        callback: function (item) {
                            if (item === 'null') {
                                return 'Ausstehend';
                            }
                            if (item === 1 || item === '1') {
                                return 'Akzeptiert';
                            }
                            if (item === 0 || item === '0') {
                                return 'Nicht Akzeptiert';
                            }
                            return '-';
                        }
                    },
                    {
                        label: 'Buchungs-Typ',
                        type: 'select',
                        model: 'bookingType',
                        options: bookingTypes,
                        callback: function (item) {
                            return bookingTypes[item];
                        }
                    },
                ];
            },
            columns() {
                return {
                    date: {
                        text: 'Datum',
                        order: true,
                    },
                    person: {
                        text: 'Person',
                    },
                    location: {
                        text: 'Standort',
                    },
                    type: {
                        text: 'Typ',
                    },
                    accepted: {
                        text: 'Akzeptiert',
                    },
                    notes: {
                        text: 'Kommentare/Notizen',
                    },
                };
            },
        }
    }
</script>
