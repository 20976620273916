<template>
    <div>
        <content-header headline="Kurse">
            <a
                    v-if="$store.getters.hasLoggedInUserCredibility('ROLE_EDUCATION_MANAGEMENT')"
                    class="button"
                    @click="$refs.dataTable.createData({applicationStatus: 'home'})"
            >
                Kurs hinzufügen
            </a>
        </content-header>
        <content-main>
            <courses-table
                ref="dataTable"
                :addQueryToUrl="true"
            />
        </content-main>
    </div>
</template>

<script>
    import CoursesTable from "../../data/CoursesTable";

    export default {
        components: {
            CoursesTable
        }
    }
</script>
