<template>
    <c-modal
        ref="eventCourseDateNotificationModal"
        headline="E-Mail-Benachrichtigung an Kursteilnehmer">
        <form
            v-if="eventCourseDate.course && eventCourseDate.course.hasOwnProperty('code')"
            v-on:submit.prevent="submit"
            class="grid p-4 gap-4"
        >
            <div class="callout warning mb-0">
                Benachrichtigung an Teilnehmer des Kurstermins {{ eventCourseDate.course.code }}
                ({{ eventCourseDate.date|formatDate('LLL') }})
            </div>

            <input-text
                class="mb-0"
                label="Betreff"
                v-model="notification.subject"
                :errors="errors.subject"
            />

            <input-text
                class="mb-0"
                label="Text"
                type="textarea"
                v-model="notification.message"
                :errors="errors.message"
            />

            <div class="text-center">
                <button type="submit" class="button mb-0">
                    Verschicken
                </button>
            </div>
        </form>
    </c-modal>
</template>
<script>
    import util from "../../../plugins/util";
    export default {
        props: {
            course: {
                required: false,
            }
        },
        data() {
            return {
                notification: {},
                errors: {},
                eventCourseDate: null,
            }
        },
        methods: {
            open(eventCourseDate) {
                this.eventCourseDate = eventCourseDate;
                this.notification.eventCourseDate = eventCourseDate['@id'];
                this.$refs.eventCourseDateNotificationModal.open();
            },
            close() {
                this.$refs.eventCourseDateNotificationModal.close();
            },
            submit() {
                this.errors = {};
                this.$sendToApi(
                    'event_course_date_notifications',
                    this.notification,
                    (entity, status, data) => {
                        if(status > 299) {
                            this.errors = util.mapConstraints(data);
                        } else {
                            this.notification = {};
                            this.close();
                        }
                    }
                )
            }
        }
    }
</script>
