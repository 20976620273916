<template>
    <c-modal
        ref="documentModal"
        headline="Dokument erstellen"
    >
        <form
            class="grid gap-4 grid-cols-2 p-4 py-12"
            v-on:submit.prevent="submit"
        >
            <label class="col-span-2">
                Dokument
                <select v-model="document.type">
                    <option :value="null" disabled selected>Bitte auswählen</option>
                    <option
                        value="education_certificates"
                    >
                        Ausbildungsbescheinigung
                    </option>
                    <option
                        value="cancellation_supported_booking_job_centers"
                    >
                        Antritt/Austritt (Job-Center)
                    </option>
                    <option
                        value="cancellation_supported_booking_labour_exchanges"
                    >
                        Antritt/Austritt (Arbeitsamt)
                    </option>
                </select>
            </label>

            <div class="col-span-2">
                <input-entity
                    label="Buchung"
                    type="touchBooking"
                    v-model="document.touchBooking"
                    :fixed-params="{
                        person: person['@id']
                    }"
                    :errors="documentErrors.touchBooking"
                />
            </div>

            <div class="col-span-2 text-center">
                <button
                    type="submit"
                    class="button"
                    :disabled="!document.type"
                >
                    Dokument erstellen
                </button>
            </div>

            <!--<div class="p-4 bg-gray-100 text-center font-bold">
                Abmahnung
            </div>
            <div class="p-4 bg-gray-100 text-center font-bold">
                Ausbildungsbescheinigung
            </div>
            <div class="p-4 bg-gray-100 text-center font-bold">
                Pflicht-TN Besche. Nach §179
            </div>
            <div class="p-4 bg-gray-100 text-center font-bold">
                Maßnahmenabbruch / Ende
            </div>
            <div class="p-4 bg-gray-100 text-center font-bold">
                Anschreiben Erfolgsbeobachtung
            </div>-->
        </form>
    </c-modal>
</template>

<script>
    import util from "../../../plugins/util";

    export default {
        data() {
            return {
                person: {},
                document: {
                    type: null,
                },
                documentErrors: {}
            }
        },
        methods: {
            open(person) {
                this.person = person;
                this.$refs.documentModal.show();
            },
            close() {
                this.$refs.documentModal.hide();
            },
            submit() {
                this.$http.post(
                    'document/' + this.document.type,
                    this.document
                ).then((res) => {
                    console.log(res);
                    this.close();
                }, (res) => {
                    this.documentErrors = util.mapConstraints(res.body);
                });
            }
        }
    }
</script>
