<template>
    <div>
        <update-modal ref="eventCourseDateUpdateModal" v-on:update="$refs.dataTable.reload()"/>

        <note-modal
            ref="noteModal"
        />

        <event-course-date-appearance-report-modal
            ref="appearanceReportModal"
        />

        <notification-modal
            ref="notificationModal"
        />

        <c-modal
            ref="appearanceModal"
            headline="Anwesenheiten"
            v-on:update="$refs.dataTable.reload()"
            :width="800"
        >
            <div style="padding: 1rem;">
                <AppearanceTable
                    :eventCourseDate="eventCourseDate"
                />
            </div>
        </c-modal>

        <data-table
            endpoint="event_course_dates"
            :columns="columns"
            :filters="filters"
            ref="dataTable"
            updateRevealSize="small"
            :fixed-query="fixedQuery"
            :add-query-to-url="addQueryToUrl"
        >
            <template slot="updateForm" slot-scope="{data: course, errors: errors}"
                      v-if="$store.getters.hasLoggedInUserCredibility('ROLE_EDUCATION_MANAGEMENT')">
                <div class="row">
                    <div class="large-6 columns">
                        <input-text v-model="course.code" :errors="errors.code" label="Kürzel"/>
                    </div>
                    <div class="large-3 columns">
                        <label>
                            Auf Anfrage
                            <br>
                            <input type="checkbox" v-model="course.onDemand">
                        </label>
                    </div>
                    <div class="large-3 columns">
                        <label>
                            Aktiv
                            <br>
                            <input type="checkbox" v-model="course.active">
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="large-12 columns">
                        <input-entity
                            label="Ausbildung/Seminar"
                            v-model="course.education"
                            :errors="errors.education"
                            type="education"
                            openDirection="above"
                        />
                    </div>

                    <div class="large-12 columns">
                        <input-entity
                            label="Standort"
                            v-model="course.location"
                            :errors="errors.location"
                            type="location"
                            openDirection="above"
                        />
                    </div>
                </div>
            </template>

            <template slot="dataRow" slot-scope="{singleData: courseDate}">
                <td>
                    <strong>{{ courseDate.date|formatDate('ddd') }}
                        <br>
                        {{ courseDate.date|formatDate('L') }}
                    </strong>
                    <template v-if="courseDate.courseEventType">
                        <br>
                        <span
                            class="bg-green-900 text-xs text-white py-1 px-2 rounded-full"
                        >
                        <template v-if="courseDate.courseEventType === 'first'">
                            Start
                        </template>
                        <template v-else-if="courseDate.courseEventType === 'last'">
                            Ende
                        </template>
                        </span>
                    </template>
                    <div
                        v-if="courseDate.curriculumTopic && courseDate.curriculumTopic.tags"
                        class="grid grid-flow-col gap-2 justify-start mt-2"
                    >
                        <span
                            v-for="tag in courseDate.curriculumTopic.tags"
                            class="bg-brand text-xs text-white py-1 px-2 rounded-full"
                        >
                            {{ tag|dataTrans('model.curriculumTopic.tag') }}
                        </span>
                    </div>
                </td>
                <td>
                    <router-link
                        v-if="courseDate.course"
                        :to="'/courses/' + courseDate.course.id"
                    >
                        {{ courseDate.course.code }}
                    </router-link>
                    <br>
                    {{ courseDate.educationName }}
                    <br>
                    {{ courseDate.locationName }}
                </td>
                <td>
                    <router-link
                        v-if="courseDate.curriculumTopic"
                        :to="'/teaching/curricula/' + courseDate.curriculumTopic.curriculum.id"
                    >
                        {{ courseDate.curriculumTopic.block }}-{{ courseDate.curriculumTopic.day }}
                        <br>
                        {{ courseDate.curriculumTopic.name }}
                    </router-link>
                    <template
                        v-if="courseDate.curriculumTopic"
                    >
                        <br>
                        {{ courseDate.curriculumTopic.durationUnits }} UEs
                    </template>
                </td>
                <td>
                    <template v-if="courseDate.room">
                        <slug-with-detail :room="courseDate.room"/>
                    </template>
                </td>
                <td>
                    <template v-if="courseDate.docent">
                        {{ courseDate.docent.displayName }}
                    </template>
                    <template v-else>
                        Kein Dozent
                        <br>
                        <router-link
                            :to="{path: '/erp/eventCourseApplications', query: {eventCourseDate: courseDate['@id']}}"
                        >
                            {{ courseDate.applicationsCount }} Bewerbung(en)
                        </router-link>
                        <template v-if="courseDate.highestApplicationStatus">
                            <br>
                            <br>
                            Höchster Bewerbungsstatus:
                            <br>
                            <span class="px-2 py-1 text-brand border-[1px] border-solid border-brand rounded-xl text-xs whitespace-nowrap">
                            {{ courseDate.highestApplicationStatus|dataTrans('model.eventCourseDateApplication.status')}}
                            </span>
                        </template>
                    </template>
                </td>
                <td>
                    <template v-if="courseDate.allAppearances">Ja</template>
                    <template v-else>Nein</template>
                </td>
                <td class="whitespace-nowrap">
                    Eingebucht: {{ courseDate.participants.standard }}
                    <br>
                    Gasthörer: {{ courseDate.participants.guests }}
                </td>
                <td>
                    <span
                        v-if="courseDate.docent && courseDate.room && courseDate.curriculumTopic && (courseDate.inFuture || courseDate.allAppearances)"
                        class="bg-green-500 px-4 py-1 text-white"
                    >
                        OK
                    </span>
                    <span
                        v-else
                        class="bg-warning-500 px-4 py-1 text-white"
                    >
                        Warnung
                    </span>
                </td>
                <td>
                    <a
                        class="underline hover:no-underline"
                        @click="$refs.noteModal.open(courseDate['@id'])"
                    >
                        {{ courseDate.noteCount }} Notiz(en)
                    </a>
                </td>
                <td>
                    {{ courseDate.lastEditedAt|formatDate('lll') }}
                </td>
                <td class="text-right">
                    <div class="grid grid-flow-col gap-1">
                        <a
                            @click="showAppearance(courseDate)"
                            title="Anwesenheiten"
                            class="action --list"
                        >

                        </a>
                        <template v-if="eventCourseDate.course">
                            <router-link
                                v-if="$route.path !== '/courses/' + courseDate.course.id"
                                :to="'/courses/' + courseDate.course.id"
                                class="action --show"
                            />
                        </template>
                        <a
                            class="action --edit"
                            @click="updateEventCourseDate(courseDate)"
                        />
                        <a
                            v-if="courseDate.userCanDelete"
                            class="action --delete"
                            @click="$refs.dataTable.deleteData(courseDate)"
                        />
                        <a
                            class="action --mail"
                            @click="$refs.notificationModal.open(courseDate)"
                        />
                        <a
                            class="action --book"
                            @click="$refs.appearanceReportModal.open(courseDate)"
                        />
                    </div>
                </td>
            </template>
        </data-table>
    </div>
</template>

<script>
    import DataTable from "../components/DataTable";
    import AppearanceTable from './Appearance/Table';
    import Notes from "@/data/Notes.vue";
    import UpdateModal from "@/data/EventCourseDate/UpdateModal.vue";
    import NoteModal from "@/data/NoteModal.vue";
    import SlugWithDetail from "@/data/Room/SlugWithDetail.vue";
    import NotificationModal from "@/data/EventCourseDate/NotificationModal.vue";
    import EventCourseDateAppearanceReportModal from "@/data/EventCourseDate/AppearanceReportModal.vue";

    export default {
        components: {
            EventCourseDateAppearanceReportModal,
            NotificationModal,
            SlugWithDetail,
            UpdateModal,
            Notes,
            AppearanceTable,
            DataTable,
            NoteModal
        },
        props: {
            endpoint: {
                type: Text | String,
                default: 'courseDates'
            },
            disableFilter: {
                type: Array,
                default: () => []
            },
            fixedQuery: {
                type: Object,
                default: () => {
                }
            },
            addQueryToUrl: {
                type: Boolean,
                default: () => false
            }
        },
        computed: {
            filters() {
                let _this = this;
                let filter = [
                    {
                        type: 'text',
                        model: 'course.code',
                        label: 'Kurs-Kürzel'
                    },
                    {
                        type: 'entity',
                        entity: 'location',
                        model: 'course.location',
                        label: 'Standort',
                        callback: function () {
                            return 'Ein Datensatz ausgewählt';
                        }
                    },
                    {
                        type: 'text',
                        model: 'course.education.name',
                        label: 'Ausbildung / Seminar'
                    },
                    {
                        type: 'dateRange',
                        model: 'date',
                        label: 'Zeitraum'
                    }
                ];

                filter = filter.filter(item => {
                    return this.disableFilter.includes(item.model) === false;
                });

                return filter;
            }
        },
        methods: {
            updateEventCourseDate(eventCourseDate) {
                this.$refs.eventCourseDateUpdateModal.open(eventCourseDate);
            },
            showAppearance(eventCourseDate) {
                this.eventCourseDate = eventCourseDate;
                this.$refs.appearanceModal.open();
            },
            createData(eventCourseDate) {
                this.$refs.dataTable.createData(eventCourseDate);
            }
        },
        data() {
            return {
                editedEventCourseDate: {},
                editedEventCourseDateErrors: {},
                eventCourseDate: {},
                columns: {
                    date: {
                        text: 'Datum',
                        order: true
                    },
                    course: {
                        text: 'Kurs',
                        order: true
                    },
                    curriculumTopic: {
                        text: 'Curriculum-Thema',
                        order: true
                    },
                    room: {
                        text: 'Raum',
                        order: false,
                    },
                    docent: {
                        text: 'Dozent',
                    },
                    allAppearances: {
                        text: 'Anwesenheiten'
                    },
                    participants: {
                        text: 'Teilnehmer',
                    },
                    status: {
                        text: 'Status'
                    },
                    noteCount: {
                        text: 'Notizen'
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    },
                }
            }
        }
    }
</script>
