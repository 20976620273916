<template>
    <div class="">
        <content-header
            headline="Massen-E-Mails"
        ></content-header>

        <content-main>
            <div class="grid grid-cols-3 gap-4 p-8 bg-white">
                <div class="uppercase col-start-1 col-end-4 text-xl font-bold">
                    Versand von Massen-E-Mails
                </div>

                <div class="col-start-1 col-end-4">
                    <input-text
                        type="textarea"
                        label="E-Mail-Adressen (kommasepariert)"
                        :errors="bulkEmailErrors.emails"
                        v-model="bulkEmail.emailAsString"
                    />

                    <template v-if="bulkEmail.emails.length > 0">
                        <div class="uppercase text-lg font-bold">
                            An E-Mails aus Textfeld
                        </div>

                        <ul>
                            <li v-for="email in bulkEmail.emails">{{ email }}</li>
                        </ul>
                    </template>
                </div>

                <div>
                    <input-entity
                        v-model="bulkEmail.reporting"
                        type="reporting"
                        label="An alle Personen in Reporting"
                    />
                </div>

                <div>
                    <input-entity
                        v-model="bulkEmail.course"
                        type="course"
                        label="An alle Personen in Kurs"
                    />
                </div>

                <div>
                    <input-entity
                        v-model="bulkEmail.templateTouchCustom"
                        type="templateTouchCustom"
                        label="Template Touch setzen"
                    />
                </div>

                <div class="col-start-1 col-end-4">
                    <input-text
                        label="Betreff"
                        :errors="bulkEmailErrors.subject"
                        v-model="bulkEmail.subject"
                    />
                </div>

                <div class="col-start-1 col-end-4">
                    <input-text
                        type="textarea"
                        label="E-Mail-Text"
                        :errors="bulkEmailErrors.text"
                        v-model="bulkEmail.text"
                    />
                    <p class="-mt-2">
                        Verfügbare Platzhalter <span v-html="'{{ salutation }}'"></span>
                    </p>
                </div>

                <div class="col-start-1 col-end-4">
                    <dropzone
                        accepted-files="application/pdf,image/png,image/jpeg,image/jpg"
                        :max-files="5"
                        endpoint="attachments"
                        :removeOnResponse="true"
                        v-on:complete="complete"
                    />

                    <template v-if="bulkEmail.attachments.length > 0">
                        <div class="uppercase text-lg font-bold">
                            Anhänge
                        </div>

                        <ul v-for="(attachment, index) in bulkEmail.attachments">
                            <li>{{ attachment.name }} <a class="underline"
                                                         @click="removeAttachment(index)">(Löschen)</a></li>
                        </ul>
                    </template>
                </div>

                <div>
                    <input-simple-select
                        :options="validSenderEmailAddresses"
                        label="Absender-E-Mail"
                        :errors="bulkEmailErrors.senderEmailAddress"
                        v-model="bulkEmail.senderEmailAddress"
                    />
                </div>

                <div>
                    <input-text
                        label="Absender-Name"
                        :errors="bulkEmailErrors.senderEmailName"
                        v-model="bulkEmail.senderEmailName"
                    />
                </div>

                <div>
                    <input-text
                        label="Reply-To"
                        :errors="bulkEmailErrors.replyToEmailAddress"
                        v-model="bulkEmail.replyToEmailAddress"
                    />
                </div>
                <div class="col-start-1 col-end-4 text-center">
                    <a class="button" @click="submit">E-Mails versenden</a>
                </div>
            </div>
        </content-main>
    </div>
</template>

<script>
    import InputText from "../../../components/form/InputText.vue";
    import InputEntity from "../../../components/form/InputEntity.vue";
    import Util from "../../../../plugins/util";
    import Dropzone from "../../../components/Dropzone.vue";

    export default {
        components: {
            Dropzone,
            InputText,
            InputEntity,
        },
        mounted() {
            this.resetBulkEmail();
        },
        data() {
            return {
                bulkEmailErrors: {},
                bulkEmail: {}
            }
        },
        watch: {
            'bulkEmail.emailAsString': function (newVal) {
                this.bulkEmail.emails = newVal.split(',').map((item) => item.trim()).filter((item) => item.length > 0);
            }
        },
        computed: {
            validSenderEmailAddresses() {
                return [
                    'zentralebildungsberatung@campusnaturalis.de',
                    'info@campusnaturalis.de',
                    'berlin@campusnaturalis.de',
                    'essen@campusnaturalis.de',
                    'frankfurt@campusnaturalis.de',
                    'hamburg@campusnaturalis.de',
                    'koeln@campusnaturalis.de',
                    'leipzig@campusnaturalis.de',
                    'muenchen@campusnaturalis.de',
                ].reduce((a, b) => {
                    a[b] = b;
                    return a;
                }, {});
            }
        },
        methods: {
            removeAttachment(index) {
                this.bulkEmail.attachments.splice(index, 1);
            },
            resetBulkEmail() {
                this.bulkEmail = {
                    emails: [],
                    emailAsString: '',
                    reporting: null,
                    course: null,
                    text: '',
                    subject: '',
                    senderEmailName: 'campus naturalis',
                    senderEmailAddress: '',
                    replyToEmailAddress: '',
                    templateTouchCustom: null,
                    attachments: [],
                }
            },
            complete(data) {
                if (data.hasOwnProperty('id')) {
                    this.bulkEmail.attachments.push(data);
                }
            },
            submit() {
                let _this = this;
                let data = Object.assign({}, this.bulkEmail);
                data.attachments = data.attachments.map((item) => {
                    return item.id;
                });

                this.$dialog
                    .confirm('Wirklich verschicken?', {
                        okText: 'Ja',
                        cancelText: 'Abbrechen',
                    })
                    .then(function (dialog) {
                        console.log("test");
                        _this.$sendToApi('bulk_emails', data, (entity, status, data) => {
                            _this.bulkEmailErrors = {};
                            if (status > 299) {
                                _this.bulkEmailErrors = Util.mapConstraints(data);
                            } else {
                                _this.resetBulkEmail();
                            }
                        });
                    })
                    .catch(function () {
                    });
            }
        }
    }
</script>
