<template>
    <content-main>
        <data-table
                endpoint="settings"
                :columns="columns"
                :filters="filters"
                ref="dataTable"
                updateRevealSize="small"
        >
            <template slot="updateForm" slot-scope="{data: setting, errors: errors}">
                <div class="row">
                    <div class="large-12 columns">
                        <template v-if="setting.type === 'text'">
                            <input-text v-model="setting.value" :label="setting.key" :errors="errors.value"/>
                        </template>
                        <template v-if="setting.type === 'textarea'">
                            <input-text v-model="setting.value" :label="setting.key" :errors="errors.value" type="textarea"/>
                        </template>
                        <template v-if="setting.type === 'file'">
                            <dropzone
                                :endpoint="'settings/' + setting.id"
                                v-on:queue-complete="closeAndUpdate()"
                            />
                        </template>
                    </div>
                </div>
            </template>
            <template slot="dataRow" slot-scope="{singleData: setting}">
                <td>
                    {{setting.key}}
                </td>
                <td>
                    {{setting.displayValue}}
                </td>
                <td>
                    <a v-if="setting.userCanUpdate" class="action --edit" @click="$refs.dataTable.editData(setting)"></a>
                </td>
            </template>
        </data-table>
    </content-main>
</template>

<script>
    import DataTable from "../../../components/DataTable";
    import Dropzone from "../../../components/Dropzone";

    export default {
        components: {
            Dropzone,
            DataTable
        },
        props: {},
        methods: {
            closeAndUpdate() {
                this.$refs.dataTable.closeUpdateReveal();
                this.$refs.dataTable.reload();
                this.$notify({
                    type: 'success',
                    text: 'Erfolgreich gespeichert'
                })
            }
        },
        computed: {
            filters() {
                return [];
            }
        },
        data() {
            return {
                columns: {
                    key: {
                        text: 'Name',
                        order: true
                    },
                    value: {
                        text: 'Wert',
                        order: false
                    }
                }
            }
        }
    }
</script>
