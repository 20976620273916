<template>
    <div>
        <content-header headline="Individuelle Touches (Vorlagen)">
            <a class="button" @click="$refs.dataTable.createData()">Hinzufügen</a>
        </content-header>
        <content-main>
            <data-table
                    endpoint="template_touch_customs"
                    :columns="columns"
                    :filters="filters"
                    ref="dataTable"
            >
                <template slot="updateForm" slot-scope="{data: templateTouchCustom, errors: errors}">
                    <div class="row">
                        <div class="large-6 columns">
                            <input-text v-model="templateTouchCustom.name" :errors="errors.name" label="Bezeichnung"/>
                        </div>
                        <div class="large-3 columns">
                            <label>
                                In Touch A Liste anzeigen
                                <br>
                                <input type="checkbox" v-model="templateTouchCustom.showAsImportantTouch"/>
                            </label>
                        </div>
                        <div class="large-3 columns">
                            <input-text v-model.number="templateTouchCustom.scoringPoints" :errors="errors.name" label="Scoring-Punkte"/>
                        </div>
                        <div class="large-12 columns">
                            <input-text v-model="templateTouchCustom.text" type="textarea" label="Beschreibung / Text / Mail-Inhalt"/>
                        </div>
                    </div>
                </template>

                <template slot="dataRow" slot-scope="{singleData: templateTouchCustom}">
                    <td>
                        {{templateTouchCustom.name}}
                    </td>
                    <td>
                        {{templateTouchCustom.scoringPoints}}
                    </td>
                    <td>
                        {{templateTouchCustom.lastEditedAt|formatDate('LLL')}}
                    </td>
                    <td class="text-right">
                        <a v-if="templateTouchCustom.userCanUpdate" class="action --edit" @click="$refs.dataTable.editData(templateTouchCustom)"></a>
                    </td>
                </template>
            </data-table>
        </content-main>
    </div>
</template>

<script>
    import DataTable from "../../../components/DataTable";
    import InputSelect from "../../../components/form/InputSelect";

    export default {
        components: {
            InputSelect,
            DataTable
        },
        data() {
            return {
                filters: [
                    {
                        type: 'text',
                        model: 'name',
                        label: 'Name'
                    }
                ],
                columns: {
                    name: {
                        text: 'Name',
                        order: true,
                    },
                    scoringPoints: {
                        text: 'ScoringPoints',
                        order: false,
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    },
                }
            }
        }
    }
</script>
