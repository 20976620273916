<template>
    <form
        class="grid grid-cols-12 gap-4"
        v-on:submit.prevent="submit"
        v-if="editedEntity.hasOwnProperty('@id')"
    >
        <h2 class="col-span-12">
            Allgemeine Daten
        </h2>

        <input-text
            class="col-span-4 mb-0"
            label="Titel"
            v-model="editedEntity.title"
            :errors="errors.title"
        />
        <input-text
            class="col-span-6 mb-0"
            label="Titel (ausgeschrieben)"
            v-model="editedEntity.displayName"
            :errors="errors.displayName"
        />

        <input-text
            class="col-span-2 mb-0"
            label="Monate"
            v-model.number="editedEntity.durationInMonths"
            :errors="errors.durationInMonths"
        />

        <input-text
            type="textarea"
            class="col-span-12 mb-0"
            label="Lehrmaterialien"
            v-model="editedEntity.learningMaterials"
            :errors="errors.learningMaterials"
        />

        <input-text
            type="textarea"
            class="col-span-12 mb-0"
            label="Thema des Beratungsgesprächs"
            v-model="editedEntity.consultationTopic"
            :errors="errors.consultationTopic"
        />

        <input-text
            type="textarea"
            class="col-span-12 mb-0"
            label="Berufsfeld"
            v-model="editedEntity.careerField"
            :errors="errors.careerField"
        />

        <div class="col-span-2">
            <label>
                Ärztliches Attest
                <br>
                <input
                    type="checkbox"
                    v-model="editedEntity.medicalCertificate"
                />
            </label>
        </div>

        <div class="col-span-2">
            <label>
                Kunstfertigkeiten
                <br>
                <input
                    type="checkbox"
                    v-model="editedEntity.artTherapy"
                />
            </label>
        </div>

        <div class="col-span-2">
            <label>
                Führungszeugnis
                <br>
                <input
                    type="checkbox"
                    v-model="editedEntity.policeRecord"
                />
            </label>
        </div>

        <div class="col-span-2">
            <label>
                Erweitertes Führungszeugnis
                <br>
                <input
                    type="checkbox"
                    v-model="editedEntity.advancedPoliceRecord"
                />
            </label>
        </div>

        <div class="col-span-2">
            <label>
                C1 Sprachniveau
                <br>
                <input
                    type="checkbox"
                    v-model="editedEntity.languageSkills"
                />
            </label>
        </div>

        <div class="col-span-2">
            <label>
                Mind. Alter
                <br>
                <input
                    type="number"
                    v-model.number="editedEntity.minimumAge"
                />
            </label>
        </div>

        <div class="col-span-6">
            <input-text
                label="Logos (dgkt, DBVB + BBGM) [kommasperatiert]"
                v-model="editedEntity.logos"
            />
        </div>

        <div class="col-span-12">
            <input-text
                label="Link"
                v-model="editedEntity.link"
                :errors="errors.link"
            />
        </div>

        <div class="col-span-12">
            <input-text
                label="Intro-Text"
                v-model="editedEntity.introText"
                :errors="errors.introText"
            />
        </div>

        <input-date
            class="col-span-5 mb-0"
            label="Erstellt am"
            v-model="editedEntity.supportCertificateCreatedAt"
            :errors="errors.supportCertificateCreatedAt"
        />
        <input-date
            class="col-span-5 mb-0"
            label="Bestätigt am"
            v-model="editedEntity.supportCertificateApprovedAt"
            :errors="errors.supportCertificateApprovedAt"
        />
        <input-text
            type="number"
            class="col-span-2 mb-0"
            label="Revision"
            v-model="editedEntity.revisionNumber"
            :errors="errors.revisionNumber"
        />

        <div class="col-span-12">
            <hr>
        </div>

        <h2 class="col-span-12">
            UEs
        </h2>

        <div class="col-span-12 grid grid-flow-col gap-4 justify-between">
            <input-text
                label="UEs (Gesamt)"
                type="number"
                v-model.number="editedEntity.durationUnits"
                :errors="errors.durationUnits"
            />
            <input-text
                label="UEs (Präsenzzeit)"
                type="number"
                v-model.number="editedEntity.durationUnitsPresence"
                :errors="errors.durationUnitsPresence"
            />
            <input-text
                label="UEs (Kleingruppe)"
                type="number"
                v-model.number="editedEntity.durationUnitsGroup"
                :errors="errors.durationUnitsGroup"
            />
            <input-text
                label="UEs (Abschlussarbeit)"
                type="number"
                v-model.number="editedEntity.durationUnitsFinalThesis"
                :errors="errors.durationUnitsFinalThesis"
            />
            <input-text
                label="UEs (Projekttage)"
                type="number"
                v-model.number="editedEntity.durationUnitsProject"
                :errors="errors.durationUnitsProject"
            />
            <input-text
                label="UEs (Praxisdokumentation )"
                type="number"
                v-model.number="editedEntity.durationUnitsPracticeDocumentation"
                :errors="errors.durationUnitsPracticeDocumentation"
            />
            <input-text
                label="UEs (Praktikum)"
                type="number"
                v-model.number="editedEntity.durationUnitsIntership"
                :errors="errors.durationUnitsIntership"
            />
        </div>

        <h2 class="col-span-12">
            Checklisten-Punkte
        </h2>

        <div
            v-if="!editedEntity.checklistItems || editedEntity.checklistItems.length === 0"
            class="callout warning col-span-12"
        >
            Keine Checklisten-Punkte vorhanden.
        </div>

        <ul
            class="grid col-span-12 m-0 pb-0 list-none grid-cols-2 gap-x-8"
            :class="{
                'bg-gray-100 p-4': editedEntity.checklistItems && editedEntity.checklistItems.length > 0,
                'p-0': !editedEntity.checklistItems || editedEntity.checklistItems.length === 0,
            }"
        >
            <li v-for="checklistItem, index in editedEntity.checklistItems">
                <div class="grid grid-cols-[1fr_auto] gap-4 items-center">
                    <input-text
                        label="Checklisten-Punkt"
                        class="mb-0"
                        type="text"
                        v-model="editedEntity.checklistItems[index]"
                    />
                    <div>
                        <a
                            @click="removeChecklistItem(index)"
                            class="button thirdly small mb-0"
                        >
                            Entfernen
                        </a>
                    </div>
                </div>
            </li>
            <li class="text-center col-span-2">
                <a
                    @click="addChecklistItem"
                    class="button thirdly small"
                >
                    Checklisten Punkt hinzufügen
                </a>
            </li>
        </ul>

        <div class="col-span-12">
            <hr>
        </div>

        <div class="text-center col-span-12">
            <button
                type="submit"
                class="button"
            >
                Aktualisieren
            </button>
        </div>
    </form>
</template>

<script>
    export default {
        props: {
            educationSupportInformation: {}
        },
        data() {
            return {
                editedEntity: {},
                errors: {},
            }
        },
        watch: {
            'educationSupportInformation[@id]': function () {
                this.loadEducationSupportInformation();
            }
        },
        mounted() {
            this.loadEducationSupportInformation();
        },
        methods: {
            submit() {
                this.$sendToApi('education_supported_informations', this.editedEntity, (entity, status, data) => {
                    this.errors = {};
                    if (status > 299) {
                        this.errors = data;
                    } else {
                        this.loadEducationSupportInformation();
                    }
                });
            },
            async loadEducationSupportInformation() {
                this.editedEntity = await this.$http.get(this.educationSupportInformation['@id'].substr(5)).then((res) => res.body);
                if (!this.editedEntity.hasOwnProperty('checklistItems')) {
                    this.$set(this.editedEntity, 'checklistItems', []);
                }
            },
            addChecklistItem() {
                this.editedEntity.checklistItems.push('');
            },
            removeChecklistItem(index) {
                delete this.editedEntity.checklistItems[index];
                this.$set(this.editedEntity, 'checklistItems', Object.values(this.editedEntity.checklistItems));
            }
        }
    }
</script>
