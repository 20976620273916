<template>
    <c-modal
        :headline="'Kurstermin-Details (' + editedEventCourseDate.id + ')'"
        ref="eventCourseDateDetails"
        class=""
        :width="1200"
    >
        <div
            class="grid grid-cols-2 gap-4 min-w-[800px]">
            <form
                class="gap-4 p-4 grid content-start"
                v-on:submit.prevent="submitEventCourseDate"
            >
                <div class="font-bold text-xl uppercase">
                    Allgemeine Daten
                </div>

                <div
                    class="bg-warning-100 p-4 text-warning-600 mb-4"
                >
                    Je nach Benutzer-Berechtigung können nicht alle Felder geändert werden.
                </div>

                <input-entity
                    label="Curriculum-Topic"
                    v-model="editedEventCourseDate.curriculumTopic"
                    :errors="editedEventCourseDateErrors.curriculumTopic"
                    type="curriculumTopic"
                    :readonly="
                        !$store.getters.hasLoggedInUserCredibility('ROLE_EDUCATION_MANAGEMENT')
                        && !$store.getters.hasLoggedInUserCredibility('ROLE_EDUCATION_DATE_MANAGEMENT')
                        && !$store.getters.hasLoggedInUserCredibility('ROLE_ADMINISTRATION')
                    "
                    :fixed-params="{
                        course: (editedEventCourseDate.hasOwnProperty('course') && editedEventCourseDate.course.hasOwnProperty('@id')) ? editedEventCourseDate.course['@id'] : editedEventCourseDate.course
                    }"
                />

                <div class="grid gap-4">
                    <input-entity
                        label="Dozent"
                        v-model="editedEventCourseDate.docent"
                        :errors="editedEventCourseDateErrors.docent"
                        type="docent"
                    />
                </div>

                <input-entity
                    label="Raum"
                    v-model="editedEventCourseDate.room"
                    :errors="editedEventCourseDateErrors.room"
                    type="room"
                />

                <div class="grid gap-4">
                    <div>
                        <input-date
                            label="Start"
                            v-model="editedEventCourseDate.date"
                            :errors="editedEventCourseDateErrors.date"
                            :time="true"
                            :readonly="
                                !$store.getters.hasLoggedInUserCredibility('ROLE_EDUCATION_MANAGEMENT')
                                && !$store.getters.hasLoggedInUserCredibility('ROLE_ADMINISTRATION')
                            "
                        />
                    </div>
                    <div>
                        <input-text
                            label="Dauer"
                            v-model.number="editedEventCourseDate.duration"
                            :errors="editedEventCourseDateErrors.duration"
                            :readonly="
                                !$store.getters.hasLoggedInUserCredibility('ROLE_EDUCATION_MANAGEMENT')
                                && !$store.getters.hasLoggedInUserCredibility('ROLE_ADMINISTRATION')
                            "
                        />
                    </div>
                </div>

                <input-entity
                    label="Gasthörer-Rechnungen"
                    :multi="true"
                    v-model="editedEventCourseDate.guestTouchBookings"
                    type="touchBooking"
                    :errors="editedEventCourseDateErrors.guestTouchBookings"
                    :readonly="
                        !$store.getters.hasLoggedInUserCredibility('ROLE_EDUCATION_MANAGEMENT')
                        && !$store.getters.hasLoggedInUserCredibility('ROLE_EDUCATION_DATE_MANAGEMENT')
                        && !$store.getters.hasLoggedInUserCredibility('ROLE_ADMINISTRATION')
                    "
                />

                <div
                    v-if="editedEventCourseDate.userCanUpdate"
                    class="text-center grid gap-4 grid-flow-col justify-center"
                >
                    <button type="submit" class="button">Aktualisieren</button>
                    <a
                        @click="deleteEventCourseDate(editedEventCourseDate)"
                        class="button bg-alert-400 text-white hover:bg-alert-600 hover:text-white"
                    >
                        Löschen
                    </a>
                </div>
            </form>
            <div>
                <notes
                    class="p-4"
                    :entity="editedEventCourseDate"
                />
            </div>
        </div>
    </c-modal>
</template>

<script>
    import Notes from "@/data/Notes.vue";
    import util from "../../../plugins/util";

    export default {
        components: {Notes},
        data() {
            return {
                editedEventCourseDate: {},
                editedEventCourseDateErrors: {},
            }
        },
        methods: {
            deleteEventCourseDate(event) {
                if (!this.$store.getters.hasLoggedInUserCredibility('ROLE_EDUCATION_MANAGEMENT')) {
                    return;
                }
                let _this = this;
                this.$dialog
                    .confirm('Event wirklich löschen', {
                        okText: 'Event löschen',
                        cancelText: 'Abbrechen',
                    })
                    .then(function () {
                        _this.$deleteFromApi('events', event, () => {
                            _this.$refs.eventCourseDateDetails.close();
                            _this.$emit('update');
                            /*delete _this.events[room['@id']][date];
                            _this.$forceUpdate();
                            //_this.loadEvents();
                            _this.loadCurrentCourseDates();*/
                        })
                    })
                    .catch(function () {
                    });

            },
            submitEventCourseDate() {
                this.editedEventCourseDateErrors = {};
                this.$sendToApi(
                    'event_course_dates',
                    this.editedEventCourseDate,
                    (entity, status, data) => {
                        if (status < 300) {
                            this.$refs.eventCourseDateDetails.close();
                            this.$emit('update');
                        } else {
                            this.editedEventCourseDateErrors = util.mapConstraints(data);
                        }
                    }
                )
            },
            open(entity) {
                this.editedEventCourseDateErrors = {};
                this.$getFromApi('event_course_dates', entity, (entity) => {
                    if(entity) {
                        this.editedEventCourseDate = Object.assign({}, entity);
                        this.$refs.eventCourseDateDetails.open();
                    }
                });
            },
            close() {
                this.$refs.eventCourseDateDetails.close();
            }
        }
    }
</script>
