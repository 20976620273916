<template>
    <div>
        <div class="grid grid-cols-4 gap-4">
            <input-text
                v-model="value.code"
                :errors="errors.code"
                label="Kürzel"
                class="col-span-4 mb-0"
            />

            <div class="">
                <label>
                    Auf Anfrage
                    <br>
                    <input type="checkbox" v-model="value.onDemand">
                </label>
            </div>
            <div class="">
                <label>
                    Aktiv
                    <br>
                    <input type="checkbox" v-model="value.active">
                </label>
            </div>
            <div class="">
                <label>
                    Online
                    <br>
                    <input type="checkbox" v-model="value.online">
                </label>
            </div>
            <div class="">
                <label class="whitespace-nowrap overflow-hidden">
                    Einladungsmails deaktivieren
                    <br>
                    <input type="checkbox" v-model="value.disableInvitationMails">
                </label>
            </div>
        </div>
        <div class="grid grid-cols-4 gap-4">
            <div class="col-span-4">
                <input-entity
                    label="Curriculum"
                    v-model="value.curriculum"
                    :errors="errors.curriculum"
                    type="curriculum"
                    openDirection="above"
                />
                <div class="bg-warning-100 p-4 text-warning-800">
                    Wenn das Curriculum geändert wird, werden alle Themenzuordnungen an Terminen gelöscht.
                </div>
            </div>

            <div class="col-span-4">
                <input-entity
                    label="Ausbildung/Seminar"
                    v-model="value.education"
                    :errors="errors.education"
                    type="education"
                    openDirection="above"
                />
            </div>

            <div class="col-span-4">
                <input-entity
                    label="Standort"
                    v-model="value.location"
                    :errors="errors.location"
                    type="location"
                    openDirection="above"
                />
            </div>

            <div class="col-span-2">
                <input-simple-select
                    :required="true"
                    label="Bewerbungsmöglichkeit"
                    v-model="value.applicationStatus"
                    :errors="errors.applicationStatus"
                    :options="$store.getters.applicationSettings.model.course.applicationStatus"
                    openDirection="above"
                />
            </div>

            <div class="col-span-2">
                <input-date
                    :required="false"
                    label="Deadline Bewerbungsmöglichkeit"
                    v-model="value.deadlineApplication"
                    :errors="errors.deadlineApplication"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import EducationTypeSelect from "../components/form/EducationTypeSelect";

    export default {
        props: {
            value: {
                type: Object,
                required: true,
            },
            errors: {
                type: Object
            },
            readonly: {
                type: Boolean,
                default: () => false
            }
        },
        components: {
            EducationTypeSelect
        },
        watch: {
            value: {
                handler(newVal) {
                    this.$emit('input', newVal);
                },
                deep: true
            }
        }
    }
</script>
