<template>
    <div class="grid gap-4 grid-cols-3">
        <div class="col-span-3">
            <input-text v-model="value.name" label="Thema" :errors="errors.name"/>
        </div>

        <div class="col-span-1">
            <input-text v-model.number="value.block" label="Block" :errors="errors.Block"/>
        </div>

        <div class="col-span-1">
            <input-text v-model.number="value.day" label="Tag" :errors="errors.day"/>
        </div>

        <div class="col-span-1">
            <input-text v-model.number="value.durationUnits" label="Unterrichts-Einheiten"
                        :errors="errors.durationUnits"/>
        </div>

        <div class="col-span-1">
            <input-text v-model="value.internalName" label="Interner Name" :errors="errors.internalName"/>
        </div>

        <div class="col-span-2">
            <label>
                Tags

                <input-simple-multi-select-rich-objects
                    label="Tags"
                    :multiple="true"
                    v-model="value.tags"
                    :options="$store.getters.applicationSettings.model.curriculumTopic.tag"
                    :errors="errors.tags"
                    empty-value="Kein Tag"
                />
            </label>
        </div>

        <div class="col-span-3">
            <input-text type="textarea" v-model="value.content" label="Inhalt / Beschreibung"/>
        </div>

        <div class="col-span-3">
            <input-text type="textarea" v-model="value.instructions" label="Anweisungen Dozent"/>
        </div>
    </div>
</template>

<script>
    import value from "../../../mixins/value";
    import InputSimpleMultiSelectRichObjects from "@/components/form/InputSimpleMultiSelectRichObjects.vue";

    export default {
        components: {InputSimpleMultiSelectRichObjects},
        mixins: [value]
    }
</script>
