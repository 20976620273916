<template>
    <div>
        <content-header headline="Räume">
            <a class="button" @click="$refs.dataTable.createData()">Raum hinzufügen</a>
        </content-header>
        <content-main>
            <data-table
                endpoint="rooms"
                :columns="columns"
                :filters="filters"
                ref="dataTable"
            >
                <template slot="updateForm" slot-scope="{data: room, errors: errors}">
                    <div class="grid grid-cols-4 gap-x-4">
                        <div class="col-span-3">
                            <input-text v-model="room.name" :errors="errors.name" label="Bezeichnung"/>
                        </div>
                        <div class="">
                            <label>
                                Online
                                <br>
                                <input type="checkbox" v-model="room.onlineRoom">
                            </label>
                        </div>
                        <div class="col-span-4">
                            <input-entity
                                type="location"
                                v-model="room.location"
                                :errors="errors.location"
                                label="Standort"
                            />
                        </div>
                    </div>
					<div class="grid grid-cols-2 gap-x-4">
						<div class="col-span-2">
							<hr class="">
						</div>
						<div class="">
							<input-text
                                v-model="room.zoomLink"
                                :errors="errors.zoomLink"
                                label="Zoom-Url"
                            />
						</div>
                        <div class="">
                            <input-text
                                v-model="room.zoomMeetingId"
                                :errors="errors.zoomMeetingId"
                                label="Zoom-Meeting-ID"
                            />
                        </div>
                        <div class="">
                            <input-text
                                v-model="room.zoomParticipantPassword"
                                :errors="errors.zoomParticipantPassword"
                                label="Zoom-Teilnehmer-Passwort"
                            />
                        </div>
                        <div class="col-span-2">
                            <hr>
                        </div>
                        <div class="">
                            <input-text
                                v-model="room.zoomUsername"
                                :errors="errors.zoomUsername"
                                label="Zoom-Benutzername"
                            />
                        </div>
                        <div class="">
                            <input-text
                                v-model="room.zoomPassword"
                                :errors="errors.zoomPassword"
                                label="Zoom-(Admin)-Password"
                            />
                        </div>
					</div>
                </template>

                <template slot="dataRow" slot-scope="{singleData: room}">
                    <td>
                        {{ room.name }}
                    </td>
                    <td>
                        <template v-if="room.location">
                            {{ room.location.name }}
                        </template>
                    </td>
                    <td>
                        <template v-if="room.onlineRoom">
                            Online-Raum
                        </template>
                    </td>
                    <td>
                        {{ room.lastEditedAt|formatDate('LLL') }}
                    </td>
                    <td class="text-right">
                        <a v-if="room.userCanUpdate" class="action --edit" @click="$refs.dataTable.editData(room)"></a>
                    </td>
                </template>
            </data-table>
        </content-main>
    </div>
</template>

<script>
    import DataTable from "../../../components/DataTable";
    import InputSelect from "../../../components/form/InputSelect";

    export default {
        components: {
            InputSelect,
            DataTable
        },
        data() {
            return {
                filters: [
                    {
                        type: 'text',
                        model: 'name',
                        label: 'Name'
                    },
                    {
                        type: 'entity',
                        entity: 'location',
                        model: 'location',
                        label: 'Standort',
                        callback: function () {
                            return 'Ein Datensatz ausgewählt';
                        }
                    },
                    {
                        type: 'select',
                        model: 'onlineRoom',
                        label: 'Online',
                        options: {
                            true: 'Ja',
                            false: 'Nein'
                        },
                    }
                ],
                columns: {
                    name: {
                        text: 'Name',
                        order: true,
                    },
                    'location.name': {
                        text: 'Standort',
                        order: false,
                    },
                    online: {
                        text: 'Typ',
                        order: false,
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    },
                }
            }
        }
    }
</script>
