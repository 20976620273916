<template>
    <div>
        <content-header headline="Dashboard" subline="Alle ToDos auf einen Blick"></content-header>
        <content-main>
            <task-table/>
        </content-main>
    </div>
</template>

<script>
    import TaskTable from "../../data/TaskTable";

    export default {
        components: {TaskTable},
    }
</script>
