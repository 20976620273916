<template>

    <div>

        <table>
            <thead>
            <tr>
                <th>Kurstermin</th>
                <th>Notizen</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="event in courseReport.events">
                <td>
                    <div class="font-bold">
                        {{ event.date|formatDate('LL') }}
                    </div>
                    <div v-if="event.curriculumTopic">
                        Thema: {{ event.curriculumTopic.name }}
                    </div>
                    <div v-if="event.docent">
                        Dozent: {{ event.docent.displayName }}
                    </div>
                </td>
                <td>
                    <note-list
                        v-if="notesMappedByEventCourseIri.hasOwnProperty(event['@id'])"
                        :notes="notesMappedByEventCourseIri[event['@id']]"
                    />
                    <div v-else>
                        Keine Notizen zu diesem Termin
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import NoteList from "@/data/Note/List.vue";

    export default {
        components: {NoteList},
        props: {
            course: {}
        },
        data() {
            return {
                courseReport: {},
            }
        },
        computed: {
            notesMappedByEventCourseIri: function () {
                if (!this.courseReport || !this.courseReport.hasOwnProperty('notes')) {
                    return {};
                }

                return this.courseReport.notes.reduce(function (map, obj) {
                    if (!map.hasOwnProperty(obj['resourceIri'])) {
                        map[obj['resourceIri']] = [];
                    }
                    map[obj['resourceIri']].push(obj);
                    return map;
                }, {});
            }
        },
        mounted() {
            this.loadCourseReport();
        },
        methods: {
            loadCourseReport() {
                this.$getFromApi('course_reports', {id: this.course.id}, (entity) => {
                    this.courseReport = entity;
                })
            }
        }
    }
</script>
