<template>
    <div class="grid gap-4 relative">
        <div
            v-if="persons.length === 0 && guestTouchBookingPersons.length === 0"
            class="text-center p-8 text-lg"
        >
            Der Kurs hat keine Teilnehmer
        </div>
        <div
            v-else
            class="ml-48 w-[calc(100%-12rem)]"
            style="overflow-x: scroll;"
        >
            <table class="--disableRows">
                <thead>
                <tr>
                    <th class="absolute left-0 w-48">Teilnehmer</th>
                    <th>Fortschritt</th>
                    <th v-for="eventCourseDate in eventCourseDates">
                        <small>{{ eventCourseDate.date|formatDate('L') }}</small>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="person in persons">
                    <th class="absolute left-0 w-48 whitespace-nowrap overflow-hidden text-ellipsis">
                        <router-link :to="'/persons/' + person.id">
                            {{ person.displayName }}
                        </router-link>
                    </th>
                    <th>
                        <template v-if="appearanceCountByPersonId.hasOwnProperty(person['@id'])">
                            {{ Math.round(appearanceCountByPersonId[person['@id']] / eventCourseDates.length * 100) }}%
                        </template>
                    </th>
                    <template v-for="eventCourseDate in eventCourseDates">
                        <td
                            v-if="appearancesOrdered.hasOwnProperty(person['@id']) && appearancesOrdered[person['@id']].hasOwnProperty(eventCourseDate['@id'])"
                            class="min-h-16 text-white h-12 border border-solid border-gray-100"
                            :class="{
                                'bg-brand-500': ['A', 'P', 'D'].indexOf(getAppearance(person, eventCourseDate).status) > -1,
                                'bg-brand-600': ['AV', 'P'].indexOf(getAppearance(person, eventCourseDate).status) > -1,
                                'bg-brand-700': ['K', 'S', 'E'].indexOf(getAppearance(person, eventCourseDate).status) > -1,
                                'bg-alert-500': getAppearance(person, eventCourseDate).status === 'UE',
                            }"
                            :title="getAppearance(person, eventCourseDate).comment"
                        >
                            <div
                                class="grid grid-flow-col justify-between"
                                @click="deleteAppearance(getAppearance(person, eventCourseDate))"
                            >
                                <div>
                                    {{ getAppearance(person, eventCourseDate).status }}
                                </div>
                                <div
                                    v-if="getAppearance(person, eventCourseDate).comment"
                                    :title="getAppearance(person, eventCourseDate).comment"
                                >
                                    (i)
                                </div>
                            </div>

                        </td>
                        <td
                            v-else
                            @click="toggleSelectedEventCourseDate(person, eventCourseDate)"
                            class="h-12 cursor-pointer text-white border border-solid border-gray-100"
                            :class="{
                                'bg-brand-100': selectedEventCourseDates.hasOwnProperty(person['@id'] + '|' + eventCourseDate['@id'])
                            }"
                        >
                            <!--<div class="text-black">
                                {{person['@id']}}
                                <br>
                                {{eventCourseDate['@id']}}
                            </div>-->
                            <span
                                v-if="selectedEventCourseDates.hasOwnProperty(person['@id'] + '|' + eventCourseDate['@id'])"
                                class="text-xs"
                            >
                                Ausgewählt
                            </span>
                            <span
                                class="text-xs"
                                v-if="hasAbsence(person, eventCourseDate)"
                                :class="{
                                    'text-black': !selectedEventCourseDates.hasOwnProperty(person['@id'] + '|' + eventCourseDate['@id']),
                                    'text-white': selectedEventCourseDates.hasOwnProperty(person['@id'] + '|' + eventCourseDate['@id'])
                                }"
                            >
                                Abwesenheit
                            </span>
                        </td>
                    </template>
                </tr>

                <tr v-for="person in guestTouchBookingPersons">
                    <th class="absolute left-0 w-48 whitespace-nowrap overflow-hidden text-ellipsis">
                        <router-link :to="'/persons/' + person.id">
                            GH - {{ person.displayName }}
                        </router-link>
                    </th>
                    <td></td>
                    <template v-for="eventCourseDate in eventCourseDates">
                        <td v-if="eventCourseDate.guestTouchBookings.filter((item) => item.person['@id'] === person['@id']).length === 0"
                            class="bg-gray-400"
                        >
							<span class="bg-gray-400">
								Kein GH
							</span>
                        </td>
                        <template v-else>
                            <td
                                v-if="appearancesOrdered.hasOwnProperty(person['@id']) && appearancesOrdered[person['@id']].hasOwnProperty(eventCourseDate['@id'])"
                                class="min-h-16 text-white h-12"
                                :class="{
                                'bg-brand-500': ['A', 'P'].indexOf(getAppearance(person, eventCourseDate).status) > -1,
                                'bg-brand-600': ['AV', 'P'].indexOf(getAppearance(person, eventCourseDate).status) > -1,
                                'bg-brand-700': ['K', 'S', 'E'].indexOf(getAppearance(person, eventCourseDate).status) > -1,
                                'bg-alert-500': getAppearance(person, eventCourseDate).status === 'UE',
                            }"
                            >
                                {{ getAppearance(person, eventCourseDate).status }}
                            </td>
                            <td
                                v-else
                                @click="toggleSelectedEventCourseDate(person, eventCourseDate)"
                                class="h-12 cursor-pointer text-white"
                                :class="{
                                'bg-brand-100': selectedEventCourseDates.hasOwnProperty(person['@id'] + '|' + eventCourseDate['@id'])
                            }"
                            >
                                <!--<div class="text-black">
                                    {{person['@id']}}
                                    <br>
                                    {{eventCourseDate['@id']}}
                                </div>-->
                                <span
                                    v-if="selectedEventCourseDates.hasOwnProperty(person['@id'] + '|' + eventCourseDate['@id'])"
                                    class="text-xs"
                                >
                                Ausgewählt
                            </span>
                                <span
                                    class="text-xs"
                                    v-if="hasAbsence(person, eventCourseDate)"
                                    :class="{
                                    'text-black': !selectedEventCourseDates.hasOwnProperty(person['@id'] + '|' + eventCourseDate['@id']),
                                    'text-white': selectedEventCourseDates.hasOwnProperty(person['@id'] + '|' + eventCourseDate['@id'])
                                }"
                                >
                                Abwesenheit
                            </span>
                            </td>
                        </template>
                    </template>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="grid grid-flow-col gap-4 justify-center"
        >
            <a @click="$refs.appearanceUpdateModal.open(currentCourse)" class="button">
                Gasthörer erfassen
            </a>

            <template
                v-if="Object.keys(selectedEventCourseDates).length > 0"
            >
                <a
                    @click="clearSelectedEventCourseDates"
                    class="button mb-0 thirdly"
                >
                    Auswahl zurücksetzen
                </a>
                <a
                    @click="$refs.modal.open()"
                    class="button mb-0"
                >
                    {{ Object.keys(selectedEventCourseDates).length }} Anwesenheiten erfassen
                </a>
            </template>
        </div>

        <update-modal :fixedCourse="course" ref="appearanceUpdateModal"/>

        <modal
            ref="modal"
            :adaptive="true"
            height="auto"
            name="appearanceUpdateModel"
        >
            <div class="modal__absoluteClose" @click="$refs.modal.close();">
            </div>
            <div class="modal__header">
                <div class="modal__headline py-4">
                    Anwesenheiten erfassen
                </div>
            </div>

            <div class="grid grid-col-[1fr_auto_1rem] p-4 gap-4">
                <div class="col-span-3 text-xl font-bold">
                    Folgende Anwesenheiten werden erfasst:
                </div>

                <template v-for="selectedEventCourseDate in selectedEventCourseDates">
                    <div class="font-bold">
                        {{ selectedEventCourseDate.person.displayName }}
                    </div>
                    <div>
                        am {{ selectedEventCourseDate.eventCourseDate.date|formatDate('LL') }}
                    </div>
                    <div class="text-alert">
                        <template
                            v-if="selectedEventCourseDate.hasOwnProperty('error') && selectedEventCourseDate.error">
                            Fehler beim Erstellen
                        </template>
                    </div>
                </template>

                <div class="col-span-3">
                    <input-simple-select
                        label="Status"
                        :options="statusOptions"
                        v-model="appearance.status"
                        :errors="errors.status"
                    ></input-simple-select>

                    <template
                        v-if="['AV', 'E'].includes(appearance.status)"
                    >
                        <input-text
                            v-if="appearance.status === 'AV'"
                            type="number"
                            label="Verspätung in h"
                            v-model.number="appearance.delay"
                            :step="0.25"
                            :errors="errors.delay"
                        />

                        <input-simple-select
                            v-if="appearance.status === 'E'"
                            label="Grund"
                            v-model="appearance.reason"
                            :options="reasonOptions"
                            :errors="errors.reason"
                        />
                    </template>

                    <input-text
                        type="textarea"
                        v-model="appearance.comment"
                        label="Kommentar"
                    >
                    </input-text>

                    <div class="text-center">
                        <a
                            @click="submitCreateAppearances"
                            class="button mb-0"
                        >
                            Anwesenheiten erfassen
                        </a>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import moment from "moment";
    import UpdateModal from "./UpdateModal.vue";

    export default {
        components: {
            UpdateModal,
        },
        props: {
            course: {
                required: true,
                type: Object
            },
        },
        data() {
            return {
                appearance: {},
                errors: {},
                appearances: [],
                eventCourseDates: [],
                persons: [],
                absences: [],
                selectedEventCourseDates: {},
                dragStart: false,
            }
        },
        mounted() {
            this.loadAppearances();
            this.loadAbsencesByCourse();
            this.loadPersons();
            this.loadEventCourseDates();
        },
        computed: {
            appearanceCountByPersonId() {
                let counts = {};
                this.appearances.forEach((item) => {
                    if (item.status === 'A' || item.status === 'AV') {
                        if (!counts.hasOwnProperty(item.person['@id'])) {
                            counts[item.person['@id']] = 0;
                        }
                        counts[item.person['@id']]++;
                    }
                });
                return counts;
            },
            apperancesIds: function () {
                return this.appearances.map((item) => item['@id'] + ': ' + item['person']['displayName'] + item['date']);
            },
            duplicated: function () {
                return this.apperancesIds.filter((item, index) => {
                    return this.apperancesIds.indexOf(item) !== index;
                })
            },
            guestTouchBookingPersons: function () {
                let persons = this.eventCourseDates.reduce((a, b) => {
                    b.guestTouchBookings.forEach((item) => {
                        if (a.indexOf(item.person) === -1) {
                            a.push(item.person);
                        }
                    })
                    return a;
                }, []);
                // Make unique
                return [...new Map(persons.map(item => [item['@id'], item])).values()];
            },
            appearancesOrdered: function () {
                let data = {};
                this.appearances.forEach((item) => {
                    let date = null;
                    if (!data.hasOwnProperty(item.person['@id'])) {
                        data[item.person['@id']] = {};
                    }

                    if (
                        item
                        && item.hasOwnProperty('eventCourseDate')
                        && item.eventCourseDate
                        && item.eventCourseDate.hasOwnProperty('@id')
                    ) {
                        //let date = this.$options.filters.formatDate(item.date, 'L');
                        let date = item.eventCourseDate['@id'];
                        if (!data[item.person['@id'].hasOwnProperty(date)]) {
                            data[item.person['@id']][date] = item;
                        }
                    }
                });

                return data;
            },
            reasonOptions: function () {
                let options = {};
                if (!this.$store.getters.applicationSettings) {
                    return [];
                }
                Object.keys(this.$store.getters.applicationSettings.model.appearance.reason).forEach((item) => {
                    options[item] = this.$store.getters.applicationSettings.model.appearance.reason[item];
                });

                return options;
            },
            statusOptions: function () {
                return this.$store.getters.applicationSettings.model.appearance.status;
            },
        },
        methods: {
            deleteAppearance(appearance) {
                let _this = this;
                return this.$dialog
                    .confirm('Anwesenheit wirklich löschen?', {
                        okText: 'Ja',
                        cancelText: 'Abbrechen',
                    })
                    .then(function (dialog) {
                        let _appearance = Object.assign({}, appearance);
                        _this.$deleteFromApi('appearances', appearance, () => {
                            _this.appearances = _this.appearances.filter((item) => item['@id'] !== _appearance['@id']);
                        });
                    });
            },
            hasAbsence(person, eventCourseDate) {
                return this.absences.filter((item) => {
                    return item.person['@id'] === person['@id']
                        && moment(eventCourseDate.date).isSameOrAfter(moment(item.start))
                        && moment(eventCourseDate.date).isSameOrBefore(moment(item.end).add(1, 'day'));
                }).length > 0;
            },
            async submitCreateAppearances() {
                let selectedEventCourseDates = Object.assign({}, this.selectedEventCourseDates);

                Object.keys(selectedEventCourseDates).forEach((item) => {
                    delete selectedEventCourseDates[item]['error'];
                });

                this.$set(this, 'selectedEventCourseDates', selectedEventCourseDates);

                selectedEventCourseDates = Object.values(this.selectedEventCourseDates);
                this.$store.commit('incrementLoadingIndex');
                let moreThanNullErrors = false;
                for (const item of selectedEventCourseDates) {
                    try {
                        let response = await this.$http.post(
                            'appearances',
                            Object.assign(this.appearance, {
                                person: item.person['@id'],
                                eventCourseDate: item.eventCourseDate['@id']
                            })
                        );

                        this.appearances.push(response.data);
                    } catch (e) {
                        moreThanNullErrors = true;
                        this.$set(
                            this.selectedEventCourseDates[item.person['@id'] + "|" + item.eventCourseDate['@id']],
                            'error',
                            true
                        )
                    }
                }
                this.$store.commit('decrementLoadingIndex');

                if (!moreThanNullErrors) {
                    this.clearSelectedEventCourseDates();
                    this.$refs.modal.close();
                }
            },
            clearSelectedEventCourseDates() {
                this.$set(this, 'selectedEventCourseDates', {});
            },
            toggleSelectedEventCourseDate(person, eventCourseDate) {
                let key = person['@id'] + '|' + eventCourseDate['@id'];

                if (this.selectedEventCourseDates.hasOwnProperty(key)) {
                    let selectedEventCourseDates = Object.assign({}, this.selectedEventCourseDates);
                    delete selectedEventCourseDates[key];
                    this.$set(this, 'selectedEventCourseDates', selectedEventCourseDates);
                } else {
                    this.$set(this.selectedEventCourseDates, key, {
                        person: person,
                        eventCourseDate: eventCourseDate,
                    });
                }
            },
            getAppearance(person, eventCourseDate) {
                if (
                    this.appearancesOrdered.hasOwnProperty(person['@id'])
                    && this.appearancesOrdered[person['@id']].hasOwnProperty(eventCourseDate['@id'])
                ) {
                    return this.appearancesOrdered[person['@id']][eventCourseDate['@id']]
                }
                return null;
            },
            async loadAppearances() {
                this.appearances = await this.$getAllFromApi(
                    'appearances',
                    {
                        'eventCourseDate.course': this.course['@id']
                    }
                )
            },
            async loadPersons() {
                this.persons = await this.$getAllFromApi(
                    'people',
                    {
                        'course': this.course['id'],
                        'order[lastName]': 'ASC',
                    }
                )
            },
            async loadAbsencesByCourse() {
                this.absences = await this.$getAllFromApi(
                    'absences',
                    {
                        'course': this.course['@id']
                    }
                );
            },
            async loadEventCourseDates() {
                this.eventCourseDates = await this.$getAllFromApi(
                    'event_course_dates',
                    {
                        'course[]': this.course['@id'],
                        'order[date]': 'ASC'
                    }
                )
            },
        }
    }
</script>
