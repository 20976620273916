<template>
    <div>
        <content-header headline="Rollenverwaltung">
            <a class="button" @click="$refs.dataTable.createData({credibilitySlugs: []})">Rolle hinzufügen</a>
        </content-header>
        <content-main>
            <data-table
                    endpoint="roles"
                    :columns="columns"
                    :filters="filters"
                    ref="dataTable"
                    updateRevealSize="large"
            >
                <template slot="updateForm" slot-scope="{data: role, errors: errors}">
                    <div class="row">
                        <div class="large-6 columns">
                            <input-text v-model="role.name" :errors="errors.name" label="Name"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="columns">
                            <credibility-select v-model="role.credibilitySlugs"/>
                        </div>
                    </div>
                </template>

                <template slot="dataRow" slot-scope="{singleData: role}">
                    <td>{{role.name}}
                    </td>
                    <td>
                        <template v-for="(credibilitySlug, index) in role.credibilitySlugs">
                            {{credibilitySlug|tSecurity}}<template v-if="index < (role.credibilitySlugs.length - 1)">,</template>
                        </template>
                    </td>
                    <td>
                        {{role.lastEditedAt|formatDate('LLL')}}
                    </td>
                    <td class="text-right">
                        <a v-if="role.userCanUpdate" class="action --edit" @click="$refs.dataTable.editData(role)"></a>
                        <a v-if="role.userCanDelete" class="action --delete" @click="$refs.dataTable.deleteData(role)"></a>
                    </td>
                </template>
            </data-table>
        </content-main>
    </div>
</template>

<script>
    import DataTable from "../../../components/DataTable";
    import InputSelect from "../../../components/form/InputSelect";
    import CredibilitySelect from "../../../components/form/CredibilitySelect";

    export default {
        components: {
            CredibilitySelect,
            InputSelect,
            DataTable
        },
        data() {
            return {
                filters: [
                    {
                        type: 'text',
                        model: 'name',
                        label: 'Name'
                    }
                ],
                columns: {
                    username: {
                        text: 'Name',
                        order: true,
                        filter: 'text'
                    },
                    credibilitySlugs: {
                        text: 'Berechtigungen',
                        order: false
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    },
                }
            }
        }
    }
</script>
