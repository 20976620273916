<template>
    <div class="reveal" :class="size" :id="revealID" ref="reveal">
        <div class="row reveal__header align-middle">
            <div class="columns">
                <div class="reveal__headline">
                    {{headline}}
                </div>
            </div>
            <div class="columns shrink">
                <a data-close class="reveal__close" @click="close"></a>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
    import Foundation from 'foundation-sites';

    export default {
        props: {
            headline: {
                type: String,
                default: '',
                required: true
            },
            size: {
                type: String,
                default: '',
            }
        },
        data() {
            return {
                reveal: {},
                revealID: '',
            }
        },
        mounted() {
            this.revealID = '_' + Math.random().toString(36).substr(2, 9);
            this.reveal = new Foundation.Reveal($(this.$refs.reveal));
        },
        methods: {
            open() {
                this.reveal.open();
            },
            close() {
                this.reveal.close();
            }
        },
        beforeDestroy() {
            $('#' + this.revealID).remove();
            this.reveal.destroy();
        }
    }
</script>
