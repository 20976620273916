<template>
    <div>
        <content-header headline="Auswertungen"/>

        <content-main>
            <div></div>
            <div class="row">
                <div class="large-12 columns">
                    <h2 class="h-margin-bottom">Schnellüberblick</h2>
                    <ul class="bigList h-largeMargin-bottom">
						<li>
							<router-link to="/reporting/supportedContractAppearance" class="text-center">
								<span class="action --performance"></span>
								<br>
								Anwesenheiten FÖBG
							</router-link>
						</li>
                        <li>
                            <router-link to="/reporting/acquisition" class="text-center">
                                <span class="action --performance"></span>
                                <br>
                                Akquisitions-Analyse
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/reporting/coursePerformance" class="text-center">
                                <span class="action --performance"></span>
                                <br>
                                Dozentenkosten
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/finance/analyses" class="text-center">
                                <span class="action --performance"></span>
                                <br>
                                Finanz-Analyse (Verträge, Abschlüsse)
                            </router-link>
                        </li>
                    </ul>
                    <h2 class="h-margin-bottom">Schnupper-Workshops</h2>
                    <ul class="bigList h-largeMargin-bottom">
                        <li>
                            <a @click="downloadEventTrialCourseStats" class="text-center">
                                <span class="action --download"></span>
                                <br>
                                Teilnehmer-Statistiken
                            </a>
                        </li>
                        <li>
                            <a @click="downloadEventTrialCourseAttendance" class="text-center">
                                <span class="action --download"></span>
                                <br>
                                Alle Teilnehmer
                            </a>
                        </li>
                    </ul>
                    <h2 class="h-margin-bottom">Individuelle Auswertungen</h2>
                    <ul class="bigList h-largeMargin-bottom">
                        <li>
                            <router-link to="/reporting/reportings" class="text-center">
                                <span class="action --show"></span>
                                <br>
                                Standardanalyse für Marketing
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </content-main>
    </div>
</template>

<script>
    export default {
        methods: {
            downloadEventTrialCourseStats() {
                this.$http.post('reportings/eventTrialCourse/stats').then(response => {
                    window.location = response.body.url;
                });
            },
            downloadEventTrialCourseAttendance() {
                this.$http.post('reportings/eventTrialCourse/attendance').then(response => {
                    window.location = response.body.url;
                });
            }
        }
    };
</script>
