<template>
	<c-modal
			ref="studentIdUpdateModal"
			headline="Studentenausweis"
	>
		<div class="p-4 py-12">
			<input-entity
					label="Rechnung"
					v-model="studentId.touchBooking"
					type="touchBooking"
					:multi="false"
					:fixedParams="{person: person['@id']}"
			/>

			<div class="bg-warning-100 p-4 text-warning-600 mb-4">
				Der Studentausweis erscheint in den Anhängen im Personenprofil. Dies kann einen Moment dauern
			</div>

			<div class="grid grid-cols-2 gap-8 items-center">
				<div>
					<button
							type="submit"
							class="button mb-0"
							@click="submit"
							:disabled="!studentId.touchBooking"
					>
						Studentausweis erstellen
					</button>
				</div>
			</div>
		</div>
	</c-modal>
</template>

<script>
    import CModal from "../../components/Modal.vue";

    export default {
        props: {
            person: {
                required: true,
            }
        },
        components: {
            CModal
        },
        data() {
            return {
                errors: {},
                studentId: {
                    touchBooking: null,
                }
            }
        },
        methods: {
            submit() {
                this.$sendToApi('document/student_ids', this.studentId, () => {
                    this.$refs.studentIdUpdateModal.hide();
                });
            },
            open() {
                this.studentId = {
                    touchBooking: null,
                };
                this.$refs.studentIdUpdateModal.show();
            }
        },
    }
</script>
