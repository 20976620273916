<template>
    <div v-if="bookingChecklistIntern"
         class="grid gap-4"
    >
        <div class="uppercase font-bold text-xl">
            Checkliste zur Akte
        </div>
        <div class="grid-cols-2 gap-4 grid">
            <div v-for="possibleItem in bookingChecklistIntern.possibleItems"
                 class="grid grid-cols-[6rem_1fr_auto] items-center gap-4"
            >
                <div class="">
                    <input
                        type="checkbox"
                        class="mb-0"
                        v-on:change="update($event, possibleItem)"
                        :disabled="bookingChecklistIntern.activeItems.includes(possibleItem)"
                        :checked="bookingChecklistIntern.activeItems.includes(possibleItem)"
                    />
                </div>
                <div>
                    {{ possibleItem|dataTrans('model.bookingChecklist.type') }}
                    <small v-if="bookingChecklistIntern.activeItems.includes(possibleItem)">
                        <br>
                        bestätigt durch {{ getData(possibleItem).acceptedBy.displayName }}
                        <br>
                        am {{ getData(possibleItem).acceptedAt|formatDate('lll') }}
                    </small>
                </div>
                <div>
                    <a
                        v-if="bookingChecklistIntern.userCanDelete && bookingChecklistIntern.activeItems.includes(possibleItem)"
                        @click="deleteBookingChecklistItem(getData(possibleItem))"
                        class="action --delete"
                    >
                    </a>
                </div>
            </div>
        </div>
        <div class="gap-4 grid">
            <div
                v-if="item.item === 'custom'"
                v-for="item in bookingChecklistIntern.bookingChecklistItems"
                class="grid grid-cols-[1fr_1fr_2rem] items-center gap-4"
            >
                <div>
                    {{ item.name }}
                </div>
                <div>
                    Geplant am:
                    <template v-if="item.planedAt">{{ item.planedAt|formatDate('LL') }}</template>
                    <br>
                    Gehalten / Durchgeführt am:
                    <template v-if="item.acceptedAt">{{ item.acceptedAt|formatDate('LL') }}</template>
                    <br>
                    Punkte:
                    <template v-if="item.points">{{ item.points }}</template>
                </div>
                <div>
                    <a
                        class="action --edit"
                        @click="editBookingChecklistItem(item)"
                    >
                    </a>
                </div>
            </div>
        </div>

        <modal
                ref="updateModal"
                :adaptive="true"
                height="auto"
               :name="'bookingChecklistItemUpdateModal' + bookingChecklist.id"
        >
            <div class="p-4">
                <form
                    class="grid grid-cols-2 gap-4"
                    v-on:submit.prevent="updateBookingChecklistItem"
                >
                    <div class="col-span-2">
                        <div class="uppercase font-bold text-xl">
                            Leistung eintragen
                        </div>
                    </div>
                    <div>
                        <input-date
                            :errors="errors.planedAt"
                            v-model="editedBookingChecklistItem.planedAt"
                            label="Geplant am"
                        />
                    </div>
                    <div>
                        <input-date
                            :errors="errors.acceptedAt"
                            v-model="editedBookingChecklistItem.acceptedAt"
                            label="Akzeptiert am"
                        />
                    </div>
                    <div class="col-span-2">
                        <input-text
                            :errors="errors.points"
                            type="text"
                            v-model.number="editedBookingChecklistItem.points"
                            label="Punkte"
                        />
                    </div>
                    <div class="col-span-2 grid-flow-col grid gap-4 justify-center">
                        <a
                            @click="$refs.updateModal.close()"
                            class="button button--small thirdly mb-0"
                        >
                            Abbrechen
                        </a>
                        <button
                            type="submit"
                            class="button button--small mb-0"
                        >
                            Speichern
                        </button>
                    </div>
                </form>
            </div>
        </modal>
    </div>
</template>

<script>
    export default {
        props: {
            bookingChecklist: {
                required: true,
            }
        },
        mounted() {
            this.bookingChecklistIntern = this.bookingChecklist;
        },
        data() {
            return {
                bookingChecklistIntern: {},
                editedBookingChecklistItem: {},
                errors: {},
            }
        },
        methods: {
            updateBookingChecklistItem() {
                this.errors = {};
                let editedBookingChecklistItem = Object.assign({}, this.editedBookingChecklistItem);
                delete editedBookingChecklistItem['createdBy'];
                delete editedBookingChecklistItem['lastEditedBy'];
                this.$sendToApi('booking_checklist_items', editedBookingChecklistItem, (entity, status, data) => {
                    if (status > 299) {
                        this.errors = data;
                    } else {
                        let objIndex = this.bookingChecklistIntern.bookingChecklistItems.findIndex((obj => obj['@id'] === entity['@id']));
                        this.$set(this.bookingChecklistIntern.bookingChecklistItems, objIndex, entity);
                        this.$modal.hide('bookingChecklistItemUpdateModal' + this.bookingChecklist.id);
                    }
                });
            },
            editBookingChecklistItem(editedBookingChecklistItem) {
                this.$set(this, 'editedBookingChecklistItem', Object.assign({}, editedBookingChecklistItem));
                this.$modal.show('bookingChecklistItemUpdateModal' + this.bookingChecklist.id);
            },
            deleteBookingChecklistItem(bookingChecklistItem) {
                this.$deleteFromApi('booking_checklist_items', bookingChecklistItem, () => {
                    this.bookingChecklistIntern.activeItems.splice(this.bookingChecklistIntern.activeItems.indexOf((item) => {
                        return item === bookingChecklistItem.type;
                    }))
                });
            },
            getData(itemSlug) {
                let data = this.bookingChecklistIntern.bookingChecklistItems.filter((item) => {
                    return item.item === itemSlug;
                });
                if (data.length > 0) {
                    return data[0];
                }
                return null;
            },
            update(event, item) {
                if (event.srcElement.checked === false) {
                    return;
                }

                let _this = this;
                let data = Object.assign(this.bookingChecklistIntern, {});
                data.items = data.activeItems;
                data.items.push(item);
                this.$dialog
                    .confirm('Ist das Dokument wirklich erbracht worden?')
                    .then(function (dialog) {
                        _this.$sendToApi('booking_checklists', data, (entity) => {
                            _this.bookingChecklistIntern = entity;
                        });
                    }).catch(function() {
                        event.srcElement.checked = false
                    });
                ;
            }
        }
    }
</script>
