<template>
    <div>
        <content-main>
            <resolve-reveal ref="resolveReveal" :person="person"/>
            <div class="row h-margin-bottom align-middle">
                <div class="columns">
                    <h1>Daten
                        <template v-if="$route.query.edit">bearbeiten</template>
                        <template v-else>aufnehmen</template>
                    </h1>
                </div>
                <div class="columns shrink" v-if="person.id">
                    <router-link class="button small thirdly h-noMargin-bottom" :to="'/persons/' + person.id">Zum
                        Personen-Profil
                    </router-link>
                </div>
            </div>
            <div class="callout warning h-largeMargin-bottom" v-if="!person.id && touchType !== null">
                Um ein Erstkontakt auszufüllen, muss erst ein Person-Datensatz ausgefüllt werden.
            </div>
            <div class="accordion">
                <template v-if="person.id || touchType !== null">
                    <div class="accordion__title" @click="setAccordion('person')"
                         :class="{'--open': !accordion.includes('person')}">
                        Stammdaten Person
                    </div>
                    <div class="accordion__content">
                        <div class="panel">
                            <form v-on:submit.prevent="updatePerson">
                                <person-form v-model="person" :errors="personErrors"/>

                                <hr>

                                <div class="text-center">
                                    <button type="submit" class="button">
                                        <template v-if="person.id">Speichern</template>
                                        <template v-if="!person.id">Erstellen</template>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </template>

                <template v-if="person.id">
                    <template v-if="touchCallback.id">
                        <div class="accordion__title" @click="setAccordion('touchCallback')"
                             :class="{'--open': !accordion.includes('touchCallback')}">
                            Rückrufanfrage
                        </div>
                        <div class="accordion__content">
                            <div class="panel">
                                <callback-table :show-personal-data="false" :touch-callback="touchCallback"/>
                            </div>
                        </div>
                    </template>
                    <template v-if="touchType === 'firstContact' || touchFirstContact.id">
                        <div class="accordion__title" @click="setAccordion('firstContact')"
                             :class="{'--open': !accordion.includes('firstContact')}">
                            Erstkontakt
                        </div>
                        <div class="accordion__content">
                            <div class="panel">
                                <div
                                    class="callout warning"
                                    :class="{'hide': touchType === 'consultation'}"
                                >
                                    Bitte speichern Sie Zwischenergebnisse
                                </div>
                                <form v-on:submit.prevent="updateTouchFirstContact">
                                    <reveal ref="firstContactCopyReveal" headline="Übersicht Erstkontakt">
                                        <div class="row">
                                            <div class="large-12 columns">
                                                <div class="callout warning">
                                                    Speichern Sie das Formular, bevor Sie hier die Daten kopieren.
                                                </div>
                                                <div v-html="touchFirstContact.quickInfoData"></div>

                                                <div class="text-center">
                                                    <button @click="copyDataToClipboard" class="button">In die
                                                        Zwischenablage
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </reveal>

                                    <first-contact-form
                                        v-model="touchFirstContact"
                                        :errors="touchFirstContactErrors"
                                        :show-only-touch-unique-fields="touchType === 'consultation'"
                                    />

                                    <hr v-if="touchType !== 'consultation'">

                                    <div class="text-center" v-if="touchType !== 'consultation'">
                                        <a class="button thirdly" @click="firstContactQuickInfoReveal">
                                            Erstkontakt-Daten für Termin
                                        </a>
                                        <button type="submit" class="button">
                                            <template v-if="touchFirstContact.id">Speichern</template>
                                            <template v-if="!touchFirstContact.id">Erstellen</template>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </template>
                    <template v-if="touchType === 'consultation' || touchConsultation.id || touchType === 'support'">
                        <div class="accordion__title" @click="setAccordion('consultation')"
                             :class="{'--open': !accordion.includes('consultation')}">
                            Beratungsgespräch
                        </div>
                        <div class="accordion__content">
                            <div class="panel">
                                <div class="callout warning">Bitte speichern Sie Zwischenergebnisse</div>
                                <form v-on:submit.prevent="updateTouchConsultation">
                                    <consultation-form v-model="touchConsultation" :errors="touchConsultationErrors"/>

                                    <hr>

                                    <div class="text-center">
                                        <button type="submit" class="button">
                                            <template v-if="touchConsultation.id">Speichern</template>
                                            <template v-if="!touchConsultation.id">Erstellen</template>
                                        </button>
                                        <a
                                            class="button thirdly"
                                            v-if="touchType !== 'support' && touchConsultation.id"
                                            @click="changeToSupport"
                                        >
                                            Förderungsdokumentation erstellen
                                        </a>
                                        <a v-if="touchConsultation.id" @click="showTaskReveal(touchConsultation)"
                                           class="button small thirdly">ToDos lösen</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </template>
                    <template v-if="touchType === 'support' || touchSupport.id">
                        <div class="accordion__title" @click="setAccordion('support')"
                             :class="{'--open': !accordion.includes('support')}">
                            Förderung
                        </div>
                        <div class="accordion__content">
                            <div class="panel">
                                <div class="callout warning">Bitte speichern Sie Zwischenergebnisse</div>
                                <form v-on:submit.prevent="updateTouchSupport">
                                    <support-form v-model="touchSupport" :errors="touchSupportErrors"/>

                                    <hr>

                                    <div class="text-center">
                                        <a class="button" v-if="touchSupport.id" @click="downloadSupportDocument">
                                            Dokument herunterladen
                                        </a>
                                        <button type="submit" class="button">
                                            <template v-if="touchSupport.id">Speichern</template>
                                            <template v-if="!touchSupport.id">Erstellen</template>
                                        </button>
                                        <a v-if="touchSupport.id" @click="showTaskReveal(touchSupport)"
                                           class="button small thirdly">ToDos lösen</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </template>
                    <template
                        v-if="touchType !== 'support' && touchType !== 'firstContact' && touchType !== 'consultation'">
                        <div class="accordion__title" @click="setAccordion('touch')"
                             :class="{'--open': !accordion.includes('touch')}">
                            {{ touchType|dataTrans('model.touch.types') }}
                        </div>
                        <div class="accordion__content">
                            <div class="panel">
                                <touch-form
                                    v-on:save="resetKeyChange()"
                                    :type="touchType"
                                    v-model="touch"
                                    :errors="touchErrors"
                                    :person="person"
                                />
                            </div>
                        </div>
                    </template>
                </template>
            </div>
        </content-main>
    </div>
</template>

<script>
    import Person from "./Person";
    import PersonForm from "../../data/PersonForm";
    import ConsultationForm from "../../data/Touch/ConsultationForm";
    import FirstContactForm from "../../data/Touch/FirstContactForm";
    import util from "../../../plugins/util";
    import Touch from "./Touch";
    import TouchForm from "../../data/TouchForm";
    import SupportForm from "../../data/Touch/SupportForm";
    import ResolveReveal from "../../data/Task/ResolveReveal";
    import CallbackTable from "../../data/Touch/CallbackTable";
    import noteUnload from "../../../mixins/noteUnload";
    import BookingForm from "../../data/Touch/BookingForm";

    export default {
        mixins: [noteUnload],
        components: {
            BookingForm,
            CallbackTable,
            ResolveReveal,
            SupportForm,
            TouchForm,
            Touch,
            FirstContactForm,
            ConsultationForm,
            PersonForm,
            Person
        },
        data() {
            return {
                accordion: [],
                person: {},
                personErrors: {},
                touch: {},
                touchType: null,
                touchErrors: {},
                touchCallback: {},
                touchFirstContact: {},
                touchFirstContactErrors: {},
                touchConsultation: {},
                touchConsultationErrors: {},
                touchSupport: {},
                touchSupportErrors: {},
                touchBooking: {},
                touchBookingErrors: {},
            }
        },
        mounted() {
            if (this.$route.query.edit || this.$route.query.touchId) {
                this.touchType = null;
                let routeEdit = this.$route.query.edit;
                if (routeEdit === 'firstContact') {
                    this.loadTouchFirstContact(this.$route.query.touchId);
                    this.touchType = 'firstContact';
                } else if (routeEdit === 'consultation') {
                    this.loadTouchConsultation(this.$route.query.touchId);
                    this.touchType = 'consultation';
                } else if (routeEdit === 'support') {
                    this.loadSupport(this.$route.query.touchId);
                    this.touchType = 'support';
                } else if (routeEdit === 'booking') {
                    this.loadBooking(this.$route.query.touchId);
                    this.touchType = 'booking';
                } else {
                    this.loadTouch(this.$route.query.touchId);
                }
            } else {
                this.touchType = this.$route.query.create;
                if (this.touchType === 'support') {
                    this.loadTouchConsultation(this.$route.query.touchConsultationId);
                } else if (this.$route.query.personId) {
                    this.loadPerson(this.$route.query.personId);
                } else if (this.$route.query.touchFirstContactId) {
                    this.touchType = 'consultation';
                    this.createFromFirstContact(this.$route.query.touchFirstContactId);
                }
            }

            if (this.$route.query.create && this.$route.query.create === 'firstContact') {
                this.$getFromApi(
                    'touch_callbacks',
                    this.$route.query.touchCallbackId,
                    (entity) => {
                        this.touchCallback = entity;
                    }
                );
            }
        },
        methods: {
            changeToSupport() {
                this.$router.push('/touchComplex/?create=support&touchConsultationId=' + this.touchConsultation.id);
                this.touchType = 'support';
                this.loadTouchConsultation(this.$route.query.touchConsultationId);
            },
            createFromFirstContact(id) {
                this.$http.post('actions/touchConsultationFromFirstContact', {'touchFirstContactId': id}).then(response => {
                    this.$router.push('/touchComplex/?edit=consultation&touchId=' + response.body.touchConsultation + '&touchFirstContactId=' + id);
                    this.loadTouchConsultation(response.body.touchConsultation);
                    this.loadTouchFirstContact(id, false);
                });
            },
            createFromConsultation(id) {
                this.$http.post('actions/touchSupportFromConsultation', {'touchConsultationId': id}).then(response => {
                    this.$router.push('/touchComplex/?edit=touchSupport&touchId=' + response.body.id);
                });
            },
            loadTouch(id, loadPerson = true) {
                this.$getFromApi('touches', id, (entity) => {
                    this.touch = entity;
                    this.touchType = this.touch.clonedType;
                    if (loadPerson) {
                        this.loadPerson(this.touch.person.id);
                    }
                });
            },
            loadBooking(id, loadPerson = true) {
                this.$getFromApi('touch_bookings', id, (entity) => {
                    this.touch = entity;
                    this.touchErrors = {};

                    if (loadPerson) {
                        this.loadPerson(this.touch.person.id);
                    }
                });
            },
            loadSupport(id, loadPerson = true, loadConsultation = true) {
                this.$getFromApi('touch_supports', id, (entity) => {
                    this.touchSupport = entity;

                    if (loadPerson) {
                        this.loadPerson(this.touchSupport.person.id);
                    }

                    if (loadConsultation) {
                        this.loadTouchConsultation(this.touchSupport.consultation.id);
                    }
                });
            },
            loadTouchFirstContact(id, loadPerson = true) {
                this.$getFromApi('touch_first_contacts', id, (entity) => {
                    this.touchFirstContact = entity;
                    if (loadPerson) {
                        this.loadPerson(this.touchFirstContact.person.id);
                    }
                });
            },
            loadTouchConsultation(id, loadPerson = true) {
                this.$getFromApi('touch_consultations', id, (entity) => {
                    this.touchConsultation = entity;
                    if (loadPerson) {
                        this.loadPerson(this.touchConsultation.person.id);
                    }
                });
            },
            loadPerson(id) {
                this.$getFromApi('people', id, (entity) => {
                    this.person = entity;
                    this.touchConsultation.person = this.person['@id'];
                    this.touchFirstContact.person = this.person['@id'];
                    this.touch.person = this.person['@id'];
                });
            },
            updatePerson() {
                this.personErrors = [];
                this.$sendToApi('people', this.person, (entity, status, response) => {
                    if (status > 299) {
                        this.personErrors = util.mapConstraints(response);
                    } else {
                        this.resetKeyChange();
                        this.person = entity;
                        this.touchConsultation.person = this.person['@id'];
                        this.touchFirstContact.person = this.person['@id'];
                        this.touchCall.person = this.person['@id'];
                        this.touchNote.person = this.person['@id'];
                    }
                });
            },
            updateTouchConsultation() {
                this.touchConsultationErrors = [];
                this.touchConsultation.person = this.person['@id'];

                this.$sendToApi(
                    'touch_consultations', this.touchConsultation, (entity, status, response) => {
                        if (status > 299) {
                            this.touchConsultationErrors = util.mapConstraints(response);
                        } else {
                            this.resetKeyChange();
                            this.touchConsultation = entity;
                            this.touchConsultation.person = this.person['@id'];
                            this.$refs.resolveReveal.open(this.touchConsultation);
                        }
                    });
            },
            updateTouchSupport() {
                this.touchSupportErrors = [];
                this.touchSupport.person = this.person['@id'];
                this.touchSupport.consultation = this.touchConsultation['@id'];
                this.$sendToApi(
                    'touch_supports', this.touchSupport, (entity, status, response) => {
                        if (status > 299) {
                            this.touchSupportErrors = util.mapConstraints(response);
                        } else {
                            this.touchSupport = entity;
                            this.touchSupport.person = entity.person['@id'];
                            this.touchSupport.consultation = entity.consultation['@id'];
                            this.$refs.resolveReveal.open(this.touchSupport);
                            this.resetKeyChange();
                        }
                    })
                ;
            },
            updateTouchFirstContact() {
                this.touchFirstContactErrors = [];
                this.touchFirstContact.person = this.person['@id'];
                this.$sendToApi(
                    'touch_first_contacts', this.touchFirstContact, (entity, status, response) => {
                        if (status > 299) {
                            this.touchFirstContactErrors = util.mapConstraints(response);
                        } else {
                            this.touchFirstContact = entity;
                            this.touchFirstContact.person = entity.person['@id'];
                            this.$refs.resolveReveal.open(this.touchFirstContact);
                            this.resetKeyChange();

                            if (this.$route.query.touchCallbackId) {
                                // Optimize
                                setTimeout(() => {
                                    this.$http.post(
                                        'actions/touchCallbackResolve',
                                        {
                                            touchFirstContact: this.touchFirstContact.id,
                                            touchCallback: this.$route.query.touchCallbackId,
                                        }
                                    );
                                }, 1000);
                            }
                        }
                    });
            },
            firstContactQuickInfoReveal() {
                this.$refs.firstContactCopyReveal.open();
            },
            copyDataToClipboard() {
                if (!document.queryCommandSupported('copy')) {
                    this.$notify({
                        type: 'alert',
                        text: 'Ihr Browser unterstützt das Kopieren aus einem Browser nicht'
                    });
                }
                const el = document.createElement('textarea');
                el.value = this.touchFirstContact.quickInfoData.replaceAll('<br>', "\n");
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                if (selected) {
                    document.getSelection().removeAllRanges();
                    document.getSelection().addRange(selected);
                }
            },
            setAccordion(id) {
                if (this.accordion.includes(id)) {
                    this.accordion.splice(this.accordion.indexOf(id), 1);
                } else {
                    this.accordion.push(id);
                }
            },
            downloadSupportDocument() {
                let _this = this;
                this.$dialog
                    .confirm('Wurden vorher alle Daten gespeichert?', {
                        okText: 'Ja, Dokument herunterladen',
                        cancelText: 'Nein, abbrechen',
                    })
                    .then(function () {
                        _this.$http.post('exports/support', {'touchSupportId': _this.touchSupport.id}).then(response => {
                            window.location = response.body.url;
                        }, response => {
                            _this.$notify({
                                type: 'alert',
                                text: 'Sind alle Felder gepflegt?<br>' + response.body.join('<br>')
                            })
                        });
                    })
                ;
            },
            showTaskReveal(touch) {
                this.$refs.resolveReveal.open(touch);
            },
        },
    }
</script>
