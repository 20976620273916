<template>
    <div class="grid grid-cols-4 gap-4">
        <div class="col-span-3">
            <input-text v-model="value.name" label="Name" :errors="errors.name"/>
        </div>
        <div class="col-span-1">
            <input-simple-select
                v-model="value.status"
                label="Status"
                :errors="errors.status"
                :options="getStati"
            />
        </div>
        <div class="col-span-2">
            <input-entity
                :readonly="value.hasOwnProperty('@id')"
                v-model="value.education"
                type="education"
                label="Ausbildung"
                :errors="errors.education"
            />
        </div>
        <div v-if="value.hasOwnProperty('id')">
            <label>
                Neue Version
                <br>
                <input type="checkbox" v-model="value.newVersion"/>
            </label>
        </div>
        <div v-if="value.hasOwnProperty('id')">
            <input-text type="number" :readonly="true" v-model.number="value.durationUnits" label="Anzahl UEs"/>
        </div>
    </div>
</template>

<script>
    import value from "../../../mixins/value";

    export default {
        mixins: [value],
        computed: {
            getStati: function() {
                return this.$store.getters.applicationSettings.model.curriculum.status;
            }
        }
    }
</script>
