<template>
    <div>
        <data-table
            ref="absenceTable"
            endpoint="absences"
            :fixed-query="fixedQuery"
            :add-query-to-url="false"
            :columns="columns"
            :show-filter-tags="true"
        >
            <template slot="nextToPagination" class="text-left">
                <div class="columns">
                    <a class="button small mb-0" @click="$refs.absenceTable.createData({person: person['@id']})">Abwesenheit
                        hinzufügen</a>
                </div>
            </template>

            <template slot="updateForm" slot-scope="{data: absence, errors: errors}">
                <div class="grid grid-cols-2 gap-2">
                    <div>
                        <input-date v-model="absence.start" label="Start" :errors="errors.start"/>
                    </div>
                    <div>
                        <input-date v-model="absence.end" label="Ende" :errors="errors.end"/>
                    </div>
                    <div class="col-span-2">
                        <input-simple-select
                            label="Status"
                            :options="statusOptions"
                            v-model="absence.status"
                            :errors="errors.status"
                        ></input-simple-select>

                        <div
                            v-if="['AV', 'E'].includes(absence.status)"
                        >
                            <input-text
                                v-if="absence.status === 'AV'"
                                type="number"
                                label="Verspätung in h"
                                v-model.number="absence.delay"
                                :step="0.25"
                                :errors="errors.delay"
                            />

                            <input-simple-select
                                v-if="absence.status === 'E'"
                                label="Grund"
                                v-model="absence.reason"
                                :options="reasonOptions"
                                :errors="errors.reason"
                            />
                        </div>

                        <input-text
                            type="textarea"
                            v-model="absence.comment"
                            label="Kommentar"
                        >
                        </input-text>
                    </div>
                </div>
            </template>

            <template slot="dataRow" slot-scope="{singleData: absence}">
                <td>
                    {{ absence.start|formatDate('LL') }} - {{ absence.end|formatDate('LL') }}
                </td>
                <td>
                    {{ absence.status|dataTrans('model.appearance.status') }}
                    <template v-if="absence.status === 'AV'">
                        <br>
                        {{ absence.delay }} Stunden
                    </template>
                    <template v-if="absence.status === 'E'">
                        <br>
                        {{ absence.reason|dataTrans('model.appearance.reason') }}
                    </template>
                    <template v-if="absence.comment && absence.comment.length > 0">
                        <br>
                        {{ absence.comment }}
                    </template>
                </td>
                <td>
                    <a
                        v-if="absence.userCanDelete"
                        @click="$refs.absenceTable.deleteData(absence)"
                        class="action --delete"
                    >
                    </a>
                </td>
            </template>
        </data-table>
    </div>
</template>

<script>
    import DataTable from "../../components/DataTable";

    export default {
        components: {
            DataTable
        },
        data() {
            return {
                absence: {}
            }
        },
        props: {
            person: {
                required: true,
            }
        },
        computed: {
            reasonOptions: function () {
                let options = {};
                if (!this.$store.getters.applicationSettings) {
                    return [];
                }
                Object.keys(this.$store.getters.applicationSettings.model.appearance.reason).forEach((item) => {
                    options[item] = this.$store.getters.applicationSettings.model.appearance.reason[item];
                });

                return options;
            },
            statusOptions: function () {
                return this.$store.getters.applicationSettings.model.absence.status;
            },
            columns: function () {
                return {
                    'start': {
                        text: 'Zeitraum',
                        order: true,
                    },
                    status: {
                        text: 'Status',
                    }
                };
            },
            fixedQuery: function () {
                return {
                    person: this.person['@id']
                }
            }
        }
    }
</script>
