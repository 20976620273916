<template>
    <div>
        <content-header headline="Akquisitionspotential"/>
        <content-main>
            <div class="panel h-largeMargin-bottom">
                <div class="row align-bottom" v-for="filterSet, index in filter">
                    <div class="columns small-12">
                        <h3>Zeitraum</h3>
                    </div>
                    <div class="columns">
                        <label>
                            <input-simple-select
                                :options="monthOptions"
                                label="Start"
                                v-model="filterSet.start"
                            />
                        </label>
                    </div>
                    <div class="columns">
                        <label>
                            <input-simple-select
                                :options="monthOptions"
                                label="Ende"
                                v-model="filterSet.end"
                            />
                        </label>
                    </div>
                    <div class="columns shrink">
                        <a
                            class="button thirdly"
                            @click="deleteFilterSet(index)"
                            :disabled="index === 0"
                        >
                            Löschen
                        </a>
                    </div>
                </div>
                <div class="row">
                    <div class="columns small-12">
                        <a
                            @click="addFilterSet"
                            style="margin-bottom: 2rem; padding: 2rem; display: inline-block; border: 1px dashed #ccc; width: 100%; text-align: center;">Vergleichszeitraum
                            hinzufügen</a>
                    </div>
                </div>
            </div>

            <loading
                v-if="loadingIndex > 0"
            />

            <template v-else>
                <table v-for="(table, index) in tables">
                    <thead>
                    <tr>
                        <th style="width: 260px;">
                            <h2>Conversion {{ index + 1 }}</h2>
                        </th>
                        <th v-for="column in columns">
                            {{ column }}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(label, key) in table">
                        <tr v-for="datum, index in data">
                            <th>
                                {{label}}
                                <br>
                                <small>{{filter[index].start}} - {{filter[index].end}}</small>
                            </th>
                            <td v-if="key === 'potential'" :colspan="columns.length"></td>
                            <td v-else-if="key === 'conversion1'" :colspan="columns.length">
                                <strong style="font-size: 2rem;">
                                    {{
                                        Math.round(datum['consultationTouches']['Gesamt'] / datum['newContacts']['Gesamt'] * 100, 2)
                                    }}%
                                </strong>
                            </td>
                            <td v-else-if="key === 'conversion2'" :colspan="columns.length">
                                <strong style="font-size: 2rem;">
                                    {{
                                        Math.round(datum['bookings']['Gesamt'] / datum['consultationTouches']['Gesamt'] * 100, 2)
                                    }}%
                                </strong>
                            </td>
                            <td v-else v-for="column in columns">
                                <template v-if="datum.hasOwnProperty(key)">
                                    {{
                                        typeof datum[key][column] === 'object' ? datum[key][column]['Gesamt'] : datum[key][column]
                                    }}
                                </template>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </template>
        </content-main>
    </div>
</template>

<script>
    import moment from "moment";

    export default {
        data() {
            return {
                data: [],
                loadingIndex: 0,
                tables: [
                    {
                        'newContacts': 'Neue Kontakte',
                        'consultationTouches': 'Beratungstermine',
                        'conversion1': 'Conversion 1',
                        'potential': 'Potential',
                        'openForConsultationTouches': 'Offen Für Beratungstermine (basierend auf Zeitraum)',
                        'openForConsultationTouchesWithVisit': 'Offen für Beratungstermine (mit Schnupper-Workshop-Teilnahme) (basierend auf Zeitraum)',
                    },
                    {
                        'consultationTouches': 'Beratungstermine',
                        'bookings': 'Buchungen',
                        'conversion2': 'Conversion 2',
                        'potential': 'Potential',
                        'openForBookingWithoutSupport': 'Offen nach Beratungstermin (SZ)',
                        'openForBookingWithSupport': 'Offen nach Beratungstermin (AZAV)',
                    }
                ],
                columns: [
                    'Standort Berlin',
                    'Standort Hamburg',
                    'Standort Frankfurt',
                    'Standort München',
                    'Standort Leipzig',
                    'Standort Essen',
                    'Kein Standort',
                    'Gesamt'
                ],
                filter: [{
                    start: null,
                    end: null,
                }]
            }
        },
        computed: {
            monthOptions: function () {
                let startDate = moment().subtract(4, 'years');
                let endDate = moment().add(2, 'month');
                let result = {};

                while (startDate.isBefore(endDate)) {
                    let month = startDate.format("YYYY-MM");
                    result[month] = startDate.format('MM/YYYY');
                    startDate.add(1, 'month');
                }

                return result;
            }
        },
        mounted() {
            this.filter = [
                {
                    start: moment().subtract(8, 'months').format('YYYY-MM'),
                    end: moment().subtract(5, 'months').format('YYYY-MM'),
                },
                {
                    start: moment().subtract(4, 'months').format('YYYY-MM'),
                    end: moment().subtract(1, 'months').format('YYYY-MM'),
                }
            ]

            this.loadData();
        },
        watch: {
            filter: {
                deep: true,
                handler: function () {
                    this.loadData();
                }
            }
        },
        methods: {
            deleteFilterSet(index) {
                if(index > 0) {
                    this.filter.splice(index, 1);
                    this.data.splice(index, 1);
                    this.loadData();
                }
            },
            addFilterSet() {
                this.filter.push({
                    start: moment().subtract(2, 'months').format('YYYY-MM'),
                    end: moment().add(1, 'months').format('YYYY-MM'),
                })
            },
            loadData() {
                this.filter.forEach((item, index) => {
                    this.loadingIndex++;
                    this.$http.get('reportings/performance', {params: item}).then((response) => {
                        this.data[index] = response.data;
                        this.loadingIndex--;
                    })
                })
            }
        }
    }
</script>
