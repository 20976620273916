<template>
    <label :class="{'--errors': hasErrors}">
        <template v-if="label">{{label}}</template>

        <select v-on:input="update($event.target.value)" v-model="value" :readonly="readonly">
            <option :value="null">-</option>
            <option :value="index" v-for="name, index in types">{{name}}</option>
        </select>

        <template v-if="errors !== null && errors.length > 0">
            <ul class="form-errors">
                <li v-for="error in errors">
                    {{error}}
                </li>
            </ul>
        </template>
    </label>
</template>

<script>
    export default {
        data() {
            return {
                showErrors: true
            }
        },
        props: {
            value: {
                type: String | Number,
                default: () => {
                    return '';
                }
            },
            placeholder: {
                type: String,
                default: () => {
                    return '';
                }
            },
            label: {
                type: String,
                default: () => {
                    return '';
                }
            },
            readonly: {
                type: Boolean,
                default: () => {
                    return false;
                }
            },
            required: {
                type: Boolean,
                default: () => {
                    return false;
                }
            },
            errors: {
                type: Object | Array,
                default: function () {
                    return {};
                }
            },
        },
        computed: {
            hasErrors() {
                return this.errors !== null && this.errors.length > 0 && this.showErrors
            },
            types() {
                return window.global.education.types;
            }
        },
        methods: {
            update(data) {
                this.errors = [];
                this.showErrors = false;
                if (!this.readonly) {
                    this.$emit('input', data);
                }
            }
        },
        watch: {
            'errors': function() {
                this.showErrors = true;
            },
        }
    }
</script>
