<template>
    <input-select v-model="value"
                  label="Berechtigungen"
                  :options="getCredibilities()"
                  :labels="getCredibilityLabels()"
    />
</template>

<script>
    export default {
        props: {
            value: {
                type: Array|Object|null,
                required: true,
            }
        },
        watch: {
            value: function(newValue) {
                this.$emit('input', newValue);
            }
        },
        methods: {
            getCredibilities() {
                return Object.keys(window.global.credibilities);
            },
            getCredibilityLabels() {
                let obj = [];
                Object.keys(window.global.credibilities).forEach((item) => {
                    obj.push({
                        label: window.global.credibilities[item],
                        id: item
                    });
                });

                return obj;
            }
        },
    }
</script>
