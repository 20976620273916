<template>
    <div class="callout secondary">
        <p>Das Passwort sollte mindestens Buchstaben, Zahlen und Zeichen enthalten.</p>
    </div>
</template>

<script>
    export default {

    }
</script>
