<template>
	<c-modal
			ref="appearanceUpdateModel"
			name="appearanceUpdateModel"
			headline="Präsenz erfassen"
	>
		<form
				v-on:submit.prevent="submitAppearance"
				class="p-4 grid"
		>
			<template v-if="selectedCourseId">
				<div
						v-if="type === 'guest'"
				>
					<form v-on:submit.prevent="guestSearch">
						<input-text
								class="relative"
								v-model="guestSearchSlug"
								label="Rechnungsnummer"
						/>

						<button
								type="submit"
								class="absolute right-0 top-0 pt-10 pr-2 w-10"
						>
							<div class="action --show"></div>
						</button>
					</form>

					<div
							v-if="!guestSearchResult && guestSearchSlug.length > 0"
							class="callout warning"
					>
						Kein Ergebnis
					</div>

					<div v-else>
						<input-text
								:value="guestSearchResult.displayName"
								label="Teilnehmer"
						/>
					</div>
				</div>

				<input-simple-select
						label="Datum / Zeitraum"
						:required="true"
						v-model="appearanceType"
						:options="{'single': 'Datum', 'period': 'Zeitraum'}"
				/>

				<div
						v-if="appearanceType === 'period'"
						class="bg-warning-100 p-4 text-warning-600 mb-4"
				>
					Für die Auswahl Zeitraum werden nur Anwesenheiten an Tagen gesetzt, die noch keine weitere
					Anwesenheit haben.
				</div>

				<div
						v-if="appearanceType === 'period'"
						class="grid grid-cols-2 gap-8"
				>
					<input-simple-select
							label="Von"
							v-model="appearance['start']"
							:options="eventCourseDatesMappedDate"
							:errors="errors.start"
					/>
					<input-simple-select
							label="Bis"
							v-model="appearance['end']"
							:options="eventCourseDatesMappedDate"
							:errors="errors.end"
					/>
				</div>

				<input-simple-select
						label="Termin"
						:options="eventCourseDatesMappedId"
						v-if="appearanceType === 'single'"
						:as-row="true"
						v-model="appearance.eventCourseDate"
						:errors="errors.eventCourseDate"
				/>

				<appearance v-model="appearance" :errors="errors"/>

				<div class="text-center">
					<button type="submit" class="button">
						Präsenz erfassen
					</button>
				</div>
			</template>

			<template v-else>
				<input-entity
						:fixed-params="{person: fixedPerson['@id']}"
						v-model="appearance.touchBooking"
						type="touchBooking"
						label="Rechnung"
						:errors="errors.touchBooking"
				/>

				<input-simple-select
						label="Datum / Zeitraum"
						:required="true"
						v-model="appearanceType"
						:options="{'single': 'Datum', 'period': 'Zeitraum'}"
				/>

				<div
						v-if="appearanceType === 'period'"
						class="bg-warning-100 p-4 text-warning-600 mb-4"
				>
					Für die Auswahl Zeitraum werden nur Anwesenheiten an Tagen gesetzt, die noch keine weitere
					Anwesenheit haben.
				</div>

				<div
						v-if="appearanceType === 'period'"
						class="grid grid-cols-2 gap-4"
				>
					<input-date
							label="Von"
							v-model="appearance.start"
							:errors="errors.start"
					/>

					<input-date
							label="Bis"
							v-model="appearance.end"
							:errors="errors.end"
					/>
				</div>

				<input-date
						label="Termin"
						v-else
						v-model="appearance.date"
						:errors="errors.date"
				/>

				<input-simple-select
						label="Type"
						:options="$store.getters.applicationSettings.model.appearance.type"
						v-model="appearance.type"
						:errors="errors.type"
						:required="true"
				/>

				<input-text
						v-if="appearanceType === 'period'"
						label="Unterrichtseinheiten pro Tag / Stunden bei Praktikum"
						:required="true"
						v-model.number="appearance['durationUnitsPerDay']"
						:errors="errors.durationUnitsPerDay"
				/>

				<input-text
						v-if="appearanceType === 'single'"
						label="Unterrichtseinheiten / Stunden bei Praktikum"
						:required="true"
						v-model.number="appearance['durationUnits']"
				/>

				<input-text
						type="textarea"
						v-model="appearance.comment"
						label="Kommentar"
				>
				</input-text>

				<div class="text-center">
					<button type="submit" class="button">
						Präsenz erfassen
					</button>
				</div>
			</template>
		</form>
	</c-modal>
</template>

<script>
    import moment from "moment";
    import Util from "../../../plugins/util";
    import DataTable from "../../components/DataTable";
    import InputSimpleMultiSelectRichObjects from "../../components/form/InputSimpleMultiSelectRichObjects.vue";
	import Appearance from "../../components/form/Appearance.vue";

    export default {
        components: {Appearance, InputSimpleMultiSelectRichObjects, DataTable},
        props: {
            fixedCourse: {
                type: Object,
                required: false
            },
            fixedPerson: {
                type: Object,
                required: false
            },
        },
        data() {
            return {
                guestSearchSlug: '',
                guestSearchResult: [],
                course: null,
                errors: {},
                appearance: {
                    status: 'A',
                },
                sortByNameAsc: false,
                type: 'guest',
                appearanceType: 'period',
                people: [],
                person: null,
                eventCourseDates: [],
                createdMultipleData: {},
                createdMultipleDataPerson: [],
                existingAppearances: [],
            }
        },
        computed: {
            selectedCourseId: function () {
                if (this.fixedCourse) {
                    return this.fixedCourse['id'];
                }

                if (this.course && this.course.hasOwnProperty('id')) {
                    return this.course['id'];
                }
                if (this.course) {
                    return this.course.slice(this.course.lastIndexOf('/') + 1);
                }

                return null;
            },
            reasonOptions: function () {
                let options = {};
                if (!this.$store.getters.applicationSettings) {
                    return [];
                }
                Object.keys(this.$store.getters.applicationSettings.model.appearance.reason).forEach((item) => {
                    options[item] = this.$store.getters.applicationSettings.model.appearance.reason[item];
                });

                return options;
            },
            statusOptionsWithoutAdditionalData: function () {
                return Object.fromEntries(Object.entries(this.statusOptions).filter(([key]) => !key.includes('AV') && !key.includes('E')));
            },
            statusOptions: function () {
                return this.$store.getters.applicationSettings.model.appearance.status;
            },
            personByDisplayName: function () {
                let data = {};
                this.people.forEach((item) => {
                    data[item['@id']] = item.displayName;
                });

                return data;
            },
            eventCourseDatesMappedId: function () {
                let data = {};
                let filter = this.$options.filters.formatDate;
                this.eventCourseDates.forEach((item) => {
                    data[item['@id']] = filter(item.date, 'dd: DD.MM.YYYY');
                });

                return data;
            },
            eventCourseDatesMappedDate: function () {
                let data = {};
                let filter = this.$options.filters.formatDate;
                this.eventCourseDates.forEach((item) => {
                    data[filter(item.date, 'YYYY-MM-DD')] = filter(item.date, 'dd: DD.MM.YYYY');
                });

                return data;
            }
        },
        methods: {
            selectAll() {
                this.createdMultipleDataPerson = Object.values(this.people).map((item) => item['@id']);
            },
            guestSearch() {
                this.guestSearchResult = null;
                this.$getListFromApi('people', {invoiceNumber: this.guestSearchSlug}, (items, count) => {
                    if (items.length === 1) {
                        this.guestSearchResult = items[0];
                    }
                });
            },
            submitAppearance() {
                this.errors = {};

                if (this.type === 'single' || this.type === 'guest' || this.fixedPerson) {
                    let params = Object.assign({}, this.appearance);
                    if (this.type === 'guest') {
                        params['person'] = this.guestSearchResult['@id'];
                    }
                    if (this.fixedPerson) {
                        params['person'] = this.fixedPerson['@id'];
                    }
                    if (this.selectedCourseId) {
                        params['course'] = '/api/courses/' + this.selectedCourseId;
                    }

                    this.$sendToApi(
                      this.appearanceType === 'single' ? 'appearances' : 'appearance_periods',
                      params,
                      (entity, status, data) => {
                          if (status > 299) {
                              this.errors = Util.mapConstraints(data);
                          } else {
                              this.appearance.eventCourseDate = null;
                              this.appearance.status = 'A';
                              this.appearance.start = null;
                              this.appearance.end = null;
                              this.$refs.appearanceUpdateModel.hide();
                          }
                          this.$emit('update');
                      },
                      {
                          errorMessageFromApi: true
                      }
                    )
                } else {
                    this.createdMultipleDataPerson.forEach((item) => {
                        let appearance = Object.assign({course: '/api/courses/' + this.selectedCourseId}, this.appearance);
                        appearance['person'] = item;
                        this.$sendToApi(
                          this.appearanceType === 'single' ? 'appearances' : 'appearance_periods',
                          appearance,
                          (entity, status, data) => {
                              delete this.createdMultipleDataPerson[item];
                              if (status > 299) {
                                  this.$set(this.createdMultipleData, item, '0 Anwesenheiten erstellt');
                                  this.createdMultipleData[item] = '0 Anwesenheiten erstellt';
                              } else {
                                  this.$set(this.createdMultipleData, item, entity.appearances.length + ' Anwesenheiten erstellt');
                              }
                              this.$emit('update');
                          },
                          {
                              errorMessageFromApi: true
                          }
                        )
                    });
                }
            },
            async loadPeople(page = 1) {
                if (page === 1) {
                    this.people = [];
                }
                this.$getListFromApi('people', {course: this.selectedCourseId, currentPage: page}, (members, max) => {
                    this.people = this.people.concat(members);
                    if (max > this.people.length) {
                        this.loadPeople(page + 1);
                    }
                });
            },
            async loadEventCourseDates(page = 1) {
                if (page === 1) {
                    this.people = [];
                }
                this.$getListFromApi('event_course_dates', {
                    'course': this.fixedCourse['@id'],
                    currentPage: page,
                    'date[before]': moment().format('YYYY-MM-DD'),
                    'order[date]': 'ASC'
                }, (members, max) => {
                    this.eventCourseDates = this.eventCourseDates.concat(members);
                    if (max > this.eventCourseDates.length) {
                        this.loadEventCourseDates(page + 1);
                    }
                });
            },
            open() {
                this.$refs.appearanceUpdateModel.open();
                if (this.selectedCourseId) {
                    this.loadPeople();
                    this.loadEventCourseDates();
                }
            },
            loadAppearances(date) {
                this.$getListFromApi('appearances', {'eventCourseDate': date}, (data) => {
                    data.forEach((item) => {
                        this.existingAppearances[item.person['@id']] = item;
                    })
                    this.$forceUpdate();
                });
            },
        },
        watch: {
            guestSearchSlug: function () {
                this.guestSearchResult = null;
            },
            'course': function () {
                if (this.selectedCourseId) {
                    this.loadEventCourseDates();
                    this.loadPeople();
                }
            },
            'appearance.eventCourseDate': function (newVal) {
                this.existingAppearances = {};
                if (newVal !== null) {
                    this.loadAppearances(newVal);
                }
            }
        },
        mounted() {
            if (this.person) {
                this.type = 'single';
            }
        }
    }
</script>
