<template>
    <div
        class="statusLight"
        v-if="status !== null && status !== 'inherit'"
        :class="'--' + status + ' --size' + size"
        :title="statusName"
    >
    </div>
</template>

<script>
    export default {
        props: {
            status: {
                required: false,
                default: () => null
            },
            size: {
                required: false,
                default: () => 'default'
            }
        },
        computed: {
            statusName: function() {
                if(this.status) {
                    return this.$store.getters.applicationSettings.model.person.stati[this.status];
                }
                return '';
            }
        }
    }
</script>
