var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-modal',{ref:"eventCourseDateDetails",attrs:{"headline":'Kurstermin-Details (' + _vm.editedEventCourseDate.id + ')',"width":1200}},[_c('div',{staticClass:"grid grid-cols-2 gap-4 min-w-[800px]"},[_c('form',{staticClass:"gap-4 p-4 grid content-start",on:{"submit":function($event){$event.preventDefault();return _vm.submitEventCourseDate.apply(null, arguments)}}},[_c('div',{staticClass:"font-bold text-xl uppercase"},[_vm._v("\n                Allgemeine Daten\n            ")]),_vm._v(" "),_c('div',{staticClass:"bg-warning-100 p-4 text-warning-600 mb-4"},[_vm._v("\n                Je nach Benutzer-Berechtigung können nicht alle Felder geändert werden.\n            ")]),_vm._v(" "),_c('input-entity',{attrs:{"label":"Curriculum-Topic","errors":_vm.editedEventCourseDateErrors.curriculumTopic,"type":"curriculumTopic","readonly":!_vm.$store.getters.hasLoggedInUserCredibility('ROLE_EDUCATION_MANAGEMENT')
                    && !_vm.$store.getters.hasLoggedInUserCredibility('ROLE_EDUCATION_DATE_MANAGEMENT')
                    && !_vm.$store.getters.hasLoggedInUserCredibility('ROLE_ADMINISTRATION'),"fixed-params":{
                    course: (_vm.editedEventCourseDate.hasOwnProperty('course') && _vm.editedEventCourseDate.course.hasOwnProperty('@id')) ? _vm.editedEventCourseDate.course['@id'] : _vm.editedEventCourseDate.course
                }},model:{value:(_vm.editedEventCourseDate.curriculumTopic),callback:function ($$v) {_vm.$set(_vm.editedEventCourseDate, "curriculumTopic", $$v)},expression:"editedEventCourseDate.curriculumTopic"}}),_vm._v(" "),_c('div',{staticClass:"grid gap-4"},[_c('input-entity',{attrs:{"label":"Dozent","errors":_vm.editedEventCourseDateErrors.docent,"type":"docent"},model:{value:(_vm.editedEventCourseDate.docent),callback:function ($$v) {_vm.$set(_vm.editedEventCourseDate, "docent", $$v)},expression:"editedEventCourseDate.docent"}})],1),_vm._v(" "),_c('input-entity',{attrs:{"label":"Raum","errors":_vm.editedEventCourseDateErrors.room,"type":"room"},model:{value:(_vm.editedEventCourseDate.room),callback:function ($$v) {_vm.$set(_vm.editedEventCourseDate, "room", $$v)},expression:"editedEventCourseDate.room"}}),_vm._v(" "),_c('div',{staticClass:"grid gap-4"},[_c('div',[_c('input-date',{attrs:{"label":"Start","errors":_vm.editedEventCourseDateErrors.date,"time":true,"readonly":!_vm.$store.getters.hasLoggedInUserCredibility('ROLE_EDUCATION_MANAGEMENT')
                            && !_vm.$store.getters.hasLoggedInUserCredibility('ROLE_ADMINISTRATION')},model:{value:(_vm.editedEventCourseDate.date),callback:function ($$v) {_vm.$set(_vm.editedEventCourseDate, "date", $$v)},expression:"editedEventCourseDate.date"}})],1),_vm._v(" "),_c('div',[_c('input-text',{attrs:{"label":"Dauer","errors":_vm.editedEventCourseDateErrors.duration,"readonly":!_vm.$store.getters.hasLoggedInUserCredibility('ROLE_EDUCATION_MANAGEMENT')
                            && !_vm.$store.getters.hasLoggedInUserCredibility('ROLE_ADMINISTRATION')},model:{value:(_vm.editedEventCourseDate.duration),callback:function ($$v) {_vm.$set(_vm.editedEventCourseDate, "duration", _vm._n($$v))},expression:"editedEventCourseDate.duration"}})],1)]),_vm._v(" "),_c('input-entity',{attrs:{"label":"Gasthörer-Rechnungen","multi":true,"type":"touchBooking","errors":_vm.editedEventCourseDateErrors.guestTouchBookings,"readonly":!_vm.$store.getters.hasLoggedInUserCredibility('ROLE_EDUCATION_MANAGEMENT')
                    && !_vm.$store.getters.hasLoggedInUserCredibility('ROLE_EDUCATION_DATE_MANAGEMENT')
                    && !_vm.$store.getters.hasLoggedInUserCredibility('ROLE_ADMINISTRATION')},model:{value:(_vm.editedEventCourseDate.guestTouchBookings),callback:function ($$v) {_vm.$set(_vm.editedEventCourseDate, "guestTouchBookings", $$v)},expression:"editedEventCourseDate.guestTouchBookings"}}),_vm._v(" "),(_vm.editedEventCourseDate.userCanUpdate)?_c('div',{staticClass:"text-center grid gap-4 grid-flow-col justify-center"},[_c('button',{staticClass:"button",attrs:{"type":"submit"}},[_vm._v("Aktualisieren")]),_vm._v(" "),_c('a',{staticClass:"button bg-alert-400 text-white hover:bg-alert-600 hover:text-white",on:{"click":function($event){return _vm.deleteEventCourseDate(_vm.editedEventCourseDate)}}},[_vm._v("\n                    Löschen\n                ")])]):_vm._e()],1),_vm._v(" "),_c('div',[_c('notes',{staticClass:"p-4",attrs:{"entity":_vm.editedEventCourseDate}})],1)])])}
var staticRenderFns = []
export { render, staticRenderFns }