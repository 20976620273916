<template>
	<c-modal
			ref="supportedContractAppearanceUpdateModal"
			headline="Abwesenheit FÖBG"
	>
		<div class="p-4 py-12">
			<input-entity
					label="Rechnung"
					v-model="supportedContractAppearance.touchBooking"
					type="touchBooking"
					:multi="false"
					:fixedParams="{person: person['@id']}"
			/>
            
            <input-text
                label="Monat (Schreibweise 2023-03)"
                v-model="supportedContractAppearance.month"
            />

			<div class="bg-warning-100 p-4 text-warning-600 mb-4">
				Das Dokument erscheint in Kürze in den Anhängen
			</div>

			<div class="grid gap-8 items-center">
				<div class="text-center">
					<button
							type="submit"
							class="button mb-0"
							@click="submit"
							:disabled="!supportedContractAppearance.touchBooking || !supportedContractAppearance.month"
					>
						Abwesenheitsmeldung erstellen
					</button>
				</div>
			</div>
		</div>
	</c-modal>
</template>

<script>
    export default {
        props: {
            person: {
                required: true
            }
        },
        data() {
            return {
                supportedContractAppearance: {
                    
                }
            }
        },
		methods: {
			submit() {
				this.$sendToApi('document/support_appearances', this.supportedContractAppearance, (entity) => {
					this.$refs.supportedContractAppearanceUpdateModal.hide();
					this.$http.get('attachments/' + entity['attachment'].replace('/api/attachments/', '') + '/download').then(response => {
						window.location = response.data;
					});
				});
			},
			open() {
				this.supportedContractAppearance = {
					touchBooking: null,
				};
				this.$refs.supportedContractAppearanceUpdateModal.show();
			}
		},
    }
</script>
