<template>
    <label>
        <template v-if="label">
            {{label}}
        </template>
        <select v-model="value">
            <option :value="null">-</option>
            <option :value="true">Ja</option>
            <option :value="false">Nein</option>
        </select>
    </label>
</template>

<script>
    import value from "../../../mixins/value";
    export default {
        mounted() {
            if(typeof this.value === 'undefined') {
                this.value = null;
            }
        },
        mixins: [value],
        props: {
            label: {
                required: false,
            }
        }
    }
</script>
