<template>
    <div style="margin-bottom: 1rem;">
        <label style="margin-bottom: 0;">
            <template v-if="label">{{ label }}</template>
        </label>

        <multiselect
            ref="multiSelect"
            v-model="value"
            :multiple="multi"
            :taggable="taggable"
            :options="options.map((item) => {return item['@id']})"
            :internal-search="true"
            :custom-label="getLabelById"
            :loading="loadingIndex > 0"
            :disabled="readonly"
            @tag="addTag"
            @input="update"
            @select="addOption"
            @search-change="searchChange"
            selectLabel="Enter Drücken zum Auswählen"
            selectGroupLabel="Enter Drücken um Gruppe auszuwählen"
            selectedLabel="Ausgewählt"
            deselectLabel="Enter Drücken zum Entfernen"
            deselectGroupLabel="Enter Drücken um Gruppe zu entfernen"
            placeholder="Eingeben und Auswählen"
            :allow-empty="true"
            :openDirection="openDirection"
            :showPointer="false"
            :options-limit="300"
            :max="60"
            :maxHeight="130"
            class="bg-red"
        >
            <template slot="noResult">
                <span v-if="loadingIndex > 0">
                    Es wird geladen.
                </span>
                <span v-else>
                    Keine Ergebnisse gefunden.
                </span>
            </template>

            <template slot="noOptions">
                <span>Keine Ergebnisse gefunden.</span>
            </template>
        </multiselect>

        <template v-if="errors !== null && errors.length > 0">
            <ul class="form-errors">
                <li v-for="error in errors">
                    {{ error }}
                </li>
            </ul>
        </template>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import moment from "moment";

    export default {
        components: {
            Multiselect
        },
        props: {
            readonly: {
                type: Boolean,
                default: () => false
            },
            type: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                default: () => {
                    return '';
                },
            },
            errors: {
                type: Object | Array,
                default: function () {
                    return {};
                }
            },
            value: {
                type: Object | Array,
                required: true,
            },
            multi: {
                type: Boolean,
                default: () => false
            },
            taggable: {
                type: Boolean,
                default: () => false
            },
            tagAttribute: {
                type: String,
                default: () => 'name'
            },
            openDirection: {
                type: String,
                default: () => 'above'
            },
            extraParams: {
                type: Object,
                required: false,
                default: () => {
                    return {}
                }
            },
            fixedParams: {
                type: Object,
                required: false,
                default: () => {
                    return {}
                }
            },
            extendWithCollectionApi: {
                type: Boolean,
                required: false,
                default: false,
            }
        },
        data() {
            return {
                q: '',
                options: [],

                loadingIndex: 0,
                baseApiUrl: '',
                itemLabel: '',
                searchParam: '',
                itemsPerPage: 30,
            };
        },
        mounted() {
            if (this.type === 'touchBooking') {
                this.baseApiUrl = 'touch_bookings';
                this.itemLabel = (value) => value.invoiceNumber + ' (' + value.person.lastName + ', ' + value.person.firstName + ')';
                this.searchParam = 'q';
            }
            if (this.type === 'portal') {
                this.baseApiUrl = 'portals';
                this.itemLabel = 'name';
                this.searchParam = 'name';
            }
            if (this.type === 'role') {
                this.baseApiUrl = 'roles';
                this.itemLabel = 'name';
                this.searchParam = 'name';
            }
            if (this.type === 'user_real') {
                this.baseApiUrl = 'user_reals';
                this.itemLabel = (value) => value.username + (!value.active ? ' (inaktiv)' : '(aktiv)');
                this.searchParam = 'username';
            }
            if (this.type === 'reporting') {
                this.baseApiUrl = 'reportings';
                this.itemLabel = 'name';
                this.searchParam = 'name';
            }
            if (this.type === 'location') {
                this.baseApiUrl = 'locations';
                this.itemLabel = 'name';
                this.searchParam = 'name';
            }
            if (this.type === 'person') {
                this.baseApiUrl = 'people';
                this.itemLabel = 'displayName';
                this.searchParam = 'lastName';
            }
            if (this.type === 'education') {
                this.baseApiUrl = 'education';
                this.itemLabel = 'name';
                this.searchParam = 'name';
                this.itemsPerPage = 15;
                this.extraParams = {
                    active: 1
                }
                this.itemLabel = function (object) {
                    return object.name + (!object.active ? ' (inaktiv)' : '')
                };
            }
            if (this.type === 'curriculum') {
                this.baseApiUrl = 'curricula';
                this.itemLabel = function (object) {
                    return object.name + ' (Version ' + object.versionNumber + ')';
                };
                this.searchParam = 'name';
            }
            if (this.type === 'curriculumTopic') {
                this.baseApiUrl = 'curriculum_topics';
                this.itemsPerPage = 300;
                this.itemLabel = function (object) {
                    return object.block + '.' + object.day + ' - ' + object.name + ' (' + object.curriculum.name + ')';
                };
                this.searchParam = 'name';
            }
            if (this.type === 'word_press_page') {
                this.baseApiUrl = 'word_press_pages';
                this.itemLabel = function (object) {
                    return object.title + ' (' + object.link + ')';
                };
                this.searchParam = 'title';
                this.itemsPerPage = 300;
            }
            if (this.type === 'user_real') {
                this.baseApiUrl = 'user_reals';
                this.itemLabel = (value) => value.displayName + (!value.active ? ' (inaktiv)' : '');
                this.searchParam = 'username';
                this.itemsPerPage = 30;
            }
            if (this.type === 'event') {
                this.searchParam = 'name';
                this.baseApiUrl = 'events';
                this.itemLabel = function (object) {
                    return object.name + ' (' + moment(object.date).format('LL') + ')';
                };
            }
            if (this.type === 'room') {
                this.searchParam = 'name';
                this.baseApiUrl = 'rooms';
                this.itemsPerPage = 300;
                this.itemLabel = function (object) {
                    return object.name + ' - ' + object.location.name;
                };
            }
            if (this.type === 'eventTrialCourse') {
                this.searchParam = 'name';
                this.baseApiUrl = 'event_trial_courses';
                this.itemsPerPage = 30;
                this.itemLabel = function (object) {
                    return object.name + ' (' + moment(object.date).format('LLLL') + ' Uhr)';
                };
            }
            if (this.type === 'templateTouchCustom') {
                this.searchParam = 'name';
                this.baseApiUrl = 'template_touch_customs';
                this.itemLabel = 'name';
            }
            if (this.type === 'course') {
                this.searchParam = 'code';
                this.baseApiUrl = 'courses';
                this.itemLabel = 'code';
            }
            if (this.type === 'supportedCostCenter') {
                this.searchParam = 'name';
                this.baseApiUrl = 'supported_cost_centers';
                this.itemLabel = function (value) {
                    return value.name + ' (' + value.street + ' - ' + value.zip + ' ' + value.city + ')';
                }
            }
            if (this.type === 'supportedCostCenterContactPerson') {
                this.searchParam = 'lastName';
                this.baseApiUrl = 'supported_cost_center_contact_people';
                this.itemLabel = 'displayName';
            }
            if (this.type === 'docent') {
                this.searchParam = 'lastName';
                this.baseApiUrl = 'docents';
                this.itemLabel = function (value) {
                    return value.lastName + ', ' + value.firstName;
                }
            }
            if (this.type === 'learningField') {
                this.searchParam = 'name';
                this.baseApiUrl = 'learning_fields';
                this.itemLabel = 'name';
            }
            if (this.type === 'eventCourseDate') {
                this.itemsPerPage = 1000;
                this.searchParam = 'course.code';
                this.baseApiUrl = 'event_course_dates';
                this.itemLabel = function (value) {
                    return value.code + ' (' + moment(value.date).format('LLLL') + ')';
                }
            }

            this.loadOptions();

            if (this.multi === false && typeof this.value === "object") {
                this.update(this.value);
            }
        },

        methods: {
            addTag(newTag) {
                this.$http.post(this.baseApiUrl, {name: newTag}).then(response => {
                    let newEnrichTag = response.data;
                    this.addOption(response.data);
                    if (!this.multi) {
                        this.value = newEnrichTag;
                    } else {
                        this.value.unshift(newEnrichTag);
                    }
                    this.update(this.value);
                }, response => {
                    this.$notify({
                        type: 'alert',
                        text: 'Optionen konnten nicht hinzugefügt werden'
                    });
                });
            },
            addOption(option) {
                if (typeof option !== "undefined" && option !== null) {
                    var index = this.options.map(listItem => listItem['@id']).indexOf(option['@id']);
                    option.id = option['@id'];
                    if (index > -1) {
                        this.options[index] = option;
                    } else {
                        this.options.unshift(option);
                    }
                }
                this.$forceUpdate();
            },
            loadOptions() {
                let params = {
                    itemsPerPage: this.itemsPerPage ? this.itemsPerPage : 30,
                    orderBy: 'createdAt'
                };
                params[this.searchParam] = this.q;
                params = Object.assign(params, this.fixedParams);

                this.loadingIndex++;
                this.$http.get(this.baseApiUrl, {params: params}).then(response => {
                    response.data['hydra:member'].forEach((item) => {
                        this.addOption(item);
                    });
                    this.loadingIndex--;

                    if (!this.multi && typeof this.value !== 'undefined' && this.value !== null) {
                        var index = this.options.map(listItem => listItem['@id']).indexOf(this.value);
                        if (index < 0) {
                            this.loadingIndex++;
                            this.$http.get(this.value.replace('/api/', '')).then(response => {
                                this.addOption(response.data);
                                this.loadingIndex--;
                            });
                        }
                    }

                    if (this.multi && this.value !== 'undefined' && Array.isArray(this.value)) {
                        let searchIds = [];
                        this.value.forEach((item) => {
                            let alreadyExists = this.options.filter((existingOption) => {
                                return existingOption['@id'] === item;
                            });
                            if (!alreadyExists.length) {
                                let endpoint = item.hasOwnProperty('@id') ? item['@id'] : item;

                                if (this.extendWithCollectionApi) {
                                    searchIds.push(endpoint);
                                } else {
                                    this.loadingIndex++;
                                    this.$http.get(endpoint.replace('/api/', '')).then(response => {
                                        this.addOption(response.data);
                                        this.loadingIndex--;
                                    });
                                }
                            }
                        });

                        if (searchIds.length > 0) {
                            let newVal = [];
                            this.loadingIndex++;
                            this.$http.get(this.baseApiUrl, {
                                params: {
                                    id: searchIds,
                                    itemsPerPage: 100
                                }
                            }).then((response) => {
                                response.body['hydra:member'].forEach((item) => {
                                    this.addOption(item);
                                    newVal.push(item['@id']);
                                })
                                this.value = newVal;
                                this.$emit('input', newVal);
                                this.loadingIndex--;
                            });
                        }
                    }
                }, response => {
                    this.loadingIndex--;
                    this.$notify({
                        type: 'alert',
                        text: 'Fehler beim Laden der Daten'
                    });
                });
            },
            searchChange(q) {
                this.q = q;
                this.loadOptions();
            },
            getLabelById(id) {
                if (typeof (id) === 'object') {
                    if (typeof this.itemLabel === 'function') {
                        return this.itemLabel(id);
                    } else {
                        return id[this.itemLabel];
                    }
                }
                let selectedObject = this.options.filter(option => option['@id'] === id);
                if (selectedObject[0]) {
                    if (typeof this.itemLabel === 'function') {
                        return this.itemLabel(selectedObject[0]);
                    } else {
                        return selectedObject[0][this.itemLabel];
                    }
                }
                return '';
            },
            update(data) {
                if (!this.multi) {
                    if (data === null) {
                        this.$emit('input', null);
                    } else {
                        if (typeof data === 'object' && data['@id']) {
                            this.$emit('input', data['@id']);
                        } else {
                            this.$emit('input', data);
                        }
                    }
                } else {
                    let _this = this;
                    let parsedData = [];
                    if (data.length > 0) {
                        data.forEach(function (item) {
                            if (typeof (item) === 'object') {
                                parsedData.unshift(item['@id']);
                            } else {
                                console.log('Load update function item ' + item);
                                if (_this.extendWithCollectionApi) {
                                    if (_this.options.filter(option => item === option['@id']).length === 0) {
                                        _this.$getFromApi(_this.baseApiUrl, item.substr(item.lastIndexOf('/') + 1), (entity) => {
                                            _this.addOption(entity);
                                        });
                                    }
                                    parsedData.unshift(item);
                                } else {
                                    _this.$getFromApi(_this.baseApiUrl, item.substr(item.lastIndexOf('/') + 1), (entity) => {
                                        _this.addOption(entity);
                                    });
                                    parsedData.unshift(item);
                                }
                            }
                        });
                        this.$emit('input', Array.from(new Set(parsedData)));
                    } else {
                        this.$emit('input', parsedData);
                    }
                }
                this.$forceUpdate();
            },
        },
        watch: {
            value: function (newValue) {
                if (!this.multi) {
                    if (typeof newValue === 'object') {
                        this.addOption(newValue);
                        this.update(newValue);
                    }
                } else {
                    if (newValue.length > 0) {
                        let parsedData = [];
                        newValue.forEach((item) => {
                            if (typeof item === 'object') {
                                this.addOption(item);
                                parsedData.unshift(item.id);
                            }
                        });

                        if (parsedData.length > 0) {
                            this.update(parsedData);
                        }
                    }
                }
                this.$forceUpdate();
            }
        }
    }
</script>
