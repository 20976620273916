<template>
    <div v-if="$store.getters.applicationSettings.model.docentBilling.status">
        <data-table
            endpoint="docent_billings"
            :columns="columns"
            :filters="filters"
            ref="dataTable"
            :fixed-query="fixedQuery"
            :add-query-to-url="addToQueryUrl"
        >
            <template slot="dataRow" slot-scope="{singleData: docentBilling}">
                <td>
                    {{ docentBilling.month }}
                </td>
                <td>
                    {{ docentBilling.type|dataTrans('model.docentBilling.type') }}
                </td>
                <td>
                    <template v-if="docentBilling.location">
                        {{ docentBilling.location.name }}
                    </template>
                </td>
                <td>
                    <router-link :to="'/erp/docents/' + docentBilling.docent.id">
                        {{ docentBilling.docent.displayName }}
                    </router-link>
                </td>
                <td>
                    {{ docentBilling.status|dataTrans('model.docentBilling.status') }}
                </td>
                <td class="text-right">
                    {{ docentBilling.sum|price }} Euro
                </td>
                <td class="text-right">
                    {{ docentBilling.lastEditedAt|formatDate('LLL') }}
                    <br>
                    {{ docentBilling.lastEditedByDisplayName }}
                </td>
                <td>
                    <a @click="showDetails(docentBilling)">Details</a>
                </td>
            </template>
        </data-table>
        <c-modal ref="modal">
            <div
                v-on:click="hide"
                class="modal__absoluteClose"></div>
            <div
                v-if="currentDocentBilling"
                class="p-4"
            >
                <div class="grid gap-4">
                    <div class="text-center font-bold text-xl uppercase">
                        Abrechnungsdetails
                    </div>
                    <table>
                        <tbody>
                        <tr>
                            <td class="font-bold">
                                Dozent
                            </td>
                            <td class="text-right">
                                {{ currentDocentBilling.docent.displayName }}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-bold">
                                Status
                            </td>
                            <td class="text-right">
                                {{ currentDocentBilling.status|dataTrans('model.docentBilling.status') }}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-bold">
                                Typ
                            </td>
                            <td class="text-right">
                                {{ currentDocentBilling.type|dataTrans('model.docentBilling.status') }}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-bold">
                                Monat
                            </td>
                            <td class="text-right">
                                {{ currentDocentBilling.month }}
                            </td>
                        </tr>
                        <tr>
                            <td class="font-bold">
                                Standort
                            </td>
                            <td class="text-right">
                                <template v-if="currentDocentBilling.location">
                                    {{ currentDocentBilling.location.name }}
                                </template>
                            </td>
                        </tr>
                        <tr>
                            <td class="font-bold">
                                Summe
                            </td>
                            <td class="text-right">
                                {{ currentDocentBilling.sum|price }} Euro
                            </td>
                        </tr>
                        <tr v-for="(data, course) in currentDocentBilling.billingData">
                            <template v-if="currentDocentBilling.type === 'courseDate'">
                                <td class="font-bold">
                                    {{ course }}
                                </td>
                                <td class="text-right grid gap-4">
                                    <div v-for="datum in data">
                                        {{ datum.date|formatDate('LL') }}
                                        <br>
                                        {{ datum.participantCount }} TN
                                        <br>
                                        {{ datum.durationUnits }} UE
                                        <br>
                                        {{ datum.costs|price }} Euro
                                    </div>
                                </td>
                            </template>
                            <template v-else>
                                <td class="font-bold" colspan="2">
                                    {{data.name}}
                                    <br>
                                    {{ data.date|formatDate('LL') }}
                                    <br>
                                    {{ data.costs|price }} Euro
                                </td>
                            </template>
                        </tr>
                        </tbody>
                    </table>

                    <div class="text-center">
                        <a
                            v-if="currentDocentBilling.userCanDelete"
                            @click="deleteDocentBilling(currentDocentBilling)"
                            class="button bg-alert hover:bg-alert-600"

                        >
                            Löschen
                        </a>
                        <a
                            v-if="currentDocentBilling.userCanUpdate"
                            @click="setStatus('accept')"
                            class="button"

                        >
                            Freigeben
                        </a>
                    </div>
                </div>
            </div>
        </c-modal>
    </div>
</template>

<script>
    import DataTable from "@/components/DataTable";

    export default {
        props: {
            fixedQuery: {
                required: false,
            },
            addToQueryUrl: {
                required: false,
                default: true,
            }
        },
        components: {
            DataTable
        },
        methods: {
            deleteDocentBilling(docentBilling) {
                let _this = this;
                this.$dialog
                    .confirm('Soll dieser Dozenten-Abrechnungs-Entwurf wirklich gelöscht werden', {
                        okText: 'Ja',
                        cancelText: 'Abbrechen',
                    })
                    .then(function (dialog) {
                        _this.$deleteFromApi('docent_billings', docentBilling, () => {
                            _this.$refs.modal.hide();
                            _this.$refs.dataTable.reload();
                        });
                    })
                    .catch(function () {
                    });
            },
            setStatus() {
                this.$http.post(this.currentDocentBilling['@id'].substr(5) + '/accept', {}).then((res) => {
                    this.$refs.dataTable.reload();
                    this.$refs.modal.hide();
                });
            },
            showDetails(docentBilling) {
                this.currentDocentBilling = docentBilling;
                this.$refs.modal.show();
            },
            hide() {
                this.$refs.modal.hide();
            }
        },
        computed: {
            filters() {
                let stati = this.$store.getters.applicationSettings.model.docentBilling.status;
                let types = this.$store.getters.applicationSettings.model.docentBilling.type;
                let filters = [
                    {
                        type: 'text',
                        model: 'month',
                        label: 'Monat'
                    },
                    {
                        type: 'select',
                        model: 'status',
                        label: 'Status',
                        options: stati,
                        callback: function (item) {
                            return stati[item];
                        }
                    },
                    {
                        type: 'select',
                        model: 'type',
                        label: 'Typ',
                        options: types,
                        callback: function (item) {
                            return types[item];
                        }
                    },
                    {
                        type: 'entity',
                        entity: 'location',
                        model: 'location',
                        label: 'Standort',
                        callback: function () {
                            return 'Ein Datensatz ausgewählt';
                        }
                    },
                ];

                if (!this.fixedQuery || !this.fixedQuery.hasOwnProperty('docent')) {
                    filters.push({
                        type: 'text',
                        model: 'docent.lastName',
                        label: 'Nachname Dozent'
                    });
                }

                return filters;
            }
        },
        data() {
            return {
                currentDocentBilling: null,
                columns: {
                    month: {
                        text: 'Monat',
                        order: true,
                    },
                    type: {
                        text: 'Typ',
                        order: true,
                    },
                    location: {
                        text: 'Standort',
                        order: false
                    },
                    docent: {
                        text: 'Dozent',
                        order: true,
                    },
                    status: {
                        text: 'Status',
                        order: true,
                    },
                    sum: {
                        text: 'Summe',
                        order: false,
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true,
                    },
                }
            }
        }
    }
</script>
