<template>
    <div>
        <content-header headline="Kurs-Verknüpfungen"/>

        <content-main>
            <ul class="tabs">
                <li v-for="(name, index) in toolTabs">
                    <a @click="tab = index" :class="{'--active': tab===index}">{{ name }}</a>
                </li>
            </ul>

            <div class="panel" v-if="tab === 'courseConnection'">
                <div class="row">
                    <div class="large-12 columns">
                        <div class="callout info">
                            <p>Kurse wie folgt eintragen (Basis-Kurs; Teilkurs; Teilkurs).
                            <br>
                            Wenn der Kurs frei wählbar sein soll, die Datums/Kursnummer angaben weglassen (auch ohne Standort möglich).
                            </p>
                            <pre>KT-F-1-21;KREW-F-1-21;KTW-F-1-21<br>KT-F-1-21;KTW-F</pre>
                        </div>
                        <loading v-if="loadingIndex > 0" :disable-wrapper="true"/>
                        <form v-on:submit.prevent="handleCourseConnection" v-else>
                            <input-text
                                type="textarea"
                                v-model="courseConnectionData"
                                label="Kursverknüpfungen"
                                :errors="courseConnectionErrors"
                            />
                            <button type="submit" class="button">Upload</button>
                        </form>
                    </div>
                </div>
            </div>

            <div class="panel" v-if="tab === 'startEndDate'">
                <div class="row">
                    <div class="large-12 columns">
                        <div class="callout info">
                            <p>Kurse wie folgt eintragen (Basis-Kurs; Teilkurs; Teilkurs):</p>
                            <pre>KT-F-1-21;2022-01-01;2023-01-01<br>KT-F-2-22;2023-01-01;2024-01-01</pre>
                            <p>Achtung! Alle anderen Termine des Courses werden gelöscht.</p>
                        </div>
                        <loading v-if="loadingIndex > 0" :disable-wrapper="true"/>
                        <form v-on:submit.prevent="handleCourseStartEnd" v-else>
                            <input-text
                                type="textarea"
                                v-model="courseStartEndData"
                                label="Kursverknüpfungen"
                                :errors="courseStartEndErrors"
                            />
                            <button type="submit" class="button">Upload</button>
                        </form>
                    </div>
                </div>
            </div>
        </content-main>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tab: 'courseConnection',
                toolTabs: {
                    courseConnection: 'Kurs-Verknüpfung',
                    startEndDate: 'Start- und Endtermin',
                },
                courseConnectionData: '',
                courseConnectionErrors: [],

                courseStartEndData: '',
                courseStartEndErrors: [],

                loadingIndex: 0,
            }
        },
        methods: {
            handleCourseStartEnd() {
                this.courseStartEndErrors = [];
                this.loadingIndex++;
                this.$http.post('actions/courseStartEnd', {data: this.courseStartEndData}).then(() => {
                    this.loadingIndex--;
                    this.courseStartEndData = '';
                    this.$notify({
                        type: 'success',
                        text: 'Kurs Start- und Endtermine erfolgreich erstellt.'
                    });
                }, response => {
                    this.loadingIndex--;
                    this.courseStartEndErrors.push(response.body.violations[0].message);
                });
            },
            handleCourseConnection() {
                this.courseConnectionErrors = [];
                this.loadingIndex++;
                this.$http.post('actions/courseConnection', {data: this.courseConnectionData}).then(() => {
                    this.loadingIndex--;
                    this.courseConnectionData = '';
                    this.$notify({
                        type: 'success',
                        text: 'Kursverknüpfungen erfolgreich erstellt.'
                    });
                }, response => {
                    this.loadingIndex--;
                    this.courseConnectionErrors.push(response.body.violations[0].message);
                });
            }
        }
    }
</script>
