<template>
    <div>
        <content-header :headline="persistedEntity.name">
            <router-link to="/teaching/curricula" class="button thirdly">Zurück zur Übersicht</router-link>
        </content-header>

        <content-main v-if="persistedEntity.id">
            <form
                v-on:submit.prevent="submitData"
                class="panel grid gap-4"
            >
                <h2>Allgemeine Daten</h2>

                <update-form v-model="editedEntity" :errors="errors"/>

                <div v-if="persistedEntity.latestChildren"
                     class="text-center"
                >
                    <router-link

                        :to="'/teaching/curricula/' + persistedEntity.latestChildren.id"
                    >
                        Zur aktuellsten Version wechseln
                    </router-link>
                </div>

                <div
                    v-else
                    class="text-center"
                >
                    <button type="submit" class="button">
                        Aktualisieren
                    </button>
                </div>
            </form>
            <div class="panel grid gap-4">
                <div class="grid grid-flow-col justify-between items-center">
                    <h2>Themenblöcke</h2>
                    <div>
                        <a
                            @click="createCurriculumTopic"
                            class="button small mb-0"
                        >
                            Themenblock ergänzen
                        </a>
                    </div>
                </div>

                <c-modal ref="curriculumTopicModal"
                         headline="Curriculum-Thema"
                >
                    <form
                        v-on:submit.prevent="updateCurriculumTopic"
                        class="p-4 gap-4"
                    >
                        <curriculum-topic-update-form
                            v-model="editedCurriculumTopic"
                            :errors="editedCurriculumTopicErrors"
                        />
                        <div class="text-center">
                            <button type="submit" class="button">Speichern</button>
                        </div>
                    </form>
                </c-modal>

                <table>
                    <thead>
                    <th>
                        Thema
                    </th>
                    <th>
                        Block.Tag
                    </th>
                    <th>
                        UEs
                    </th>
                    <th>
                        Inhalt / Beschreibung
                    </th>
                    <th>

                    </th>
                    </thead>
                    <tbody>
                    <tr v-for="topic in editedEntity.topics">
                        <td>
                            {{ topic.name }}
                            <template v-if="topic.tags && topic.tags.length > 0">
                                <br>
                                <div class="grid grid-flow-col gap-2 justify-start">
                                <span
                                    v-for="tag in topic.tags"
                                    class="bg-brand text-sm text-white py-1 px-2 rounded-full"
                                >
                                    {{ tag|dataTrans('model.curriculumTopic.tag') }}
                                </span>
                                </div>
                            </template>
                        </td>
                        <td>
                            {{ topic.block }}.{{ topic.day }}
                        </td>
                        <td>
                            {{ topic.durationUnits }}
                        </td>
                        <td v-html='topic.content.replaceAll("\n", "<br>")'>
                        </td>
                        <td class="text-right">
                            <a
                                v-if="topic.userCanDelete"
                                @click="deleteCurriculumTopic(topic)"
                                class="action --delete"
                            >

                            </a>
                            <a
                                v-if="topic.userCanUpdate"
                                @click="editCurriculumTopic(topic)"
                                class="action --edit"
                            >

                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div class="panel grid gap-4">
                <h2>Anhänge</h2>
                <attachments :entity-id="editedEntity['@id']"/>
            </div>
        </content-main>
    </div>
</template>

<script>
    import detailView from "../../../../../mixins/detailView";
    import UpdateForm from "@/data/Curriculum/UpdateForm.vue";
    import CurriculumTopicUpdateForm from "@/data/CurriculumTopic/UpdateForm.vue";
    import util from "../../../../../plugins/util";
    import Attachments from "@/data/Person/Attachments.vue";

    export default {
        components: {Attachments, UpdateForm, CurriculumTopicUpdateForm},
        mixins: [detailView],
        data() {
            return {
                editedCurriculumTopic: {},
                editedCurriculumTopicErrors: {},
            }
        },
        methods: {
            editCurriculumTopic(topic) {
                this.editedCurriculumTopic = Object.assign({}, topic);
                this.editedCurriculumTopicErrors = {};
                this.$refs.curriculumTopicModal.open();
            },
            deleteCurriculumTopic(topic) {
                let _this = this;
                this.$dialog
                    .confirm('Thema wirklich entfernen?',
                        {
                            okText: 'Bestätigen',
                            cancelText: 'Abbrechen',
                        })
                    .then(function (dialog) {
                        _this.$deleteFromApi('curriculum_topics', topic, () => {
                            _this.loadData();
                        });
                    })
                    .catch(function () {
                    });
            },
            updateCurriculumTopic() {
                this.editedCurriculumTopicErrors = {};
                this.$sendToApi('curriculum_topics', this.editedCurriculumTopic, (entity, status, data) => {
                    if (status < 300) {
                        this.editedCurriculumTopic = {
                            curriculum: this.editedEntity['@id']
                        };
                        this.$refs.curriculumTopicModal.close();
                        this.loadData();
                    } else {
                        this.editedCurriculumTopicErrors = util.mapConstraints(data);
                    }
                });
            },
            createCurriculumTopic() {
                this.editedCurriculumTopic = {
                    curriculum: this.editedEntity['@id']
                };
                this.$refs.curriculumTopicModal.open();
            },
            getEndpoint() {
                return 'curricula';
            },
            convertLoadedEntity(entity) {
                if (entity.latestChildren) {
                    let _this = this;
                    this.$dialog
                        .confirm('Es gibt eine aktuellere Curriculum-Version - zu dieser wechseln?')
                        .then(function (dialog) {
                            _this.$router.push('/teaching/curricula/' + entity.latestChildren.id);
                        }).catch(function () {

                    });
                }

                return entity;
            },
        }
    }
</script>
