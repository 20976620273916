<template>
    <div>
        <content-header headline="Praktikumsstellen">
            <a
                class="button"
                @click="$refs.dataTable.createData({})"
            >
                Praktikumsstelle hinzufügen
            </a>
        </content-header>
        <content-main>
            <data-table
                endpoint="intership_companies"
                :columns="columns"
                :filters="filters"
                :filter-layout="filterLayout"
                :filter-large="true"
                ref="dataTable"
                updateRevealSize="large"
            >
                <template slot="updateForm" slot-scope="{data: intershipCompany, errors: errors}">
                    <update-form v-model="intershipCompany" :errors="errors"/>
                </template>
                <template slot="dataRow" slot-scope="{singleData: intershipCompany}">
                    <td>
                        {{ intershipCompany.name }}
                    </td>
                    <td>
                        <template v-if="intershipCompany.street">{{ intershipCompany.street }}</template>
                        <br v-if="intershipCompany.street && (intershipCompany.zip || intershipCompany.city)">
                        <template v-if="intershipCompany.zip">{{ intershipCompany.zip }}</template>
                        <template v-if="intershipCompany.city">{{ intershipCompany.city }}</template>
                    </td>
                    <td>
                        <template v-if="intershipCompany.email">
                            {{ intershipCompany.email }}
                        </template>
                        <template v-if="intershipCompany.email && intershipCompany.phoneNumber">
                            <br>
                        </template>
                        <template v-if="intershipCompany.phoneNumber">
                            {{ intershipCompany.phoneNumber }}
                        </template>
                    </td>
                    <td>
                        {{ intershipCompany.lastEditedAt|formatDate('LLL') }}
                    </td>
                    <td class="text-right">
                        <a
                            @click="$refs.dataTable.editData(intershipCompany)"
                            class="action --edit"
                        >
                        </a>
                    </td>
                </template>
            </data-table>
        </content-main>
    </div>
</template>


<script>
    import DataTable from "../../../../components/DataTable.vue";
    import UpdateForm from "../../../../data/IntershipCompany/UpdateForm.vue";

    export default {
        components: {
            UpdateForm,
            DataTable
        },
        methods: {
            reload() {
                this.$refs.dataTable.reload();
            },
            createData(data) {
                this.$refs.dataTable.createData(data);
            },
        },
        computed: {
            filters() {
                return [
                    {
                        type: 'text',
                        model: 'name',
                        label: 'Name'
                    },
                    {
                        type: 'text',
                        model: 'street',
                        label: 'Straße'
                    },
                    {
                        type: 'text',
                        model: 'city',
                        label: 'Stadt'
                    },
                    {
                        type: 'text',
                        model: 'phoneNumber',
                        label: 'Telefonnummer'
                    },,
                    {
                        type: 'text',
                        model: 'email',
                        label: 'E-Mail'
                    },
                ];
            },
            columns() {
                return {
                    name: {
                        text: 'Name',
                        order: true,
                    },
                    city: {
                        text: 'Adresse',
                    },
                    email: {
                        text: 'Kontakt',
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    }
                };
            },
        },
        data() {
            return {
                duplicate: null,
                filterLayout: [
                    ['name'],
                    ['street', 'city'],
                    ['email', 'phoneNumber'],
                ],
            }
        }
    }
</script>
