<template>
    <div>
        <content-header headline="Farbenherbst">
            <a class="button" @click="exportAutumnOfColorWorks">
                Export
            </a>
        </content-header>
        <content-main>
            <data-table
                endpoint="autumn_of_color_works"
                :columns="columns"
                :filters="filters"
                :filter-layout="filterLayout"
                :filter-large="true"
                ref="dataTable"
                updateRevealSize="large"
            >
                <template slot="dataRow" slot-scope="{singleData: autumnOfColorWork}">
                    <td>
                        {{autumnOfColorWork.status|dataTrans('model.autumnOfColorWork.status')}}
                    </td>
                    <td>
                        <router-link :to="'/persons/' + autumnOfColorWork.person.id">
                            {{autumnOfColorWork.person.displayName}}
                        </router-link>
                    </td>
                    <td>
                        {{autumnOfColorWork.createdAt|formatDate('lll')}} Uhr
                    </td>
                    <td>
                        {{autumnOfColorWork.location.name}}
                    </td>
                    <td>
                        {{autumnOfColorWork.education|dataTrans('model.autumnOfColorWork.education')}}
                    </td>
                    <td>
                        <template v-if="autumnOfColorWork.isMaterialWork">
                            {{autumnOfColorWork.titleOfWork}}
                        </template>
                        <template v-else>
                            Keine materielle Arbeit
                        </template>
                    </td>
                    <td>
                        {{autumnOfColorWork.lastEditedAt|formatDate('LLL')}} Uhr
                    </td>
                    <td class="text-right">
                        <router-link :to="'/tools/autumnOfColorWorks/' + autumnOfColorWork.id" class="action --show"></router-link>
                    </td>
                </template>
            </data-table>
        </content-main>
    </div>
</template>


<script>
    import DataTable from "@/components/DataTable.vue";

    export default {
        components: {
            DataTable
        },
        methods: {
            exportAutumnOfColorWorks() {
                this.$http.post('exports/autumnOfColorWorkExport').then((response) => {
                    window.location.href = response.data.url;
                })
            },
        },
        computed: {
            filters() {
                return [
                    {
                        type: 'select',
                        model: 'status',
                        label: 'Status',
                        options: this.$store.getters.applicationSettings.model.autumnOfColorWork.status,
                        callback: function (newVal) {
                            return 'Status ausgewählt';
                        }
                    },
                    {
                        type: 'select',
                        model: 'education',
                        label: 'Ausbildung',
                        options: this.$store.getters.applicationSettings.model.autumnOfColorWork.education,
                        callback: function () {
                            return 'Ausbildung ausgewählt';
                        }
                    },
                    {
                        type: 'entity',
                        model: 'location',
                        entity: 'location',
                        label: 'Standort',
                        callback: function () {
                            return 'Ein Datensatz ausgewählt';
                        }
                    },
                ];
            },
            columns() {
                return {
                    status: {
                        text: 'Status',
                        order: true,
                    },
                    person: {
                        text: 'Person',
                        order: false,
                    },
                    createdAt: {
                        text: 'Eingereicht',
                        order: true,
                    },
                    location: {
                        text: 'Standort',
                    },
                    education: {
                        text: 'Ausbildung',
                    },
                    titleOfWork: {
                        text: 'Ausbildung',
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    }
                };
            },
        },
        data() {
            return {
                filterLayout: [
                    [
                        'status', 'location'
                    ],
                    [
                        'education'
                    ]
                ],
            }
        }
    }
</script>
