<template>
    <div>
        <div class="row">
            <div class="large-3 columns">
                <input-simple-select
                    v-model="value.academicDegree"
                    :errors="errors.academicDegree"
                    :options="academicDegreeTypes"
                    label="Höchster Schulabschluss / Akad. Abschluss"
                />
            </div>

            <div class="large-3 columns">
                <input-simple-select
                    v-model="value.currentSituation"
                    :errors="errors.currentSituation"
                    :options="currentSituation"
                    label="Aktuelle Situation"
                />
            </div>

            <div class="large-3 columns">
                <label>
                    Förderung gewünscht
                    <select v-model="value.support">
                        <option :value="null"></option>
                        <option :value="true">Ja</option>
                        <option :value="false">Nein</option>
                    </select>
                </label>
            </div>
            <div class="large-3 columns">
                <label>
                    Mit Behinderung
                    <br>
                    <input type="checkbox" v-model="value.handicap"/>
                </label>
            </div>
        </div>
        <div class="row">
            <template v-if="showPayment">
                <div class="large-3 columns">
                    <label>
                        Selbstzahler
                        <br>
                        <input type="checkbox" v-model="value.paymentTypeOwn"/>
                    </label>
                </div>
                <div class="large-3 columns">
                    <label>
                        Sonstige Förderung
                        <br>
                        <input type="checkbox" v-model="value.paymentEtc"/>
                    </label>
                </div>
                <div class="large-3 columns">
                    <label>
                        Bildungsgutschein
                        <br>
                        <input type="checkbox" v-model="value.paymentTypeVoucher"/>
                    </label>
                </div>
            </template>

            <div class="small-12 columns">
                <input-rte
                    v-model="value.note"
                    :errors="errors.note"
                    label="Notizen (Bitte in den Notizen auf respektvollen Umgang mit unseren Interessenten und Teilnehmenden achten. Bitte kurz halten.)"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import value from "../../../../mixins/value";
    import InputRte from "../../../components/form/InputRte";

    export default {
        components: {InputRte},
        mixins: [value],
        props: {
            showPayment: {
                type: Boolean,
                default: () => true
            }
        },
        computed: {
            academicDegreeTypes: function () {
                return this.$store.getters.applicationSettings.model.currentSituation.academicDegreeTypes;
            },
            currentSituation: function () {
                return this.$store.getters.applicationSettings.model.currentSituation.types;
            },
        }
    }
</script>
