<template>
    <c-modal
            ref="attendanceCertificationGeneratorUpdateModal"
            headline="Teilnehmerbescheinigungen"
    >
        <div class="p-4 py-12">
            <template v-if="person">
                <input-entity
                    label="Rechnung"
                    v-model="certificationGeneration.touchBooking"
                    type="touchBooking"
                    :fixedParams="{person: person['@id']}"
                />

                <input-entity
                    v-model="certificationGeneration.course"
                    type="course"
                    label="Kurs"
                    :fixedParams="{person: person['@id']}"
                />
            </template>

            <div
                v-if="course"
                class="bg-warning-100 p-4 text-warning-600 mb-4"
            >
                Das Teilnehmerbescheinigung erscheint in den Anhängen im Personenprofil. Sollten alle Teilnehmer
                ausgewählt werden, erscheint zusätzlich eine Zip-Datei in den Anhängen des Kurses.
            </div>

            <table v-if="course">
                <thead>
                <tr>
                    <td>
                        <input type="checkbox" class="mb-0" v-model="setAllTouchBookings"/>
                    </td>
                    <td>Name</td>
                    <td>Rechnung</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="touchBooking in touchBookings">
                    <td>
                        <input type="checkbox" class="mb-0" v-model="certificationGeneration.touchBookings"
                               :value="touchBooking['@id']"/>
                    </td>
                    <td>
                        {{ touchBooking.person.displayName }}
                    </td>
                    <td>
                        {{ touchBooking.invoiceNumber }}
                    </td>
                </tr>
                </tbody>
            </table>

            <div class="grid grid-cols-2 gap-8 items-center">
                <div>
                    <input-date
                        v-model="certificationGeneration.date"
                        label="Zeitpunkt"
                        :errors="errors.date"
                    />
                </div>
                <div>
                    <button
                        type="submit"
                        class="button mb-0"
                        @click="submit"
                        :disabled="(certificationGeneration.touchBookings.length === 0 && !certificationGeneration.touchBooking) || !certificationGeneration.date"
                    >
                        Teilnehmerbescheinigung erstellen
                    </button>
                </div>
            </div>
        </div>
    </c-modal>
</template>

<script>
    import CModal from "../../components/Modal.vue";
    export default {
        props: {
            person: {
                required: false,
            },
            course: {
                required: false,
            },
        },
        components: {
            CModal
        },
        data() {
            return {
                errors: {},
                touchBookings: [],
                setAllTouchBookings: false,
                certificationGeneration: {
                    course: null,
                    touchBookings: [],
                    touchBooking: null,
                }
            }
        },
        watch: {
            setAllTouchBookings: function (newVal) {
                if (newVal) {
                    this.certificationGeneration.touchBookings = this.touchBookings.map((item) => {
                        return item['@id'];
                    })
                } else {
                    this.certificationGeneration.touchBookings = [];
                }
            }
        },
        methods: {
            submit() {
                if (this.certificationGeneration.touchBooking) {
                    this.certificationGeneration.touchBookings = [this.certificationGeneration.touchBooking];
                }

                this.$sendToApi('document/attendance_certificates', this.certificationGeneration, () => {
                    this.$refs.attendanceCertificationGeneratorUpdateModal.hide();
                });
            },
            open() {
                this.certificationGeneration = {
                    course: null,
                    touchBookings: [],
                    touchBooking: null,
                };

                if (this.course) {
                    this.certificationGeneration.course = this.course['@id'];
                    this.$getListFromApi('courses/' + this.course.id + '/touch_bookings', {}, (data) => {
                        this.touchBookings = data;
                        this.$refs.attendanceCertificationGeneratorUpdateModal.open();
                    });
                } else {
                    this.$refs.attendanceCertificationGeneratorUpdateModal.open();
                }
            }
        },
    }
</script>
