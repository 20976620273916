<template>
	<div>
		<div
				v-if="$store.getters.getLoggedInUser.hasOwnProperty('@id') && Object.keys($store.getters.applicationSettings).length > 0"
				class="pApplication"
		>
			<search-modal ref="searchModal"/>

			<div class="pApplication__sidebar">
				<div class="grid grid-rows-[1fr_auto] h-screen py-4 max-h-screen overflow-y-auto">
					<div>
						<ul
								class="list-none m-0 p-0 grid gap-4"
						>
							<li
								v-for="item in menuItems"
								v-if="checkVisible(item)"
							>
								<router-link
										:to="item.to"
										v-if="item.to"
										class="text-white inline-block py-3 px-6 hover:text-white w-full hover:bg-brand-600 focus:text-white"
								>
									{{ item.label }}
								</router-link>
								<div
										v-if="!item.to"
										class="text-gray-400 inline-block px-6 pb-2 w-full uppercase"
								>
									{{ item.label }}
								</div>
								<template
										v-for="child in item.children"
										v-if="checkVisible(child)"
								>
									<router-link
										:to="child.to"
										v-if="!child.children || child.children.length === 0"
										class="text-white inline-block py-3 px-6 hover:text-white w-full hover:bg-brand-600  cursor-pointer focus:text-white"
									>
										{{ child.label }}
									</router-link>
									<a
											v-if="child.children && child.children.length > 0"
											class="text-white inline-block py-3 px-6 hover:text-white w-full hover:bg-brand-600  cursor-pointer"
											:class="{
											'grid grid-cols-[1fr_auto]': child.children && child.children.length > 0
										}"
											@click="toggleMenuItem(child)"
									>
										<span>
										{{ child.label }}
										</span>
										<span
												class="action"
												v-if="child.children && child.children.length > 0"
												:class="{
												'--plus': !openItems.includes(child),
												'--close': openItems.includes(child),
											}"
										>
									</span>
									</a>
									<ul
											v-if="child.children && openItems.includes(child)"
											class="m-0 p-0 list-none pl-8 mb-4"
									>
										<li
											v-if="checkVisible(subChild)"
											v-for="subChild in child.children"
										>
											<router-link
													:to="subChild.to"
													class="text-white inline-block py-3 px-6 hover:text-gray-400 w-full focus:text-white"
											>
												{{ subChild.label }}
											</router-link>
										</li>
									</ul>
								</template>

							</li>
						</ul>
					</div>
					<ul class="list-none text-white grid m-0 p-0 mt-10">
						<li>
							<a
									@click="showToken"
									class="text-white inline-block py-3 px-6 hover:text-white w-full hover:bg-brand-600"
							>
								Token
							</a>
						</li>
						<li class="">
							<router-link
									to="/profile"
									class="text-white inline-block py-3 px-6 hover:text-white w-full hover:bg-brand-600"
							>
								Profil
							</router-link>
						</li>
						<li class="">
							<router-link
									to="/logout"
									class="text-white inline-block py-3 px-6 hover:text-white w-full hover:bg-brand-600"
							>
								Abmelden
							</router-link>
						</li>
					</ul>
				</div>
			</div>
			<div class="pApplication__main">
				<notifications
						:duration="1000"
						class="pApplication__notifications"
				/>

				<div class="pApplication__header">
					<div class="row align-middle">
						<div class="columns">
							<img src="../../assets/img/campus-naturalis-logo.svg" class="pApplication__headerLogo"/>
						</div>
						<div class="columns shrink grid grid-flow-col gap-2 items-center">
							<a @click="$refs.searchModal.open()"
							   class="button small thirdly h-noMargin-bottom grid grid-cols-[1fr_auto] items-center gap-1"
							>
                                <span>
                                    Globale Suche
                                </span>
								<span class="action --show text-sm"></span>
							</a>
							<router-link
									v-if="$route.path !== '/touchComplex'"
									to="/touchComplex?create=firstContact"
									class="button small thirdly h-noMargin-bottom"
							>
								Erstkontakt für Neukunden erstellen
							</router-link>
						</div>
					</div>
				</div>

				<router-view></router-view>
			</div>

			<div class="pApplication__loading" v-show="loadingIndex > 0">
				<loading/>
			</div>
		</div>

	</div>
</template>

<script>
    import auth from "../../plugins/auth";
    import SearchModal from "../data/Search/Modal.vue";

    export default {
        data() {
            return {
                openItems: [],
                menuItems: [
                    {
                        label: 'Dashboard',
                        to: '/dashboard'
                    },
                    {
                        label: 'CRM',
                        children: [
                            {
                                label: 'Kunden und Interessenten',
                                to: '/persons'
                            },
                            {
                                label: 'Customer Touchpoints',
                                to: '/touches',
                            },
                            {
                                label: 'Beratungsgespräche',
                                to: '/touches/consultation'
                            },
							{
								label: 'Kostenstellen (FÖBG)',
								to: '/crm/supportedCostCenters'
							},
                            {
                                label: 'Praktikumsstellen',
                                to: '/crm/intershipCompanies'
                            }
                        ]
                    },
                    {
                        label: 'ERP',
                        children: [
                            {
                                label: 'Unterrichts-Planung',
                                children: [
                                    {
                                        label: 'Ausbildungen, Seminare, Workshops',
                                        to: '/educations'
                                    },
                                    {
                                        label: 'Kurse',
                                        to: '/courses'
                                    },
                                    {
                                        label: 'Kurstermine',
                                        to: '/courseDates'
                                    },
                                    {
                                        label: 'Curricula',
                                        to: '/teaching/curricula'
                                    }
                                ]
                            },
                            {
                                label: 'Unterrichts-Durchführung',
                                children: [
                                    {
                                        label: 'Kurstermine',
                                        to: '/courseDates'
                                    },
                                    {
                                        label: 'Anwesenheiten',
                                        to: '/erp/appearances'
                                    },
                                    {
                                        label: 'Dozentenbewerbungen',
                                        to: '/erp/eventCourseApplications'
                                    }
                                ]
                            },
                            {
                                label: 'Veranstaltungen',
                                to: '/events',
                            },
							{
								label: 'Dozenten',
								to: '/erp/docents',
							},
                            {
                                label: 'Buchhaltung',
                                credentials: ['ROLE_CONTROLLING'],
                                children: [
                                    {
                                        label: 'Einnahmen',
                                        to: '/finance/earnings'
                                    },
                                    {
                                        label: 'Einnahmen-Import',
                                        to: '/finance/earningImport'
                                    },
                                    {
                                        label: 'Mahnwesen',
                                        to: '/finance/reminders'
                                    }
                                ]
                            }
                        ]
                    },
					{
						label: 'Auswertungen',
						to: '/reporting',
					},
                    {
                        label: 'Verwaltung',
                        children: [
                            {
                                label: 'Tools',
                                children: [
                                    {
                                        label: 'Bulk-E-Mail',
                                        to: '/tools/bulkEmail',
                                        credentials: ['ROLE_BULK_EMAIL', 'ROLE_EVENT_ADMINISTRATOR']
                                    },
                                    {
                                        label: 'Kurs-Toolings',
                                        to: '/tools/courseTools',
                                        credentials: ['ROLE_EDUCATION_MANAGEMENT']
                                    },
                                    {
                                        label: 'Kurs-Planung',
                                        to: '/tools/eventPlaning'
                                    },
                                    {
                                        label: 'Personen-Bulk-Bearbeitung',
                                        to: '/tools/personBulk',
                                        credentials: ['ROLE_ADMINISTRATION']

                                    },
                                    {
                                        label: 'Kataloganfragen',
                                        to: '/tools/catalogueRequestExport'
                                    },
                                    {
                                        label: 'Externe Portale',
                                        to: '/tools/portals',
                                        credentials: ['ROLE_EDUCATION_MANAGEMENT']
                                    },
                                    {
                                        label: 'Duplikate bereinigen',
                                        to: '/tools/personDuplicateResolver',
                                        credentials: ['ROLE_LEAD_MANAGEMENT']
                                    },
                                    {
                                        label: 'Stundenplan-Vorschlag',
                                        to: '/tools/timetableSuggest',
                                        credentials: ['ROLE_ADMINISTRATION']
                                    },
                                    {
                                        label: 'Dozentenabrechnung',
                                        to: '/tools/docentBilling',
                                        credentials: ['ROLE_CONTROLLING', 'ROLE_ADMINISTRATION']
                                    },
                                    {
                                        label: 'Farbenherbst',
                                        to: '/tools/autumnOfColorWorks',
                                        credentials: ['ROLE_EDUCATION_MANAGEMENT', 'ROLE_ADMINISTRATION']
                                    },
                                    {
                                        label: 'Dokumente Teilnehmenden Plattform',
                                        to: '/tools/customerInformationAttachments',
                                        credentials: ['ROLE_EDUCATION_MANAGEMENT', 'ROLE_ADMINISTRATION']
                                    },
                                ]
                            },
                            {
                                label: 'Administration',
                                credentials: ['ROLE_ADMINISTRATION', 'ROLE_SECURITY'],
                                children: [
                                    {
                                        label: 'Globale Einstellungen',
                                        to: '/administration/settings',
                                        credentials: ['ROLE_ADMINISTRATION'],
                                    },
                                    {
                                        label: 'Benutzer',
                                        to: '/administration/users',
                                        credentials: ['ROLE_SECURITY'],
                                    },
                                    {
                                        label: 'API',
                                        to: '/administration/users-api',
                                        credentials: ['ROLE_SECURITY'],
                                    },
                                    {
                                        label: 'Rollen',
                                        to: '/administration/roles',
                                        credentials: ['ROLE_SECURITY'],
                                    },
                                    {
                                        label: 'Standorte',
                                        to: '/administration/locations',
                                        credentials: ['ROLE_ADMINISTRATION'],
                                    },
                                    {
                                        label: 'Räume',
                                        to: '/administration/rooms',
                                        credentials: ['ROLE_ADMINISTRATION'],
                                    },
                                    {
                                        label: 'Individuelle Touches Vorlagen',
                                        to: '/administration/template-custom-touch',
                                        credentials: ['ROLE_ADMINISTRATION'],
                                    },
                                    {
                                        label: 'Import-Logs',
                                        to: '/administration/importLogs',
                                        credentials: ['ROLE_ADMINISTRATION'],
                                    },
                                    {
                                        label: 'Ferien & Feiertage',
                                        to: '/administration/holidays',
                                        credentials: ['ROLE_ADMINISTRATION'],
                                    },
                                ]
                            }
                        ]
                    },
                ]
            }
        },
        components: {
            SearchModal
        },
        computed: {
            loadingIndex() {
                return this.$store.getters.getLoadingIndex;
            },
            user() {
                return this.$store.getters.getLoggedInUser;
            }
        },
        mounted() {
            this.$store.dispatch("getEnvironment");
            this.$store.dispatch("getApplicationSettings");
            this.$store.dispatch("getLoggedInUser");
        },
        methods: {
            toggleMenuItem(menuItem) {
                let index = this.openItems.indexOf(menuItem);
                if (index !== -1) {
                    this.openItems.splice(index, 1);
                } else {
                    this.openItems.push(menuItem);
                }
            },
			checkVisible(menuItem) {
				if(!menuItem.hasOwnProperty('credentials'))	{
					return true;
				}

				let minimumOne = false;
				menuItem.credentials.forEach((item) => {
					if(this.$store.getters.hasLoggedInUserCredibility(item)) {
						minimumOne = true;
					}
				})

				return minimumOne;
			},
            showToken() {
                var dummy = $('<input>').val(auth.getToken()).appendTo('body').select();
                document.execCommand('copy');
                //$(dummy).remove();
            }
        }
    }
</script>
