<template>
    <div class="grid gap-6">
        <note-form
            :resourceIri="entity['@id']"
            @update="update"
        />

        <div class="grid gap-2">
            <div class="font-bold text-xl uppercase">Notizen</div>
            <note-list :notes="notes"/>
        </div>
    </div>
</template>

<script>
    import NoteList from "@/data/Note/List.vue";
    import NoteForm from "@/data/Note/Form.vue";
    export default {
        components: {
            NoteForm,
            NoteList
        },
        props: {
            entity: {
                required: true,
            }
        },
        data() {
            return {
                notes: [],
                newNote: {
                    text: '',
                    type: 'intern'
                }
            }
        },
        mounted() {
            this.loadNotes();
        },
        watch: {
            '$route': function () {
                this.loadNotes();
            }
        },
        methods: {
            update(note) {
                this.notes.unshift(note);
            },
            async loadNotes() {
                this.notes = await this.$getAllFromApi(
                    'notes',
                    {
                        'resourceIri': this.entity['@id'],
                        'order[createdAt]': 'DESC',
                    }
                );
            }
        }
    }
</script>
