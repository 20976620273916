<template>
    <div>
        <content-header headline="Imports">
            <router-link to="/tools/imports" class="button small thirdly">Zurück zur Übersicht</router-link>
        </content-header>

        <content-main>
            <div class="panel">
                <div class="row">
                    <div class="large-6 columns">
                        <label>
                            Import-Art
                            <select v-model="upload.type">
                                <option>Bitte auswählen</option>
                                <option value="courseConnection">Kursverknüpfung</option>
                            </select>
                        </label>
                    </div>
                    <div class="large-3 columns">
                        <label>
                            Testlauf
                            <br>
                            <input type="checkbox" v-model="upload.dryRun">
                        </label>
                    </div>
                    <div class="large-3 columns">
                        <label>
                            Erste Zeile überspringen
                            <br>
                            <input type="checkbox" v-model="upload.skipFirstLine">
                        </label>
                    </div>

                    <div class="large-12 columns">
                        <dropzone
                            label="Beratungsdatei"
                            accepted-files="text/csv"
                            :max-files="1"
                            endpoint="import_files"
                            :params="uploadParams"
                            :removeOnResponse="true"
                            v-on:complete="complete"
                        />
                    </div>
                </div>
            </div>
        </content-main>
    </div>
</template>

<script>
    import Dropzone from "../../../components/Dropzone";

    export default {
        components: {
            Dropzone
        },
        data() {
            return {
                upload: {
                    dryRun: false,
                    skipFirstLine: false,
                    type: 'courseConnection'
                },
                importMessage: {}
            }
        },
        computed: {
            uploadParams: function() {
                return {
                    dryRun: this.upload.dryRun,
                    skipFirstLine: this.upload.skipFirstLine,
                    type: this.upload.type,
                }
            },
        },
        methods: {

            complete(response, success) {
                if(success) {
                    this.$notify({
                        type: 'success',
                        text: 'Import erfolgreich eingereicht.<br>Sie sehen den Erfolg in der Import-Übersichtsliste'
                    });
                    //this.$router.push('/tools/imports');
                } else {
                    this.$notify({
                        type: 'alert',
                        text: 'Fehler beim Upload'
                    });
                }
            }
        }
    }
</script>
