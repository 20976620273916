<template>
	<div class="dataTable h-default-marginBottom">
		<div>
			<c-modal
					ref="updateReveal"
					:headline="editedData.id ? 'Datensatz bearbeiten' : 'Datensatz hinzufügen'"
					:size="updateRevealSize"
			>

				<form
					v-on:submit.prevent="submitEditedData"
					v-on:reset.prevent="$refs.updateReveal.close()"
				>
					<div class="px-4">
						<slot name="updateForm" :data="editedData" :errors="editedDataErrors"></slot>
					</div>
					<hr class="h-noMargin-small">
					<div class="text-center">
						<button type="submit" class="button">
							<template v-if="editedData.id">Aktualisieren</template>
							<template v-else>Speichern</template>
						</button>
						<button type="reset" class="button thirdly">Abbrechen</button>
					</div>
				</form>
			</c-modal>

			<div class="row dataTable__header">
				<slot name="nextToPagination"></slot>
				<div class="columns shrink">
					<a data-toggle="filterDropdown" class="button thirdly --filter" v-if="filters.length > 0">Datensätze
						Filtern</a>
					<form class="dropdown-pane dataTable__filterDropdownPane"
						  :class="{'--large': filterLarge}"
						  ref="filterDropdown"
						  id="filterDropdown"
						  @submit.prevent="submitFilter"
						  @reset.prevent="resetFilter"
					>
						<div class="dropdown-headline">Filtern</div>
						<div class="dropdown-close" @click="closeFilterReveal()"></div>

						<template v-if="filters.length > 0">
							<div v-for="filter in filters" v-if="filterLayout === false">
								<data-table-filter :filter="filter" v-model="query"/>
							</div>
							<div v-if="filterLayout !== false">
								<div class="row" v-for="filterRow in filterLayout">
									<div class="columns" v-for="filterColumn in filterRow">
										<data-table-filter :filter="getFilterByModel(filterColumn)" v-model="query"/>
									</div>
								</div>
							</div>
						</template>
						<slot v-else name="filter" :query="query"></slot>

						<div class="dropdown-footer">
							<button type="submit" class="button small">Filtern</button>
							<button type="reset" class="button small thirdly">Zurücksetzen</button>
						</div>
					</form>
				</div>
				<div class="columns">
					<ul class="dataTable__filterTags" v-if="showFilterTags">
						<li v-for="filterValue, filterName in query" v-if="isValidFilter(filterName, filterValue)">
							<a @click="updateFilter(filterName)">
								{{ getValidFilterLabel(filterName) }}:
								{{ getValidFilterValue(filterName, filterValue) }}
							</a>
						</li>
					</ul>
				</div>
				<div class="columns shrink" v-if="exportable">
					<a class="button" @click="exportData()">Export</a>
				</div>
				<div class="columns shrink dataTable__headerPageSelect">
					<label>Anzahl Datensätze</label>
					<select v-model="query.itemsPerPage" @change="changeItemsPerPage()">
						<option :value="10">10</option>
						<option :value="25">25</option>
						<option :value="50">50</option>
						<option :value="100">100</option>
					</select>
				</div>
				<div class="columns shrink text-right">
					<div v-show="loadingIndex === 0">
						<div
								v-if="!disablePagination"
								class="row align-middle dataTable__headerPagination"
						>
							<div class="shrink columns"><label>Seiten</label></div>
							<div class="columns">
								<ul class="dataTable__pagination pagination">
									<template v-for="n in pages">
										<li
												v-if="n === 1 || n === query.currentPage - 1 || n === query.currentPage || n === query.currentPage + 1|| n === pages"
												v-bind:class="{current: n === query.currentPage}"
										>
											<span v-if="query.currentPage === n">{{ n }}</span>
											<a v-else v-on:click="changePage(n)">{{ n }}</a>
										</li>
										<li v-if="((n === query.currentPage - 3 || n === query.currentPage + 2))">
											<span>...</span>
										</li>
									</template>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="dataTable__noDataFound" v-show="loadingIndex === 0 && memberCount === 0">
				Kein Datensatz gefunden
			</div>

			<div v-show="loadingIndex === 0 && memberCount !== 0">
				<table>
					<thead>
					<tr>
						<th v-for="(column, index) in columns"
							:class="{
                                '--sortable': column.order,
                               '--asc': query.orderBy === index && query.orderDirection === 'ASC',
                               '--desc': query.orderBy === index && query.orderDirection === 'DESC'
                            }"
						>
							<a v-if="column.order"
							   @click="order(index)"
							>
								{{ column.text }}
							</a>
							<template v-else>{{ column.text }}</template>
						</th>
						<th></th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="singleData in data['hydra:member']">
						<slot name="dataRow" :singleData="singleData"></slot>
					</tr>
					</tbody>
				</table>
				<div
                    class="row dataTable__pager align-middle" v-if="pages > 0"
                >
					<div class="columns text-left">
						<a @click="query.currentPage--; loadData(query)" v-if="query.currentPage > 1">
							Vorherige Seite
						</a>
					</div>
					<div
							v-if="!disablePagination"
							class="columns text-center"
					>
						Zeige {{ memberCount }} von {{ totalItems }} Ergebnisse
						<br>
						Seite {{ query.currentPage }} von {{ pages }}
					</div>
					<div class="columns text-right">
						<a @click="query.currentPage++; loadData(query)"
						   v-if="disablePagination || query.currentPage < pages">
							Nächste Seite
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    import Foundation from 'foundation-sites';
    import util from "../../plugins/util";
    import DataTableFilter from "./DataTableFilter";

    export default {
        components: {DataTableFilter},
        data() {
            return {
                data: {},
                query: {},
                baseQuery: {
                    itemsPerPage: 10,
                    currentPage: 1
                },
                dropdownPane: {},
                editedData: {},
                editedDataErrors: {}
            }
        },
        props: {
            showFilterTags: {
                type: Boolean,
                default: () => {
                    return true;
                }
            },
            disablePagination: {
                type: Boolean,
                default: () => {
                    return false;
                }
            },
            stateKey: {
                type: String,
                default: () => {
                    return null;
                }
            },
            endpoint: {
                type: String,
                required: true
            },
            filters: {
                type: Array,
                default: () => {
                    return [];
                }
            },
            filterLarge: {
                type: Boolean,
                default: () => false,
            },
            filterLayout: {
                type: Array | Boolean,
                default: () => false,
            },
            columns: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            updateRevealSize: {
                type: String,
                default: () => ''
            },
            addQueryToUrl: {
                type: Boolean,
                default: () => true
            },
            fixedQuery: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            preSetQuery: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            exportable: {
                default: () => false
            }
        },
        computed: {
            loadingIndex() {
                return this.$store.getters.getLoadingIndex;
            },
            pages: function () {
                if (!this.data || typeof this.data['hydra:totalItems'] === 'undefined') {
                    return 1;
                }
                return Math.ceil(this.data['hydra:totalItems'] / this.query.itemsPerPage);
            },
            totalItems: function () {
                if (!this.data || typeof this.data['hydra:totalItems'] === 'undefined') {
                    return 0;
                }
                return this.data['hydra:totalItems'];
            },
            memberCount: function () {
                if (!this.data || typeof this.data['hydra:member'] === 'undefined') {
                    return 0;
                }
                return this.data['hydra:member'].length;
            }
        },
        mounted() {
            this.dropdownPane = new Foundation.Dropdown(
              $('#filterDropdown'),
              {
                  vOffset: 15
              }
            );

            if (Object.keys(this.preSetQuery).length > 0) {
                this.query = this.preSetQuery;
                if (
                  this.stateKey !== null && localStorage.getItem('filter_' + this.stateKey)
                ) {
                    this.query = Object.assign(JSON.parse(localStorage.getItem('filter_' + this.stateKey)));
                }
            } else {
                if (this.addQueryToUrl) {
                    this.query = this.$route.query;
                }
                if (this.stateKey !== null && localStorage.getItem('filter_' + this.stateKey)) {
                    this.query = Object.assign(this.query, JSON.parse(localStorage.getItem('filter_' + this.stateKey)));
                }
            }

            if (typeof this.query.itemsPerPage === 'undefined') {
                this.query.itemsPerPage = this.baseQuery.itemsPerPage;
            }
            if (typeof this.query.currentPage === 'undefined') {
                this.query.currentPage = this.baseQuery.currentPage;
            }

            this.loadData(this.query);
        },
        methods: {
            exportData() {
                let query = {}
                if (Object.keys(this.preSetQuery).length > 0) {
                    query = this.preSetQuery;
                } else {
                    query = this.$route.query;
                }

                this.$store.commit('incrementLoadingIndex');
                this.$http.get(this.endpoint + '_export.csv', {params: Object.assign(query, this.query)}).then(response => {
                    this.$store.commit('decrementLoadingIndex');
                    let element = document.createElement('a');
                    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(response.body));
                    element.setAttribute('download', 'download.csv');

                    element.style.display = 'none';
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                });
            },
            editData(data) {
				this.editedDataErrors = {};
                this.$getFromApi(this.endpoint, data, (entity) => {
                    this.editedData = entity;
                    this.$refs.updateReveal.open();
                });
            },
            deleteData(data) {
                let _this = this;
                this.$dialog
                  .confirm('Eintrag wirklich löschen?')
                  .then(function (dialog) {
                      _this.$deleteFromApi(_this.endpoint, data, (entity) => {
                          _this.loadData(_this.query);
                      });
                  })
                ;
            },
            createData(data = {}) {
                this.editedDataErrors = {};
                this.editedData = data;
                this.$refs.updateReveal.open();
            },
            submitEditedData() {
                this.$sendToApi(this.endpoint, this.editedData, (entity, status, errors) => {
                    if (status < 300) {
                        this.editedData = {};
                        this.editedDataErrors = [];
                        this.$refs.updateReveal.close();
                        this.loadData(this.query);
                    } else {
                        this.editedDataErrors = util.mapConstraints(errors);
                    }
                })
            },
            order(orderBy) {
                this.query.orderBy = orderBy;
                if (this.query.orderDirection === 'ASC') {
                    this.query.orderDirection = 'DESC';
                } else {
                    this.query.orderDirection = 'ASC';
                }
                this.query.order = {};
                this.query.order[orderBy] = this.query.orderDirection;
                this.loadData(this.query);
            },
            changeItemsPerPage() {
                this.query.currentPage = 1;
                this.loadData(this.query);
            },
            changePage(n) {
                this.query.currentPage = n;
                this.loadData(this.query);
            },
            closeFilterReveal() {
                this.dropdownPane.close();
            },
            submitFilter() {
                this.query.currentPage = 1;
                this.dropdownPane.close();
                this.loadData(this.query);
            },
            resetFilter() {
                if (this.stateKey) {
                    localStorage.removeItem(this.stateKey);
                }
                let itemsPerPage = this.query.itemsPerPage;
                this.query = this.baseQuery;
                this.query.itemsPerPage = itemsPerPage;
                this.dropdownPane.close();
                this.loadData(this.query);
            },
            updateFilter(filterName) {
				let filter = Object.assign({}, this.query);
                delete filter[filterName];
				this.$set(this, 'query', filter);
                this.loadData(this.query);
            },
            isValidFilter(name, value) {
                if (name === 'itemsPerPage' || name === 'orderBy' || name === 'orderDirection' || name === 'page' || name === 'order') {
                    return false;
                }

                if (Object.keys(this.fixedQuery).indexOf(name) > -1) {
                    return false;
                }

                if (typeof value === 'object') {
                    return true;
                }
                if (value === '') {
                    return false;
                }
                return this.filters.filter((item) => {
                    return item.model === name;
                }).length > 0 && value.length > 0;
            },
            getValidFilterLabel(name) {
                let filters = this.filters.filter((item) => {
                    return item.model === name;
                });

                if (filters.length > 0) {
                    return filters[0].label;
                }

                return false;
            },
            getValidFilterValue(name, value) {
                var filter = this.filters.filter((item) => {
                    return item.model === name;
                });

                if (filter.length === 1 && filter[0]['type'] === 'dateRange') {
                    let dateRange = '';
                    if (value['after']) {
                        dateRange += 'von ' + value['after'];
                    }

                    if (value['after'] && value['after']) {
                        dateRange += ' ';
                    }

                    if (value['before']) {
                        dateRange += 'bis ' + value['before'];
                    }

                    return dateRange;
                }

                if (filter.length === 1 && typeof filter[0]['callback'] === 'function') {
                    return filter[0]['callback'](value, this.$store);
                }

                if (filter.length === 1 && filter[0]['type'] === 'bool') {
                    return value === "0" ? 'Nein' : 'Ja';
                }

                return value;
            },
            getFilterByModel(model) {
                let filters = this.filters.filter((item) => {
                    return item.model === model;
                });
                if (filters.length > 0) {
                    return filters[0];
                }
                return false;
            },
            reload() {
                this.loadData(this.query);
            },
            closeUpdateReveal() {
                this.$refs.updateReveal.close()
            },
            loadData(query) {
                this.query = Object.assign(query, this.fixedQuery);

                if (typeof this.query.timestamp !== 'undefined') {
                    delete this.query.timestamp;
                }
                if (this.addQueryToUrl) {
                    this.$router.replace({path: this.$route.path + '?timestamp=' + Date.now(), query: this.query});
                }
                if (this.stateKey) {
                    localStorage.setItem('filter_' + this.stateKey, JSON.stringify(query));
                }
                this.$store.commit('incrementLoadingIndex');
                this.$http.get(this.endpoint, {params: Object.assign(query, this.fixedQuery)}).then(
                  response => {
                      this.$store.commit('decrementLoadingIndex');
                      this.data = response.data;
                  },
                  response => {
                      this.$store.commit('decrementLoadingIndex');
                      this.$notify({
                          type: 'alert',
                          text: 'Allgemeiner Fehler'
                      });
                  }
                );
            }
        }
    }
</script>

<style lang="scss">
.loadingWrapper {
  margin-top: 4rem;
}
</style>
