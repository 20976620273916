<template>
    <input-simple-select v-model="value" :errors="errors" :options="options" label="Online/Präsenz"/>
</template>

<script>
    export default {
        props: {
            value: {
                type: String | Text,
                required: true,
            },
            errors: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
                options: window.global.event.locationTypes
            }
        },
        watch: {
            'value': function (newVal) {
                this.$emit('input', newVal);
            }
        }
    }
</script>
