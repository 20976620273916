<template>
    <div>
        <content-header headline="Rechnungen"/>
        <content-main>
            <data-table ref="dataTable" endpoint="invoices" :columns="columns" :filters="filters">
                <template slot="dataRow" slot-scope="{singleData: invoice}">
                    <td width="160px">
                        {{invoice.number}}
                        <br>
                        {{invoice.date|formatDate('LL')}}
                    </td>
                    <td>
                        <template v-if="invoice.person">
                            {{invoice.person.displayName}}
                        </template>
                    </td>
                    <td>
                        <template v-if="invoice.prepayment">{{invoice.prepayment|price}} Euro<br></template>
                        <template v-if="invoice.prepaymentDate">{{invoice.prepaymentDate|formatDate('LL')}}</template>
                    </td>
                    <td>
                        <template v-if="invoice.rates">Raten: {{invoice.rates}}<br></template>
                        <template v-if="invoice.rateValue">Ratenhöhe: {{invoice.rateValue|price}} Euro<br></template>
                        <template v-if="invoice.ratePrepayment">Anzahlung: {{invoice.ratePrepayment|price}} Euro<br></template>
                        <template v-if="invoice.firstRateDate">Erste Rate: {{invoice.firstRateDate|formatDate('LL')}}<br></template>
                    </td>
                    <td>
                        {{invoice.total|price}}  Euro
                        <br>
                        <i>Errechnet: {{invoice.rateValue * invoice.rates + invoice.ratePrepayment|price}} Euro</i>
                    </td>
                    <td>
                        <template v-if="invoice.examinationFee">{{invoice.examinationFee|price}} Euro<br></template>
                        <template v-if="invoice.examinationFeeDate">{{invoice.examinationFeeDate|formatDate('LL')}}</template>
                    </td>
                    <td>
                        <template v-if="invoice.warningStepOne">{{invoice.warningStepOne|formatDate('LLL')}}</template>
                        <template v-if="invoice.warningStepTwo">{{invoice.warningStepTwo|formatDate('LLL')}}</template>
                        <template v-if="invoice.warningStepThree">{{invoice.warningStepThree|formatDate('LLL')}}</template>
                    </td>
                    <td>

                    </td>
                </template>
            </data-table>
        </content-main>
    </div>
</template>

<script>
    import DataTable from "../../components/DataTable";

    export default {
        components: {
            DataTable
        },
        data() {
            return {
                eventType: null,
                columns: {
                    number: {
                        text: 'R.-Nr.',
                        order: true
                    },
                    person: {
                        text: 'Kunde'
                    },
                    prepayment: {
                        text: 'Vorauszahlung',
                        order: true
                    },
                    rates: {
                        text: 'Raten'
                    },
                    total: {
                        text: 'Gesamtsumme',
                        order: true
                    },
                    examination: {
                        text: 'Prüfungsgebühr',
                        order: true
                    },
                    inkasso: {
                        text: 'Inkasso',
                        order: false,
                    },
                }
            }
        },
        computed: {
            filters() {
                return [
                    {
                        type: 'text',
                        model: 'number',
                        label: 'R.-Nr.'
                    },
                    {
                        type: 'dateRange',
                        model: 'date',
                        label: 'Zeitraum'
                    }
                ];
            }
        }
    }
</script>
