<template>
	<div class="grid gap-x-8 grid-cols-6">
			<label class="col-span-3 mb-4">
				Anrede
				<select v-model="value.gender">
					<option :value="null"></option>
					<option value="m">Herr</option>
					<option value="w">Frau</option>
					<option value="d">Diverse</option>
				</select>
			</label>
		<input-text
				class="col-span-3 col-start-1"
				label="Vorname"
				v-model="value.firstName"
				:errors="errors.firstName"
		/>
		<input-text
				class="col-span-3"
				label="Nachname"
				v-model="value.lastName"
				:errors="errors.lastName"
		/>
		<input-text
			class="col-span-3"
			label="E-Mail"
			v-model="value.email"
			:errors="errors.email"
		/>
		<input-text
			class="col-span-3"
			label="Telefonnummer"
			v-model="value.phoneNumber"
			:errors="errors.phoneNumber"
		/>
	</div>
</template>

<script>
    import value from "../../../mixins/value";

    export default {
        mixins: [value]
    }
</script>
