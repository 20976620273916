<template>
	<div v-if="docent">
		<content-header
				:headline="docent.displayName"
		>
            <router-link to="/erp/docents"
                class="button thirdly small"
            >
                Zurück zur Übersicht
            </router-link>
        </content-header>
		<content-main>
			<ui-tabs>
				<ui-tab title="Allgemeine Daten">
					<form
							v-on:submit.prevent="updateData"
							class="panel grid gap-4"
					>
						<div class="font-bold text-xl uppercase">Allgemeine Daten</div>
						<update-form
								v-model="docent"
								:errors="errors"
						/>
						<div class="text-center">
							<button type="submit" class="button">
								Aktualisieren
							</button>
						</div>
					</form>
				</ui-tab>
				<ui-tab title="Notizen">
					<div class="panel pb-4">
						<notes :entity="docent"/>
					</div>
				</ui-tab>
                <ui-tab title="Anhänge">
                    <div class="panel pb-4">
                        <attachments :entity-id="docent['@id']"/>
                    </div>
                </ui-tab>
                <ui-tab title="Bewerbungen">
                    <data-eventCourseApplication :fixedQuery="{docent: docent['@id']}"/>
                </ui-tab>
                <ui-tab title="Kurstermine">
                    <course-dates-table  :fixedQuery="{docent: docent['@id']}" :add-query-to-url="false"/>
                </ui-tab>
                <ui-tab title="Abrechnung">
                    <docent-billing-table :fixedQuery="{docent: docent['@id']}" :add-to-query-url="false"/>
                </ui-tab>
			</ui-tabs>

		</content-main>
	</div>
</template>


<script>
    import UpdateForm from "@/data/Docent/UpdateForm.vue";
    import Util from "../../../../../plugins/util";
	import Notes from "@/data/Notes.vue";
    import Attachments from "@/data/Person/Attachments.vue";
    import DataEventCourseApplication from "@/data/EventCourseApplication.vue";
    import CourseDatesTable from "@/data/CourseDatesTable.vue";
    import DocentBillingTable from "@/data/DocentBilling/Table.vue";

    export default {
        components: {
            DocentBillingTable,
            CourseDatesTable,
            DataEventCourseApplication,
            Attachments,
			Notes,
            UpdateForm
        },
        props: {
            endpoint: {
                type: Text | String,
                default: 'people'
            },
            disableFilter: {
                type: Array,
                default: () => []
            },
            addQueryToUrl: {
                type: Boolean,
                default: () => false
            },
            stateKey: {
                type: String | null,
                default: () => null,
            }
        },
        methods: {
            updateData() {
                this.errors = {};
                this.$sendToApi(
                  'docents',
                  this.docent,
                  (docent, status, errors) => {
                      if (status < 300) {
                          this.docent = Object.assign({}, docent);
                      } else {
                          this.errors = Util.mapConstraints(errors);
                      }
                  }
                )
            },
            loadData() {
                this.$getFromApi(
                  'docents',
                  this.$route.params.id,
                  (docent) => {
                      this.docent = Object.assign({}, docent)
                  }
                )
            }
        },
        watch: {
            'route': function (newVal) {
                this.loadData();
            }
        },
        mounted() {
            this.loadData();
        },
        data() {
            return {
                errors: {},
                docent: null
            }
        }
    }
</script>
