<template>
    <div>
        <resolve-reveal ref="resolveReveal" :person="person"/>

        <form @submit.prevent="submit">
            <template v-if="type === 'booking'">
                <booking-form
                    v-model="value"
                    :errors="errors"

                />

                <div class="text-center">
                    <button type="submit" class="button">
                        <template v-if="value.id">Speichern</template>
                        <template v-else>Erstellen</template>
                    </button>
                </div>
            </template>

            <template v-else>
                <div class="row">
                    <template v-if="type === 'scholarship'">
                        <div class="large-6 columns">
                            <input-simple-select
                                :options="scholarshipScorings"
                                label="Bewertung"
                                v-model="value.scoring"
                                :required="true"
                            />
                        </div>

                        <div class="large-6 columns">
                            <input-entity
                                type="education"
                                label="Ausbildung"
                                v-model="value.education"
                            />
                        </div>

                        <div class="large-6 columns">
                            <input-entity
                                type="location"
                                label="Standort"
                                v-model="value.location"
                            />
                        </div>

                        <div class="large-6 columns">
                            <h3>Bestätigung</h3>
                            <template v-if="value.optInTimestamp">
                                Bestätigt am {{ value.optInTimestamp|formatDate('LLL') }}
                            </template>
                            <template v-else>
                                Noch keine Bestätigung
                            </template>
                        </div>

                        <div
                            v-if="value.links && value.links.length > 0"
                            class="large-6 columns"
                        >
                            <h3>Links</h3>
                            <ul>
                                <li v-for="link in value.links">
                                    {{ link }}
                                </li>
                            </ul>
                        </div>

                        <div
                            v-if="value.attachments && value.attachments.length > 0"
                            class="large-6 columns"
                        >
                            <h3>Anhänge</h3>
                            <ul v-for="attachment in value.attachments">
                                <li>
                                    <a @click="download(attachment)">{{ attachment.name }}</a>
                                </li>
                            </ul>
                        </div>

                        <div class="large-12 columns">
                            <input-text
                                type="textarea"
                                v-model="value.text"
                                label="Motivationsschreiben"
                                :errors="errors.text"
                            />
                        </div>
                    </template>

                    <div class="large-12 columns h-margin-bottom" v-if="type === 'request'">
                        <div class="row">
                            <div class="large-6 columns">
                                <input-date
                                    :readonly="true"
                                    v-model="value.date"
                                    :time="true"
                                    label="Zeitpunkt der Anfrage"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="large-6 columns" v-if="value.locations.length > 0">
                                <h3>Standort</h3>
                                <ul>
                                    <li v-for="location in value.locations">
                                        {{ location.name }}
                                    </li>
                                </ul>
                            </div>
                            <div class="large-6 columns" v-if="value.educations.length > 0">
                                <h3>Ausbildung</h3>
                                <ul>
                                    <li v-for="education in value.educations">
                                        {{ education.name }}
                                    </li>
                                </ul>
                            </div>
                            <div class="large-12 columns">
                                <input-text
                                    type="textarea"
                                    v-model="value.message"
                                    label="Nachricht"
                                    :readonly="true"
                                ></input-text>
                            </div>
                            <div class="large-8 columns">
                                <input-rte
                                    type="textarea"
                                    :label="getLabel()"
                                    v-model="value.note"
                                    :errors="errors.note"
                                />
                            </div>
                            <div class="large-4 columns">
                                <label>
                                    Anfrage beantwortet
                                    <br>
                                    <input type="checkbox" v-model="value.requestAnswered"/>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="large-12 columns h-margin-bottom" v-if="type === 'registration'">
                        <div class="callout warning" v-if="!person.email">
                            Die Person hat keine E-Mail-Adresse hinterlegt
                        </div>
                        <template v-else>
                            <input-entity
                                type="eventTrialCourse"
                                label="Veranstaltung"
                                v-model="value.event"
                                :errors="errors.event"
                                :fixed-params="{'future': true}"
                                key="event"
                            />

                            <label>
                                Einladungsmail direkt verschicken
                                <br>
                                <input type="checkbox" v-model="value.sendMail"/>
                            </label>

                            <div class="callout warning">
                                <p>Das Datum wird automatisch auf das Startdatum der Veranstaltung gesetzt.</p>
                            </div>
                        </template>
                    </div>
                    <div class="large-12 columns h-margin-bottom" v-if="type === 'visit'">
                        <input-entity
                            type="event"
                            label="Veranstaltung"
                            v-model="value.event"
                            :errors="errors.event"
                            key="event"
                        />

                        <div class="callout warning">
                            <p>Das Datum wird automatisch auf das Startdatum der Veranstaltung gesetzt.</p>
                        </div>
                    </div>
                    <div class="large-12 columns" v-if="type === 'custom'">
                        <input-entity
                            type="templateTouchCustom"
                            label="Aus Vorlage"
                            v-model="value.templateTouchCustom"
                            :errors="errors.templateTouchCustom"
                        />
                        <input-date
                            label="Datum/Uhrzeit"
                            v-model="value.date"
                            :errors="errors.date"
                            :time="true"
                        />
                    </div>
                    <div class="large-12 columns h-margin-bottom"
                         v-if="type === 'note' || type === 'call' || type === 'email'">
                        <input-rte
                            type="textarea"
                            :label="getLabel()"
                            v-model="value.note"
                            :errors="errors.note"
                        />
                        <input-date
                            label="Datum/Uhrzeit"
                            v-model="value.date"
                            :errors="errors.date"
                            :time="true"
                        />

                        <follow-up
                            v-model="value"
                            :errors="errors"
                            :touch-type="type"
                        />
                    </div>
                    <hr>
                    <div class="large-12 columns text-center"
                         v-if="type !== 'registration' || person.email"
                    >
                        <button type="submit" class="button">
                            <template v-if="value.id">Speichern</template>
                            <template v-else>Erstellen</template>
                        </button>
                    </div>
                </div>
            </template>
        </form>
    </div>
</template>

<script>
    import util from "../../plugins/util";
    import InputRte from "../components/form/InputRte";
    import ConsultationForm from "./Touch/ConsultationForm";
    import FollowUp from "./Touch/parts/FollowUp";
    import value from "../../mixins/value";
    import ResolveReveal from "./Task/ResolveReveal";
    import BookingForm from "./Touch/BookingForm";

    export default {
        mixins: [value],
        components: {BookingForm, FollowUp, ConsultationForm, InputRte, ResolveReveal},
        props: {
            type: {
                type: String,
                required: true,
            },
            person: {
                type: Object,
                required: true
            }
        },
        computed: {
            scholarshipScorings: function () {
                return this.$store.getters.applicationSettings.model.touchScholarship.scorings;
            }
        },
        data() {
            return {
                value: {
                    person: this.person['@id']
                },
                errors: {}
            }
        },
        methods: {
            download(attachment) {
                this.$http.get('attachments/' + attachment['id'] + '/download').then(response => {
                    window.location = response.data;
                });
            },
            getLabel() {
                if (this.type === 'email') {
                    return 'E-Mail-Verkehr Inhalt';
                } else {
                    return 'Notiz - Bitte auf verbal respektvoller Umgang mit unseren Interessenten und Teilnehmenden und bitte kurz halten.';
                }
            },
            set(value) {
                this.value = value;
                this.value.person = this.value.person['@id'];
            },
            submit() {
                let endpoint = '';
                switch (this.type) {
                    case 'booking':
                        endpoint = 'touch_bookings';
                        break;
                    case 'consultation':
                        endpoint = 'touch_consultations';
                        break;
                    case 'custom':
                        endpoint = 'touch_customs';
                        break;
                    case 'note':
                        endpoint = 'touch_notes';
                        break;
                    case 'visit':
                        endpoint = 'touch_visits';
                        break;
                    case 'call':
                        endpoint = 'touch_calls';
                        break;
                    case 'registration':
                        endpoint = 'touch_registrations';
                        break;
                    case 'email':
                        endpoint = 'touch_emails';
                        break;
                    case 'request':
                        endpoint = 'touch_requests';
                        break;
                    case 'scholarship':
                        endpoint = 'touch_scholarships';
                        break;
                    default:
                }
                this.$sendToApi(endpoint, this.value, (entity, status, error) => {
                    if (status < 300) {
                        this.person = entity.person;
                        this.$emit('save', entity);
                        this.value = entity;
                        this.value.person = entity.person['@id'];
                        this.$refs.resolveReveal.open(this.value);
                    } else {
                        this.errors = util.mapConstraints(error);
                    }
                });
            }
        }
    }
</script>
