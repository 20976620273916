<template>
    <div>
        <div class="row">
            <div class="columns"
                 :class="{'large-3': getShowNoteVisible(), 'large-12': !getShowNoteVisible()}"
            >
                <div class="row">
                    <div class="columns"
                         :class="{'large-3': !getShowNoteVisible(), 'large-12': getShowNoteVisible()}"
                    >
                        <input-simple-select
                            v-model="value.followUpType"
                            :errors="errors.followUpType"
                            label="To-Do"
                            :options="followUpTypes"
                        />
                    </div>
                    <template v-if="value.followUpType === 'block' || value.followUpType === 'noAction'">
                        <div class="columns"
                             :class="{'large-3': !getShowNoteVisible(), 'large-12': getShowNoteVisible()}"
                        >
                            <input-simple-select
                                v-model="value.followUpSpecificType"
                                :errors="errors.followUpSpecificType"
                                label="Begründung"
                                :options="getFollowUpSpecificTypes(value.followUpType)"
                            />
                        </div>
                    </template>
                    <template v-if="value.followUpType === followUpConsultation">
                        <div class="columns"
                             :class="{'large-3': !getShowNoteVisible(), 'large-12': getShowNoteVisible()}"
                        >
                            <input-simple-select
                                v-model="value.followUpLocationType"
                                :errors="errors.followUpLocationType"
                                label="Ort des Beratungsgespräches"
                                :options="locationTypes"
                            />
                        </div>
                    </template>
                    <template
                        v-if="
                            value.followUpType === followUpConsultation
                            || value.followUpType === followUpTypeCustomTime
                            || value.followUpType === 'individual'
                            || value.followUpType === 'goingToBook'
                            || (value.followUpType === 'noAction' && value.followUpSpecificType === followUpTypeFeedbackCustomTime)
                        ">
                        <div class="columns"
                             :class="{'large-3': !getShowNoteVisible(), 'large-12': getShowNoteVisible()}"
                        >
                            <input-date
                                v-model="value.followUpDate"
                                label="Datum des To-Dos"
                                :errors="errors.followUpDate"
                                :time="value.followUpType === followUpConsultation"
                            />
                        </div>
                        <div class="columns"
                             :class="{'large-3': !getShowNoteVisible(), 'large-12': getShowNoteVisible()}"
                        >
                            <input-entity
                                v-if="value.followUpType === followUpConsultation || value.followUpType === 'individual'"
                                type="user_real"
                                v-model="value.followUpUser"
                                :label="value.followUpType === followUpConsultation ? 'Wer führt das Gespräch?' : 'Zugewiesen'"
                                :errors="errors.followUpUser"
                            />
                        </div>
                    </template>
                </div>
            </div>

            <div class="large-9 columns">
                <input-rte
                    v-if="getShowNoteVisible()"
                    v-model="value.followUpNote"
                    :errors="errors.followUpNote"
                    label="Notizen To-Do (Bitte in den Notizen auf respektvollen Umgang mit unseren Interessenten und Teilnehmenden achten. Bitte kurz halten.)"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import value from "../../../../mixins/value";
    import InputRte from "../../../components/form/InputRte";

    export default {
        components: {InputRte},
        mixins: [value],
        props: {
            locationType: {
                required: false,
                default: () => false
            },
            consultationEnabled: {
                required: false,
                default: () => true
            },
            noteVisible: {
                required: false,
                default: () => true
            },
            touchType: {
                required: false,
            }
        },
        methods: {
            getShowNoteVisible() {
                if(this.value.followUpType === 'goingToBook') {
                    return false;
                }
                if (this.value.followUpType === 'noAction') {
                    if (
                        this.touchType === 'note'
                        && this.value.followUpSpecificType === 'note'
                    ) {
                        return true;
                    }
                    return false;
                }

                if (
                    this.value.followUpType === 'block'
                    || this.value.followUpType === 'noMarketing'
                ) {
                    return false;
                }

                return true;
            },
            getFollowUpSpecificTypes: function (type) {
                if (type === 'block') {
                    return this.$store.getters.applicationSettings.model.followUpSpecificTypesBlock;
                } else if (type === 'noAction') {
                    let types = this.$store.getters.applicationSettings.model.followUpSpecificTypesNoAction;

                    if(
                        this.touchType !== 'note'
                        && typeof types['note'] !== 'undefined'
                    ) {
                        delete types['note'];
                    }

                    return types;
                }
                return [];
            },
        },
        computed: {
            followUpTypes: function () {
                var types = this.$store.getters.applicationSettings.model.followUpTypes;
                let _this = this;

                if (!this.consultationEnabled) {
                    types = Object.keys(types)
                        .filter(function (item) {
                            return item !== _this.$store.getters.applicationSettings.model.followUpTypeConsultation;
                        })
                        .reduce((obj, key) => {
                            obj[key] = types[key];
                            return obj;
                        }, {});
                }

                return types;
            },
            followUpTypeCustomTime: function() {
                return this.$store.getters.applicationSettings.model.followUpTypeCustomTime;
            },
            followUpConsultation: function () {
                return this.$store.getters.applicationSettings.model.followUpTypeConsultation;
            },
            followUpTypeFeedbackCustomTime: function() {
                return this.$store.getters.applicationSettings.model.followUpTypeFeedbackCustomTime;
            },
            locationTypes: function () {
                return this.$store.getters.applicationSettings.model.consultationLocationTypes;
            }
        }
    }
</script>
