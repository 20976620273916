<template>
    <div>
        <content-header headline="Auswertungen">
            <router-link
                to="/reporting/reporting/create"
                class="button small"
            >
                Auswertung anlegen
            </router-link>
        </content-header>

        <content-main>
            <data-table
                endpoint="reportings"
                :columns="columns"
                :filters="filters"
            >
                <template slot="dataRow" slot-scope="{singleData: reporting}">
                    <td>{{ reporting.name }}</td>
                    <td>
                        {{ reporting.createdAt|formatDate('LLL') }}
                    <br>
                        {{reporting.createdByDisplayName}}
                    </td>
                    <td class="text-right">
                        <router-link :to="'/reporting/reporting/' + reporting.id" class="action --show"></router-link>
                    </td>
                </template>
            </data-table>
        </content-main>
    </div>
</template>

<script>
    import DataTable from "../../../components/DataTable";

    export default {
        components: {DataTable},
        data() {
            return {
                filters: [
                    {
                        type: 'text',
                        model: 'name',
                        label: 'Name'
                    }
                ],
                columns: {
                    name: {
                        text: 'Name',
                        order: true,
                    },
                    createdAt: {
                        text: 'Erstellt',
                        order: true,
                    },
                }
            }
        }
    }
</script>
