<template>
    <div>
        <input-simple-select
            label="Typ"
            v-model="value.type"
            :options="typeChoices"
            :errors="errors.type"
            :readonly="value.id"
        />
        <input-text
            :label="value.type === 'email' ? 'E-Mail' : 'Telefonnummer'"
            v-model="value.value"
            :errors="errors.value"
        />

        <label class="h-noMargin-bottom">
            Primärer Kontakt
            <br>
            <input type="checkbox" v-model="value.first">
        </label>

        <div class="callout warning" v-if="value.first">
            Achtung! Bitte bei der Eingabe auf korrekte Schreibweise achten.
        </div>
    </div>
</template>

<script>
    import value from "../../mixins/value";

    export default {
        mixins: [value],
        data() {
            return {
                typeChoices: {
                    "email": 'E-Mail-Adresse',
                    "phone": 'Telefonnummer'
                },
            }
        }
    }
</script>
