<template>
    <div class="c-data-person-finance">
        <loading v-if="loadingIndex > 0"/>

        <template v-else>
            <div style="grid-column: 1/3;">
                <table>
                    <tbody>
                    <tr>
                        <th>Getätigte Zahlungen</th>
                        <td>{{ sumEarnings }}</td>
                    </tr>
                    <tr>
                        <th>Offene Zahlungen (Zahlungsziel 4 Wochen)</th>
                        <td>{{ expectedFutureIncome }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div v-for="tableColumn in tableColumns">
                <h3>{{ tableColumn.label }}</h3>
                <table>
                    <thead>
                    <tr>
                        <th>Nr.</th>
                        <th>Rechnung</th>
                        <th>Datum</th>
                        <th>Betrag</th>
                        <th v-if="tableColumn.showType">Typ</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="tableColumn['data'].length === 0">
                        <td colspan="2">Bisher keine Einnahmen</td>
                    </tr>
                    <template v-for="earning, index in tableColumn['data']">
                        <tr>
                            <td>
                                {{ tableColumn['data'].length - index }}
                            </td>
                            <td>{{ earning.calculatedInvoiceNumber }}</td>
                            <td>
                                {{ earning.date|formatDate('LL') }}
                            </td>
                            <td>
                                {{ earning.value|price }} Euro
                            </td>
                            <td v-if="tableColumn.showType">
                                {{ earning.type|dataTrans('model.expectedEarning.type') }}
                            </td>
                        </tr>
                        <tr v-if="earning.description">
                            <td colspan="4">
                                {{ earning.description }}
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>
        </template>
    </div>
</template>

<style lang="scss">
.c-data-person-finance {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
}
</style>

<script>
    import moment from "moment";

    export default {
        props: {
            person: {},
            touchBooking: {},
        },
        data() {
            return {
                expectedEarnings: [],
                earnings: [],
                loadingIndex: 0,
            }
        },
        watch: {
            'touchBooking': {
                deep: true,
                function(newVal) {
                    this.loadEarnings(1);
                    this.loadExpectedEarnings(1);
                }
            }
        },
        computed: {
            basicQueryParams: function () {
                let params = {
                    order: {date: 'DESC'},
                    itemsPerPage: 30,
                };
                if (this.person && this.person.hasOwnProperty('@id')) {
                    params['person'] = this.person['@id'];
                }
                if (this.touchBooking && this.touchBooking.hasOwnProperty('@id')) {
                    params['touchBooking'] = this.touchBooking['@id'];
                    params['booking'] = this.touchBooking['@id'];
                }

                return params;
            },
            tableColumns: function () {
                return [
                    {
                        label: 'Erwartete Einnahmen',
                        data: this.expectedEarnings,
                        showType: true,
                    },
                    {
                        label: 'Einnahmen',
                        data: this.earnings,
                        showType: false,
                    },
                ];
            },
            sumEarnings: function () {
                let sum = 0;
                this.earnings.forEach((item) => {
                    sum += item.value;
                })

                return Math.round(sum * 100) / 100;
            },
            sumExpectedEarnings: function () {
                let sum = 0;
                this.expectedEarnings.forEach((item) => {
                    if (moment(item.date.slice(0, 10)).isBefore(moment().subtract(4, 'weeks'))) {
                        sum += item.value;
                    }
                })

                return Math.round(sum * 100) / 100;
            },
            allExpectedEarnings: function () {
                let sum = 0;
                this.expectedEarnings.forEach((item) => {
                    sum += item.value;
                })

                return Math.round(sum * 100) / 100;
            },
            expectedFutureIncome: function () {
                return Math.round((this.sumExpectedEarnings - this.sumEarnings) * 100) / 100;
            }
        },
        async mounted() {
            this.loadExpectedEarnings();
            this.loadEarnings();
        },
        methods: {
            async loadEarnings(page = 1) {
                if (page === 1) {
                    this.earnings = [];
                }
                this.loadingIndex++;
                let response = await this.$http.get('earnings', {
                    params: Object.assign({currentPage: page}, this.basicQueryParams)
                });
                this.loadingIndex--;
                this.earnings = this.earnings.concat(response.data['hydra:member']);
                if (response.data['hydra:totalItems'] > this.earnings.length) {
                    await this.loadEarnings(page + 1);
                }
            },
            async loadExpectedEarnings(page = 1) {
                if (page === 1) {
                    this.expectedEarnings = [];
                }
                this.loadingIndex++;
                let response = await this.$http.get('expected_earnings', {
                    params: Object.assign({currentPage: page}, this.basicQueryParams)
                });
                this.loadingIndex--;
                this.expectedEarnings = this.expectedEarnings.concat(response.data['hydra:member']);
                if (response.data['hydra:totalItems'] > this.expectedEarnings.length) {
                    await this.loadExpectedEarnings(page + 1);
                }
            },
        }
    }
</script>
