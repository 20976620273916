import 'regenerator-runtime/runtime';
import Vue from 'vue';
import Vuex from 'vuex';

import './assets/scss/main.scss';
import './assets/scss/tailwind.css';
import router from "./router";
import store from "./store";
import Root from "./view/pages/Root";

import CKEditor from '@ckeditor/ckeditor5-vue';
import '@ckeditor/ckeditor5-build-classic/build/translations/de';
Vue.use(CKEditor);

import CModal from "./view/components/Modal.vue";
Vue.component('c-modal', CModal);

import UiTab from './view/components/ui/Tab.vue';
Vue.component('ui-tab', UiTab);
import UiTabs from './view/components/ui/Tabs.vue';
Vue.component('ui-tabs', UiTabs);

require('babel-polyfill');
require('./components');
require('./filter');
require('./functions');
require('./includes');
require('./plugins/http');
require('./plugins/api');
require('./plugins/download');

new Vue({
    el: '#app',
    render: h => h(Root),
    router: router,
    store: store
});
