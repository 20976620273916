<template>
    <reveal ref="reveal" headline="ToDo als erledigt markieren">
        <form v-on:submit.prevent="submitTasks" class="row">
            <div class="large-12 columns">
                <table>
                    <thead>
                    <tr>
                        <th>Fällig seit</th>
                        <th>ToDo-typ</th>
                        <th>Zugewiesen</th>
                        <th>Status</th>
                        <th>Erledigt</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="task in tasks">
                        <td>{{ task.started|formatDate('LL') }}</td>
                        <td>
                            {{ task.type|dataTrans('model.task.types') }}
                            <br>
                            <div
                                v-if="task.followUpNote"
                                v-html="task.followUpNote"
                            ></div>
                        </td>
                        <td>{{ task.assigned.displayName }}</td>
                        <td>{{ task.status|dataTrans('model.task.stati') }}</td>
                        <td>
                            <input type="checkbox" v-model="releasedTouches[task.id]"/>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div class="large-12 columns text-center">
                <a @click="close()" class="button thirdly">Schließen</a>
                <button class="button" type="submit">ToDos lösen</button>
            </div>
        </form>
    </reveal>
</template>

<script>
    import moment from "moment";

    export default {
        props: {
            person: {
                required: true
            }
        },
        data() {
            return {
                touch: {},
                tasks: {},
                releasedTouches: {}
            }
        },
        methods: {
            open(touch) {
                this.touch = touch;
                this.loadTasks();
            },
            loadTasks() {
                this.$http.get('tasks', {
                    params: {
                        'triggeredTouch.person': this.person.id,
                        itemsPerPage: 1000,
                        exists: {
                            releasedTouch: 0
                        }
                    }
                }).then(response => {
                    this.tasks = response.body['hydra:member'].filter((item) => {
                        return (moment.duration(moment().diff(moment(item.createdAt))).asSeconds()) > 30;
                    });
                    if (this.tasks.length === 0) {
                        this.$refs.reveal.close();
                        this.$notify({
                            type: 'success',
                            text: 'Keine offenen ToDos für diese Person'
                        });
                    } else {
                        this.$refs.reveal.open();
                    }
                });
            },
            close() {
                this.$refs.reveal.close();
            },
            submitTasks() {
                Object.keys(this.releasedTouches).forEach((item) => {
                    console.log(this.releasedTouches[item]);
                    if (this.releasedTouches[item] !== true) {
                        delete this.releasedTouches[item];
                    }
                });

                this.$http.post('tasks/resolve', {
                    tasks: Object.keys(this.releasedTouches),
                    touch: this.touch['id']
                }).then(
                    response => {
                        this.$notify({
                            type: 'success',
                            text: 'ToDo als erledigt markiert'
                        });
                        this.$refs.reveal.close();
                    }, response => {
                        this.$notify({
                            type: 'alert',
                            text: 'ToDos konnten nicht als erledigt markiert werden'
                        });
                    }
                );
            }
        }
    }
</script>
