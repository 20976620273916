<template>
    <div>
        <content-header headline="Dokumentation" subline="Wichtige Informationen zur Bedienung des CRM-Systems"/>
        <content-main>
            <div class="panel" id="consultingImport">
                <h2>Beratungsbögen-Upload</h2>
                <p>Unter <i>Tools</i> <i>Beratungsbögen-Upload</i> können vorhandene Beratungsbögen aus den Standorten
                    hochgeladen werden. Die Daten aus den Bögen werden im System ergänzt.</p>
                <div class="callout warning">
                    Die Beratungsbögen müssen im CSV-Format, Semikolon-getrennt und im UTF-8-Format vorliegenen. Zellen
                    werden mit " escapet.
                </div>
                <p>Die CSV-Datei muss folgende Struktur besitzen:</p>
                <table>
                    <thead>
                    <tr>
                        <th>Feld</th>
                        <th>Mögliche Werte</th>
                        <th>Nähere Beschreibung Beschreibung</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="row in consultingImportTable">
                        <td v-for="column in row">
                            <div v-if="column === 'nv'" class="callout warning">Keine Verwendung</div>
                            <div v-else v-html="column"></div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </content-main>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                consultingImportTable: [
                    [
                        'ID', 'Beliebiger Wert', 'nv'
                    ],
                    [
                        'Datum des Gesprächs', '18.05.94<br>18.05.1994'
                    ],
                    [
                        'Kürzel des Beraters', 'AK<br>FN', 'Kürzel muss bei einem Benutzer im System hinterlegt sein.'
                    ],
                    [
                        'Name', 'Felix Niedballa<br>Niedballa, Felix', ''
                    ],
                    [
                        'E-Mail', 'felix.niedballa@thinkhalle.de', 'Personen mit gleichen E-Mail-Adressen werden zusammengeführt'
                    ],
                    [
                        'Telefon', 'Beliebige Nummer', ''
                    ],
                    [
                        'Straße', 'Straße 10', ''
                    ],
                    [
                        'PLZ', '35510', ''
                    ],
                    [
                        'Ort', 'Butzbach', ''
                    ],
                    [
                        'Geburtstag', '18.05.94<br>18.05.1994', ''
                    ],
                    [
                        'Intressengebiet 1', 'KT<br>MT', 'Kürzel muss im System hinter Ausbildung hinterlegt sein.'
                    ],
                    [
                        'Intressengebiet 2', 'KT<br>MT', 'Kürzel muss im System hinter Ausbildung hinterlegt sein.'
                    ],
                    [
                        'Selbstzahler', 'x oder leer', 'Wenn nicht leer, dann als Selbstzahler markiert'
                    ],
                    [
                        'Kurzdokumentation', 'beliebiger Text', ''
                    ],
                    [
                        'Mappengespräch', '', 'nv'
                    ],
                    [
                        'Verbleibt', '', 'nv'
                    ],
                    [
                        'Anmeldung erfolgt', '', 'nv'
                    ],
                    [
                        'Ergebnis Follow Up', 'beliebiger Text', ''
                    ],
                    [
                        'nächste Kontaktaufnahme', 'beliebiger Text', ''
                    ],
                    [
                        'zum TdoT eingeladen', '', 'nv'
                    ],
                    [
                        'zum Farbenherbst eingeladen', '', 'nv'
                    ],
                    [
                        'möglicher Einstieg in Gruppe', '', 'nv'
                    ],
                    [
                        'Einstiegsjahr', '2020<br>2019', ''
                    ]
                ]
            }
        }
    }
</script>
