<template>
    <div>
        <div class="panel">
            <div class="row">
                <div class="large-12 columns">
                    <h1>Passwort vergessen</h1>
                </div>
                <div class="large-12 columns">
                    <form v-on:submit.prevent="passwordReset()">
                        <input-text v-model="email" label="E-Mail" :errors="formErrors.email"/>

                        <div class="text-center">
                            <button type="submit" class="button">Passwort zurücksetzen</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="text-center">
            <router-link to="/login">Zurück zum Login</router-link>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                email: '',
                formErrors: {}
            }
        },
        methods: {
            passwordReset() {
                this.formErrors = {};
                this.$parent.showLoading();
                this.$http.delete("actions/passwordReset", {body: {email: this.email}}).then(response => {
                    this.$parent.hideLoading();
                    this.$notify({
                        type: 'success',
                        text: 'Passwort erfolgreich zurückgesetzt. Sie erhalten in Kürze eine E-Mail zum Setzen des neuen Passwortes.'
                    });
                    this.$router.push('/login');
                }, response => {
                    this.formErrors = {
                        email: [
                            "Kein Benutzer gefunden oder Passwort falsch"
                        ]
                    };

                    this.$parent.hideLoading();
                });
            }
        }
    }
</script>
