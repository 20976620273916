<template>
    <div>
        <content-header headline="Curriculum">
            <a
                class="button"
                @click="$refs.dataTable.createData({})"
            >
                Curriculum hinzufügen
            </a>
        </content-header>
        <content-main>
            <data-table
                endpoint="curricula"
                :columns="columns"
                :filters="filters"
                :filter-layout="filterLayout"
                :filter-large="true"
                ref="dataTable"
                updateRevealSize="large"
            >
                <template slot="updateForm" slot-scope="{data: curriculum, errors: errors}">
                    <update-form v-model="curriculum" :errors="errors"/>
                </template>
                <template slot="dataRow" slot-scope="{singleData: curriculum}">
                    <td>
                        {{ curriculum.name }}
                    </td>
                    <td>
                        {{ curriculum.status|dataTrans('model.curriculum.status') }}
                    </td>
                    <td>
                        <template v-if="curriculum.education">
                            {{ curriculum.education.name }}
                        </template>
                    </td>
                    <td>
                        {{curriculum.versionNumber}}
                    </td>
                    <td>
                        {{ curriculum.lastEditedAt|formatDate('LLL') }}
                    </td>
                    <td class="text-right">
                        <router-link :to="'/teaching/curricula/' + curriculum.id" class="action --show"></router-link>
                    </td>
                </template>
            </data-table>
        </content-main>
    </div>
</template>


<script>
    import DataTable from "@/components/DataTable.vue";
    import UpdateForm from "@/data/Curriculum/UpdateForm.vue";

    export default {
        components: {
            UpdateForm,
            DataTable
        },
        methods: {
            reload() {
                this.$refs.dataTable.reload();
            },
            createData(data) {
                this.$refs.dataTable.createData(data);
            },
        },
        computed: {
            filters() {
                return [
                    {
                        type: 'text',
                        model: 'name',
                        label: 'Name'
                    }
                ];
            },
            columns() {
                return {
                    name: {
                        text: 'Name',
                        order: true,
                    },
                    status: {
                        text: 'Status',
                        order: false,
                    },
                    education: {
                        text: 'Ausbildung',
                        order: false,
                    },
                    versionNumber: {
                        text: 'Versionsnummer',
                        order: true,
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    }
                };
            },
        },
        data() {
            return {
                filterLayout: [
                    ['name'],
                    ['education'],
                ],
            }
        }
    }
</script>
