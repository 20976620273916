<template>
    <div
        :class="{'--errors': hasErrors, 'row': asRow, 'align-middle': asRow}"
        class="label c-form-inputDateRange"
    >
        <template
            v-if="label && !asRow"
            class="label"
        >
            {{ label }}
        </template>

        <div v-if="asRow && label"
             class="columns shrink"
        >
            {{ label }}
        </div>
        <br v-if="!asRow">
        <input-date
            :class="{'columns': asRow}"
            v-model="value['after']"
            label="Von"
        />
        <input-date
            :class="{'columns': asRow}"
            v-model="value['before']"
            label="Bis"
        />
    </div>
</template>

<style lang="scss">
.c-form-inputDateRange {
    .vue-flatPickr__wrapper input {
        margin-bottom: 0 !important;
        background: white;
    }
}
</style>

<script>
    import InputDate from "@/components/form/InputDate.vue";
    export default {
        data() {
            return {
                showErrors: true
            }
        },
        components: {
            InputDate
        },
        props: {
            asRow: {
                type: Boolean,
                default: false,
            },
            value: {
                type: Object,
                default: () => {
                    return {};
                }
            },
            placeholder: {
                type: String,
                default: () => {
                    return '';
                }
            },
            label: {
                type: String,
                default: () => {
                    return '';
                }
            },
            readonly: {
                type: Boolean,
                default: () => {
                    return false;
                }
            },
            required: {
                type: Boolean,
                default: () => {
                    return false;
                }
            },
            errors: {
                type: Object | Array,
                default: function () {
                    return {};
                }
            },
        },
        computed: {
            hasErrors() {
                return this.errors !== null && this.errors.length > 0 && this.showErrors
            }
        },
        methods: {
            update(data) {
                this.errors = [];
                this.showErrors = false;
                if (!this.readonly) {
                    if (data.before === null || data.before === '') {
                        delete data.before;
                    }
                    if (data.after === null || data.after === '') {
                        delete data.after;
                    }
                    this.$emit('input', data);
                }
            }
        },
        watch: {
            'errors': function () {
                this.showErrors = true;
            },
            'value': {
                handler: function (newVal) {
                    this.update(newVal);
                },
                deep: true,
            }
        }
    }
</script>
