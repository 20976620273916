<template>
    <div>
        <content-header headline="Kunden und Interessenten">
            <a
                    class="button"
                    @click="$refs.dataTable.createData({primaryContactChannel: 'email'})"
            >
                Person hinzufügen
            </a>
        </content-header>
        <content-main>
            <persons-table ref="dataTable" :add-query-to-url="true" state-key="persons"/>
        </content-main>
    </div>
</template>

<script>
    import PersonsTable from "../../data/PersonsTable";

    export default {
        components: {
            PersonsTable
        },
        mounted() {

        },
        data() {
            return {
            }
        },
    }
</script>
