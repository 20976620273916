<template>
    <div>
        <div class="row">
            <div class="columns">
                <input-text v-model="value.name" label="Name der Veranstaltung" :errors="errors.name"/>
            </div>
            <div v-if="value.id" class="columns shrink">
                <label>
                    Als abgesagt markieren
                    <br>
                    <input type="checkbox" v-model="value.canceled"/>
                </label>
            </div>
        </div>

        <div class="row">
            <div class="columns large-6">
                <event-location-type-select
                    v-model="value.locationType"
                    :errors="errors.locationType"
                />
            </div>
            <div class="columns large-6">
                <input-entity
                    type="location"
                    v-model="value.location"
                    label="Veranstaltungsort"
                    :errors="errors.location"/>
            </div>
        </div>

        <div class="row">
            <div class="columns large-6">
                <input-date v-model="value.date" label="Start" :time="true" :errors="errors.date"/>
            </div>
            <div class="columns large-6">
                <input-text
                    type="number"
                    v-model.number="value.duration"
                    :step="0.5"
                    label="Dauer (in Stunden)"
                    :errors="errors.duration"
                />
            </div>
        </div>

        <div class="row" v-if="value['@type'] === 'EventTrialCourse'">
            <div class="large-12 columns">
                <hr>
                <h3>Dozenten / Berater-Details</h3>
            </div>
            <div class="large-6 columns">
                <input-entity
                    v-model="value.docent"
                    type="docent"
                    label="Dozent"
                    :errors="errors.docent"
                />
            </div>
            <div class="large-6 columns">
                <input-entity
                    v-model="value.internConsultant"
                    type="user_real"
                    label="Interner Berater"
                    :errors="errors.internConsultant"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import value from "../../../mixins/value";
    import EventLocationTypeSelect from "../../components/form/EventLocationTypeSelect";

    export default {
        components: {EventLocationTypeSelect},
        mixins: [value]
    }
</script>
