<template>
    <div>
        <content-header headline="Einnahmen"/>
        <content-main>
            <data-table
                endpoint="earnings"
                :columns="columns"
                ref="dataTable"
                :filters="filters"
            >
                <template slot="updateForm" slot-scope="{data: earning, errors: errors}">
                    <div class="row">
                        <div class="columns small-12">
                            <table>
                                <tbody>
                                <tr>
                                    <th>Verwendungszweck</th>
                                    <td>{{ earning.description }}</td>
                                </tr>
                                <tr>
                                    <th>Betrag</th>
                                    <td>{{ earning.value|price }} Euro</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="columns small-12">
                            <input-entity
                                v-model="earning.touchBooking"
                                type="touchBooking"
                                label="Buchung"
                            />
                        </div>
                    </div>
                </template>
                <template slot="dataRow" slot-scope="{singleData: earning}">
                    <td style="white-space: nowrap;">{{ earning.createdAt|formatDate('LLL') }}</td>
                    <td style="white-space: nowrap;">{{ earning.date|formatDate('LL') }}</td>
                    <td style="white-space: nowrap;">{{ earning.value|price }} Euro</td>
                    <td style="white-space: nowrap;">{{ earning.calculatedInvoiceNumber }}</td>
                    <td>
                        {{ earning.description }}
                    </td>
                    <td class="text-right">
                        <div class="grid grid-flow-col gap-2">
                            <a v-if="earning.userCanUpdate" class="action --edit"
                               @click="$refs.dataTable.editData(earning)"></a>
                            <a v-if="earning.userCanDelete" class="action --delete"
                               @click="$refs.dataTable.deleteData(earning)"></a>
                        </div>
                    </td>
                </template>
            </data-table>
        </content-main>
    </div>
</template>
<script>
    import DataTable from "../../../components/DataTable";
    import InputCurrency from "../../../components/form/InputCurrency";

    export default {
        components: {InputCurrency, DataTable},
        computed: {
            filters() {
                let filter = [
                    {
                        type: 'text',
                        model: 'description',
                        label: 'Verwendungszweck'
                    },
                    {
                        type: 'select',
                        model: 'exists[touchBooking]',
                        label: 'Rechnung vorhanden',
                        options: {
                            false: 'Nicht vorhanden',
                            true: 'Vorhanden'
                        }
                    },
                    {
                        type: 'checkbox',
                        model: 'positive',
                        label: 'Einnahmen'
                    }
                ];

                return filter;
            }
        },
        data() {
            return {
                columns: {
                    createdAt: {
                        text: 'Import',
                        order: true,
                    },
                    date: {
                        text: 'Datum',
                        order: true,
                    },
                    value: {
                        text: 'Betrag',
                    },
                    calculatedInvoiceNumber: {
                        text: 'Rechnung',
                    },
                    description: {
                        text: 'Verwendungszweck'
                    },
                }
            }
        }
    }
</script>
