<template>
    <div class="pageApplicationToolsEventPlaning">
        <update-modal ref="eventCourseDateUpdateModal" v-on:update="loadEvents() && loadCurrentCourseDates()"/>

        <c-modal
            ref="createEventsModal"
            headline="Erstellen einer neuen Terminreihe"
        >
            <div class="p-4">
                <ul class="m-0 p-0 grid gap-4">
                    <li v-for="(eventCourseDate, index) in eventCourseDates"
                        class="grid-cols-6 grid auto-cols-fr gap-x-4 border-b border-t-0 border-l-0 border-r-0 border-gray-300"
                        :class="{
                            'border-solid': index < eventCourseDates.length - 1
                        }"
                    >
                        <div class="col-span-2">
                            <input-text readonly :value="eventCourseDate.date|formatDate('LL')" label="Tag"/>
                        </div>
                        <div class="col-span-2">
                            <input-text
                                v-model="eventCourseDate.start"
                                label="Start"
                            />
                        </div>
                        <div class="col-span-2">
                            <input-text
                                v-model="eventCourseDate.end"
                                label="Ende"
                            />
                        </div>
                        <div class="col-span-3">
                            <input-entity
                                v-model="eventCourseDate.docent"
                                label="Dozent"
                                type="docent"
                            />
                        </div>
                        <div class="col-span-3">
                            <input-entity
                                v-model="eventCourseDate.curriculumTopic"
                                label="Curriculum-Thema"
                                type="curriculumTopic"
                                :fixed-params="{
                                    course: course
                                }"
                            />
                        </div>
                    </li>
                </ul>
            </div>

            <div class="text-center">
                <button
                    class="button"
                    @click="createEventCourseDates"
                >
                    Erstellen
                </button>
            </div>
        </c-modal>

        <header>
            <div>
                <h1>Raum- und Terminplanung</h1>
            </div>
            <input-entity
                v-if="location"
                type="course"
                v-model="course"
                label="Kurs"
                :fixed-params="{location: this.location}"
            />
            <input-entity type="location" v-model="location" label="Standort"/>
            <input-date-range v-model="period" :as-row="true" style="margin-bottom: 0;"/>
        </header>

        <main class="relative">
            <div class="ml-48 w-[calc(100%-12rem)]" style="overflow-x: scroll;">
                <table>
                    <thead>
                    <tr>
                        <th class="absolute left-0 w-48"></th>
                        <th v-for="date in dates" style="padding-bottom: 0;">
                            {{ date|formatDate('dd') }}
                        </th>
                    </tr>
                    <tr>
                        <th class="absolute left-0 w-48">Raum</th>
                        <th v-for="date in dates">
                            <small>{{ date|formatDate('L') }}</small>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th class="absolute left-0 w-48">Feiertage / Ferien</th>
                        <template v-for="date in dates">
                            <td class="min-h-16" style="height: 3rem;">
                                <ul v-if="holidays.hasOwnProperty(date)"
                                    style="list-style: none; margin: 0; padding: 0;"
                                >
                                    <li v-for="holiday in holidays[date]">
                                        {{ holiday.name }}
                                    </li>
                                </ul>
                            </td>
                        </template>
                    </tr>
                    <tr v-for="room in rooms">
                        <th class="absolute left-0 w-48 whitespace-nowrap">
                            {{ room.name }}
                        </th>
                        <template v-for="date in dates">
                            <template
                                v-if="events.hasOwnProperty(room['@id']) && events[room['@id']].hasOwnProperty(date)"
                            >
                                <td style="background: #00243B; color: white; height: 3rem;" class="min-h-16">
                                    <small @click="updateEventCourseDate(events[room['@id']][date])">
                                        <strong>{{ events[room['@id']][date].name }}</strong>
                                        <br>
                                        {{ events[room['@id']][date].start }} - {{ events[room['@id']][date].end }}
                                    </small>
                                </td>
                            </template>
                            <td
                                v-else
                                class="min-h-16"
                                style="height: 3rem;"
                                @mousedown="timelineDragStart(room['@id'], date)"
                                @mouseenter="timelineDrag(room['@id'], date)"
                                :class="{'--active': selectedRoom === room['@id'] && selectedDates.includes(date)}"
                            >
                            </td>
                        </template>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div
                v-if="course"
                class="grid grid-cols-2 items-start gap-4"
            >
                <div class="grid gap-2 overflow-scroll">
                    <div class="uppercase font-bold text-lg">
                        Kurse als Liste
                    </div>
                    <table>
                        <thead>
                        <tr>
                            <th>Wochentag</th>
                            <th>Tag</th>
                            <th>Uhrzeit</th>
                            <th>Thema</th>
                            <th>Raum</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(currentCourseDate, index) in currentCourseDates">
                            <tr v-if="index > 0 && isNotNextDay(currentCourseDates[index-1], currentCourseDates[index])">
                                <td colspan="5">
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>{{ currentCourseDate.date|formatDate('dddd') }}</td>
                                <td>{{ currentCourseDate.date|formatDate('L') }}</td>
                                <td>{{ currentCourseDate.date|formatDate('LT') }} - {{ currentCourseDate.end|formatDate('LT') }}</td>
                                <td>
                                    <template v-if="currentCourseDate.curriculumTopic">
                                        {{ currentCourseDate.curriculumTopic.name }}
                                        <br>
                                        <i>{{ currentCourseDate.curriculumTopic.internalName }}</i> (Pos: {{ currentCourseDate.curriculumTopic.position }})
                                    </template>
                                </td>
                                <td>
                                    <template v-if="currentCourseDate.room">
                                        {{ currentCourseDate.room.name }}
                                        <br
                                            {{ currentCourseDate.room.location.name}}
                                    </template>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
                <div class="grid gap-2">
                    <div class="uppercase font-bold text-lg">
                        Kurse als Liste
                    </div>
                    <div v-for="group in currentCourseDateGroups">
                        {{ group[0].date|formatDate('L') }} - {{ group[group.length - 1].date|formatDate('L') }}
                    </div>
                </div>
            </div>
            <div
                class="text-center"
            >
                <router-link to="/courses" class="button thirdly">Planung beenden</router-link>
            </div>
        </main>
    </div>
</template>

<style lang="scss">
.pageApplicationToolsEventPlaning {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    background: white;
    z-index: 100;
    padding: 4rem;
    overflow-y: scroll;

    header {
        grid-template-columns: 1fr auto auto;
        align-items: center;
        display: grid;
        grid-column-gap: 2rem;
        grid-auto-flow: column;
        margin-bottom: 2rem;
    }

    main {
        max-width: 100%;
        overflow-x: scroll;

        table {
            white-space: nowrap;

            td.--active {
                background: #39A8D8;
            }

            tbody td {
                border-right: 1px solid #ededed;
                padding: 0 .5rem;
            }
        }
    }
}
</style>

<script>
    import moment from "moment-timezone";
    import UpdateModal from "@/data/EventCourseDate/UpdateModal.vue";

    export default {
        components: {UpdateModal},
        data() {
            return {
                selectedRoom: false,
                selectedDates: [],
                period: {
                    after: null,
                    before: null,
                },
                eventCourseDates: [],
                course: null,
                currentCourseDates: [],

                holidays: [],
                events: [],
                rooms: [],

                location: null,
            }
        },
        mounted() {
            window.addEventListener('click', this.timelineDragEnd);

            this.period.after = moment().subtract(4, 'weeks').format('YYYY-MM-DD');
            this.period.before = moment().add(4, 'weeks').format('YYYY-MM-DD');

            this.loadRooms();
            this.loadEvents();
            this.loadHolidays();
        },
        computed: {
            dates() {
                let dates = [];
                for (let m = moment(this.period.after); m.diff(this.period.before, 'days') <= 0; m.add(1, 'days')) {
                    dates.push(m.format('YYYY-MM-DD'));
                }
                return dates;
            },
            currentCourseDateGroups() {
                let grouped = [];
                let currentIndex = 0;

                this.currentCourseDates.forEach((item, index) => {
                    if (index === 0) {
                        grouped.push([item]);
                    } else {
                        let lastOfGroup = grouped[currentIndex][(grouped[currentIndex].length - 1)];
                        if (moment(item.date).diff(moment(lastOfGroup.date), 'days') < 2) {
                            grouped[currentIndex].push(item);
                        } else {
                            grouped.push([item]);
                            currentIndex++;
                        }
                    }
                });

                return grouped;
            }
        },
        methods: {
            isNotNextDay(date, prevDate) {
                let diff = moment(prevDate.date).diff(moment(date.date), 'days');
                console.log(diff);
                return diff > 2;
            },
            async loadCurrentCourseDates(page = 1) {
                this.currentCourseDates = await this.$getAllFromApi(
                    'event_course_dates',
                    {
                        course: this.course,
                        'order[date]': 'ASC'
                    }
                );
            },
            updateEventCourseDate(event) {
                this.$refs.eventCourseDateUpdateModal.open(event);
            },
            deleteEventCourseDate(event, room, date) {
                if (!this.$store.getters.hasLoggedInUserCredibility('ROLE_EDUCATION_MANAGEMENT')) {
                    return;
                }
                let _this = this;
                this.$dialog
                    .confirm('Event wirklich löschen', {
                        okText: 'Event löschen',
                        cancelText: 'Abbrechen',
                    })
                    .then(function () {
                        _this.$deleteFromApi('events', event, () => {
                            delete _this.events[room['@id']][date];
                            _this.$forceUpdate();
                            //_this.loadEvents();
                            _this.loadCurrentCourseDates();
                        })
                    })
                    .catch(function () {
                    });

            },
            createEventCourseDates() {
                let items = this.eventCourseDates.length;
                this.eventCourseDates.forEach((item) => {
                    item.date = moment(item.date).tz('Europe/Berlin').set('hour', parseInt(item.start.split(':')[0])).set('minute', parseInt(item.start.split(':')[1]));
                    let end = moment(item.date).tz('Europe/Berlin').set('hour', parseInt(item.end.split(':')[0])).set('minute', parseInt(item.end.split(':')[1]));
                    item.duration = moment.duration(end.diff(item.date)).asMinutes() / 60;
                    item.date = item.date.format('YYYY-MM-DDTHH:mm:ss')

                    this.$sendToApi('event_course_dates', item, (entity, status, error) => {
                        items--;
                        if (items === 0) {
                            this.loadEvents();
                            this.loadCurrentCourseDates();
                            this.selectedDates = [];
                            this.$refs.createEventsModal.close();
                        }
                    });
                })
            },
            closeCreateEventsModal() {
                this.selectedRoom = false;
                this.selectedDates = [];
                this.$refs.createEventsModal.close();
            },
            toggleSelectedDate(date) {
                if (this.selectedDates.includes(date)) {
                    this.selectedDates.splice(this.selectedDates.indexOf(date), 1);
                } else {
                    this.selectedDates.push(date);
                }
            },
            timelineDragStart(room, value) {
                if (!this.$store.getters.hasLoggedInUserCredibility('ROLE_EDUCATION_MANAGEMENT')) {
                    return;
                }

                if (this.course) {
                    this.selectedRoom = room;
                    this.toggleSelectedDate(value);
                }
            },
            timelineDrag(room, value) {
                if (this.selectedRoom === room && this.course) {
                    this.toggleSelectedDate(value);
                }
            },
            timelineDragEnd() {
                if (!this.$store.getters.hasLoggedInUserCredibility('ROLE_EDUCATION_MANAGEMENT')) {
                    return;
                }

                let eventCourseDates = [];
                if (this.selectedRoom && this.course) {
                    this.eventCourseDates = [];

                    this.selectedDates.forEach((item) => {
                        eventCourseDates.push({
                            date: item,
                            room: this.selectedRoom,
                            course: this.course,
                            duration: 8,
                            start: '09:00',
                            end: '18:00',
                        });
                    });
                    this.selectedRoom = null;
                    this.$set(this, 'eventCourseDates', eventCourseDates);
                    this.$refs.createEventsModal.open();
                }
            },
            async loadRooms() {
                if (!this.location) {
                    return;
                }

                this.rooms = await this.$getAllFromApi(
                    'rooms',
                    {
                        location: this.location,
                        'order[onlineRoom]': 'DESC',
                        'order[name]': 'ASC',
                    }
                );
            },
            async loadHolidays(page = 1) {
                if (!this.location) {
                    return;
                }
                if (page === 1) {
                    this.holidays = {};
                }

                let holidays = await this.$getAllFromApi(
                    'holidays',
                    {
                        itemsPerPage: 30,
                        location: this.location
                    }
                );

                holidays.forEach((item) => {
                    if (!this.holidays.hasOwnProperty(moment(item.date).format('YYYY-MM-DD'))) {
                        this.holidays[moment(item.date).format('YYYY-MM-DD')] = [];
                    }
                    this.holidays[moment(item.date).format('YYYY-MM-DD')].push(item);
                })
            },
            async loadEvents(page = 1) {
                if (!this.location) {
                    return;
                }
                if (page === 1) {
                    this.events = {};
                }
                if (this.location) {
                    let events = await this.$getAllFromApi(
                        'event_course_dates',
                        {
                            'date[after]': this.period.after,
                            'date[before]': this.period.before,
                            'orderBy[id]': 'ASC',
                            location: this.location,
                        }
                    );
                    events = events.filter((item) => {
                        return item.hasOwnProperty('room') && item.room !== null;
                    });
                    events.forEach((item) => {
                        if (!this.events.hasOwnProperty(item.room['@id'])) {
                            this.$set(this.events, item.room['@id'], {});
                        }

                        this.$set(
                            this.events[item.room['@id']],
                            moment(item.date).format('YYYY-MM-DD'),
                            {
                                id: item.id,
                                '@id': item['@id'],
                                room: item['room']['@id'],
                                name: item.code,
                                start: moment(item.date).tz('Europe/Berlin').format('HH:mm'),
                                end: moment(item.date).tz('Europe/Berlin').add(parseInt(item.duration), 'hours').format('HH:mm'),
                            }
                        );
                    });
                }
            }
        },
        watch: {
            period: {
                deep: true,
                handler: function () {
                    this.loadEvents();
                    this.loadEvents();
                    this.loadHolidays();
                }
            },
            course: function () {
                this.loadCurrentCourseDates();
            },
            location: function () {
                this.loadRooms();
                this.loadEvents();
                this.loadHolidays();
            }
        }
    }
</script>
