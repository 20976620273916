<template>
    <input-simple-select
        v-model="value"
        :errors="errors"
        :label="label"
        :options="courseLocationTypes"
    />
</template>

<script>
    import value from "../../../mixins/value";

    export default {
        mixins: [value],
        props: {
            label: {
                type: String,
                default: () => {
                    return 'Ort (Typ)'
                }
            }
        },
        computed: {
            courseLocationTypes: function () {
                return this.$store.getters.applicationSettings.model.courseLocationTypes;
            }
        }
    }
</script>
