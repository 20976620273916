<template>
	<div class="grid gap-x-8 grid-cols-6">
		<input-simple-select
			class="col-span-2"
			v-model="value.status"
			label="Status"
			:options="$settingsObject('model.docent.status')"
		/>
		<gender-select
			class="col-span-2"
			v-model="value.gender"
			:errors="errors.firstName"
		/>
		<input-text
			class="col-span-2"
			label="Title"
			v-model="value.title"
			:errors="errors.title"
		/>
		<input-text
				class="col-span-3"
				label="Vorname"
				v-model="value.firstName"
				:errors="errors.firstName"
		/>
		<input-text
				class="col-span-3"
				label="Nachname"
				v-model="value.lastName"
				:errors="errors.lastName"
		/>
        <input-text
            class="col-span-2"
            label="Straße"
            v-model="value.street"
            :errors="errors.street"
        />
        <input-text
            class="col-span-2"
            label="PLZ"
            v-model="value.zip"
            :errors="errors.zip"
        />
        <input-text
            class="col-span-2"
            label="Stadt"
            v-model="value.city"
            :errors="errors.city"
        />
		<input-text
			class="col-span-3"
			label="E-Mail"
			v-model="value.email"
			:errors="errors.email"
		/>
		<input-text
			class="col-span-3"
			label="Telefonnummer"
			v-model="value.phoneNumber"
			:errors="errors.phoneNumber"
		/>
        <input-text
            class="col-span-2"
            label="Code"
            v-model="value.code"
            :errors="errors.code"
        />
        <input-entity
            class="col-span-4"
            label="Lernfelder"
            type="learningField"
            :multi="true"
            v-model="value.learningFields"
            :errors="errors.learningFields"
        />
        <input-entity
            class="col-span-6"
            label="Standort (Heimat)"
            type="location"
            :multi="false"
            v-model="value.homeLocation"
            :errors="errors.homeLocation"
        />
        <input-entity
            class="col-span-6"
            label="Standorte"
            type="location"
            :multi="true"
            v-model="value.locations"
            :errors="errors.locations"
        />
	</div>
</template>

<script>
    import value from "../../../mixins/value";
	import GenderSelect from "../../components/form/GenderSelect.vue";

    export default {
		components: {GenderSelect},
        mixins: [value]
    }
</script>
