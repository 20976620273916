<template>
    <div class="row">
        <div class="large-8 large-offset-2 columns">
            <div class="panel h-largeMargin-top">
                <div class="panel__headline">Rückrufanfrage</div>
                <callback-table :touch-callback="touchCallback" />

                <div class="text-center">
                    <router-link class="button thirdly" :to="'/persons/' + touchCallback.person.id">Zum Profil
                    </router-link>
                </div>

                <hr>

                <div class="row" v-if="type === 'accept'">
                    <div class="columns small-12">
                        <div class="callout warning">
                            <p>
                                Wenn Sie den Rückrufwunsch akzeptieren, wird ein To-Do erstellt, der Ihrem Benutzer
                                zugeordnet wird.
                                <br>
                                Die Person erhält ein Mail mit positiver Bestätigung.
                            </p>
                        </div>
                    </div>
                    <div class="columns small-12">
                        <input-entity v-model="touchCallback.user" label="Zugeordneter Benutzer" type="user_real"/>
                    </div>
                    <div class="columns small-12 text-center">
                        <button class="button" @click="acceptCallback">Rückrufanfrage akzeptieren</button>
                        <button class="button thirdly" @click="type = 'change'">Anderen Zeitpunkt setzen</button>
                    </div>
                </div>

                <form class="row" v-on:submit.prevent="changeCallback()" v-else>
                    <div class="columns small-12">
                        <div class="callout warning">
                            <p>
                                Wenn Sie den Rückrufanfrage ändern, wird ein To-Do erstellt, der Ihrem Benutzer
                                zugeordnet wird.
                                <br>
                                Die Person erhält ein Mail mit dem neuen Rückrufzeitraum.
                            </p>
                        </div>
                    </div>
                    <div class="large-6 columns">
                        <input-date :time="true" v-model="change.callbackTime" label="Neuer Rückrufzeitraum"/>
                    </div>
                    <div class="columns small-6">
                        <input-entity v-model="change.user" label="Zugeordneter Benutzer" type="user_real"/>
                    </div>

                    <div class="large-6 columns" v-if="change.callbackTime">
                        <label>
                            Warnhinweis gelesen
                            <br>
                            <input type="checkbox" required v-model="change.warningAccepted">
                        </label>
                    </div>

                    <div class="columns small-12 text-center">
                        <button
                            class="button"
                            type="submit"
                            :disabled="!change.warningAccepted || !change.callbackTime"
                        >
                            Rückrufanfrage ändern
                        </button>
                        <button class="button thirdly" @click="type = 'accept'">
                            Ursprünglichen RÜckrufwunsch ansehen
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import CallbackTable from "../../data/Touch/CallbackTable";
    import InputEntity from "@/components/form/InputEntity.vue";
    export default {
        components: {CallbackTable, InputEntity},
        data() {
            return {
                change: {},
                touchCallback: {
                    user: null
                },
                type: 'accept'
            }
        },
        mounted() {
            this.load();
            this.type = this.$route.params.action;
        },
        methods: {
            load() {
                this.$getFromApi(
                    'touch_callbacks',
                    this.$route.params.touchCallbackId,
                    (entity) => {
                        this.touchCallback = entity;
                        this.change.touchCallback = entity.id;
                        this.touchCallback.user = this.$store.getters.getLoggedInUser['@id'];
                        this.change.user = this.$store.getters.getLoggedInUser['@id'];
                    }
                );
            },
            acceptCallback() {
                this.$http.post(
                    'actions/touchCallbackAccept',
                    {
                        touchCallback: this.touchCallback.id,
                        user: this.touchCallback.user,
                    }
                ).then(response => {
                    this.$router.push("/persons/" + this.touchCallback.person.id);
                });
            },
            changeCallback() {
                this.$http.post('actions/touchCallbackChange', this.change).then(response => {
                    this.$router.push("/persons/" + this.touchCallback.person.id);
                });
            }
        }
    }
</script>
