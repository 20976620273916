<template>
    <div>
        <content-header :headline="portal.id ? portal.name : 'Neues Portal hinzufügen'">
            <router-link class="button thirdly" to="/tools/portals">Zurück zur Übersicht</router-link>
        </content-header>
        <content-main>
            <div class="panel">
                <form v-on:submit.prevent="updatePortal">
                    <div class="row">
                        <div class="small-12 columns">
                            <h2>Stammdaten des Portals</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="small-12 large-4 columns">
                            <input-text
                                label="Name des Portals"
                                v-model="portal.name"
                                :errors="portalErrors.name"
                            />
                        </div>
                        <div class="small-12 large-4 columns">
                            <input-date
                                label="Letztes Update"
                                v-model="portal.lastUpdated"
                                :errors="portalErrors.lastUpdated"
                            />
                        </div>
                        <div class="small-12 large-4 columns">
                            <input-simple-select
                                label="Automatischer Export"
                                v-model="portal.exportType"
                                :required="false"
                                :options="getExportTypes"
                                :errors="portalErrors.exportType"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="small-6 large-2 columns">
                            <label>
                                Start wird eingetragen
                                <br>
                                <input type="checkbox" v-model="portal.trackStartChange"/>
                            </label>
                        </div>
                        <div class="small-6 large-2 columns">
                            <label>
                                Ende wird eingetragen
                                <br>
                                <input type="checkbox" v-model="portal.trackEndChange"/>
                            </label>
                        </div>
                        <div class="small-6 large-2 columns">
                            <label>
                                Alles wird eingetragen
                                <br>
                                <input type="checkbox" v-model="portal.trackAllChange"/>
                            </label>
                        </div>
                        <div class="small-6 large-6 columns">
                            <input-entity
                                label="Standorte"
                                v-model="portal.locations"
                                type="location"
                                :errors="portalErrors.locations"
                                :multi="true"
                            />
                        </div>
                        <div class="small-12 columns">
                            <input-text
                                type="textarea"
                                label="Notiz"
                                v-model="portal.note"
                                :errors="portalErrors.note"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="small-12 columns text-center">
                            <button type="submit" class="button">
                                <template v-if="portal.id">Speichern</template>
                                <template v-if="!portal.id">Erstellen</template>
                            </button>
                        </div>
                    </div>
                </form>

                <template v-if="portal.id">
                    <hr>
                    <div class="row align-middle">
                        <div class="columns">
                            <h2>Änderungsvorschläge *</h2>
                            <p>* Je nachdem wie im BHS die Termine eingetragen werden, kann es vorkommen, dass nicht alle Änderungen 100% erfasst werden.</p>
                        </div>
                        <div class="columns shrink">
                            {{courseDataChanges.length}} Änderungen

                        </div>
                        <div class="columns shrink" v-if="courseDataChanges.length > 0">
                            <label>
                                Sortierung
                                <select v-model="sort">
                                    <option value="start">Start</option>
                                    <option value="code">Kürzel</option>
                                </select>
                            </label>
                        </div>
                    </div>

                    <div class="callout warning" v-if="courseDataChanges.length === 0">
                        Keine Änderung seit letztem Update vom {{portal.lastUpdated|formatDate('LL')}}
                    </div>

                    <table v-if="courseDataChanges.length > 0">
                        <thead>
                            <tr>
                                <th>Kurs-Kürzel</th>
                                <th>Start</th>
                                <th>Ende</th>
                                <th>Typ</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="courseDataChange in courseDataChanges">
                                <td>
                                    {{courseDataChange.code}}
                                    <br>
                                    {{courseDataChange.name}}
                                </td>
                                <td>
                                    <template v-if="courseDataChange.firstDate">
                                        {{courseDataChange.firstDate}}
                                    </template>
                                </td>
                                <td>
                                    <template v-if="courseDataChange.lastDate">
                                        {{courseDataChange.lastDate}}
                                    </template>
                                <td>
                                    <span v-if="courseDataChange.type === 'start'">Aus Portal löschen (bereits gestartet)</span>
                                    <span v-if="courseDataChange.type === 'change'">Änderung der Termine</span>
                                    <span v-if="courseDataChange.type === 'new'">Neuer Kurs</span>
                                </td>
                                <td class="text-right">
                                    <router-link :to="'/courses/' + courseDataChange.id"
                                        class="action --show"
                                    >

                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </div>
        </content-main>
    </div>
</template>

<script>
    import InputText from "../../../components/form/InputText";
    import InputEntity from "../../../components/form/InputEntity";
    import InputDate from "../../../components/form/InputDate";
    import util from "../../../../plugins/util";

    export default {
        components: {
            InputEntity,
            InputText,
            InputDate,
        },
        computed: {
            getExportTypes: function() {
                return this.$store.getters.applicationSettings.model.portal.exportTypes;
            }
        },
        mounted() {
            if (this.$route.params.id === 'create') {
                this.portal = {
                    locations: []
                };
                this.portalErrors = {};
            } else {
                this.loadPortal(this.$route.params.id);
            }
        },
        methods: {
            updatePortal() {
                this.$sendToApi('portals', this.portal, (entity, statusCode, data) => {
                    if(statusCode > 299) {
                        this.portalErrors = util.mapConstraints(data);
                    } else {
                        this.$router.push('/tools/portals/' + entity.id);
                        this.loadPortal(entity.id);
                    }
                });
            },
            loadPortal(id) {
                this.$getFromApi('portals', id, (entity) => {
                    this.portal = entity;
                    this.portalErrors = {};
                    this.loadCourseDataChanges(id);
                })
            },
            loadCourseDataChanges(id) {
                this.$http.get('portals/' + id + '/courseDataChanges', {params: {sort: this.sort}}).then(response => {
                    this.courseDataChanges = response.body;
                });
            }
        },
        watch: {
            sort: function() {
                this.loadCourseDataChanges(this.portal.id);
            }
        },
        data() {
            return {
                portal: {},
                portalErrors: {},
                courseDataChanges: {},
                sort: null,
            }
        }
    }
</script>
