<template>
    <div class="relative">
        <template v-if="showAppearanceDetails">
            <c-modal
                headline="Anwesenheit eintragen"
                ref="appearanceModal"
            >
                <form
                    v-on:submit.prevent="submitAppearance"
                    class="py-12 px-4 grid gap-4"
                >
                    <appearance v-model="editedAppearance" :errors="editedAppearanceErrors"/>

                    <div class="text-center">
                        <button type="submit" class="button mb-0">
                            Eintragen
                        </button>
                    </div>
                </form>
            </c-modal>
        </template>

        <form
            v-on:submit.prevent="searchCourse"
            v-on:reset.prevent="resetSearchCourse"
            class="grid grid-cols-2 items-center gap-4 bg-white p-4"
        >
            <div>
                <input-simple-select
                    label="Zeitform"
                    v-model="filter.timeDirection"
                    :options="{'past': 'Vergangenheit', 'future': 'Zukunft'}"
                />
            </div>
            <div>
                <input-simple-select
                    label="Kurstyp"
                    v-model="filter.type"
                    :options="{'guest': 'Gasthörerschaften', 'booking': 'Standard', 'checklist': 'Persönliche Termine'}"
                />
            </div>
            <div class="col-span-2">
                <input-date-range
                    :asRow="true"
                    label="Zeitraum"
                    v-model="filter.period"
                />
            </div>

            <div>
                <input-text
                    label="Kurskürzel"
                    v-model="filter.course"
                />
            </div>

            <div>
                <label>
                    Rechnung / Ausbildung
                    <br>
                    <select v-model="filter.touchBooking">
                        <option :value="null">-</option>
                        <option
                            v-for="touchBooking in touchBookings"
                            :value="touchBooking['@id']"
                        >
                            {{ touchBooking.communicatedEducationTitle }} ({{ touchBooking.invoiceNumber }})
                        </option>
                    </select>
                </label>
            </div>

            <div class="col-span-2 text-center">
                <button type="reset" class="button thirdly">
                    Zurücksetzen
                </button>
                <button type="submit" class="button">
                    Filtern
                </button>
            </div>
        </form>

        <div
            v-if="loadingIndex > 0"
            class="text-center p-8 bg-gray-100"
        >
            Es wird geladen.
        </div>

        <template v-else>
            <table>
                <thead>
                <tr>
                    <th>Kurs</th>
                    <th>Datum</th>
                    <th>Buchung</th>
                    <th v-if="showCurriculumTopic">
                        Thema
                    </th>
                    <th>
                        Dozent
                    </th>
                    <th>Status</th>
                    <th v-if="showAppearanceDetails">
                        Längere Abwesenheit
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="event in timetable.events">
                    <td>
                        <router-link
                            v-if="event.course"
                            :to="'/courses/' + event.course.id"
                        >
                            <strong>
                                {{ event.code }}
                            </strong>
                        </router-link>
                        <strong
                            v-else-if="event.code"
                        >
                            {{ event.code }}
                        </strong>
                        <strong v-else-if="event.bookingChecklistItem">
                            {{ event.bookingChecklistItem.name }}
                        </strong>
                        <br>
                        <template v-if="event.timetableType === 'booking'">Eingebuchter<br>Kurs</template>
                        <template v-else-if="event.timetableType === 'guest'">Gasthörer</template>
                        <template v-if="event.online"><br>Online</template>
                    </td>
                    <td>
                        {{ event.date|formatDate('LL') }}
                        <template v-if="event.hasOwnProperty('end')">
                            <br>
                            {{event.date|formatDate('HH:mm')}} - {{event.end|formatDate('HH:mm')}}
                        </template>
                        <template v-if="event.room && showRoom">
                            <br>
                            {{ event.room.name }}
                        </template>
                    </td>
                    <td>
                        <template v-if="eventCourseDateTouchBooking.hasOwnProperty(event.code)">
                            {{ eventCourseDateTouchBooking[event.code].invoiceNumber }}
                            <br>
                            {{ eventCourseDateTouchBooking[event.code].communicatedEducationTitle }}
                        </template>
                        <template v-if="event.hasOwnProperty('touchBooking')">
                            {{ event.touchBooking.invoiceNumber }}
                            <br>
                            {{ event.touchBooking.communicatedEducationTitle }}
                        </template>
                    </td>
                    <td v-if="showCurriculumTopic">
                        <template v-if="event.curriculumTopic">
                            {{ event.curriculumTopic.name }}
                        </template>
                    </td>
                    <td>
                        <template v-if="event.docent">
                            {{ event.docent.displayName }}
                        </template>
                    </td>
                    <td v-if="showAppearanceDetails">
                        <template v-if="appearancesMappedByEventCourseDate.hasOwnProperty(event['@id'])">
                            {{
                                appearancesMappedByEventCourseDate[event['@id']].status|dataTrans('model.appearance.status')
                            }}
                            <template v-if="appearancesMappedByEventCourseDate[event['@id']].status === 'AV'">
                                <br>
                                {{ appearancesMappedByEventCourseDate[event['@id']].delay }} Stunden
                            </template>
                            <template v-if="appearancesMappedByEventCourseDate[event['@id']].status === 'E'">
                                <br>
                                {{
                                    appearancesMappedByEventCourseDate[event['@id']].reason|dataTrans('model.appearance.reason')
                                }}
                            </template>
                        </template>
                        <template v-else>
                            <a
                                v-if="eventCourseDateTouchBooking.hasOwnProperty(event.code)"
                                @click="openAppearanceModal(event)"
                                class="action --plus"
                            >
                            </a>
                        </template>
                    </td>
                    <td v-else>
                        {{ event.appearanceStatus|dataTrans('model.appearance.status') }}
                    </td>
                    <td v-if="showAppearanceDetails">
                        <template v-if="hasAbsence(event)">
                            {{ hasAbsence(event).status|dataTrans('model.appearance.status') }}
                        </template>
                    </td>
                </tr>
                </tbody>
            </table>

            <div class="text-center">
                <a class="button" @click="download">
                    Download als CSV
                </a>
            </div>
        </template>
    </div>
</template>

<script>
    import moment from "moment";
    import Appearance from "../components/form/Appearance.vue";
    import util from "../../plugins/util";
    import InputSimpleSelect from "@/components/form/InputSimpleSelect.vue";
    import InputText from "@/components/form/InputText.vue";
    import InputDateRange from "@/components/form/InputDateRange.vue";

    export default {
        components: {Appearance, InputSimpleSelect, InputText, InputDateRange},
        props: {
            person: {},
            touchBookings: {
                required: false,
                default: () => []
            },
            showAppearanceDetails: {
                required: false,
                default: () => false
            },
            showRoom: {
                required: false,
                default: () => false
            },
            showCurriculumTopic: {
                required: false,
                default: () => false
            },
            auth: {
                required: false,
                default: () => false
            }
        },
        data() {
            return {
                courseCode: null,
                editedAppearance: {},
                editedAppearanceErrors: {},
                appearances: [],
                absences: [],
                timetable: null,
                filter: {},
                loadingIndex: 0,
            }
        },
        computed: {
            appearancesMappedByEventCourseDate: function () {
                if (!this.appearances || this.appearances.length === 0) {
                    return {};
                }
                return this.appearances.reduce((acc, cur) => {
                    if (cur.eventCourseDate && cur.eventCourseDate.hasOwnProperty('@id')) {
                        acc[cur.eventCourseDate['@id']] = cur;
                    }
                    return acc;
                }, {});
            },
            eventCourseDateTouchBooking: function () {
                return this.timetable.touchBookingCourseCodes;
            }
        },
        mounted() {
            this.loadTimetable();

            if (this.showAppearanceDetails) {
                this.loadAppearances();
                this.loadAbsences();
            }
        },
        watch: {
            '$route': function () {
                this.loadTimetable();

                if (this.showAppearanceDetails) {
                    this.loadAppearances();
                    this.loadAbsences();
                }
            }
        },
        methods: {
            async searchCourse() {
                this.timetable = {};
                await this.loadTimetable();
            },
            async resetSearchCourse() {
                this.$set(this, 'filter', {});
                await this.loadTimetable();
            },
            submitAppearance() {
                this.editedAppearanceErrors = {};
                this.$sendToApi('appearances', this.editedAppearance, (entity, status, data) => {
                    if (status < 300) {
                        this.editedAppearance = {};
                        this.$refs.appearanceModal.close();
                        this.appearances[entity.eventCourseDate['@id']] = entity;
                    } else {
                        this.editedAppearanceErrors = util.mapConstraints(data);
                    }
                });
            },
            openAppearanceModal(eventCourseDate) {
                this.editedAppearanceErrors = {};
                this.editedAppearance = {
                    touchBooking: this.eventCourseDateTouchBooking[eventCourseDate.code]['@id'],
                    person: this.person['@id'],
                    eventCourseDate: eventCourseDate['@id'],
                }
                this.$refs.appearanceModal.open();
            },
            hasAbsence(eventCourseDate) {
                let absences = this.absences.filter((item) => {
                    return moment(eventCourseDate.date).isSameOrAfter(moment(item.start))
                        && moment(eventCourseDate.date).isSameOrBefore(moment(item.end).add(1, 'day'));
                });
                if (absences.length > 0) {
                    return absences[0];
                }
                return null;
            },
            async loadAbsences() {
                this.absences = await this.$getAllFromApi(
                    'absences',
                    {
                        person: this.person['@id'],
                    }
                )
            },
            async loadAppearances() {
                this.appearances = await this.$getAllFromApi(
                    'appearances',
                    {
                        person: this.person['@id'],
                        type: 'course'
                    }
                );
                console.log(this.appearances);
                this.$forceUpdate();
            },
            download() {
                let csv = this.timetable.events.map(row => [row.date, row.code, row.room ? row.room.name : '', '']);

                csv = csv.sort(function (a, b) {
                    return new Date(a[0]) - new Date(b[0]);
                });

                csv = csv.map((item) => {
                    item[0] = this.$options.filters.formatDate(item[0], 'LL');
                    return item;
                });

                csv.unshift(['Datum', 'Kurs', 'Raum', 'Gasthörer']);
                this.$downloadCsv(csv, `Stundenplan-${this.person.lastName}.csv`);
            },
            async loadTimetable() {
                this.loadingIndex++;
                let headers = {};
                if (this.auth) {
                    headers = {
                        zip: this.auth.zip,
                        email: this.auth.email,
                        customerNumber: this.auth.customerNumber,
                    }
                };

                let timetable = await this.$http.get(
                    window.baseApiUrl + '/timetables/' + this.person.id,
                    {
                        params: this.filter,
                        headers: headers
                    }).then((res) => res.data);

                this.$set(this, 'timetable', timetable);
                this.loadingIndex--;
                this.$forceUpdate();
            }
        }
    }
</script>
