<template>
    <div class="p-finance-analysis">
        <content-header headline="Auswertungen"/>
        <content-main>
            <div class="">
                <h2 class="h-largeMargin-bottom">Summe offene Beträge von Verträgen Stand heute</h2>

                <div
                    v-if="analyses.hasOwnProperty('cashFlow')"
                    v-for="(data, location) in analyses.cashFlow"
                    class="h-largeMargin-bottom"
                >
                    <h3 class="h-margin-bottom">{{ location }}</h3>
                    <table>
                        <tbody>
                        <template v-for="(datum, year) in data">
                            <tr>
                                <th>geflossen im Jahr {{ year }} bis {{currentData}}</th>
                                <th>fallig im Jahr {{ year }} bis {{ currentData }}</th>
                                <th>fallig im Jahr {{ year }} nach {{ currentData }}</th>
                                <th>fallig im Jahr {{ year }} (gesamt)</th>
                                <th>fallig im Jahr {{ parseInt(year) + 1 }}</th>
                                <th>fallig im Jahr {{ parseInt(year) + 2 }}</th>
                            </tr>
                            <tr>
                                <td
                                    class="text-right"
                                    v-for="key in ['earningsUntilTodayDate', 'expectedUntilTodayDate', 'expectedLeftThisYear', 'expectedThisYear', 'expectedNextYear', 'expectedYearAfterNext']"
                                    :style="{
                                    'background': year === currentYear ? '#ececec' : 'transparent',
                                    'color': year === currentYear && !['expectedNextYear', 'expectedYearAfterNext'].includes(key) ? (data[currentYear][key] > data[currentYear -1][key] ? 'green' : 'red') : 'black',
                                }"
                                >
                                    <i
                                        v-if="year === currentYear && !['expectedNextYear', 'expectedYearAfterNext'].includes(key)"
                                        class="ti"
                                        :class="{
                                        'ti-arrow-up': data[currentYear][key] > data[currentYear -1][key],
                                        'ti-arrow-down': data[currentYear][key] < data[currentYear -1][key]
                                    }"
                                    ></i>
                                    <span v-if="year === currentYear"
                                          style="margin-right: 2rem; display: inline-block;"
                                    >
                                    {{ Math.round((1 - (data[currentYear][key] / data[currentYear -1][key])) * -1000)/10 }}%
                                </span>

                                    {{ datum[key]|price }}
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>

                <h2 class="h-largeMargin-bottom">Volumina nach Datum des Ausbildungsbeginns</h2>

                <div
                    v-if="analyses.hasOwnProperty('volumina')"
                    v-for="(data, location) in analyses.volumina"
                    class="h-largeMargin-bottom"
                >
                    <h3 class="h-margin-bottom">{{ location }}</h3>
                    <table>
                        <thead>
                        <tr>
                            <th>Volumina</th>
                            <th
                                v-for="volumina, label in data[Object.keys(data)[0]]"
                            >
                                {{ label }}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="datum, label in data">
                            <th>{{ label }}</th>
                            <td v-for="value in datum">{{ value }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <h2 class="h-largeMargin-bottom">Insgesamt geflossen</h2>

            <div style="display: grid; grid-auto-flow: column; grid-gap: 0.5rem;">
                <div
                    v-if="analyses.hasOwnProperty('earnings')"
                    v-for="(data, location) in analyses.earnings"
                    class="h-largeMargin-bottom"
                >
                    <h3 class="h-margin-bottom">{{ location }}</h3>
                    <table>
                        <thead>
                        <tr>
                            <th>Jahr</th>
                            <th>Geflossen</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="value, year in data">
                            <td>{{ year }}</td>
                            <td>{{ value|price }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <template
                v-for="label, key in {'aquisitionCurrentYear': 'Akquisition nach Monat (Einnahmen laufendes Jahr)', 'aquisition': 'Akquisition'}">
                <h2 class="h-largeMargin-bottom">{{ label }}</h2>

                <div style="display: grid; grid-auto-flow: column; grid-gap: 0.5rem;">
                    <div
                        v-if="analyses.hasOwnProperty(key)"
                        v-for="(data, location) in analyses[key]"
                        class="h-largeMargin-bottom"
                    >
                        <h3 class="h-margin-bottom">{{ location }}</h3>
                        <table>
                            <thead>
                            <tr>
                                <th>Monat</th>
                                <th v-if="key === 'aquisitionCurrentYear'">Aquirierter Umsatz (Laufendes Jahr)</th>
                                <th v-else>Aquirierter Umsatz</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="value, year in data">
                                <td>{{ year }}</td>
                                <td>{{ value|price }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
        </content-main>
    </div>
</template>

<script>
    import moment from "moment";

    export default {
        data() {
            return {
                analyses: {}
            }
        },
        mounted() {
            this.loadAnalyses();
        },
        computed: {
            currentData: function () {
                return moment().format('DD.MM.');
            },
            currentYear: function () {
                return moment().format('YYYY');
            }
        },
        methods: {
            loadAnalyses: function () {
                let _this = this;
                ['cashFlow', 'earnings', 'volumina', 'aquisition', 'aquisitionCurrentYear'].forEach((item) => {
                    this.$http.get('analyses/' + item).then((response) => {
                        this.analyses[item] = response.body.data;
                        this.$forceUpdate();
                    })
                })
            }
        }
    }
</script>
