<template>
    <div>
        <div
            v-if="person && educationComponentsAnalyses.length > 0"
            class="bg-white p-4 grid gap-6 mb-8"
        >
            <c-modal
                ref="appearanceAnalyseModal"
                headline="Anwesenheitsanalyse"
                :width="1200"
            >
                <div class="p-4">
                    <table>
                        <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Typ</th>
                            <th>Status</th>
                            <th>Kommentare</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="appearanceAnalysis in appearanceAnalyses">
                            <td>
                                {{ appearanceAnalysis.appearance.date|formatDate('LL') }}
                            </td>
                            <td>
                                {{ appearanceAnalysis.appearance.type|dataTrans('model.appearance.type') }}
                                <template v-if="appearanceAnalysis.appearance.type === 'course'">
                                    <br>
                                    {{ appearanceAnalysis.appearance.eventCourseDate.code }}
                                    <br>
                                    {{ appearanceAnalysis.appearance.eventCourseDate.educationName }}
                                </template>
                            </td>
                            <td>
                                {{ appearanceAnalysis.appearance.status }}
                                <br>
                                {{ appearanceAnalysis.appearance.status }}
                            </td>
                            <td>
                                <note-list
                                    v-if="appearanceAnalysis.notes"
                                    :notes="appearanceAnalysis.notes"
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </c-modal>

            <div v-for="educationComponentsAnalysis in educationComponentsAnalyses"
                 class="grid gap-8"
                 :key="educationComponentsAnalysis.touchBooking.invoiceNumber"
            >
                <div class="grid gap-2">
                    <div class="grid grid-flow-col justify-between">
                        <div class="uppercase font-bold text-xl">
                            Anwesenheit
                        </div>

                        <a
                            class="underline hover:no-underline"
                            @click="showAppearanceAnalyseModal(educationComponentsAnalysis.touchBooking)"
                        >
                            Details
                        </a>
                    </div>

                    <div
                        class="grid grid-cols-4 gap-8 items-center"
                    >
                        <div>
                            <strong>{{ educationComponentsAnalysis.touchBooking.invoiceNumber }}</strong>
                            <template v-if="educationComponentsAnalysis.touchBooking.communicatedEducationTitle">
                                <br>
                                {{ educationComponentsAnalysis.touchBooking.communicatedEducationTitle }}
                            </template>
                        </div>
                        <div>
                            <strong>Soll</strong>
                        </div>
                        <div>
                            <strong>Ist</strong>
                        </div>
                        <div>
                            <strong>noch offen</strong>
                        </div>
                    </div>
                    <div
                        class="grid grid-cols-4 gap-8 items-center"
                        v-for="(label, type) in {
                            'durationUnitsGroup': 'Kleingruppe (UE)',
                            'durationUnitsPresence': 'Selbstlernzeiten (UE)',
                            'durationUnitsProject': 'Projekttage (UE)',
                            'durationUnitsFinalThesis': 'Abschlussarbeit (UE)',
                            'durationUnitsPracticeDocumentation': 'Praxisdokumenation (UE)',
                            'daysMissingUnexcused': 'Unentschuldigtes Fehlen (Kurstermin)',
                            'daysMissing': 'Abwesenheit (Kurstermin)',
                        }"
                    >
                        <div>{{ label }}</div>
                        <div>
                            <input
                                v-if="type !== 'daysMissingUnexcused' && type !== 'daysMissing'"
                                type="text"
                                class="mb-0"
                                @click="$router.push('/touchComplex?edit=booking&touchId=' + educationComponentsAnalysis.touchBooking.id)"
                                :value="educationComponentsAnalysis.touchBooking[type]"
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                class="mb-0"
                                :readonly="true"
                                :value="educationComponentsAnalysis[type]"
                            />
                        </div>
                        <div>
                            <input
                                v-if="type !== 'daysMissingUnexcused' && type !== 'daysMissing'"
                                type="text"
                                class="mb-0"
                                :readonly="true"
                                :value="educationComponentsAnalysis.touchBooking[type] - educationComponentsAnalysis[type]"
                            />
                        </div>
                    </div>
                </div>

                <booking-checklist-table
                    v-if="educationComponentsAnalysis.bookingChecklist"
                    :bookingChecklist="educationComponentsAnalysis.bookingChecklist"
                />
            </div>
        </div>

        <update-modal
            ref="appearanceUpdateModal"
            :fixed-person="person"
            v-on:update="reloadData"
        />

        <note-modal ref="noteModal" :show-create="false"/>

        <data-table
            ref="appearanceTable"
            endpoint="appearances"
            :fixed-query="fixedQuery"
            :add-query-to-url="false"
            :columns="columns"
            :show-filter-tags="true"
            :filters="filters"
        >
            <template slot="nextToPagination">
                <div class="columns">
                    <a class="button small mb-0"
                       @click="$refs.appearanceUpdateModal.open()"
                    >
                        Präsenz erfassen
                    </a>
                </div>
            </template>

            <template slot="dataRow" slot-scope="{singleData: appearance}">
                <td>
                    <template v-if="appearance.eventCourseDate">
                        {{ appearance.eventCourseDate.date|formatDate('dddd') }}
                        <br>
                        {{ appearance.eventCourseDate.date|formatDate('LL') }}
                    </template>
                    <template v-else>
                        {{ appearance.date|formatDate('dddd') }}
                        <br>
                        {{ appearance.date|formatDate('LL') }}
                    </template>
                </td>
                <td v-if="person">
                    <template v-if="appearance.accepted">
                        Ja
                    </template>
                    <template v-else-if="appearance.accepted === false">
                        Nein
                        <br>
                        <i>
                            {{ appearance.notAcceptedReason|dataTrans('model.appearance.notAcceptedReason') }}
                        </i>
                    </template>
                    <template v-else>
                        Ausstehend
                    </template>
                </td>
                <td v-if="person || eventCourseDate">
                    <router-link
                        :to="'/courses/' + appearance.course.id"
                        class="whitespace-nowrap"
                        v-if="appearance.eventCourseDate"
                    >
                        {{ appearance.eventCourseDate.code }}
                    </router-link>
                    <template v-else>
                        {{ appearance.type|dataTrans('model.appearance.type') }}
                        <br>
                        {{ appearance.durationUnits }} UE
                        <template v-if="appearance.touchBooking">
                            <br>
                            Rechnung: {{ appearance.touchBooking.invoiceNumber }}
                        </template>
                    </template>
                </td>
                <td v-if="course || eventCourseDate">
                    <router-link :to="'/persons/' + appearance.person.id">
                        {{ appearance.person.displayName }}
                    </router-link>
                </td>
                <td>
                    {{ appearance.status|dataTrans('model.appearance.status') }}
                    <template v-if="appearance.status === 'AV'">
                        <br>
                        {{ appearance.delay }} Stunden
                    </template>
                    <template v-if="appearance.status === 'E'">
                        <br>
                        {{ appearance.reason|dataTrans('model.appearance.reason') }}
                    </template>
                    <template v-if="appearance.absence">
                        <br>
                        (Aus Abwesenheit generiert)
                    </template>
                </td>
                <td>
                    <a @click="$refs.noteModal.open(appearance['@id'])">
                        Kommentare
                    </a>
                </td>
                <td>
                    <a
                        v-if="appearance.userCanDelete"
                        @click="deleteAppearance(appearance)"
                        class="action --delete"
                    >
                    </a>
                </td>
            </template>
        </data-table>
    </div>
</template>

<script>
    import BookingChecklistTable from "../BookingChecklist/Table.vue";
    import DataTable from "../../components/DataTable";
    import UpdateModal from "./UpdateModal.vue";
    import NoteModal from "@/data/NoteModal.vue";
    import Notes from "@/data/Notes.vue";
    import NoteList from "@/data/Note/List.vue";

    export default {
        data() {
            return {
                educationComponentsAnalyses: [],
                appearanceAnalyses: [],
            }
        },
        components: {
            NoteList,
            Notes,
            NoteModal,
            UpdateModal,
            DataTable,
            BookingChecklistTable
        },
        props: {
            person: {
                default: null,
            },
            course: {
                default: null,
            },
            eventCourseDate: {
                default: null,
            }
        },
        mounted() {
            if (this.person) {
                this.loadEducationComponentsAnalyses();
            }
        },
        methods: {
            showAppearanceAnalyseModal(touchBooking) {
                this.$getListFromApi('appearance_analyses', {touchBooking: touchBooking['@id']}, (data) => {
                    this.appearanceAnalyses = data;
                    this.$refs.appearanceAnalyseModal.show();
                })
            },
            reloadData() {
                if (this.$refs.appearanceTable) {
                    this.$refs.appearanceTable.reload();
                }
                if (this.person) {
                    this.loadEducationComponentsAnalyses();
                }
            },
            loadEducationComponentsAnalyses() {
                this.$getListFromApi('education_components_analyses', {person: this.person['@id']}, (data) => {
                    this.educationComponentsAnalyses = data;
                })
            },
            deleteAppearance(appearance) {
                let _this = this;
                this.$dialog
                    .confirm('Anwesenheit wirklich entfernen?',
                        {
                            okText: 'Bestätigen',
                            cancelText: 'Abbrechen',
                        })
                    .then(function (dialog) {
                        _this.$deleteFromApi('appearances', appearance, () => {
                            _this.$refs.appearanceTable.reload();
                        });
                    })
                    .catch(function () {
                    });

            },
        },
        computed: {
            filters() {
                let items = this.$store.getters.applicationSettings.model.appearance.type;
                return [
                    {
                        type: 'dateRange',
                        model: 'date',
                        label: 'Datum'
                    },
                    {
                        label: 'Typ',
                        type: 'select',
                        model: 'type',
                        options: items,
                        callback: function (item) {
                            return items[item];
                        }
                    }
                ]
            },
            columns: function () {
                if (this.person) {
                    return {
                        'date': {
                            text: 'Datum',
                            order: true,
                        },
                        'accepted': {
                            text: 'Akzp.',
                        },
                        'eventCourseDate.course.code': {
                            text: 'Typ',
                            order: true,
                        },
                        status: {
                            text: 'Status',
                        },
                        comment: {
                            text: 'Kommentar'
                        }
                    }
                }
                if (this.course) {
                    return {
                        'eventCourseDate.date': {
                            text: 'Datum',
                            order: true,
                        },
                        person: {
                            text: 'Person',
                        },
                        status: {
                            text: 'Status',
                        }
                    }
                }
                if (this.eventCourseDate) {
                    return {
                        'eventCourseDate.date': {
                            text: 'Datum',
                            order: true,
                        },
                        course: {
                            text: 'Kurs',
                        },
                        person: {
                            text: 'Person',
                        },
                        status: {
                            text: 'Status',
                        }
                    }
                }

                return {};
            },
            fixedQuery: function () {
                if (this.person) {
                    return {
                        person: this.person['@id']
                    }
                }
                if (this.course) {
                    return {
                        'eventCourseDate.course': this.course['@id'],
                    }
                }
                if (this.eventCourseDate) {
                    return {
                        'eventCourseDate': this.eventCourseDate['@id']
                    }
                }

                return {};
            }
        }
    }
</script>
