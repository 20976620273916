<template>
	<label>
		Anrede
		<select v-model="value">
			<option :value="null"></option>
			<option value="m">Herr</option>
			<option value="w">Frau</option>
			<option value="d">Diverse</option>
		</select>
	</label>
</template>

<script>
    export default {
        props: {
            value: {}
        },
        watch: {
            value: function(newVal) {
                this.$emit('input', newVal);
            }
        }
    }
</script>
