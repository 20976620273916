<template>
	<modal
			:name="name"
			:adaptive="true"
			height="auto"
            :width="width"
	>
		<template v-if="headline">
			<div class="modal__absoluteClose" @click="hide();">
			</div>
			<div class="modal__header px-4">
				<div class="modal__headline">
					{{ headline }}
				</div>
			</div>
		</template>

		<slot></slot>
	</modal>
</template>

<script>
    export default {
        name: 'MyComponent',
		data() {
			return {
				name: ('modal-' + Math.round(Math.random() * 100000))
			}
		},
        props: {
            headline: {
                default: false,
            },
            width: {
                default: 600
            }
        },
        methods: {
            show() {
                this.$modal.show(this.name, {}, {height: 'auto'});
            },
            hide() {
                this.$modal.hide(this.name);
            },
			open() {
				this.show();
			},
			close() {
				this.hide();
			}
        },
        mount() {
            this.show();
        }
    }
</script>
