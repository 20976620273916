<template>
    <div>
        <div class="row h-largeMargin-bottom">
            <div class="large-12 columns">
                <h2 class="h-margin-bottom">Allgemeine Daten</h2>
            </div>
            <div class="large-3 columns">
                <input-simple-select
                    v-model="value.touchStatus"
                    :errors="errors.touchStatus"
                    label="Status Gespräch"
                    :options="touchStati"
                />
            </div>
            <div class="large-3 columns">
                <input-simple-select
                    v-model="value.touchLocationType"
                    :errors="errors.touchLocationType"
                    label="Art des Gesprächs"
                    :options="touchLocationTypes"
                />
            </div>
            <div class="large-3 columns">
                <input-date
                    label="Datum des Beratungsgesprächs"
                    v-model="value.date"
                    :errors="errors.date"
                    :time="true"
                />
            </div>
        </div>

        <div class="row h-largeMargin-bottom">
            <div class="large-12 columns">
                <h2 class="h-margin-bottom">Interessen</h2>
            </div>

            <div class="large-3 columns">
                <input-entity
                    type="education"
                    label="Ausbildungswunsch (Primär)"
                    v-model="value.primaryEducation"
                    :errors="errors.primaryEducation"
                    key="education"
                />
            </div>

            <div class="large-3 columns">
                <input-entity
                    type="education"
                    label="Ausbildungswunsch (Sekundär)"
                    v-model="value.secondaryEducation"
                    :errors="errors.secondaryEducation"
                    key="education"
                />
            </div>

            <div class="large-3 columns">
                <input-simple-select
                    v-model="value.locationType"
                    :errors="errors.locationTypes"
                    label="Wunschausbildungsart"
                    :options="courseLocationTypes"
                />
            </div>

            <div class="columns small-3">
                <label>
                    Seminarinteresse
                    <br>
                    <input type="checkbox" v-model="value.seminarInterest"/>
                </label>
            </div>

            <div class="large-4 columns">
                <input-entity
                    type="education"
                    label="Möglicher Einstieg"
                    v-model="value.possibleGroup"
                    :errors="errors.possibleGroup"
                    key="education"
                />
            </div>

            <div class="large-4 columns">
                <quarter-select
                    v-model="value.possibleStart"
                    label="Möglicher Start"
                    :errors="errors.possibleStart"
                />
            </div>
            <div class="large-4 columns">
                <input-entity
                    type="location"
                    label="Wunschstandort"
                    v-model="value.location"
                    :errors="errors.location"
                    key="location"
                />
            </div>
        </div>

        <div class="row h-largeMargin-bottom">
            <div class="large-12 columns">
                <h2 class="h-margin-bottom">Vorkenntnisse</h2>
            </div>

            <div class="large-6 columns">
                <input-rte
                    label="Berufliche Vorbildung (für Förderung)"
                    v-model="value.professionalPreparatoryEducation"
                    :errors="errors.professionalPreparatoryEducation"
                />
            </div>

            <div class="large-6 columns">
                <input-rte
                    label="Vorkenntnisse im Bereich der gewünschten Ausbildung (für Förderung)"
                    v-model="value.professionalPreparatory"
                    :errors="errors.professionalPreparatory"
                />
            </div>
        </div>

        <div class="row h-largeMargin-bottom">
            <div class="large-12 columns">
                <h2 class="h-margin-bottom">Das Gespräch</h2>

                <current-situation v-model="value" :errors="errors" :show-payment="true"/>
            </div>
        </div>

        <div>
            <div class="row">
                <div class="large-12 columns">
                    <h2>Informationen</h2>
                </div>
            </div>

            <marketing v-model="value" :errors="errors"/>

            <quick-actions-and-invitation v-model="value" :errors="errors"/>

            <follow-up v-model="value" :errors="errors"/>
        </div>
    </div>
</template>

<script>
    import value from "../../../mixins/value";
    import InputRte from "../../components/form/InputRte";
    import CurrentSituation from "./parts/CurrentSituation";
    import QuickActionsAndInvitation from "./parts/QuickActionsAndInvitation";
    import FollowUp from "./parts/FollowUp";
    import Marketing from "./parts/Marketing";
    import QuarterSelect from "../../components/form/QuarterSelect";

    export default {
        components: {
            QuarterSelect, Marketing, FollowUp, QuickActionsAndInvitation, CurrentSituation, InputRte
        },
        mixins: [
            value
        ],
        data() {
            return {
                support: {
                    'check': 'Bildungscheck',
                    'voucher': 'Bildungsgutschein',
                    'own': 'Selbstzahler'
                }
            }
        },
        computed: {
            touchStati: function () {
                return this.$store.getters.applicationSettings.model.touchConsultation.touchStati;
            },
            courseLocationTypes: function () {
                return this.$store.getters.applicationSettings.model.courseLocationTypes;
            },
            touchLocationTypes: function() {
                return this.$store.getters.applicationSettings.model.consultationLocationTypes;
            }
        }
    }
</script>
