<template>
    <div>
        <reveal headline="Veranstaltung hinzufügen" ref="eventAddReveal">
            <div class="row" v-if="eventType === null">
                <div class="column large-12 h-margin-bottom">
                    <h3>Art der Veranstaltung</h3>
                </div>
                <div class="column">
                    <a class="button thirdly expanded" @click="eventType = 'EventOpenDoorDay'">Tag der offenen Tür</a>
                </div>
                <div class="column">
                    <a class="button thirdly expanded" @click="eventType = 'EventTrialCourse'">Schnupper-Workshop</a>
                </div>
            </div>

            <form v-on:submit.prevent="create" v-if="eventType !== null">
                <event-form v-model="event" :errors="errors"/>

                <div class="text-center">
                    <button type="submit" class="button">Erstellen</button>
                </div>
            </form>
        </reveal>
        <content-header headline="Veranstaltungen">
            <a
                class="button"
                @click="openEvent"
            >
                Veranstaltung hinzufügen
            </a>
        </content-header>
        <content-main>
            <data-table
                ref="dataTable"
                endpoint="events"
                :preSetQuery="{'clonedType': 'trialCourse', 'future': '1', 'order': {'date': 'ASC'}}"
                :columns="columns"
                :filters="filters">
                <template slot="dataRow" slot-scope="{singleData: event}">
                    <td>
                        <template v-if="event.duration !== null">
                            {{ event.date|formatDate('LLL') }}<br>
                            bis {{ event.end|formatDate('LLL') }}
                        </template>
                        <template v-else>
                            {{ event.date|formatDate('LL') }}
                        </template>
                    </td>
                    <td>
                        <template v-if="event['@type'] === 'EventCourseDate' && event.code">
                            {{ event.name }} {{ event.code }}<br>{{ event.educationName }}<br>{{ event.locationName }}
                        </template>
                        <template v-else>
                            {{ event.name }}
                        </template>
                        <template v-if="event.canceled">
                            (abgesagt)
                        </template>
                    </td>
                    <td>
                        {{ event.locationType|tEventLocationType }}
                        <template v-if="event.location"><br>{{ event.location.name }}</template>
                    </td>
                    <td>
                        <router-link
                            v-if="event.docent"
                            :to="'/erp/docents/' + event.docent.id"
                        >
                            {{ event.docent.displayName }}
                        </router-link>
                    </td>
                    <td>
                        <template v-if="event['@type'] === 'EventTrialCourse'">
                            Teilgenommen: {{ event.visitCount }}
                            <br>
                            Anmeldungen: {{ event.registrationCount }}
                        </template>
                    </td>
                    <td>
                        {{ event.lastEditedAt|formatDate('LLL') }}
                    </td>
                    <td class="text-right">
                        <router-link v-if="event.course" class="action --show"
                                     :to="'/courses/' + event.course.id"></router-link>
                        <router-link class="action --show" :to="'/events/' + event.id" v-else></router-link>
                    </td>
                </template>
            </data-table>
        </content-main>
    </div>
</template>

<script>
    import DataTable from "../../components/DataTable";
    import EventForm from "../../data/Event/EventForm";
    import util from "../../../plugins/util";

    export default {
        components: {
            EventForm,
            DataTable
        },
        data() {
            return {
                event: {},
                errors: {},
                eventType: null,
                columns: {
                    date: {
                        text: 'Datum',
                        order: true
                    },
                    name: {
                        text: 'Name',
                        order: true
                    },
                    locationType: {
                        text: 'Standort',
                        order: false
                    },
                    docent: {
                        text: 'Dozent',
                        order: false,
                    },
                    data: {
                        text: 'Infos',
                        order: false
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    },
                }
            }
        },
        methods: {
            openEvent() {
                this.eventType = null;
                this.errors = {};
                this.event = {
                    duration: 1,
                    locationType: 'online'
                };
                this.$refs.eventAddReveal.open();
                this.$forceUpdate();
            },
            create() {
                let url = this.event['@type'] === 'EventTrialCourse' ? 'event_trial_courses' : 'event_open_door_days';
                this.$sendToApi(url, this.event, (entity, status, data) => {
                    if (status === 201) {
                        this.$refs.dataTable.reload();
                        this.$refs.eventAddReveal.close();
                    } else {
                        this.errors = util.mapConstraints(data);
                    }
                })
            }
        },
        watch: {
            eventType: function (newVal) {
                this.event['@type'] = newVal;
            }
        },
        computed: {
            filters() {
                let _this = this;

                let options = {
                    openDoorDay: 'Tag der offenen Tür',
                    trialCourse: 'Schnupper-Workshop',
                    eventCourseDate: 'Unterrichtstermin'
                };

                return [
                    {
                        type: 'bool',
                        model: 'future',
                        label: 'In der Zukunft'
                    },
                    {
                        type: 'text',
                        model: 'name',
                        label: 'Name'
                    },
                    {
                        type: 'dateRange',
                        model: 'date',
                        label: 'Zeitraum'
                    },
                    {
                        type: 'select',
                        model: 'clonedType',
                        label: 'Art',
                        options: options,
                        callback: function (value) {
                            return this.options[value];
                        }
                    }
                ];
            }
        }
    }
</script>
