<template>
    <label :class="{'--errors': hasErrors}">
        <template v-if="label">{{label}}</template>
        <multiselect
                v-model="value"
                :options="options"
                :multiple="multiple"
                :customLabel="getLabel"
                v-on:input="update"
                selectLabel="Enter Drücken zum Auswählen"
                selectGroupLabel="Enter Drücken um Gruppe auszuwählen"
                selectedLabel="Ausgewählt"
                deselectLabel="Enter Drücken zum Entfernen"
                deselectGroupLabel="Enter Drücken um Gruppe zu entfernen"
                placeholder="Eingeben und Auswählen"
                @select="close"
        >
            <template slot="singleLabel" slot-scope="{ option }">{{getLabel(option)}}</template>
            <template slot="option" slot-scope="{ option }">{{getLabel(option)}}</template>
        </multiselect>
        <template v-if="errors !== null && errors.length > 0">
            <ul class="form-errors">
                <li v-for="error in errors">
                    {{error}}
                </li>
            </ul>
        </template>
    </label>
</template>

<script>
    import Multiselect from 'vue-multiselect'

    export default {
        components: {Multiselect},
        data() {
            return {
                value: null
            }
        },
        props: {
            multiple: {
                type: Boolean,
                default: () => true
            },
            options: {
                type: Array,
                default: () => [],
                required: true,
            },
            labels: {
                type: Object,
                default: () => {},
                required: true,
            },
            value: {
                type: String | Number,
                default: () => {
                    return '';
                }
            },
            placeholder: {
                type: String,
                default: () => {
                    return '';
                }
            },
            label: {
                type: String,
                default: () => {
                    return '';
                }
            },
            readonly: {
                type: Boolean,
                default: () => {
                    return false;
                }
            },
            required: {
                type: Boolean,
                default: () => {
                    return false;
                }
            },
            errors: {
                type: Object | Array,
                default: function () {
                    return {};
                }
            },
        },
        computed: {
            hasErrors() {
                return this.errors !== null && this.errors.length > 0 && this.showErrors
            }
        },
        methods: {
            update(data) {
                this.errors = [];
                this.showErrors = false;
                if (!this.readonly) {
                    this.$emit('input', data);
                }
            },
            getLabel(option) {
                let object = this.labels.filter((item) => {
                    return item.id === option;
                })[0] || {};
                if(object !== {}) {
                    return object.label;
                }
            }
        },
        watch: {
            'errors': function() {
                this.showErrors = true;
            }
        }
    }
</script>
