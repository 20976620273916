<template>
    <div>
        <content-header headline="Import-Logs"/>
        <content-main>
            <data-table
                    endpoint="imports"
                    :columns="columns"
                    :filters="filters"
                    ref="dataTable"
                    updateRevealSize="large"
            >
                <template slot="dataRow" slot-scope="{singleData: importData}">
                    <td>
                        {{importData.file}}
                    </td>
                    <td>{{importData.status}}</td>
                    <td>{{importData.createdAt|formatDate('LLL')}}</td>
                    <td class="text-right">
                        <a @click="exportCsv(importData.id)" class="action --download"></a>
                    </td>
                </template>
            </data-table>
        </content-main>
    </div>
</template>

<script>
    import DataTable from "../../../components/DataTable";
    import InputSelect from "../../../components/form/InputSelect";
    import CredibilitySelect from "../../../components/form/CredibilitySelect";

    export default {
        components: {
            CredibilitySelect,
            InputSelect,
            DataTable
        },
        data() {
            return {
                filters: [],
                columns: {
                    file: {
                        text: 'Name',
                        order: false
                    },
                    status: {
                        text: 'Status'
                    },
                    createdAt: {
                        text: 'Datum',
                        order: true
                    },
                }
            }
        },
        methods: {
            exportCsv(id) {
                this.$http.post('exports/imports/' + id).then(response => {
                    window.location = response.body.url;
                });
            }
        }
    }
</script>
