<template>
    <div>
        <content-header headline="Feiertage/Ferientage">
            <a class="button" @click="$refs.dataTable.createData()">Feiertag/Ferientag hinzufügen</a>
        </content-header>
        <content-main>
            <data-table
                endpoint="holidays"
                :columns="columns"
                :filters="filters"
                ref="dataTable"
                updateRevealSize="large"
            >
                <template slot="updateForm" slot-scope="{data: holiday, errors: errors}">
                    <div class="row">
                        <div class="columns large-6">
                            <input-text
                                v-model="holiday.name"
                                label="Name *"
                            />
                        </div>
                        <div class="columns large-6">
                            <input-entity
                                type="location"
                                v-model="holiday.location"
                                label="Standort *"
                            />
                        </div>
                        <div class="columns large-6">
                            <input-date
                                v-model="holiday.date"
                                label="Tag od. Start *"
                            />
                        </div>
                        <div class="columns large-6" v-if="!holiday.hasOwnProperty('@id')">
                            <input-date
                                v-model="holiday.end"
                                label="Ende"
                            />
                        </div>
                    </div>
                </template>

                <template slot="dataRow" slot-scope="{singleData: holiday}">
                    <td>
                        {{holiday.name}}
                    </td>
                    <td>
                        {{holiday.date|formatDate('LL')}}
                    </td>
                    <td>
                        {{holiday.location.name}}
                    </td>
                    <td>
                        {{holiday.lastEditedAt|formatDate('LLL')}}
                    </td>
                    <td class="text-right">
                        <a v-if="holiday.userCanUpdate" class="action --edit" @click="$refs.dataTable.editData(holiday)"></a>
                        <a v-if="holiday.userCanDelete" class="action --delete" @click="$refs.dataTable.deleteData(holiday)"></a>
                    </td>
                </template>
            </data-table>
        </content-main>
    </div>
</template>

<script>
    import DataTable from "../../../components/DataTable";
    import InputSelect from "../../../components/form/InputSelect";

    export default {
        components: {
            InputSelect,
            DataTable
        },
        data() {
            return {
                filters: [
                    {
                        type: 'text',
                        model: 'name',
                        label: 'Name'
                    },
                    {
                        type: 'entity',
                        model: 'location',
                        entity: 'location',
                        label: 'Standort',
                        callback: function () {
                            return 'Ein Datensatz ausgewählt';
                        }
                    }
                ],
                columns: {
                    name: {
                        text: 'Name',
                        order: true,
                    },
                    date: {
                        text: 'Tag',
                        order: true,
                    },
                    'location.name': {
                        text: 'Standort',
                        order: true,
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    },
                }
            }
        }
    }
</script>
