<template>
    <content-main>
        <data-table
            endpoint="touch_consultations"
            :columns="columns"
            :filters="filters"
            ref="dataTable"
            updateRevealSize="small"
            state-key="touchesConsultations"
        >
            <template slot="dataRow" slot-scope="{singleData: touch}">
                <td>
                    {{ touch.date|formatDate('dddd') }}
                    <br>
                    {{ touch.date|formatDate('LLL') }}
                </td>
                <td>
                    <template v-if="touch.location">
                        {{touch.location.name}}
                    </template>
                </td>
                <td>
                    {{touch.createdByDisplayName}}
                </td>
                <td>
                    <status-light :status="touch.person.status"/>
                </td>
                <td>
                    {{touch.person.displayName}}
                </td>
                <td>
                    <template v-if="touch.primaryEducation">
                        {{touch.primaryEducation.name}}<br>
                    </template>
                    <template v-if="touch.secondaryEducation">
                        {{touch.secondaryEducation.name}}<br>
                    </template>
                    <template v-if="touch.seminarInterest">
                        An Seminar interessiert
                    </template>
                </td>
                <td>
                    <template v-if="touch.paymentTypeOwn">Selbstzahler</template>
                    <template v-if="touch.paymentTypeOwn && touch.paymentTypeVoucher"><br></template>
                    <template v-if="touch.paymentTypeVoucher">Bildungsgutschein</template>
                </td>
                <td>
                    <template v-if="touch.possibleGroup">
                        {{touch.possibleGroup.name}}
                    </template>
                    <template v-if="touch.possibleStart">
                        {{touch.possibleStart}}
                    </template>
                </td>
                <td>
                    <template v-if="touch.openDoorDayInvitation">Tag der offenen Tür<br></template>
                    <template v-if="touch.autumnOfColorsInvitation">Farbenherbst<br></template>
                    <template v-if="touch.healthDaysInvitation">Gesundheitstage<br></template>
                </td>
                <td>
                    {{touch.lastEditedAt|formatDate('LLL')}}
                    <br>
                    {{touch.lastEditedByDisplayName}}
                </td>
                <td class="text-right">
                    <router-link :to="'/persons/' + touch.person.id" class="action --show"/>
                </td>
            </template>
        </data-table>
    </content-main>
</template>

<script>
    import DataTable from "../../components/DataTable";
    import StatusLight from "../../data/Person/StatusLight";

    export default {
        components: {
            StatusLight,
            DataTable
        },
        props: {},
        methods: {},
        computed: {
            filters() {
                let _this = this;
                return [
                    {
                        type: 'dateRange',
                        model: 'date',
                        label: 'Zeitraum'
                    },
                    {
                        type: 'entity',
                        model: 'location',
                        label: 'Standort',
                        entity: 'location',
                        callback: function(value) {
                            return 'Ein Standort ausgewählt';
                        }
                    },
                    {
                        type: 'entity',
                        label: 'Kontaktperson',
                        model: 'createdBy',
                        entity: 'user_real',
                        callback: function(value) {
                            return 'Eine Person ausgewählt';
                        }
                    },
                    {
                        type: 'select',
                        options: _this.$store.getters.applicationSettings.model.person.stati,
                        label: 'Status',
                        model: 'person.status',
                        callback: function(value) {
                            if(value) {
                                return _this.$store.getters.applicationSettings.model.person.stati[value]
                            }
                            return '';
                        }
                    },

                    {
                        type: 'text',
                        label: 'Ausbildungswunsch',
                        model: 'educationSearch'
                    },
                    {
                        label: 'Seminar-Interesse',
                        type: 'bool',
                        model: 'seminarInterest'
                    },
                    {
                        type: 'text',
                        label: 'Möglicher Einstieg',
                        model: 'possibleGroupSearch',
                    },
                    {
                        type: 'text',
                        label: 'Möglicher Start',
                        model: 'possibleStart'
                    },
                    {
                        label: 'Selbstzahler',
                        type: 'bool',
                        model: 'paymentTypeOwn'
                    },
                    {
                        label: 'Bildungsgutschein',
                        type: 'bool',
                        model: 'paymentTypeVoucher'
                    },
                    {
                        label: 'Tag der offenen Tür',
                        type: 'bool',
                        model: 'openDoorDayInvitation'
                    },
                    {
                        label: 'Farbenherbst',
                        type: 'bool',
                        model: 'autumnOfColorsInvitation'
                    },
                    {
                        label: 'Gesundheitstage',
                        type: 'bool',
                        model: 'healthDaysInvitation'
                    },
                ];
            }
        },
        data() {
            return {
                columns: {
                    date: {
                        text: 'Wann',
                        order: true
                    },
                    'location.name': {
                        text: 'Standort',
                        order: true,
                    },
                    'createdBy.code': {
                        text: 'Kontaktperson',
                        order: true
                    },
                    'person.status': {
                        text: 'Status',
                        order: true,
                    },
                    'person.lastName': {
                        text: 'Person',
                        order: true
                    },
                    education: {
                        text: 'Ausbildungswunsch'
                    },
                    payment: {
                        text: 'Zahlung',
                    },
                    start: {
                        text: 'Einstieg'
                    },
                    etc: {
                        text: 'Einladungen'
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    },
                }
            }
        }
    }
</script>
