<template>
    <div>
        <content-header :headline="education.name">
            <router-link to="/educations" class="button thirdly">Zurück zur Übersicht</router-link>
        </content-header>

        <content-main v-if="currentEducation.id">
            <ul class="tabs">
                <li v-for="(name, index) in tabs">
                    <a @click="tab = index" :class="{'--active': tab===index}">{{ name }}</a>
                </li>
            </ul>

            <div class="panel" v-if="tab === 'general'">
                <form v-on:submit.prevent="updateEducation">
                    <div class="row">
                        <div class="large-8 columns --borderRight">
                            <education-form
                                v-model="currentEducation"
                                :errors="formErrors"
                            />

                            <div class="text-right">
                                <button type="submit" class="button">Aktualisieren</button>
                            </div>
                        </div>
                        <div class="large-4 columns">
                            <div class="panel --veryLightGray">
                                <h3 class="h-smallMargin-bottom">Allgemeine Daten</h3>
                                <p>
                                    <strong>Erstellt von:</strong> {{ currentEducation.createdByDisplayName }}
                                    <br>
                                    <strong>Erstellt am:</strong> {{ currentEducation.createdAt|formatDate('LLL') }}
                                </p>
                                <p v-if="currentEducation.lastEditedByDisplayName">
                                    <strong>Zuletzt bearbeitet von:</strong>
                                    {{ currentEducation.lastEditedByDisplayName }}
                                    <br>
                                    <strong>Zuletzt bearbeitet am:</strong>
                                    {{ currentEducation.lastEditedAt|formatDate('LLL') }}
                                </p>
                                <p>
                                    <strong>Anzahl Kurse: </strong>{{ currentEducation.coursesCount }}
                                    <br>
                                    <strong>Anzahl Standorte: </strong>{{ currentEducation.locationCount }}
                                </p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div class="panel" v-if="tab === 'information'">
                <form v-on:submit.prevent="updateEducationInformation">
                    <education-information-form
                        v-model="educationInformation"
                        :errors="educationInformationErrors"
                    />

                    <hr>

                    <div class="text-center">
                        <button type="submit" class="button">Aktualisieren</button>
                    </div>
                </form>
            </div>

            <div
                v-if="tabs.hasOwnProperty('supportedInformation') && tab === 'supportedInformation'"
                class="panel"
            >
                <education-support-information-updateForm
                    :educationSupportInformation="education.educationSupportedInformation"
                />
            </div>

            <div v-if="tab === 'courses'" class="panel">
                <courses-table
                    :disable-filter="['education.name']"
                    :fixed-query="{education: currentEducation['@id']}"
                    :add-query-to-url="false"
                    class="h-smallMargin-bottom h-smallMargin-top"
                />
            </div>

            <div v-if="tab === 'courseDates'" class="panel">
                <course-dates-table
                    :disable-filter="['course.education.name']"
                    :fixed-query="{'course.education': currentEducation['@id']}"
                    :add-query-to-url="false"
                    class="h-smallMargin-bottom h-smallMargin-top"
                />
            </div>
        </content-main>
    </div>
</template>

<script>
    import EducationForm from "../../data/EducationForm";
    import CoursesTable from "../../data/CoursesTable";
    import CourseDatesTable from "../../data/CourseDatesTable";
    import EducationInformationForm from "../../data/EducationInformationForm";
    import util from "../../../plugins/util";
    import EducationSupportInformationUpdateForm from '@/data/EducationSupportInformation/UpdateForm.vue';

    export default {
        components: {
            EducationInformationForm,
            CourseDatesTable,
            CoursesTable,
            EducationForm,
            EducationSupportInformationUpdateForm
        },
        data() {
            return {
                tab: 'general',
                education: {},
                currentEducation: {},
                formErrors: {},

                educationInformation: {},
                educationInformationErrors: {},
            }
        },

        computed: {
            tabs: function () {
                let tabs = {
                    general: 'Allgemeine Daten',
                    courses: 'Kurse',
                    courseDates: 'Termine',
                    information: 'Weitere Informationen',
                };

                if (
                    this.education.hasOwnProperty('educationSupportedInformation')
                    && this.education.type === 'supported'
                    && this.education.educationSupportedInformation
                    && this.education.educationSupportedInformation.hasOwnProperty('@id')
                ) {
                    tabs['supportedInformation'] = 'Informationen Förderung';
                }

                return tabs;
            }
        },

        mounted() {
            this.loadEducation();
            this.loadEducationInformation();
        },
        methods: {
            loadEducation() {
                this.$getFromApi('education', this.$route.params.id, (entity) => {
                    this.education = entity;
                    this.currentEducation = Object.assign({}, entity);
                });
            },
            updateEducation() {
                this.$sendToApi('education', this.currentEducation, (entity, status, errors) => {
                    if (status === 200) {
                        this.education = entity;
                        this.currentEducation = Object.assign({}, this.education);
                    } else {
                        this.formErrors = errors;
                    }
                })
            },
            loadEducationInformation() {
                this.$getFromApiSearch(
                    'education_informations',
                    {'education': this.$route.params.id},
                    (entity) => {
                        if (entity.hasOwnProperty('@id')) {
                            this.educationInformation = entity;
                        } else {
                            this.educationInformation = {};
                        }
                    }
                );
            },
            updateEducationInformation() {
                this.educationInformation.education = this.education['@id'];
                let educationInformationData = Object.assign({}, this.educationInformation);

                [
                    'educationPoints',
                    'examinationFee',
                    'depositRate',
                    'amountOfRate',
                    'numberOfRates',
                    'oneOffPayment',
                    'oralExaminationFee',
                    'registrationFee',
                    'writtenExaminationFee',
                ].forEach((item) => {
                    if (educationInformationData[item] === "") {
                        educationInformationData[item] = null;
                    }
                });

                this.$sendToApi('education_informations', educationInformationData, (entity, status, errors) => {
                    if (status < 300) {
                        this.educationInformationErrors = {};
                        this.educationInformation = entity;
                    } else {
                        this.educationInformationErrors = util.mapConstraints(errors);
                    }
                })
            }
        }
    }
</script>
