import jQuery from 'jquery';
import Vue from 'vue';
import Notifications from 'vue-notification';
import VuejsDialog from "vuejs-dialog";
import VueCurrencyInput from 'vue-currency-input'

Vue.use(VueCurrencyInput, {
  /* see config reference */
  globalOptions: {currency: 'EUR'}
})

Vue.use(Notifications);
Vue.use(VuejsDialog, {
  html: true,
  loader: false,
  okText: 'OK',
  cancelText: 'Abbrechen',
  animation: 'fade',
});

Vue.config.productionTip = false;

window.$ = jQuery;
window.jQuery = jQuery;

import VModal from 'vue-js-modal/dist/index.nocss.js'

Vue.use(VModal);
