<template>
    <data-table
        :endpoint="endpoint"
        :columns="columns"
        :filters="filters"
        ref="dataTable"
        :fixed-query="fixedQuery"
        updateRevealSize="small"
        :add-query-to-url="addQueryToUrl"
        :pre-set-query="preSetQuery"
    >
        <template slot="updateForm" slot-scope="{data: course, errors: errors}"
                  v-if="$store.getters.hasLoggedInUserCredibility('ROLE_EDUCATION_MANAGEMENT')">
            <course-form v-model="course" :errors="errors"/>
        </template>

        <template slot="dataRow" slot-scope="{singleData: course}">
            <td>
                <template v-if="!course.active">(inaktiv)</template>
                {{ course.code }}
            </td>
            <td>
                {{ course.locationType|dataTrans('model.course.locationType') }}
            </td>
            <td>
                <template v-if="course.firstDate">
                    {{ course.firstDate|formatDate('L') }} <br>
                </template>
                <template v-if="course.lastDate">
                    {{ course.lastDate|formatDate('L') }}
                </template>
            </td>
            <td>
                {{ course.courseDatesCount }}
            </td>
            <td>
                {{ course.touchBookingsCount }}
            </td>
            <td>
                <template v-if="course.education">
                    {{ course.education.name }}
                </template>
            </td>
            <td>
                <template v-if="course.location">
                    {{ course.location.name }}
                </template>
                <template v-if="!course.location">
                    Global
                </template>
            </td>
            <td>
                {{ course.applicationStatus|dataTrans('model.course.applicationStatus') }}
                <br>
                <span
                    :class="{
                    'text-green-500': course.eventCourseDatesWithDocent === course.courseDatesCount
                    }"
                >
                {{course.eventCourseDatesWithDocent}} / {{ course.specificDatesCount }} belegt
                </span>
                <template v-if="course.deadlineApplication">
                    <br>
                    Deadline: {{course.deadlineApplication|formatDate('l')}}
                </template>
            </td>
            <td>
                <template v-if="course.type === 'module'">
                    Modul
                </template>
                <template v-else>
                    Kurs
                </template>
            </td>
            <td>
                {{ course.lastEditedAt|formatDate('LLL') }}
            </td>
            <td class="text-right">
                <router-link :to="'/courses/' + course.id" class="action --show"/>
                <a v-if="course.userCanDelete" class="action --delete"></a>
            </td>
        </template>
    </data-table>
</template>

<script>
    import DataTable from "../components/DataTable";
    import CredibilitySelect from "../components/form/CredibilitySelect";
    import EducationTypeSelect from "../components/form/EducationTypeSelect";
    import CourseForm from "./CourseForm";

    export default {
        components: {
            CourseForm,
            EducationTypeSelect,
            CredibilitySelect,
            DataTable
        },
        props: {
            endpoint: {
                type: Text | String,
                default: 'courses'
            },
            disableFilter: {
                type: Array,
                default: () => []
            },
            fixedQuery: {
                type: Object,
                default: () => {
                }
            },
            preSetQuery: {
                type: Object,
                default: () => {
                }
            },
            addQueryToUrl: {
                type: Boolean,
                default: () => false
            }
        },
        methods: {
            createData(data) {
                this.$refs.dataTable.createData(data);
            }
        },
        computed: {
            filters() {
                let _this = this;
                let filter = [
                    {
                        type: 'text',
                        model: 'code',
                        label: 'Kürzel'
                    },
                    {
                        type: 'entity',
                        entity: 'location',
                        model: 'location',
                        label: 'Standort',
                        callback: function () {
                            return 'Ein Datensatz ausgewählt';
                        }
                    },
                    {
                        type: 'text',
                        model: 'education.name',
                        label: 'Ausbildung/Seminar'
                    },
                    {
                        type: 'bool',
                        model: 'active',
                        label: 'Aktiv'
                    },
                    {
                        type: 'bool',
                        model: 'datesInFuture',
                        label: 'Nur Kurse mit Terminen in Zukunft'
                    },
                    {
                        type: 'bool',
                        model: 'onDemand',
                        label: 'Anfrage-Kurs'
                    },
                    {
                        type: 'dateRange',
                        model: 'startAt',
                        label: 'Kurs gestartet zwischen'
                    },
                    {
                        type: 'dateRange',
                        model: 'deadlineApplication',
                        label: 'Deadline Bewerbung'
                    },
                    {
                        type: 'select',
                        model: 'applicationStatus',
                        label: 'Bewerbungsmöglichkeit',
                        options: this.$store.getters.applicationSettings.model.course.applicationStatus,
                        callback: function (val) {
                            if(!val) {
                                return '-';
                            }
                            return _this.$store.getters.applicationSettings.model.course.applicationStatus[val];
                        }
                    },
                ];

                filter = filter.filter(item => {
                    return this.disableFilter.includes(item.model) === false;
                });

                return filter;
            }
        },
        data() {
            return {
                columns: {
                    code: {
                        text: 'Kürzel',
                        order: true
                    },
                    locationType: {
                        text: 'Online/Präsenz',
                        order: false,
                    },
                    'start': {
                        text: 'Zeitraum',
                        order: true
                    },
                    eventCourseDateCount: {
                        text: 'Termine',
                        order: false,
                    },
                    touchBookingsCount: {
                        text: 'Teilnehmer',
                    },
                    education: {
                        text: 'Ausbildung/Seminar'
                    },
                    location: {
                        text: 'Standort'
                    },
                    applicationStatus: {
                        text: 'Bewerbungsstatus'
                    },
                    type: {
                        text: 'Typ',
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    },
                }
            }
        }
    }
</script>
