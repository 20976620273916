<template>
    <div>
        <content-header :headline="currentEvent.name">
            <a class="button small thirdly" @click="exportTouchRegistrations">Teilnehmer Daten exportieren</a>
            <a class="button small thirdly" @click="$router.go(-1)">Zurück zur Übersicht</a>
        </content-header>
        <reveal headline="Daten bearbeiten">
        </reveal>
        <content-main>
            <ul class="tabs">
                <li>
                    <a @click="tab = 'general'" :class="{'--active': tab === 'general'}">Allgemeine Daten</a>
                </li>
                <li>
                    <a @click="tab = 'touches'" :class="{'--active': tab === 'touches'}">Teilnehmer</a>
                </li>
            </ul>

            <div class="panel" v-if="tab === 'general'">
                <form v-on:submit.prevent="update">
                    <event-form v-model="event" :errors="errors"/>

                    <button type="submit" class="button">Aktualisieren</button>
                </form>
            </div>

            <div class="panel" v-if="tab === 'touches'">
                <h2 class="h-smallMargin-bottom">Teilnehmer</h2>
                <data-table
                    endpoint="touch_registrations"
                    :columns="registrationColumns"
                    :add-query-to-url="false"
                    :fixed-query="{event: event.id}"
                >
                    <template slot="dataRow" slot-scope="{singleData: touchRegistration}">
                        <td>{{ touchRegistration.createdAt|formatDate('LLL') }}</td>
                        <td>{{ touchRegistration.person.displayName }}</td>
                        <td>
                            <template v-if="touchRegistration.hasTouchVisit">
                                Teilgenommen
                            </template>
                        </td>
                        <td>
                            <router-link :to="'/persons/' + touchRegistration.person.id" class="action --show"></router-link>
                        </td>
                    </template>
                </data-table>
            </div>
        </content-main>
    </div>
</template>
<script>
    import EventForm from "../../data/Event/EventForm";
    import util from "../../../plugins/util";
    import DataTable from "../../components/DataTable";

    export default {
        components: {DataTable, EventForm},
        data() {
            return {
                currentEvent: {},
                event: {},
                tab: 'general',
                errors: {},
                registrationColumns: {
                    createdAt: {
                        text: 'Anmeldung',
                        order: true,
                    },
                    person: {
                        text: 'Teilnehmer'
                    },
                    visit: {
                        text: 'Teilgenommen'
                    }
                }
            }
        },
        mounted() {
            this.loadEvent();
        },
        methods: {
            exportTouchRegistrations() {
                this.$http.post('reportings/eventTouchRegistration/' + this.$route.params.id).then(
                    response => {
                        window.location = response.body.url;
                    }
                );
            },
            loadEvent() {
                this.$getFromApi('events', this.$route.params.id, (entity) => {
                    this.event = entity;
                    this.currentEvent = Object.assign({}, entity);
                    this.$refs.eventForm.edit(entity);
                });
            },
            update() {
                let url = this.event['@type'] === 'EventTrialCourse' ? 'event_trial_courses' : 'event_open_door_days';
                delete this.event.touches;
                this.$sendToApi(url, this.event, (entity, status, errors) => {
                    if (status === 200) {
                        this.loadEvent();
                    } else {
                        this.errors = util.mapConstraints(errors);
                    }
                })
            }
        }
    }
</script>
