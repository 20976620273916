<template>
    <div v-if="$store.getters.applicationSettings.model.docentBilling.status">
        <content-header headline="Dozenten-Abrechnung">
        </content-header>
        <content-main>
            <docent-billing-table/>
        </content-main>
    </div>
</template>

<script>
    import DocentBillingTable from "@/data/DocentBilling/Table";

    export default {
        components: {
            DocentBillingTable
        }
    }
</script>
