<template>
    <div>
        <span
            @click="show"
            class="underline hover:no-underline"
        >
            {{ room.name }}
        </span>
        <c-modal
            v-if="loadedRoom"
            ref="roomSlugWithDetailModal"
            headline="Raum-Details"
        >
            <div class="grid gap-4 p-4">
                <div
                    class="grid grid-cols-2 gap-4"
                    v-for="(label, index) in attributeMapping"
                >
                    <strong>
                        {{ label }}
                    </strong>
                    <div>
                        {{ loadedRoom[index] }}
                    </div>
                </div>
                <div class="text-center mt-4">
                    <a
                        @click="$refs.roomSlugWithDetailModal.close()"
                        class="button thirdly small"
                    >
                        Schließen
                    </a>
                </div>
            </div>
        </c-modal>
    </div>
</template>

<script>
    export default {
        props: {
            room: {}
        },
        data() {
            return {
                loadedRoom: null,
                attributeMapping: {
                    name: 'Name',
                    zoomLink: 'Zoom-Url',
                    zoomUsername: 'Host-Benutzername',
                    zoomPassword: 'Host-Passwort',
                    zoomParticipantPassword: 'Benutzer-Passwort',
                    zoomMeetingId: 'Zoom-Meeting-Id',
                }
            }
        },
        methods: {
            show() {
                let id = this.room.hasOwnProperty('@id') ? this.room.id : this.room;
                this.$getFromApi('rooms', id, (entity) => {
                    this.loadedRoom = Object.assign({}, entity);
                    this.$nextTick(() => {
                        this.$refs.roomSlugWithDetailModal.open();
                    })
                });
            }
        }
    }
</script>
