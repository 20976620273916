<template>
    <modal ref="searchModal" :adaptive="true" height="auto" name="appearanceUpdateModel" width="1200px">
        <div class="grid gap-y-2 py-2">
            <div class="px-4 uppercase font-bold text-xl">
                Globale Suche
            </div>
            <form v-on:submit.prevent="submitSearch"
                  class="px-4 grid grid-cols-[1fr_auto] gap-2 items-center"
            >
                <input-text
                    label="Suchbegriffe (kommasepariert)"
                    class="mb-0"
                    v-model="search.termsAsString"
                />
                <button class="button mb-0">Suchen</button>
            </form>

            <div
                v-if="search.hasOwnProperty('@id')"
                class="grid grid-cols-2"
            >
                <div v-for="(label, key) in searchAreas">
                    <div class="bg-gray-100 p-4 font-bold text-lg uppercase">
                        {{ label }}
                    </div>
                    <div class="px-4">
                        <ul class="list-none m-0 p-0 grid gap-4 py-2">
                            <li class="grid grid-cols-[1fr_auto] border-0 border-l-2 border-brand border-solid items-center pl-2 gap-2"
                                v-for="entity in search[key]"
                            >
                                <div>
                                    <template v-if="key === 'persons'">
                                        {{ entity.displayName }}
                                        <br>
                                        {{ entity.email }} {{ entity.phoneNumbers }}
                                    </template>
                                    <template v-if="key === 'educations'">
                                        {{ entity.name }} - {{ entity.code }}
                                        <template v-if="!entity.active">(inaktiv)</template>
                                    </template>
                                    <template v-if="key === 'courses'">
                                        {{ entity.code }} - {{ entity.education.name }}
                                        <template v-if="!entity.active">(inaktiv)</template>
                                    </template>
                                    <template v-if="key === 'touchBookings'">
                                        {{ entity.invoiceNumber }} - {{ entity.person.displayName }}
                                    </template>
                                    <template v-if="key === 'reportings'">
                                        {{ entity.name }}
                                    </template>
                                    <template v-if="key === 'eventTrialCourses'">
                                        {{ entity.name }}
                                        <br>
                                        {{ entity.date|formatDate('LLL') }}
                                    </template>

                                    <div>
                                        <i class="">
                                            {{ entity.lastEditedAt|formatDate('LL') }} -
                                            {{ entity.lastEditedByDisplayName }}
                                        </i>
                                    </div>
                                </div>

                                <div>
                                    <router-link class="action --show"
                                                 :to="getLink(entity)"
                                    >

                                    </router-link>
                                </div>
                            </li>
                            <li v-if="search.moreThanDisplayResults && search.moreThanDisplayResults.hasOwnProperty(key)"
                                class="p-4 text-center"
                            >
                                Mehr Ergebnisse gefunden - Suche bitte erweitern
                            </li>
                            <li v-if="search.hasOwnProperty(key) && search[key].length === 0"
                                class="p-4 text-center bg-warning-100 text-warning-700"
                            >
                                Keine Ergebnisse gefunden oder keine Berechtigung
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="text-center py-4">
                <a class="button small mb-0 thirdly"
                    @click="$refs.searchModal.close();"
                >
                    Suche beenden
                </a>
            </div>
        </div>
    </modal>
</template>

<script>
    export default {
        data() {
            return {
                search: {
                    termsAsString: ''
                },
                searchAreas: {
                    'persons': 'Personen',
                    'educations': 'Ausbildungen',
                    'courses': 'Kurse',
                    'touchBookings': 'Buchungen',
                    'eventTrialCourses': 'Schnupper-Workshops',
                    'reportings': 'Auswertungen',
                }
            }
        },
        watch: {
            $route: function(newVal) {
                this.$refs.searchModal.close();
            }
        },
        methods: {
            getLink(entity) {
                switch (entity['@type']) {
                    case "EventTrialCourse": {
                        return '/events/' + entity['id'];
                    }
                    case "Person": {
                        return '/persons/' + entity['id'];
                    }
                    case "Course": {
                        return '/courses/' + entity['id'];
                    }
                    case "Education": {
                        return '/educations/' + entity['id'];
                    }
                    case "Reporting": {
                        return '/reporting/reporting/' + entity['id'];
                    }
                    case "TouchBooking": {
                        return '/touchComplex?edit=booking&touchId=' + entity['id'];
                    }
                }

                return '';
            },
            open() {
                this.$refs.searchModal.open();
            },
            submitSearch() {
                if(this.search.termsAsString.length === 0) {
                    alert("Bitte Suchbegriff eingeben");
                    return;
                }
                delete this.search['@id'];
                delete this.search['id'];

                this.search.terms = this.search.termsAsString.split(/\s|,/);

                this.$sendToApi('searches', this.search, (entity, status, data) => {
                    this.search = entity;
                    this.search.termsAsString = this.search.terms.join(' ');
                }, {disableToast: true});
            }
        }
    }
</script>
