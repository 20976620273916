<template>
    <form
        v-on:submit.prevent="submitNote"
        class="grid gap-2 grid-cols-[1fr_auto] items-end gap-x-4"
    >
        <div class="font-bold text-xl uppercase col-span-2">Neue Notiz</div>
        <input-text
            v-model="newNote.text"
            type="textarea"
            class="mb-0"
        />

        <div class="">
            <input-simple-select
                v-model="newNote.type"
                :options="$settingsObject('model.note.type')"
                label="Sichtbarkeit"
                :required="true"
            />
            <button
                type="submit"
                :disabled="newNote.text.length === 0"
                class="button mb-5"
            >
                Notiz ergänzen
            </button>
        </div>
    </form>
</template>
<script>
    export default {
        props: {
            resourceIri: {
                required: true,
            }
        },
        data() {
            return {
                newNote: {
                    text: '',
                    type: 'intern',
                }
            }
        },
        methods: {
            submitNote() {
                this.$sendToApi(
                    'notes',
                    Object.assign(
                        {
                            'resourceIri': this.resourceIri,
                        },
                        this.newNote
                    ),
                    (entity, status, data) => {
                        if (status < 300) {
                            this.newNote = {
                                text: '',
                                type: 'intern'
                            };
                            this.$emit('update', entity);
                        }
                    }
                )
            },
        }
    }
</script>
