<template>
    <div>
        <content-header headline="Systeminformationen" subline="Diese Seite liefert Informationen zur Fehlersuche">
        </content-header>
        <content-main>
            <table>
                <tbody>
                <tr>
                    <th>Browser</th>
                    <td>{{browser.name}} (Version {{browser.version}})</td>
                </tr>
                <tr>
                    <th>Betriebssystem</th>
                    <td>{{browser.os}}</td>
                </tr>
                </tbody>
            </table>
        </content-main>
    </div>
</template>

<script>
    const {detect} = require('detect-browser');

    export default {
        data() {
            return {
                browser: {},
            }
        },
        mounted() {
            this.browser = detect();
        }
    }
</script>
