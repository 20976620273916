<template>
    <div class=""
         style="border-left: 1px solid #00243B; background: rgba(0, 0, 0, 0.0125); margin: 0 0 1rem; padding: 1rem 1rem 1px 1.5rem; margin-bottom: 1rem;">
        <div class="row align-middle">
            <div class="columns shrink">
                <label>
                    Typ
                    <select v-model="value['type']">
                        <option value="touch">Touch</option>
                        <option value="subset">Unterbedingung</option>
                        <option value="person">Person-Filterung</option>
                    </select>
                </label>
            </div>

            <div class="columns shrink">
                <label>
                    &nbsp
                    <select v-model="value.appearance">
                        <option disabled>Bitte auswählen</option>
                        <option value="include">einschließen</option>
                        <option value="exclude">ausschließen</option>
                    </select>
                </label>
            </div>

            <div class="columns text-right">
                <a @click="deleteCondition" style="display: block; margin-top: .5rem;">
                    <i class="ti ti-trash" style="font-size: 1.5rem;"></i>
                </a>
            </div>
        </div>

        <div class="row" v-if="value['@type']">
            <template v-if="value['@type'] === 'TouchCondition'">
                <div class="columns shrink">
                    <input-simple-select
                        v-model="value.touchType"
                        label="Touch"
                        :required="true"
                        :options="$store.getters.applicationSettings.model.reporting.supportedTouches"
                    />
                </div>

                <div class="columns" v-if="value.touchType">
                    <div class="row">
                        <div
                            class="columns small-12"
                            v-if="value.filters.dateRange"
                        >
                            <input-date-range
                                :asRow="true"
                                label="Zeitraum"
                                v-model="value.filters.dateRange"
                            />
                        </div>

                        <template v-if="value.touchType === 'support'">
                            <div class="columns small-12">
                                <input-date-range
                                    label="Möglicher Start"
                                    v-model="value.filters.possibleStartRange"
                                    :as-row="true"
                                />
                            </div>
                        </template>

                        <template v-if="value.touchType === 'booking'">
							<div class="columns small-12">
								<label>
									Ausbildung (falls gepflegt)
									<input-entity
                                            :multi="true"
											type="education"
											v-model="value.filters.educations"
									/>
								</label>
							</div>

                            <div class="columns small-12">
                                <label>
                                    Buchungs-Typ
                                    <input-simple-multi-select-rich-objects
                                        :options="$store.getters.applicationSettings.model.touchBooking.bookingType"
                                        v-model="value.filters.bookingTypes"
                                    />
                                </label>
                            </div>

                            <div class="columns small-12">
                                <input-text
                                    v-model="value.filters.measureText"
                                    label="Massnahmen-Text"
                                />
                            </div>
                        </template>

                        <template v-if="value.touchType === 'firstContact' || value.touchType === 'consultation'">
                            <div class="columns small-12">
                                <quarter-select
                                    :multi="true"
                                    label="Möglicher Start"
                                    v-model="value.filters.possibleStart"
                                />
                            </div>
                        </template>

                        <template v-if="value.touchType === 'firstContact'">
                            <div class="columns small-4">
                                <input-tri-state-select
                                    label="Förderung gewünscht"
                                    v-model="value.filters.support"
                                />
                            </div>
                        </template>

                        <template v-if="value.touchType === 'catalogueRequest'">
                            <div class="columns">
                                <input-simple-select
                                    label="Digital"
                                    :options="{true: 'Digital', false: 'Print'}"
                                    v-model="value.filters.digital"
                                />
                            </div>
                            <div class="columns">
                                <input-simple-select
                                    label="Verschickt"
                                    :options="{true: 'Ja', false: 'Nein'}"
                                    v-model="value.filters.catalogueSend"
                                />
                            </div>
                        </template>

                        <template v-if="value.touchType === 'registration'">
                            <div class="columns small-12">
                                <input-entity
                                    :multi="true"
                                    label="Veranstaltung"
                                    type="eventTrialCourse"
                                    v-model="value.filters.events"
                                />
                            </div>
                            <div class="columns small-12">
                                <input-simple-select
                                    label="Teilnahme"
                                    :options="{true: 'Teilgenommen', false: 'Nicht teilgenommen'}"
                                    v-model="value.filters.visit"
                                />
                            </div>
                        </template>

                        <template
                            v-if="value.touchType === 'consultation' || value.touchType === 'firstContact' || value.touchType === 'scholarship' || value.touchType === 'booking'">
                            <div class="columns  small-12">
                                <input-entity
                                    label="Standorte"
                                    v-model="value.filters.locations"
                                    type="location"
                                    :multi="true"
                                />
                            </div>
                        </template>

                        <template
                            v-if="value.touchType === 'scholarship'">
                            <div class="columns  small-12">
                                <label>
                                    Bewertung
                                    <input-simple-multi-select-rich-objects
                                        v-model="value.filters.scorings"
                                        :options="$store.getters.applicationSettings.model.touchScholarship.scorings"
                                        :multi="true"
                                    />
                                </label>
                            </div>
                        </template>

                        <template v-if="value.touchType === 'custom'">
                            <div class="columns">
                                <input-entity
                                    label="Touch-Vorlage"
                                    v-model="value.filters.templateTouchCustoms"
                                    type="templateTouchCustom"
                                    :multi="true"
                                />
                            </div>

                            <div class="columns">
                                <input-text
                                    label="Touch-Vorlage (Text)"
                                    v-model="value.filters.templateTouchCustomName"
                                />
                            </div>
                        </template>

                        <template
                            v-if="value.touchType === 'consultation' || value.touchType === 'firstContact' || value.touchType === 'scholarship'">
                            <div class="columns small-12">
                                <input-entity
                                    type="education"
                                    label="Ausbildung"
                                    v-model="value.filters.educations"
                                    :multi="true"
                                />
                            </div>
                        </template>

                        <template v-if="value.touchType === 'consultation'">
                            <div class="columns small-6">
                                <input-tri-state-select
                                    label="Bildungsgutschein"
                                    v-model="value.filters.paymentTypeVoucher"
                                />
                            </div>

                            <div class="columns small-6">
                                <input-simple-select
                                    label="Status Touch"
                                    :options="{decline: 'Abgesagt', notTakePlace: 'Nicht stattgefunden', takePlace: 'Stattgefunden'}"
                                    v-model="value.filters.touchStatus"
                                />
                            </div>

                        </template>
                        <template v-if="value.touchType === 'consultation' || value.touchType === 'firstContact'">
                            <div class="columns small-6">
                                <input-tri-state-select
                                    label="Einladung Tag der offenen Tür"
                                    v-model="value.filters.openDoorDayInvitation"
                                />
                            </div>

                            <div class="columns small-6">
                                <input-tri-state-select
                                    label="Farbenherbst"
                                    v-model="value.filters.autumnOfColorsInvitation"
                                />
                            </div>
                        </template>

                        <template v-if="value.touchType === 'request'">
                            <div class="columns small-12">
                                <input-simple-select
                                    :options="{'intern': 'Intern', 'extern': 'Extern'}"
                                    label="Intern / Extern"
                                    :required="false"
                                    v-model="value.filters.intern"
                                />
                            </div>
                        </template>
                    </div>
                </div>
            </template>

            <template v-if="value['@type'] === 'PersonCondition'">
                <div class="columns small-12">
                    <label>
                        Stati
                        <input-simple-multi-select-rich-objects
                            :options="personStati"
                            v-model="value.stati"
                            :errors="[]"
                            label="Status"
                        />
                    </label>
                </div>
            </template>

            <template v-if="value['@type'] === 'SubsetCondition'">
                <div v-if="value.conditions" class="columns small-12">
                    <template
                        v-for="row, i in value.conditions.items"
                    >
                        <sub-condition-builder
                            v-model="value.conditions.items[i]"
                            v-on:delete="deleteSubCondition(i)"
                        />

                        <div
                            style="width: 200px; margin: 0 auto;"
                            v-if="i < (value.conditions.items.length - 1)"
                        >
                            <input-simple-select
                                v-model="value.conditions.conditionConnection"
                                :required="true"
                                label="Verknüpfung"
                                :options="{'and': 'Und', 'or': 'Oder'}"
                            />
                        </div>
                    </template>

                    <div class="text-center">
                        <a
                            class="button thirdly"
                            @click="addCondition"
                        >
                            Unterbedingung hinzufügen
                        </a>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import value from "../../../mixins/value";
    import InputSimpleMultiSelect from "../../components/form/InputSimpleMultiSelect";
    import QuarterSelect from "../../components/form/QuarterSelect";
    import InputSimpleMultiSelectRichObjects from "../../components/form/InputSimpleMultiSelectRichObjects";
    import InputTriStateSelect from "../../components/form/InputTriStateSelect";

    export default {
        components: {
            InputTriStateSelect,
            InputSimpleMultiSelectRichObjects,
            QuarterSelect,
            InputSimpleMultiSelect
        },
        beforeCreate: function () {
            this.$options.components.SubConditionBuilder = require('./ConditionBuilder.vue').default;
        },
        computed: {
            personStati: function () {
                return this.$store.getters.applicationSettings.model.person.stati;
            }
        },
        mixins: [value],
        methods: {
            addCondition() {
                this.value.conditions.items.push({
                    '@type': 'TouchCondition',
                    type: 'touch',
                    appearance: 'include',
                    touchType: 'booking',
                    filters: {
                        dateRange: {
                            before: null,
                            after: null,
                        },
                    }
                });
            },
            deleteSubCondition(i) {
                this.value.conditions.items.splice(i, 1);
            },
            deleteCondition() {
                this.$emit('delete');
            }
        },
        watch: {
            'value.type': function (newVal) {
                if (newVal === 'subset') {
                    this.value = {
                        '@type': 'SubsetCondition',
                        'type': newVal,
                    }
                    this.$set(
                        this.value,
                        'conditions',
                        {
                            items: [],
                            conditionConnection: 'and'
                        }
                    );
                } else if (newVal === 'person') {
                    this.$set(this, 'value', {
                        '@type': 'PersonCondition',
                        'type': newVal,
                        stati: []
                    });
                } else {
                    this.$set(this, 'value', {
                        '@type': 'TouchCondition',
                        'type': newVal,
                        filters: {
                            dateRange: {
                                before: null,
                                after: null,
                            },
                        }
                    });
                }
            },
            'value.touchType': function (newVal) {
                this.$set(
                    this.value,
                    'filters',
                    {
                        possibleStartRange: {
                            before: null,
                            after: null,
                        },
                        dateRange: {
                            before: null,
                            after: null,
                        },
                    }
                );
            }
        }
    }
</script>
