<template>
    <div :class="{'loadingWrapper': !disableWrapper}">
        <div class="loading">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            disableWrapper: {
                type: Boolean,
                default: () => false,
            }
        }
    }
</script>
