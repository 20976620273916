<template>
    <div>
        <content-header headline="Imports">
            <router-link to="/tools/import" class="button small">Datei importieren</router-link>
        </content-header>

        <content-main>
            <data-table
                endpoint="import_files"
                :columns="columns"
            >
                <template slot="dataRow" slot-scope="{singleData: importFile}">
                    <td>
                        {{ importFile.name }}
                    </td>
                    <td>
                        {{ importFile.createdAt|formatDate('LLL') }}
                    </td>
                    <td>{{ importFile.type }}</td>
                    <td>{{ importFile.dryRun }}</td>
                    <td>{{ importFile.status }}
                        <template v-if="importFile.messages">
                            <br>
                            {{importFile.messages}}
                        </template>
                    </td>
                </template>
            </data-table>
        </content-main>
    </div>
</template>

<script>
    import DataTable from "../../../components/DataTable";

    export default {
        components: {
            DataTable,
        },
        methods: {
            showMessages(messages) {
                this.importMessage = messages;
            }
        },
        data() {
            return {
                upload: {
                    dryRun: false,
                    skipFirstLine: false,
                    type: 'consulting'
                },
                importMessage: {},
                columns: {
                    name: {
                        text: 'Datei'
                    },
                    createdAt: {
                        text: 'Erstellt',
                        order: true
                    },
                    type: {
                        text: 'Type',
                    },
                    dryRun: {
                        text: 'Testlauf',
                    },
                    status: {
                        text: 'Status',
                    }
                }
            }
        }
    }
</script>
