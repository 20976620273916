<template>
    <div>
        <content-header headline="Gruppen-Organisiation">
            <div class="grid gap-4 grid-flow-col">
                <input-entity
                    class="mt-0"
                    style="margin-bottom: 0;"
                    value=""
                    type="location"
                />
            </div>
        </content-header>

        <content-main>
            <div>
                <table>
                    <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Thema</th>
                        <th>Dozent</th>
                        <th>Raum</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th colspan="5">
                            <div class="text-2xl font-bold uppercase">
                                KTW-2-312-
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <td>
                            14.05.2023
                        </td>
                        <td>
                            XYZ
                        </td>
                        <td>
                            Felix Niedballa
                        </td>
                        <td>
                            Raum xyz
                        </td>
                        <td>
                            Aktionen
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </content-main>
    </div>
</template>

<script>
    export default {
        data() {
            return {}
        }
    }
</script>
