<template>
    <div>
        <!--<div class="callout warning text-center">
            <p>Der Upload startet sofort. Vergewissern Sie sich, dass alle Parameter richtig gesetzt sind.</p>
        </div>-->
        <vue-dropzone
            ref="dropzone"
            :options="dropzoneOptions"
            v-on:vdropzone-queue-complete="queueSuccess"
            v-on:vdropzone-success="emitResponse"
            v-on:vdropzone-error="emitResponse"
            v-on:vdropzone-sending="setParams"
            id="dropzone"
        />
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone';
    import auth from "../../plugins/auth";

    export default {
        components: {
            vueDropzone: vue2Dropzone
        },
        props: {
            acceptedFiles: {
                type: String,
                default: () => {
                    let mimeTypes = [
                        'application/pdf',
                        'image/jpeg',
                        'image/png',
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        'application/msword',
                        'application/vnd.ms-excel',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'application/vnd.ms-powerpoint',
                        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
                    ];
                    return mimeTypes.join(',');
                }
            },
            maxFiles: {
                type: Number,
                default: () => {
                    return 5;
                }
            },
            endpoint: {
                type: String,
                required: true,
                default: () => {
                    return '';
                }
            },
            params: {
                type: Object,
                required: false,
                default: () => {
                    return {};
                }
            },
            removeOnResponse: {
                type: Boolean,
                required: false,
                default: () => {
                    return false;
                }
            }
        },
        methods: {
            setParams(file, xhr, formData) {
                Object.keys(this.params).forEach((item) => {
                    formData.append(item, this.params[item]);
                })
            },
            accept(file) {
                let reader = new FileReader();
                reader.onload = handleReaderLoad;
                reader.readAsDataURL(file);
                let _this = this;

                function handleReaderLoad(evt) {
                    _this.$emit('input', evt.target.result);
                }
            },
            remove() {
                this.$emit('input', null);
            },
            queueSuccess() {
                let _this = this;
                this.$emit('queue-complete');
                setTimeout(function () {
                    //_this.$refs.dropzone.removeAllFiles();
                }, 2000);
            },
            emitResponse(file, response, xhr = null) {
                if (this.removeOnResponse) {
                    this.$refs.dropzone.removeAllFiles();
                }

                var success = true;
                if (xhr !== null && xhr.status > 299) {
                    success = false;
                }
                this.$emit('complete', response, success);
            }
        },
        computed: {
            dropzoneOptions: function () {
                return {
                    url: window.baseApiUrl + '/' + this.endpoint,
                    acceptedFiles: this.acceptedFiles,
                    maxFiles: this.maxFiles,
                    maxFilesize: 20,
                    autoQueue: true,
                    addRemoveLinks: true,
                    dictDefaultMessage: 'Legen Sie Dateien hier ab um Sie hochzuladen.',
                    dictFallbackMessage: 'Ihr Browser unterstützt Drag&Drop Dateiuploads nicht',
                    dictFallbackText: 'Benutzen Sie das Formular um Ihre Dateien hochzuladen',
                    dictFileTooBig: 'Die Datei ist zu groß. Die maximale Dateigröße beträgt {{maxFileSize}}MB',
                    dictInvalidFileType: 'Eine Datei dieses Typs kann nicht hochgeladen werden',
                    dictResponseError: 'Der Server hat ihre Anfrage mit Status {{statusCode}} abgelehnt',
                    dictCancelUpload: 'Hochladen abbrechen',
                    dictCancelUploadConfirmation: null,
                    dictRemoveFile: 'Datei entfernen',
                    dictMaxFilesExceeded: 'Sie können (gleichzeitig) keine weiteren Dateien mehr hochladen',
                    headers: {
                        'Authorization': auth.getTokenHeader()
                    },
                    previewTemplate: '<div class="dz-preview dz-file-preview">\n' +
                        '                <div class="row align-middle">\n' +
                        '                    <div class="large-5 columns text-left">\n' +
                        '                        <div class="dz-filename"><span data-dz-name>test.csv</span></div>\n' +
                        '                    </div>\n' +
                        '                    <div class="large-5 columns text-center">\n' +
                        '                        <span class="dz-error-message data-dz-errormessage></span>\n' +
                        '                        <span class="dz-size" data-dz-size>14.5kb</span>\n' +
                        '                        <span class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></span>\n' +
                        '                    </div>\n' +
                        '                    <div class="large-2 columns text-right">\n' +
                        '                        <span data-dz-remove class="action --close">\n' +
                        '                    </div>\n' +
                        '                </div>\n' +
                        '            </div>'
                }
            },
        }
    }
</script>
