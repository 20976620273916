import Vue from 'vue';
import VueResource from 'vue-resource';
import VueNotification from "vue-notification";

Vue.use(VueResource);
Vue.use(VueNotification);

Vue.use({
    install(Vue) {
      Vue.prototype.$getUuid = function (value) {
        let s = "" + value;

        s = s.match('[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}');
        if (s === null) {
          return false;
        }
        return s[0];
      };

      Vue.prototype.$getAllFromApi = async function (baseApiUrl, params) {
        params.itemsPerPage = 25;
        let keys = Object.keys(params).filter((item) => {
          return item.indexOf('orderBy') > -1;
        });
        if(keys.length === 0) {
          params['orderBy[date]'] = 'ASC';
        }
        this.$store.commit('incrementLoadingIndex');
        const responseData = await this.$http.get(baseApiUrl, {params: Object.assign(params, {currentPage: 1})});
        let items = [].concat(responseData.data['hydra:member']);
        let maxPages = Math.ceil(responseData.data['hydra:totalItems'] / params.itemsPerPage);

        if(maxPages === 1) {
          this.$store.commit('decrementLoadingIndex');
          return items;
        }

        for (let i = 2; i <= maxPages; i++) {
          let pageDataResponse = await this.$http.get(
            baseApiUrl,
            {
              params: Object.assign(params, {currentPage: i})
            }
          );
          items = items.concat(pageDataResponse.data['hydra:member']);
        }
        this.$store.commit('decrementLoadingIndex');

        return items;
      };

      Vue.prototype.$getListFromApi = function (baseApiUrl, params, callback) {
        this.$store.commit('incrementLoadingIndex');
        this.$http.get(baseApiUrl, {params: params}).then((response) => {
          callback(response.data['hydra:member'], response.data['hydra:totalItems']);
          this.$store.commit('decrementLoadingIndex');
        });
      };

      Vue.prototype.$sendToApi = function (baseApiUrl, entity, callback, config = {}) {
        let method = entity.id ? 'put' : 'post';
        let url = entity.id ? baseApiUrl + '/' + entity.id : baseApiUrl;

        let objectToSend = Object.assign({}, entity);
        Object.keys(objectToSend).forEach((item) => {
          if (
            typeof objectToSend[item] !== 'undefined'
            && objectToSend[item] !== null
            && objectToSend[item].hasOwnProperty('@id')
          ) {
            objectToSend[item] = objectToSend[item]['@id'];
          }

          if (Array.isArray(objectToSend[item])) {
            Object.keys(objectToSend[item]).forEach((arrayItem) => {
              if (
                typeof objectToSend[item][arrayItem] !== 'undefined'
                && objectToSend[item][arrayItem] !== null
                && objectToSend[item][arrayItem].hasOwnProperty('@id')
              ) {
                objectToSend[item][arrayItem] = objectToSend[item][arrayItem]['@id'];
              }
            });
          }
        });

        this.$store.commit('incrementLoadingIndex');
        this.$http[method](url, entity).then(response => {
          this.$store.commit('decrementLoadingIndex');
          if (!config.hasOwnProperty('disableToast')) {
            this.$notify({
              type: 'success',
              text: 'Erfolgreich gespeichert'
            });
          }
          entity = response.data;
          callback(entity, parseInt(response.status), {});
        }, response => {
          this.$store.commit('decrementLoadingIndex');

          if (!config.hasOwnProperty('disableToast')) {

            if (config.hasOwnProperty('errorMessageFromApi')) {
              this.$notify({
                type: response.status < 500 ? 'warning' : 'alert',
                text: response.data['hydra:description'].split(':')[1],
                duration: 4000
              });
            } else {
              this.$notify({
                type: response.status < 500 ? 'warning' : 'alert',
                text: parseInt(response.status) === 403 ? 'Ihr Benutzer ist nicht authorisiert den Datensatz zu bearbeiten' : 'Fehler beim Speichern'
              });
            }
          }
          callback(entity, parseInt(response.status), response.data);
        });
      };

      Vue.prototype.$getFromApiSearch = function (baseApiUrl, params, callback) {
        this.$store.commit('incrementLoadingIndex');
        this.$http['get'](baseApiUrl, {params: params}).then(response => {
          this.$store.commit('decrementLoadingIndex');
          if (response.data['hydra:member'].length > 0) {
            callback(response.data['hydra:member'][0]);
          }
        }, response => {
          this.$store.commit('decrementLoadingIndex');
          this.$notify({
            type: response.status < 500 ? 'warning' : 'alert',
            text: 'Daten konnten nicht geladen werden'
          });
          callback(null);
        });
      }

      Vue.prototype.$getFromApi = function (baseApiUrl, entity, callback) {
        let url = baseApiUrl + '/' + (typeof entity.id !== 'undefined' ? entity.id : entity);

        this.$store.commit('incrementLoadingIndex');
        this.$http['get'](url).then(response => {
          this.$store.commit('decrementLoadingIndex');
          entity = response.data;
          callback(entity);
        }, response => {
          this.$store.commit('decrementLoadingIndex');
          this.$notify({
            type: response.status < 500 ? 'warning' : 'alert',
            text: 'Daten konnten nicht geladen werden'
          });
          callback(entity);
        });
      };

      Vue.prototype.$deleteFromApi = function (baseApiUrl, entity, callback) {
        let url = baseApiUrl + '/' + (typeof entity.id !== 'undefined' ? entity.id : entity);

        this.$store.commit('incrementLoadingIndex');
        this.$http['delete'](url).then(response => {
          this.$store.commit('decrementLoadingIndex');
          entity = response.data;
          callback(null);
        }, response => {
          this.$store.commit('decrementLoadingIndex');
          this.$notify({
            type: 'warning',
            text: 'Daten konnten nicht gelöscht werden.'
          });
          callback(entity);
        });
      };
    }
  }
)

