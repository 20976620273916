<template>
    <div>
        <ul
            v-if="notes.length > 0"
            class="list-none p-0 m-0 grid gap-5"
        >
            <li v-for="note in notes" class="grid p-0 m-0 gap-2">
                <div class="opacity-75 grid grid-flow-col items-center gap-4 justify-start">
                    <span v-if="note.hasOwnProperty('createdByDisplayName')">
                        {{ note.createdByDisplayName }}
                    </span>
                    <span>
                        {{ note.createdAt|formatDate('LLL') }}
                    </span>
                    <span v-if="note.hasOwnProperty('type')">
                        Sichtbarkeit: {{ note.type|dataTrans('model.note.type') }}
                    </span>
                    <a
                        v-if="note.userCanDelete"
                        @click="deleteNote(note)"
                        class="action --delete"
                    ></a>
                </div>
                <div class="text-lg" v-html='note.text.replace("\n", "<br>")'>
                </div>
            </li>
        </ul>
        <div v-else>
            Bisher keine Notizen
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            notes: []
        },
        methods: {
            deleteNote(note) {
                let _this = this;
                this.$dialog
                    .confirm('Soll die Notiz wirklich gelöscht werden?', {
                        okText: 'Ja',
                        cancelText: 'Abbrechen',
                    })
                    .then(function (dialog) {
                        _this.$deleteFromApi('notes', note);
                        _this.$set(_this, 'notes', _this.notes.filter((item) => item.id !== note.id))
                    })
                    .catch(function () {
                    });
            }
        }
    }
</script>
