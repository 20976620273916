<template>
    <table>
        <tbody>
        <tr v-if="showPersonalData">
            <th>
                Name
            </th>
            <td>
                {{ touchCallback.person.displayName }}
            </td>
        </tr>
        <tr v-if="showPersonalData">
            <th>
                E-Mail-Adresse
            </th>
            <td>
                {{ touchCallback.person.email }}
            </td>
        </tr>
        <tr v-if="showPersonalData">
            <th>
                Telefonnummer
            </th>
            <td>
                {{ touchCallback.person.phoneNumbers }}
            </td>
        </tr>
        <tr>
            <th>
                URL
            </th>
            <td>
                {{ touchCallback.url }}
            </td>
        </tr>
        <tr>
            <th>
                Name des Standortes
            </th>
            <td>
                {{ touchCallback.locationName }}
            </td>
        </tr>
        <tr>
            <th>
                Notiz
            </th>
            <td>
                {{ touchCallback.note }}
            </td>
        </tr>
        <tr>
            <th>
                Gewünschter Rückrufzeitpunkt
            </th>
            <td>
                <strong>{{ touchCallback.callbackTime|formatDate('LLL') }}</strong>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
    export default {
        props: {
            showPersonalData: {
                type: Boolean,
                default: () => true,
            },
            touchCallback: {
                type: Object
            }
        }
    }
</script>
