<template>
    <div>
        <div class="row">
            <div class="large-3 columns">
                <input-simple-select
                    v-model="value.marketingChannel"
                    :errors="errors.marketingChannel"
                    label="Woher kommt die Person?"
                    :options="getMarketingChannelsGlobal()"
                />
            </div>

            <div class="large-3 columns">
                <input-simple-select
                    v-model="value.marketingChannelDetail"
                    :errors="errors.marketingChannelDetail"
                    label="Woher kommt die Person (Detail)?"
                    :readonly="Object.keys(getMarketingChannelsDetail()).length === 0"
                    :options="getMarketingChannelsDetail()"
                />

            </div>

            <div class="large-6 columns">
                <input-text
                    v-if="value.marketingChannel === 'reference'"
                    v-model="value.marketingChannelExtra"
                    :errors="errors.marketingChannelExtra"
                    label="Kontaktperson"
                >
                </input-text>
            </div>
        </div>
    </div>
</template>

<script>
    import value from "../../../../mixins/value";
    import InputRte from "../../../components/form/InputRte";

    export default {
        components: {InputRte},
        mixins: [value],
        methods: {
            getMarketingChannelsGlobal: function () {
                return this.$store.getters.applicationSettings.model.marketingChannels.global;
            },
            getMarketingChannelsDetail: function () {
                var details = $store.getters.applicationSettings.model.marketingChannels.detail;
                if(this.value.marketingChannel === null) {
                    return [];
                }

                return Object.keys(details)
                    .filter(item => item.indexOf(this.value.marketingChannel) === 0)
                    .reduce((obj, key) => {
                        obj[key] = details[key];
                        return obj;
                    }, {});
            },
        },
        watch: {
            'value.marketingChannel': function (newVal) {
                this.value.marketingChannelDetail = null;
            },
        }
    }
</script>
