<template>
	<c-modal ref="businessLetterModal" headline="Geschäftsbrief erstellen">
		<form class="grid gap-2 p-4" v-on:submit.prevent="submit">
			<input-entity v-model="businessLetter.location" type="location" label="Standort" :errors="errors.location"/>
			<input-text label="Betreff" v-model="businessLetter.subject"  :errors="errors.subject"/>
			<input-text label="Kleiner Text (optional)" v-model="businessLetter.subText"  :errors="errors.subText"/>
			<input-text type="textarea" label="Inhalt" v-model="businessLetter.text" :errors="errors.text"/>
			<div class="text-center">
				<button type="submit" class="button small">Erstellen</button>
			</div>
		</form>
	</c-modal>
</template>
<script>
    import util from "../../../plugins/util";

	export default {
        props: {
            person: {
                required: true,
            }
        },
        data() {
            return {
                businessLetter: {
                    subject: '',
                    text: '',
                    subText: '',
                },
				errors: {}
            }
        },
        methods: {
            submit() {
				this.errors = {};
                this.$sendToApi('document/business_letters', Object.assign({person: this.person['@id']}, this.businessLetter), (data, status, errors) => {
					if(status > 299) {
						this.errors = util.mapConstraints(errors);
					} else {
						this.businessLetter = {
							subject: '',
							text: '',
							subText: '',
						};
						this.$refs.businessLetterModal.close();
						window.location = data.downloadUrl;
					}
                })
            },
            open() {
                this.$refs.businessLetterModal.open();
            }
        }
    }
</script>
