<template>
    <div v-if="persistedEntity">
        <content-header
            :headline="'Einreichung Farbenherbst von ' + persistedEntity.person.displayName"
        >
            <router-link to="/tools/autumnOfColorWorks"
                         class="button thirdly small"
            >
                Zurück zur Übersicht
            </router-link>
        </content-header>
        <content-main>
            <div class="panel">
                <form
                    v-on:submit.prevent="submitData"
                    class="grid gap-4"
                >
                    <update-form
                        v-model="editedEntity"
                        :errors="mappedErrors"
                    />

                    <div class="text-center">
                        <button type="submit" class="button">
                            Aktualisieren
                        </button>
                    </div>
                </form>
            </div>
        </content-main>
    </div>
</template>


<script>
    import detailView from "@/../mixins/detailView";
    import UpdateForm from "@/data/AutumnOfColorWork/UpdateForm.vue";
    import util from "../../../../../plugins/util";

    export default {
        data() {
            return {
                mappedErrors: {}
            }
        },
        components: {UpdateForm},
        mixins: [
            detailView
        ],
        methods: {
            afterError(error) {
                this.mappedErrors = util.mapConstraints(error);
            },
            getEndpoint() {
                return 'autumn_of_color_works';
            }
        },
    }
</script>
