<template>
    <div>
        <modal ref="performanceModal"
               min-width="80vw"
        >
            <div style="padding: 0 1rem;">
                <Bar
                    height="200px"
                    width="100%"
                    :chart-options="{responsive: true, maintainAspectRatio: false,}"
                    :chart-data="chartData"
                    style="margin-bottom: 2rem;"
                />

                <div class="text-center">
                    <a @click="$refs.performanceModal.close()"
                       class="button thirdly"
                    >
                        Schließen
                    </a>
                </div>
            </div>
        </modal>
        <content-header headline="Reporting">
            <router-link
                class="button small thirdly"
                to="/reporting/reportings"
            >
                Zurück zur Übersicht
            </router-link>
        </content-header>

        <content-main>
            <request-panel
                v-model="reportingRequest"
                :errors="errors"

            />
            <div class="row">
                <div class="columns text-center">
                    <a @click="getEmailsAsCsv"
                       class="button thirdly"
                    >
                        E-Mail Export
                    </a>
                    <a
                        v-if="reportingRequest.hasOwnProperty('@id')"
                        class="button thirdly"
                        @click="$modal.show('createTemplateTouchCustom')"
                    >
                        Individuelle Touches erstellen
                    </a>
                    <a class="button thirdly" @click="$modal.show('createTaskModal')">
                        ToDos erstellen
                    </a>
                    <a class="button thirdly" @click="showPerformance">ToDo Statistik</a>
                    <a class="button thirdly" @click="clone">Auswertung duplizieren</a>
                    <a class="button primary" @click="submit">Auswertung speichern</a>
                </div>
            </div>

            <modal name="createTemplateTouchCustom" :adaptive="true" height="auto">
                <form v-on:submit.prevent="createTemplateTouchCustom" style="padding: 8rem 2rem;">
                    <div class="columns">
                        <input-entity
                            v-model="reportingTemplateTouchCustomGenerator.templateTouchCustom"
                            type="templateTouchCustom"
                            label="Individuelle Touches Vorlage"
                            :multi="false"
                            :errors="reportingTemplateTouchCustomGeneratorErrors.templateTouchCustom"
                        />
                    </div>
                    <div class="columns small-12 text-center">
                        <a class="button thirdly" @click="$modal.hide('createTemplateTouchCustom')">
                            Abbrechen
                        </a>
                        <button type="submit" class="button">
                            Individuelle Touches erstellen
                        </button>
                    </div>
                </form>
            </modal>

            <modal name="createTaskModal" :adaptive="true" height="auto">
                <form v-on:submit.prevent="createReportingTouchGenerator">
                    <div class="columns small-12">
                        <input-entity
                            v-model="reportingTouchGenerator.users"
                            type="user_real"
                            label="Zugeordnete Benutzer"
                            :multi="true"
                            :errors="reportingRequestsErrors.users"
                        />

                        <input-date
                            v-model="reportingTouchGenerator.date"
                            label="Zieldatum"
                            :errors="reportingRequestsErrors.date"
                        />

                        <input-text
                            type="textarea"
                            v-model="reportingTouchGenerator.note"
                            label="Nähere Beschreibung"
                            :errors="reportingRequestsErrors.note"
                        />
                    </div>

                    <div class="columns small-12 text-center">
                        <a class="button thirdly" @click="$modal.hide('createTaskModal')">
                            Abbrechen
                        </a>
                        <button type="submit" class="button">
                            ToDos erstellen
                        </button>
                    </div>
                </form>
            </modal>

            <persons-table
                ref="personTable"
                v-if="reportingRequest.id"
                :fixedQuery="{'reporting': reportingRequest.id}"
            />
        </content-main>
    </div>
</template>

<script>
    import RequestPanel from "../../../data/Reporting/RequestPanel";
    import util from "../../../../plugins/util";
    import PersonsTable from "../../../data/PersonsTable";
    import {Bar} from 'vue-chartjs/legacy'
    import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'

    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

    export default {
        components: {PersonsTable, RequestPanel, Bar},
        data() {
            return {
                performance: {},
                reportingRequest: {
                    name: '',
                    conditions: {
                        items: [],
                        conditionConnection: 'and',
                    },
                },
                reportingRequestsErrors: {},

                reportingTemplateTouchCustomGenerator: {
                    templateTouchCustom: null,
                },
                reportingTemplateTouchCustomGeneratorErrors: {},

                reportingTouchGenerator: {
                    users: [],
                },
                errors: {},
            }
        },
        mounted() {
            if (this.$route.params.id) {
                this.loadExisting();
            }
        },
        watch: {
            '$route': function (newVal) {
                this.loadExisting();
            }
        },
        methods: {
            showPerformance() {
                this.$http.get('reportings/' + this.reportingRequest['id'] + '/performance').then((response) => {
                    this.$refs.performanceModal.open();
                    this.performance = response.data.performance;
                });
            },
            getEmailsAsCsv() {
                this.$http.get('reportings/' + this.reportingRequest['id'] + '/emails').then((response) => {
                    console.log(response.data);
                    this.$downloadCsv(response.data.emails, response.data.name + '.csv');
                });
            },
            createTemplateTouchCustom() {
                this.$sendToApi(
                    'reporting_template_touch_custom_generators',
                    Object.assign({reporting: this.reportingRequest['@id']}, this.reportingTemplateTouchCustomGenerator),
                    (entity, status, data) => {
                        if (status > 299) {
                            this.reportingTemplateTouchCustomGeneratorErrors = util.mapConstraints(data);
                        } else {
                            this.$modal.hide('createTemplateTouchCustom');
                        }
                    }
                );
            },
            createReportingTouchGenerator() {
                this.$sendToApi(
                    'reporting_touch_generators',
                    this.reportingTouchGenerator,
                    (entity, status, data) => {
                        if (status > 299) {
                            this.reportingRequestsErrors = util.mapConstraints(data);
                        } else {
                            this.$modal.hide('createTaskModal');
                        }
                    }
                );
            },
            clone() {
                this.$store.commit('incrementLoadingIndex');
                this.$http.put('reportings/' + this.reportingRequest.id + '/duplicate', {}).then(response => {
                    this.$router.push('/reporting/reporting/' + response.data.id);
                    this.$store.commit('decrementLoadingIndex');
                });
            },
            loadExisting() {
                if (this.$route.params.id) {
                    this.$getFromApi('reportings', this.$route.params.id, (entity) => {
                        this.reportingRequest = entity;
                        this.reportingTouchGenerator.reporting = entity['@id'];
                    });
                } else {
                    this.reportingRequest = {};
                }
            },
            submit() {
                this.errors = {};
                this.$sendToApi('reportings', this.reportingRequest, (entity, status, errors) => {
                    if (status > 299) {
                        this.errors = util.mapConstraints(errors);
                    } else {
                        if (this.$route.params.id !== entity.id) {
                            this.$router.push('/reporting/reporting/' + entity.id);
                        }
                        this.$refs.personTable.reload();
                    }
                });
            }
        },
        computed: {
            chartData: function () {
                let labels = Object.keys(this.performance).map((item) => {
                    return this.performance[item].displayName;
                });

                let datasets = Object.keys(this.performance).map((item) => {
                    return [
                        this.performance[item].open,
                        this.performance[item].resolved,
                    ]
                });

                return {
                    labels: [].concat(labels),
                    datasets: [
                        {
                            label: 'Offen',
                            data: [].concat(Object.keys(this.performance).map((item) => {
                                return this.performance[item].open;
                            })),
                            backgroundColor: 'red',
                        },
                        {
                            label: 'Geschlossen',
                            data: [].concat(Object.keys(this.performance).map((item) => {
                                return this.performance[item].resolved;
                            })),
                            backgroundColor: 'green',
                        }
                    ]
                }
            }
        }
    }
</script>
