<template>
    <div>
        <content-header headline="Benutzerverwaltung">
            <a class="button" @click="$refs.dataTable.createData({credibilitySlugs: []})">Benutzer hinzufügen</a>
        </content-header>
        <content-main>
            <data-table
                endpoint="user_reals"
                :columns="columns"
                :filters="filters"
                ref="dataTable"
                updateRevealSize="large"
            >
                <template slot="updateForm" slot-scope="{data: user, errors: errors}">
                    <div class="row">
                        <div class="columns large-2">
                            <input-text
                                v-model="user.code"
                                label="Kürzel"
                                :errors="errors.code"
                            />
                        </div>
                        <div class="large-4 columns">
                            <input-text v-model="user.username" :errors="errors.username" label="Benutzername"/>
                        </div>
                        <div class="large-6 columns">
                            <input-text v-model="user.email" :errors="errors.email" label="E-Mail"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="large-6 columns">
                            <input-text v-model="user.firstName" :errors="errors.firstName" label="Vorname"/>
                        </div>
                        <div class="large-6 columns">
                            <input-text v-model="user.lastName" :errors="errors.lastName" label="Nachname"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="columns large-2">
                            <label>
                                Aktiv
                                <br>
                                <input type="checkbox" v-model="user.active">
                            </label>
                        </div>
                        <div class="columns large-4">
                            <input-entity label="Role" v-model="user.role" :errors="errors.role" type="role"/>
                        </div>
                        <div class="columns large-6">
                            <credibility-select v-model="user.credibilitySlugs"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="columns small-12">
                            <input-entity
                                type="user_real"
                                v-model="user.backup"
                                label="Vertretung"
                                :errors="errors.backup"
                            />
                        </div>
                        <div class="columns small-12">
                            <div class="callout warning">
                                <p>Vertreter sollten gewählt werden, damit automatisiert erstellte ToDos nicht
                                    verloren gehen,
                                    <br>
                                    wenn eine Person/Mitarbeiter krank oder im Urlaub ist.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="columns large-6">
                            <input-text :errors="errors.phone" v-model="user.phone" label="Telefonnummer"/>
                        </div>
                    </div>
                </template>

                <template slot="dataRow" slot-scope="{singleData: user}">
                    <td>
                        {{ user.code }}
                    </td>
                    <td>{{ user.username }}
                        <template v-if="!user.active"> (gesperrt)</template>
                        <br>{{ user.displayName }}
                    </td>
                    <td>{{ user.email }}</td>
                    <td>
                        <template v-if="user.role">
                            {{ user.role.name }}
                        </template>
                    </td>
                    <td>
                        <template v-for="(credibilitySlug, index) in user.credibilitySlugs">
                            {{ credibilitySlug|tSecurity }}
                            <template v-if="index < (user.credibilitySlugs.length - 1)">,</template>
                        </template>
                    </td>
                    <td>
                        {{ user.lastEditedAt|formatDate('LLL') }}
                    </td>
                    <td class="text-right">
                        <a v-if="user.userCanUpdate" class="action --edit" @click="$refs.dataTable.editData(user)"></a>
                        <a v-if="user.userCanDelete" class="action --delete"></a>
                    </td>
                </template>
            </data-table>
        </content-main>
    </div>
</template>

<script>
    import DataTable from "../../../components/DataTable";
    import InputSelect from "../../../components/form/InputSelect";
    import CredibilitySelect from "../../../components/form/CredibilitySelect";

    export default {
        components: {
            CredibilitySelect,
            InputSelect,
            DataTable
        },
        data() {
            return {
                filters: [
                    {
                        type: 'text',
                        model: 'email',
                        label: 'E-Mail'
                    },
                    {
                        type: 'text',
                        model: 'username',
                        label: 'Benutzername'
                    }
                ],
                columns: {
                    code: {
                        text: 'Kürzel'
                    },
                    username: {
                        text: 'Benutzername',
                        order: true,
                        filter: 'text'
                    },
                    email: {
                        text: 'E-Mail',
                        order: true,
                        filter: 'text'
                    },
                    role: {
                        text: 'Rolle',
                        order: false
                    },
                    credibilitySlugs: {
                        text: 'Berechtigungen',
                        order: false
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    },
                }
            }
        }
    }
</script>
