<template>
    <div class="touchList">
        <div v-if="Object.keys(groupedTouches).length === 0" class="warning callout">
            <template v-if="touches.length === 0">
                Keine Touches gefunden
            </template>
            <template>
                Mit gesetzter Filterung keine Touches gefunden.
                <br>
                <br>
                <a @click="resetViewTouchTypes">Filterung zurücksetzen</a>
            </template>
        </div>

        <c-modal headline="Touches filtern" ref="touchListFilter">
            <form
                v-on:submit.prevent="updateProfileFilter(true)"
                class="p-4 grid gap-4"
            >
                <ul class="grid grid-cols-2 gap-4">
                    <li
                        v-for="(label, key) in $store.getters.applicationSettings.model.touch.types"
                        class="grid grid-cols-[5rem_1fr] gap-4 items-center"
                    >
                        <input type="checkbox" v-model="viewTouchTypes" :value="key" class="mb-0"/>
                        <span>
						{{ label }}
						</span>
                    </li>
                </ul>

                <div class="text-center grid grid-flow-col gap-4">
                    <a
                        class="button thirdly"
                        @click="toggleAllViewTouchTypes"
                    >
                        Alle aktivieren / deaktivieren
                    </a>
                    <button
                        type="submit"
                        class="button"
                        :disabled="viewTouchTypes.length === 0"
                    >
                        Vorauswahl für Profil speichern
                    </button>
                </div>
            </form>
        </c-modal>

        <ul>
            <template
                v-for="(touches, month) in groupedTouches"
            >
                <li class="--month grid grid-cols-[1fr_auto]">
					<span>
						{{ month }}
					</span>
                    <div
                        v-if="firstMonth === month"
                    >
                        <a
                            @click="$refs.touchListFilter.open()"
                            class="button small mb-0 thirdly"
                        >
                            Filtern
                            <template v-if="viewTouchTypes.length > 0">({{ viewTouchTypes.length }} Touch-Typen
                                ausgewählt)
                            </template>
                        </a>
                    </div>
                </li>
                <li class="--actions">
                    <ul>
                        <li v-for="touch in touches">
                            <div class="touchList__item">
                                <div class="touchList__itemHeader">
                                    <div class="row align-middle">
                                        <div class="columns shrink">
                                            <template
                                                v-if="touch.clonedType !== 'callback' && touch.clonedType !== 'custom'">
                                                <router-link
                                                    :to="'/events/' + touch.event.id"
                                                    v-if="touch.clonedType === 'registration' || touch.clonedType === 'visit'"
                                                    class="action --edit"
                                                >
                                                </router-link>
                                                <router-link
                                                    :to="'/touchComplex?edit=' + touch.clonedType + '&touchId=' + touch.id"
                                                    class="action --edit"
                                                    v-else-if="touch.userCanUpdate"
                                                >
                                                </router-link>
                                            </template>
                                            <a
                                                v-if="touch.userCanDelete"
                                                class="action --delete"
                                                @click="deleteTouch(touch)"
                                            />
                                        </div>
                                        <div class="columns">
                                            <template
                                                v-if="touch.clonedType === 'call' || touch.clonedType === 'email'"
                                            >
                                                <h3>
                                                    {{
                                                        touch.clonedType|dataTrans('model.touch.types')
                                                    }} mit
                                                    {{ touch.createdByDisplayName }}
                                                </h3>
                                                <template v-if="touch.number">
                                                    Verwendete Telefonnummer: {{ touch.number }}
                                                </template>
                                            </template>
                                            <template
                                                v-else-if="touch.clonedType === 'catalogue'">
                                                <h3>Katalog bestellt</h3>
                                            </template>
                                            <template
                                                v-else-if="touch.clonedType === 'scholarship'">
                                                <h3>Stipendiumsbewerbung</h3>
                                            </template>
                                            <template v-else-if="touch.clonedType === 'note'">
                                                <h3>Notiz erstellt von
                                                    {{ touch.createdByDisplayName }}</h3>
                                            </template>
                                            <template v-else-if="touch.clonedType === 'visit'">
                                                <h3>SW Besuch: {{ touch.event.name }} am
                                                    {{ touch.date|formatDate('LL') }}</h3>
                                            </template>
                                            <template v-else-if="touch.clonedType === 'booking'">
                                                <h3>Buchung</h3>
                                            </template>
                                            <template v-else-if="touch.clonedType === 'consultation'">
                                                <h3>
                                                    Beratungsgespräch mit
                                                    {{ touch.createdByDisplayName }}
                                                </h3>
                                            </template>
                                            <template v-else-if="touch.clonedType === 'reporting'">
                                                <h3>
                                                    ToDo aus Auswertung gesetzt
                                                </h3>
                                            </template>
                                            <template v-else-if="touch.clonedType === 'support'">
                                                <h3>
                                                    Förderungsdokumentation
                                                </h3>
                                            </template>
                                            <template v-else-if="touch.clonedType === 'autumnOfColorWork'">
                                                <h3>
                                                    Einreichungen Farbenherbst
                                                </h3>
                                            </template>
                                            <template v-else-if="touch.clonedType === 'firstContact'">
                                                <h3>
                                                    Erstkontakt mit
                                                    {{ touch.createdByDisplayName }}
                                                </h3>
                                            </template>
                                            <template v-else-if="touch.clonedType === 'appearance'">
                                                Einbuchung "{{ touch.course.code }}"
                                            </template>
                                            <template v-else-if="touch.clonedType === 'callback'">
                                                Rückrufanfrage für
                                                {{ touch.callbackTime|formatDate('LLL') }}
                                                <br>
                                                <template v-if="touch.status">
                                                    {{
                                                        touch.status|dataTrans('model.touchCallback.stati')
                                                    }}
                                                </template>
                                                <br>
                                                <template v-if="touch.acceptedUser">
                                                    (akzeptiert durch
                                                    {{ touch.acceptedUser.displayName }})
                                                </template>
                                            </template>
                                            <template v-else-if="touch.clonedType === 'custom'">
                                                Individueller Touch: {{ touch.name }}
                                            </template>
                                            <template
                                                v-else-if="touch.clonedType === 'registration'">
                                                <h3>
                                                    SW Anmeldung zu: {{ touch.event.name }}
                                                    <br>
                                                    ({{ touch.event.date|formatDate('LLL') }})
                                                </h3>
                                            </template>
                                            <template
                                                v-else-if="touch.clonedType === 'catalogueRequest'"
                                            >
                                                <h3>Katalog angefordert
                                                    <template v-if="touch.digital">(digital)</template>
                                                    <template v-else>(Post)</template>
                                                </h3>
                                            </template>
                                            <template
                                                v-else-if="touch.clonedType === 'catalogueSend'"
                                            >
                                                <h3>Katalog versendet
                                                    <template v-if="touch.digital">(digital)</template>
                                                    <template v-else>(Post)</template>
                                                </h3>
                                            </template>
                                            <template
                                                v-else-if="touch.clonedType === 'request'"
                                            >
                                                <h3>Anfrage</h3>
                                            </template>
                                        </div>
                                        <div class="columns shrink">
                                            {{ touch.date|formatDate('llll') }} Uhr
                                        </div>
                                    </div>
                                </div>
                                <div class="touchList__itemContent"
                                     v-if="touch.clonedType === 'note' || touch.clonedType === 'call' || touch.clonedType === 'email' || touch.clonedType === 'firstContact'">
                                    <div
                                        class="max-h-[120px] overflow-y-auto"
                                        v-html="touch.note"
                                    ></div>
                                </div>
                                <div v-if="touch.clonedType === 'autumnOfColorWork'"
                                     class="touchList__itemContent"
                                >
                                    <router-link
                                        :to="'/tools/autumnOfColorWorks/' + touch.autumnOfColorWork.id"
                                        class="underline hover:no-underline"
                                    >
                                        Zur Einreichung
                                    </router-link>
                                </div>
                                <div
                                    v-if="touch.clonedType === 'scholarship'"
                                    class="touchList__itemContent"
                                >
                                    <div class="row">
                                        <div class="columns">
                                            Bewertung: {{ touch.scoring|dataTrans('model.touchScholarship.scorings') }}
                                        </div>
                                        <div class="columns">
                                            <template v-if="touch.optInTimestamp">
                                                Bestätigung der E-Mail-Adresse am
                                                {{ touch.optInTimestamp|formatDate('LLL') }}
                                            </template>
                                            <template v-else>
                                                Noch keine Bestätigung der E-Mail-Adresse
                                            </template>
                                        </div>
                                    </div>
                                    <hr>
                                    <template v-if="touch.location">
                                        {{ touch.location.name }}
                                        <hr>
                                    </template>
                                    <template v-if="touch.education">
                                        {{ touch.education.name }}
                                        <hr>
                                    </template>
                                </div>
                                <div class="touchList__itemContent"
                                     v-if="touch.clonedType === 'request'">
                                    <div v-html="touch.message"></div>
                                    <br>
                                    <div v-if="touch.educations.length > 0">
                                        <strong>Ausbildung:</strong>
                                        <ul>
                                            <li v-for="education in touch.educations">
                                                {{ education.name }}
                                            </li>
                                        </ul>
                                    </div>
                                    <div v-if="touch.locations.length > 0">
                                        <strong>Standort:</strong>
                                        <ul>
                                            <li v-for="location in touch.locations">
                                                {{ location.name }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div
                                    v-if="touch.clonedType === 'custom' && touch.templateTouchCustom"
                                    class="touchList__itemContent"
                                >
                                    {{ touch.templateTouchCustom.text }}
                                </div>

                                <div class="touchList__itemContent"
                                     v-if="touch.clonedType === 'booking'"
                                >
                                    <div v-if="touch.cancellation"
                                         class="bg-alert-400 p-4 mb-4 text-white"
                                    >
                                        Achtung! Stornierungsrechnung
                                    </div>

                                    <div
                                        v-if="touch.versionStatus"
                                        class="bg-warning-50 p-4 mb-4 text-warning-800 border border-warning border-solid"
                                    >
                                        <template v-if="touch.versionStatus.cancellation">Storniert durch:</template>
                                        <template v-if="!touch.versionStatus.cancellation">Geändert durch:</template>
                                        <template>{{ touch.versionStatus.invoiceNumber }}</template>
                                    </div>

                                    <h3>Rechnungsnummer</h3>
                                    <p>
                                        {{ touch.invoiceNumber }}
                                    </p>

                                    <h3>Bemerkung</h3>
                                    <p>
                                        {{ touch.invoiceAdditionalText }}
                                    </p>

                                    <h3>Maßnahme</h3>
                                    <p>
                                        {{ touch.invoiceMeasureText }}
                                    </p>

                                    <h3>
                                        Kundennummer FÖBG
                                    </h3>
                                    <p>
                                        {{ touch.supportedCustomerNumber }}
                                    </p>

                                    <h3>
                                        Ausbildung
                                    </h3>
                                    <p>
                                        <template v-if="touch.education">
                                            {{ touch.education.name }}
                                        </template>
                                        <template v-else>
                                            Keine Ausbildung hinterlegt
                                        </template>
                                    </p>

                                    <h3>Kurse</h3>
                                    <ul>
                                        <!--<li v-if="touch.mainCourseCode">
                                            <p><strong>{{ touch.mainCourseCode }}</strong> (Hauptkurs)</p>
                                        </li>-->
                                        <li v-for="code in touch.courseCodes">
                                            <p>{{ code }}</p>
                                        </li>
                                    </ul>

                                    <template v-if="touch.start && touch.end">
                                        <h3>Zeitraum</h3>
                                        <p>{{ touch.start|formatDate('LL') }} bis {{ touch.end|formatDate('LL') }}</p>
                                    </template>

                                    <template v-if="touch.bookingType">
                                        <h3>Bezahlung</h3>
                                        <p>{{ touch.bookingType|dataTrans('model.touchBooking.bookingType') }}</p>
                                    </template>

                                    <div class="text-center">
                                        <!--<router-link :to="'/tools/timetableSuggest?invoiceNumber=' + touch.invoiceNumber" class="button thirdly small">
                                            Stundenplan erstellen
                                        </router-link>-->
                                    </div>
                                </div>

                                <div
                                    class="touchList__itemContent text-center"
                                    v-if="touch.invitationLink && touch.event.inFuture"
                                >
                                    <a
                                        class="button thirdly"
                                        @click="copyToClipboard(touch.invitationLink)"
                                    >
                                        Einladungslink kopieren
                                    </a>
                                </div>

                                <div
                                    class="touchList__itemContent"
                                    v-if="touch.clonedType === 'support'"
                                >
                                    <div v-if="touch.education">Maßnahme: {{ touch.education.name }}</div>
                                    <div class="text-center">
                                        <router-link
                                            :to="'/touchComplex/?edit=support&touchId=' + touch.id"
                                            class="button thirdly small">
                                            Beratungsgespräch mit Förderung ansehen
                                        </router-link>

                                    </div>
                                </div>
                                <div class="touchList__itemContent"
                                     v-if="touch.clonedType === 'consultation'"
                                >
                                    <table>
                                        <tr v-if="touch.primaryEducation">
                                            <td width="240px">Ausbildungswunsch (Primär)</td>
                                            <td>{{ touch.primaryEducation.name }}</td>
                                        </tr>
                                        <tr v-if="touch.secondaryEducation">
                                            <td width="240px">Ausbildungswunsch (Sekundär)</td>
                                            <td>{{ touch.secondaryEducation.name }}</td>
                                        </tr>
                                        <tr v-if="touch.possibleStart">
                                            <td width="240px">Möglicher Start</td>
                                            <td>{{ touch.possibleStart }}</td>
                                        </tr>
                                    </table>

                                    <div v-if="touch.note" v-html="touch.note">
                                    </div>

                                    <div class="text-center">
                                        <router-link
                                            :to="'/touchComplex/?edit=consultation&touchId=' + touch.id"
                                            class="button thirdly small">
                                            Beratungsgespräch ansehen
                                        </router-link>
                                        <router-link
                                            :to="'/touchComplex/?create=support&touchConsultationId=' + touch.id"
                                            class="button thirdly small">
                                            Förderungsdokumentation erstellen
                                        </router-link>
                                    </div>
                                </div>
                                <div class="touchList__itemContent"
                                     v-if="touch.clonedType === 'callback'"
                                >
                                    <div class="h-margin-bottom">
                                        Anfrage von: {{ touch.url }}
                                        <br>
                                        Wunschstandort: {{ touch.locationName }}
                                        <br>
                                        <br>
                                        <div v-html="touch.note"></div>
                                    </div>
                                    <div class="text-center">
                                        <router-link
                                            v-if="touch.status === 'closed' && touch.touchFirstContact.id"
                                            :to="'/touchComplex/?edit=firstContact&touchId=' + touch.touchFirstContact.id"
                                            class="button small">
                                            Zugehörigen Erstkontakt bearbeiten
                                        </router-link>
                                        <router-link
                                            v-if="touch.status === 'pending'"
                                            :to="'/callbacks/' + touch.id + '/accept'"
                                            class="button small">
                                            Rückrufanfrage annehmen
                                        </router-link>
                                        <router-link
                                            v-if="touch.status === 'pipeline'"
                                            :to="'/callbacks/' + touch.id + '/change'"
                                            class="button thirdly small">
                                            Rückrufanfrage bearbeiten
                                        </router-link>
                                        <router-link
                                            v-if="touch.status === 'pipeline'"
                                            :to="'/touchComplex/?create=firstContact&personId=' + person.id + '&touchCallbackId=' + touch.id"
                                            class="button small">
                                            Aufbauenden Erstkontakt erstellen
                                        </router-link>
                                    </div>
                                </div>
                                <div class=""
                                     v-if="touch.clonedType === 'firstContact'"
                                >
                                    <div class="text-center">
                                        <router-link
                                            :to="'/touchComplex/?edit=firstContact&touchId=' + touch.id"
                                            class="button thirdly small">
                                            Erstkontakt ansehen / bearbeiten
                                        </router-link>
                                        <router-link
                                            :to="'/touchComplex/?create=consultation&touchFirstContactId=' + touch.id"
                                            class="button thirdly small">
                                            Aufbauendes Beratungsgespräch erstellen
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
    export default {
        props: {
            touches: {
                type: Object | Array,
                required: true,
            },
            person: {
                type: Object | Array,
                required: true,
            }
        },
        data() {
            return {
                viewTouchTypes: []
            }
        },
        mounted() {
            this.viewTouchTypes = this.$store.getters.getLoggedInUser.viewTouchTypes;
        },
        computed: {
            groupedTouches() {
                let newData = {};

                this.touches
                    .filter((item) => {
                        return this.viewTouchTypes.includes(item.clonedType);
                    })
                    .forEach((item) => {
                        let date = this.$options.filters.formatDate(item.date, 'MMMM YYYY');
                        if (!newData.hasOwnProperty(date)) {
                            newData[date] = [];
                        }
                        newData[date].push(item);
                    });

                return newData;
            },
            firstMonth() {
                return Object.keys(this.groupedTouches)[0];
            }
        },
        methods: {
            resetViewTouchTypes() {
                this.viewTouchTypes = Object.keys(this.$store.getters.applicationSettings.model.touch.types);
                this.updateProfileFilter(false);
            },
            toggleAllViewTouchTypes() {
                if (this.viewTouchTypes.length > 0) {
                    this.viewTouchTypes = [];
                    this.updateProfileFilter(false);
                } else {
                    this.viewTouchTypes = Object.keys(this.$store.getters.applicationSettings.model.touch.types);
                    this.updateProfileFilter(false);
                }
            },
            updateProfileFilter(closeLayer) {
                this.$http.put('accounts/current', {viewTouchTypes: this.viewTouchTypes}).then((response) => {
                    this.$notify({
                        type: 'success',
                        text: 'Erfolgreich gespeichert'
                    });
                    console.log(closeLayer);
                    if (closeLayer) {
                        this.$refs.touchListFilter.hide();
                    }
                }, response => {
                    this.$notify({
                        type: 'alert',
                        text: 'Fehler beim Speichern'
                    });
                    this.viewTouchTypes = [];
                });
            },
            download(attachment) {
                this.$http.get('attachments/' + attachment['id'] + '/download').then(response => {
                    window.location = response.data;
                });
            },
            deleteTouch(touch) {
                let _this = this;
                this.$dialog
                    .confirm('Touch wirklich löschen', {
                        okText: 'Touch löschen',
                        cancelText: 'Abbrechen',
                    })
                    .then(function (dialog) {
                        _this.$deleteFromApi('touches', touch, () => {
                            _this.$emit('deleteTouch');
                        });
                    })
                    .catch(function () {
                    });
            },
            copyToClipboard(url) {
                const el = document.createElement('textarea');
                el.value = url;
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
                if (selected) {
                    document.getSelection().removeAllRanges();
                    document.getSelection().addRange(selected);
                }
            }
        }
    }
</script>
