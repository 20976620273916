<template>
    <data-table
        endpoint="contact_datas"
        :columns="columns"
        :filter-large="true"
        ref="dataTable"
        :fixed-query="fixedQuery"
        :add-query-to-url="false"
    >
        <template slot="nextToPagination" class="text-left">
            <div class="columns">
                <a class="button small h-noMargin-bottom" @click="$refs.dataTable.createData({person: person['@id']})">Kontakt hinzufügen</a>
            </div>
        </template>
        <template slot="updateForm" slot-scope="{data: contactData, errors: errors}">
            <contact-data-form v-model="contactData" :errors="errors"/>
        </template>

        <template slot="dataRow" slot-scope="{singleData: contactData}">
            <td>
                {{ contactData.type }}
                <br>
                <h3 class="h-noMargin-bottom">{{ contactData.value }}</h3>
            </td>
            <td>
                <template v-if="contactData.first">Ja</template>
            </td>
            <td>
                {{ contactData.lastEditedByDisplayName }}
                <br>
                {{ contactData.lastEditedAt|formatDate('LLL') }}
            </td>
            <td class="text-right">
                <a v-if="contactData.userCanUpdate" class="action --edit"
                   @click="$refs.dataTable.editData(contactData)"></a>
                <a v-if="contactData.userCanDelete" class="action --delete"
                    @click="$refs.dataTable.deleteData(contactData)"
                ></a>
            </td>
        </template>
    </data-table>
</template>

<script>
    import DataTable from "../components/DataTable";
    import ContactDataForm from "./ContactDataForm";

    export default {
        components: {ContactDataForm, DataTable},
        props: {
            person: {
                required: true,
            }
        },
        mounted() {
            this.personID = this.person.id;
        },
        data() {
            return {
                personID: null,
                fixedQuery: {
                    person: this.person.id,
                },
                columns: {
                    type: {
                        text: '',
                        order: false
                    },
                    first: {
                        text: 'Primär'
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    },
                }
            }
        }
    }
</script>
