<template>
    <div class="pSecurity" :class="{'--login': loginProcess}">
        <div class="pSecurity__decorationImage"></div>
        <div class="pSecurity__wrapper">
            <notifications
                    :duration="1000"
                    class="pSecurity__notifications"
            />
            <div class="pSecurity__loading" v-show="loading">
                <loading/>
            </div>
            <div class="pSecurity__content" v-show="!loading">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    import Loading from "../components/Loading";

    export default {
        components: {
            Loading
        },
        data() {
            return {
                loginProcess: false,
                loading: false,
            }
        },
        methods: {
            login() {
                this.loginProcess = true;
                setTimeout(() => {
                    this.$router.push("/persons");
                }, 500);
            },
            showLoading() {
                this.loading = true;
            },
            hideLoading() {
                this.loading = false;
            }
        }
    }
</script>
