<template>
    <content-main>
        <person-form v-model="person" :errors="personErrors"/>
        <touch-form :type="type" v-model="touch" :errors="touchErrors"/>
    </content-main>
</template>

<script>
    import TouchForm from "../../data/TouchForm";
    import PersonForm from "../../data/PersonForm";
    export default {
        components: {
            PersonForm,
            TouchForm
        },
    }
</script>
