<template>
    <div>
        <content-header headline="Standorte">
            <a class="button" @click="$refs.dataTable.createData()">Standort hinzufügen</a>
        </content-header>
        <content-main>
            <data-table
                    endpoint="locations"
                    :columns="columns"
                    :filters="filters"
                    ref="dataTable"
                    updateRevealSize="large"
            >
                <template slot="updateForm" slot-scope="{data: location, errors: errors}">
                    <div class="row">
                        <div class="large-2 columns">
                            <input-text v-model="location.code" :errors="errors.code" label="Kürzel"/>
                        </div>
                        <div class="large-4 columns">
                            <input-text v-model="location.name" :errors="errors.name" label="Bezeichnung"/>
                        </div>
                        <div class="columns large-2">
                            <label>
                                Aktiv
                                <br>
                                <input type="checkbox" v-model="location.active">
                            </label>
                        </div>
                        <div class="columns large-2">
                            <label>
                                Zentrale
                                <br>
                                <input type="checkbox" v-model="location.administrationLocation">
                            </label>
                        </div>
                        <div class="columns large-2">
                            <label>
                                Schulungsstandort
                                <br>
                                <input type="checkbox" v-model="location.workshopLocation">
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="large-6 columns">
                            <input-text v-model="location.street" :errors="errors.street" label="Straße"/>
                        </div>
                        <div class="large-2 columns">
                            <input-text v-model="location.zip" :errors="errors.zip" label="PLZ"/>
                        </div>
                        <div class="large-4 columns">
                            <input-text v-model="location.city" :errors="errors.city" label="Stadt"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="large-12 columns">
                            <hr>
                        </div>
                        <div class="large-6 columns">
                            <input-text v-model="location.email" :errors="errors.email" label="E-Mail des Standortes"/>
                        </div>
                        <div class="large-6 columns">
                            <input-text v-model="location.phoneNumber" :errors="errors.phoneNumber" label="Telefonnummer des Standortes"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="large-12 columns">
                            <hr>
                        </div>
                        <div class="large-6 columns">
                            <input-text v-model="location.trialCourseZoomLink" :errors="errors.trialCourseZoomLink" label="Schnupper-Workshop-Zoom-Url"/>
                        </div>
                        <div class="large-6 columns">
                            <input-text v-model="location.trialCourseZoomUsername" :errors="errors.trialCourseZoomUsername" label="Schnupper-Workshop-Zoom-Benutzername"/>
                        </div>
                        <div class="large-6 columns">
                            <input-text v-model="location.trialCourseZoomPassword" :errors="errors.trialCourseZoomPassword" label="Schnupper-Workshop-Zoom-Passwort"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="large-12 columns">
                            <hr>
                        </div>
                        <div class="large-4 columns">
                            <input-text
                                v-model="location.iban"
                                :errors="errors.iban"
                                label="IBAN"
                            />
                        </div>

                        <div class="large-4 columns">
                            <input-text
                                v-model="location.bic"
                                :errors="errors.bic"
                                label="BIC"
                            />
                        </div>

                        <div class="large-4 columns">
                            <input-text
                                v-model="location.bankName"
                                :errors="errors.bankName"
                                label="Name der Bank"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="large-12 columns">
                            <hr>
                        </div>
                        <div class="large-4 columns">
                            <input-text
                                v-model="location.mgoCostCenterNumber"
                                :errors="errors.mgoCostCenterNumber"
                                label="MGO Kostenstelle"
                            />
                        </div>
                    </div>
                </template>

                <template slot="dataRow" slot-scope="{singleData: location}">
                    <td>{{location.code}}
                    </td>
                    <td>{{location.name}}
                        <template v-if="!location.active"> (inaktiv)</template>
                    </td>
                    <td>
                        <template v-if="location.administrationLocation">Zentrale</template>
                        <br v-if="location.administrationLocation && location.workshopLocation">
                        <template v-if="location.workshopLocation">Schulungsstandort</template>
                    </td>
                    <td>
                        {{location.street}}
                        <br>
                        {{location.zip}} {{location.city}}
                    </td>
                    <td>
                        {{location.lastEditedAt|formatDate('LLL')}}
                    </td>
                    <td class="text-right">
                        <a v-if="location.userCanUpdate" class="action --edit" @click="$refs.dataTable.editData(location)"></a>
                    </td>
                </template>
            </data-table>
        </content-main>
    </div>
</template>

<script>
    import DataTable from "../../../components/DataTable";
    import InputSelect from "../../../components/form/InputSelect";

    export default {
        components: {
            InputSelect,
            DataTable
        },
        data() {
            return {
                filters: [
                    {
                        type: 'text',
                        model: 'name',
                        label: 'Name'
                    },
                    {
                        type: 'text',
                        model: 'city',
                        label: 'Stadt'
                    }
                ],
                columns: {
                    code: {
                        text: 'Kürzel',
                        order: true,
                    },
                    name: {
                        text: 'Name',
                        order: true,
                    },
                    type: {
                        text: 'Art',
                        order: false,
                    },
                    address: {
                        text: 'Adresse',
                        order: false,
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    },
                }
            }
        }
    }
</script>
