<template>
    <div>
        <content-header headline="Personen-Bulk-Bearbeitung"/>

        <content-main>
            <div class="panel">
                <div class="row">
                    <div class="small-4 columns">
                        <input-simple-select
                            label="Art der Bulk-Bearbeitung"
                            v-model="data.type"
                            :options="{'import': 'Einfacher Import', 'touchConnection': 'Touch-Zuordnung'}"
                        />
                    </div>
                    <template v-if="data.type === 'touchConnection'">
                        <div class="small-4 columns">
                            <input-entity
                                label="Individueller Touch"
                                v-model="data.templateTouchCustom"
                                type="templateTouchCustom"
                                :errors="errors.templateTouchCustom"
                            />
                        </div>
                        <div class="small-4 columns">
                            <input-date
                                v-model="data.touchDate"
                                label="Zeitpunkt des Touches"
                                :errors="errors.touchDate"
                            />
                        </div>
                    </template>


                    <div class="large-12 columns">
                        <div class="callout info">
                            <p>
                                Personen wie folgt eintragen (ID (falls vorhanden);Geschlecht;Titel;Vorname;Nachname;Straße;PLZ;Wohnort):
                            </p>
                        </div>
                        <loading v-if="loadingIndex > 0" :disable-wrapper="true"/>
                        <form v-on:submit.prevent="upload" v-else>
                            <input-text
                                type="textarea"
                                v-model="data.data"
                                label="Personen-Import"
                                :errors="errors.data"
                            />
                            <button
                                type="submit"
                                class="button"
                                :disabled="data.type === null"
                            >
                                Bulk-Bearbeitung starten
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </content-main>
    </div>
</template>

<script>
    import util from "../../../../plugins/util";

    export default {
        data() {
            return {
                data: {
                    data: '',
                    type: 'import',
                    touchDate: null,
                    templateTouchCustom: null,
                },
                errors: [],
                loadingIndex: 0,
            }
        },
        methods: {
            upload() {
                this.errors = [];
                this.loadingIndex++;
                this.$http.post('actions/personBulk', this.data).then(response => {
                    this.loadingIndex--;
                    this.data = {
                        data: '',
                        type: 'import',
                        touchDate: null,
                        templateTouchCustom: null,
                    };
                    this.$notify({
                        type: 'success',
                        text: 'Personen-Bulk-Prozess erfolgreich.'
                    });
                }, response => {
                    this.loadingIndex--;
                    this.errors = util.mapConstraints(response.body);
                });
            }
        }
    }
</script>
