export default {
    props: {
        value: {
            type: [Object, Array],
            required: true,
        },
        errors: {
            type: Object,
        }
    },
    watch: {
        value: {
            deep: true,
            handler: function(newVal) {
                this.$emit('input', newVal);
            }
        }
    }
};
