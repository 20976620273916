<template>
    <div style="margin-bottom: 1rem;">
        <label v-if="label !== false" style="margin-bottom: 0.25rem;">{{label}}</label>
        <div style="position: relative;">
            <div v-if="readonly" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgb(0, 0, 0, 0.1); z-index: 100;"></div>
            <ckeditor :editor="editor" contenteditable="true" v-model="value" :config="editorConfig" aria-readonly="true"></ckeditor>
        </div>
    </div>
</template>

<script>
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

    export default {
        props: {
            value: {

            },
            label: {
                type: String|Boolean,
                default: () => {
                    return false;
                }
            },
            readonly: {
                type: Boolean,
                default: () => false
            }
        },
        components: {},
        data() {
            return {
                editor: ClassicEditor,
                editorConfig: {
                    language: 'de',
                    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
                    readonly: true,
                    disabled: true
                }
            }
        },
        watch: {
            value: function (newVal) {
                this.$emit('input', newVal);
            }
        }
    }
</script>
