<template>
    <data-table
            endpoint="education"
            :columns="columns"
            :filters="filters"
            ref="dataTable"
            updateRevealSize="small"
    >
        <template slot="updateForm" slot-scope="{data: education, errors: errors}"
                  v-if="$store.getters.hasLoggedInUserCredibility('ROLE_EDUCATION_MANAGEMENT')">
            <education-form v-model="education" :errors="errors"/>
        </template>

        <template slot="dataRow" slot-scope="{singleData: education}">
            <td>
                <template v-if="!education.active">(inaktiv)</template>
                {{education.name}}
            </td>
            <td>{{education.code}}</td>
            <td>{{education.coursesCount}} Kurs(e)<br>an {{education.locationCount}} Standort(en)<br>{{education.courseDateCount}} Termine</td>
            <td>{{education.type|tEducationType}}</td>
            <td>
                {{education.lastEditedAt|formatDate('LLL')}}
            </td>
            <td class="text-right">
                <router-link class="action --show"
                             :to="'/educations/' + education.id"></router-link>
                <a v-if="education.userCanDelete" @click="$refs.dataTable.deleteData(education)" class="action --delete"></a>
            </td>
        </template>
    </data-table>
</template>

<script>
    import DataTable from "../components/DataTable";
    import CredibilitySelect from "../components/form/CredibilitySelect";
    import EducationTypeSelect from "../components/form/EducationTypeSelect";
    import EducationForm from "../data/EducationForm";

    export default {
        components: {
            EducationForm,
            EducationTypeSelect,
            CredibilitySelect,
            DataTable
        },
        computed: {
            filters() {
                let _this = this;
                return [
                    {
                        type: 'text',
                        model: 'name',
                        label: 'Name'
                    },
                    {
                        type: 'text',
                        model: 'code',
                        label: 'Kürzel'
                    },
                    {
                        type: 'bool',
                        model: 'active',
                        label: 'Aktiv'
                    },
                    {
                        type: 'educationTypeSelect',
                        model: 'type',
                        label: 'Typ',
                        callback: function (value) {
                            return _this.$options.filters.tEducationType(value);
                        }
                    },
                ]
            }
        },
        methods: {
            createData(data) {
                this.$refs.dataTable.createData(data);
            }
        },
        data() {
            return {
                columns: {
                    name: {
                        text: 'Name',
                        order: true
                    },
                    code: {
                        text: 'Kürzel',
                        order: true
                    },
                    courses: {
                        text: 'Kurse'
                    },
                    type: {
                        text: 'Typ',
                        order: false
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    },
                }
            }
        }
    }
</script>
