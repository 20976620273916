<template>
    <div class="p-finance-earningImport">
        <content-header headline="Einnahmen-Import"/>

        <content-main>
            <div class="panel">
                <label>
                    Rohdaten
                    <textarea v-model="plainData" rows="25">

                    </textarea>
                </label>

                <div class="text-center">
                    <button @click="importFromTextarea" class="button">Von Rohdaten importieren</button>
                </div>

                <!--<vue-dropzone
                    v-if="plainDataRows.length === 0"
                    ref="dropzone"
                    :options="dropzoneOptions"
                    @vdropzone-file-added="vfileAdded"
                    :keep-local="true"
                    id="dropzone"
                />-->

                <table v-if="plainDataRows.length">
                    <thead>
                    <tr>
                        <th>Rechnung</th>
                        <th>Datum</th>
                        <th>Betrag</th>
                        <th>Verwendungszweck</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(datum, index) in plainDataRows">
                        <tr>
                            <td style="width: 140px;">
                                <h2>Zahlungseingang</h2>
                                <input-text
                                    label="Rechnungsnummer"
                                    v-model="imports[datum.description]"
                                />
                                <a class="button small h-noMargin-bottom"
                                   v-if="imports[datum.description]"
                                   @click="loadBookings(datum, imports[datum.description])"
                                >
                                    Anzeigen
                                </a>

                            </td>
                            <td>
                                {{ datum.date }}
                            </td>
                            <td>
                                {{ datum.value|price }}
                            </td>
                            <td>
                                {{ datum.description }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="5"
                                style="border-bottom: 2px solid rgba(0,0,0,.2); padding-bottom: 2rem;"
                            >
                                <h3>Vorschläge</h3>
                                <ul v-if="touchBookings[datum.description].length"
                                    class="p-finance-earningImport__suggests"
                                >
                                    <li v-for="touchBooking in touchBookings[datum.description]">
                                        <table>
                                            <tbody>
                                            <tr>
                                                <th>Rechnungsnummer</th>
                                                <td>
                                                    {{ touchBooking.invoiceNumber }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Maßnahmentext
                                                </th>
                                                <td>
                                                    {{ touchBooking.invoiceMeasureText }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Zusätzlicher Text
                                                </th>
                                                <td>
                                                    {{ touchBooking.invoiceAdditionalText }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Ratenhöhe
                                                </th>
                                                <td>
                                                    {{ touchBooking.rateValue|price }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Vorrauszahlung
                                                </th>
                                                <td>
                                                    {{ touchBooking.prepayment|price }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Vorrauszahlung
                                                </th>
                                                <td>
                                                    {{ touchBooking.enrollmentFee|price }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Gebühr
                                                </th>
                                                <td>
                                                    {{ touchBooking.fee|price }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Person
                                                </th>
                                                <td>
                                                    <router-link
                                                        :to="'/persons/' + touchBooking.person.id"
                                                        target='_blank'
                                                    >
                                                        {{ touchBooking.person.displayName }}
                                                    </router-link>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <div class="text-center">
                                                        <a class="button small h-noMargin-bottom"
                                                           @click="importEarning(datum, touchBooking['@id'])"
                                                        >
                                                            Auswählen und importieren
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </li>
                                </ul>
                                <div v-else>
                                    <p>Keine Vorschläge gefunden</p>
                                    <a class="button small h-noMargin-bottom"
                                       @click="importEarning(datum)"
                                    >
                                        Ohne Rechnungszuordnung importieren
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>

            </div>
        </content-main>
    </div>
</template>

<style lang="scss">
.p-finance-earningImport {
    .p-finance-earningImport__suggests {
        grid-template-columns: 1fr 1fr 1fr;
        display: grid;
        list-style: none;
        margin: 0;
        padding: 0;
        grid-gap: 2rem;
    }
}
</style>

<script>
    import vue2Dropzone from 'vue2-dropzone';

    export default {
        components: {
            vueDropzone: vue2Dropzone
        },
        methods: {
            importFromTextarea() {
                this.importFromPlainData(this.plainData);
            },
            importFromPlainData(plainData, split = "\n") {
                let _this = this;
                let data = [];
                let rows = plainData.split(split);
                rows = rows.splice(1);
                console.log(plainData.split(split));

                if(rows.length > 500) {
                    alert("Maximal 500 Datensätze auf einmal möglich, " + rows.length + ' in Textfeld');
                    return;
                }

                rows.forEach((item) => {
                    if (item.length > 0) {
                        let datum = item.split(";");
                        let bankObject = {
                            date: datum[1],
                            value: parseFloat(datum[5].replace(',', '.')) * (datum[6] === '"-"' ? -1 : 1),
                        }
                        let transactionContentText = datum.slice(22, 29).map((item) => item.slice(1, -1)).join('') + ' # ' + bankObject.value.toFixed(2) + ' # ' + bankObject.date;
                        bankObject['description'] = transactionContentText;

                        let uniqueMatches = transactionContentText.match(/(\d{3}(-|\s){1}\d{5,6})|(\d{3}[A-Z]{1}\d{6,8})/g);
                        let supportedMatch = transactionContentText.match(/(\d{2,5}\/\d{2,5}\/\d{2,5})/g);

                        this.$store.commit('incrementLoadingIndex');
                        _this.$http.get('earnings', {params: {description: bankObject.description}}).then((response) => {
                            this.$store.commit('decrementLoadingIndex');
                            if (response.data['hydra:member'].length === 1) {
                            } else {
                                data.push(Object.assign(bankObject, {}));
                                _this.plainDataRows = data;
                                _this.$set(_this.touchBookings, transactionContentText, []);

                                if (uniqueMatches && uniqueMatches.length > 0) {
                                    this.$store.commit('incrementLoadingIndex');
                                    _this.$http.get('touch_bookings', {params: {q: uniqueMatches[0]}}).then((response) => {
                                        this.$store.commit('decrementLoadingIndex');
                                        if (
                                            response.data.hasOwnProperty('hydra:member')
                                            && response.data['hydra:member'].length === 1
                                        ) {
                                            _this.$set(_this.touchBookings, transactionContentText, response.data['hydra:member']);
                                        } else {
                                            let results = response.data['hydra:member'];
                                            results.forEach((item) => {
                                                if (item.rateValue === bankObject.value || item.prepayment === bankObject.value) {
                                                    _this.$set(_this.touchBookings, transactionContentText, [item]);
                                                }
                                            });
                                            if (_this.touchBookings[transactionContentText].length === 0) {
                                                _this.$set(_this.touchBookings, transactionContentText, response.data['hydra:member']);
                                            }
                                        }

                                        if (_this.touchBookings[transactionContentText].length === 1) {
                                            _this.importEarning(bankObject, _this.touchBookings[transactionContentText][0]['@id']);
                                        }
                                    })
                                } else {
                                    if (supportedMatch && supportedMatch.length > 0) {
                                        this.$store.commit('incrementLoadingIndex');
                                        _this.$http.get('touch_bookings', {params: {q: supportedMatch[0]}}).then((response) => {
                                            this.$store.commit('decrementLoadingIndex');
                                            _this.$set(_this.touchBookings, transactionContentText, response.data['hydra:member']);


                                            console.log(_this.touchBookings[transactionContentText]);
                                            if (_this.touchBookings[transactionContentText].length > 0) {
                                                _this.touchBookings[transactionContentText] = _this.touchBookings[transactionContentText].filter((item) => {
                                                    return transactionContentText.toLowerCase().indexOf(item.person.lastName.toLowerCase()) > -1;
                                                });
                                            }
                                            console.log(_this.touchBookings[transactionContentText]);

                                            if (_this.touchBookings[transactionContentText].length === 1) {
                                                _this.importEarning(bankObject, _this.touchBookings[transactionContentText][0]['@id']);
                                            } else {
                                                _this.$set(_this.touchBookings, transactionContentText, response.data['hydra:member']);
                                            }
                                        })
                                    }
                                }
                            }
                        })
                    }
                })
            },
            loadBookings(data, invoiceNumber) {
                this.$http.get('touch_bookings', {params: {internNumber: invoiceNumber.replace("101-", "")}}).then((response) => {
                    this.$set(this.touchBookings, data.description, response.data['hydra:member']);
                });
            },
            importEarning(data, touchBooking = null, automatic = false) {
                this.$sendToApi(
                    'earnings',
                    {
                        description: data.description,
                        value: data.value,
                        date: data.date,
                        touchBooking: touchBooking
                    },
                    (entity, status, data) => {
                        if (status < 300) {
                            this.plainDataRows = this.plainDataRows.filter((item) => {
                                return item.description !== entity.description;
                            })
                            this.$notify({
                                type: 'success',
                                text: 'Datensatz erfolgreich importiert'
                            });
                        }
                        if (status === 422) {
                            if (data['hydra:description'].indexOf('alreadyExists') >= 0) {
                                this.plainDataRows = this.plainDataRows.filter((item) => {
                                    return item.description !== entity.description;
                                });
                            } else {
                                this.$notify({
                                    type: 'warning',
                                    text: 'Fehler beim Speichern'
                                });
                            }


                        } else {

                        }
                    },
                    {
                        disableToast: true
                    }
                )
            },
            vfileAdded: function (file) {
                let _this = this;
                var reader = new FileReader();
                reader.onload = function (event) {
                    let plainData = event.target.result;
                    _this.importFromPlainData(plainData, "\r");
                };
                reader.readAsText(file);
            }
        },
        data() {
            return {
                plainDataRows: [],
                touchBookings: {},
                earnings: [],
                imports: {},
                plainData: '',
            }
        },
        computed: {
            dropzoneOptions: function () {
                return {
                    url: window.baseApiUrl + '/' + this.endpoint,
                    acceptedFiles: this.acceptedFiles,
                    maxFiles: this.maxFiles,
                    maxFilesize: 20,
                    autoQueue: false,
                    addRemoveLinks: true,
                    dictDefaultMessage: 'Legen Sie Dateien hier ab um Sie hochzuladen.',
                    dictFallbackMessage: 'Ihr Browser unterstützt Drag&Drop Dateiuploads nicht',
                    dictFallbackText: 'Benutzen Sie das Formular um Ihre Dateien hochzuladen',
                    dictFileTooBig: 'Die Datei ist zu groß. Die maximale Dateigröße beträgt {{maxFileSize}}MB',
                    dictInvalidFileType: 'Eine Datei dieses Typs kann nicht hochgeladen werden',
                    dictResponseError: 'Der Server hat ihre Anfrage mit Status {{statusCode}} abgelehnt',
                    dictCancelUpload: 'Hochladen abbrechen',
                    dictCancelUploadConfirmation: null,
                    dictRemoveFile: 'Datei entfernen',
                    dictMaxFilesExceeded: 'Sie können (gleichzeitig) keine weiteren Dateien mehr hochladen',
                    previewTemplate: '<div class="dz-preview dz-file-preview">\n' +
                        '                <div class="row align-middle">\n' +
                        '                    <div class="large-5 columns text-left">\n' +
                        '                        <div class="dz-filename"><span data-dz-name>test.csv</span></div>\n' +
                        '                    </div>\n' +
                        '                    <div class="large-5 columns text-center">\n' +
                        '                        <span class="dz-error-message data-dz-errormessage></span>\n' +
                        '                        <span class="dz-size" data-dz-size>14.5kb</span>\n' +
                        '                        <span class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></span>\n' +
                        '                    </div>\n' +
                        '                    <div class="large-2 columns text-right">\n' +
                        '                        <span data-dz-remove class="action --close">\n' +
                        '                    </div>\n' +
                        '                </div>\n' +
                        '            </div>'
                }
            },
        }
    }
</script>
