<template>
    <div>
        <dropzone
            accepted-files="application/pdf,image/png,image/jpeg,image/jpg,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            :max-files="5"
            endpoint="attachments"
            :params="params"
            :removeOnResponse="true"
            v-on:complete="complete"
        />

        <DataTable
            ref="dataTable"
            endpoint="attachments"
            :columns="columns"
            :filters="filters"
            :addQueryToUrl="false"
            :pre-set-query="{entityId: entityId}"

        >
            <template slot="dataRow" slot-scope="{singleData: attachment}">
                <td :style="{'padding-bottom': editedId === attachment.id ? 0 : 'auto'}">
                    <div class="grid grid-cols-[1fr_auto] gap-x-2 items-center">
                        <div
                            v-if="editedId !== attachment.id"
                        >
                            {{ attachment.name }}
                        </div>
                        <div
                            v-else
                            class="grid grid-cols-2 gap-x-2"
                        >
                            <div class="col-span-2">
                                <input-text v-model="attachment.name"/>
                            </div>
                            <div>
                                <input-simple-select
                                    label="Tag"
                                    v-model="attachment.tag"
                                    :options="$store.getters.applicationSettings.model.attachment.tag"
                                />
                            </div>
                            <div>
                                <label>
                                    In Portalen freigegeben
                                    <br>
                                    <input
                                        v-model="attachment.shared"
                                        type="checkbox"
                                    />
                                </label>
                            </div>
                        </div>
                        <div
                            v-if="attachment.userCanUpdate"
                            class="grid gap-2"
                        >
                            <div v-if="editedId !== attachment.id">
                                <a class="ti ti-pencil" @click="editAttachmentName(attachment)"></a>
                            </div>
                            <div v-else>
                                <a class="ti ti-save transparent" @click="updateAttachmentName(attachment)"></a>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <template v-if="attachment.tag">
                        {{ attachment.tag|dataTrans('model.attachment.tag') }}
                    </template>
                </td>
                <td>
                    {{ attachment.createdByDisplayName }}
                    <br>
                    {{ attachment.createdAt|formatDate('LLL') }}
                </td>
                <td>
                    <a class="ti ti-download"
                       @click="download(attachment)"
                    ></a>
                    <a class="ti ti-trash"
                       v-if="attachment.userCanDelete"
                       @click="$refs.dataTable.deleteData(attachment)"
                    ></a>
                </td>
            </template>
        </DataTable>
    </div>
</template>

<script>
    import Dropzone from "../../components/Dropzone";
    import DataTable from "../../components/DataTable";
    import InputSimpleMultiSelectRichObjects from "@/components/form/InputSimpleMultiSelectRichObjects.vue";

    export default {
        components: {InputSimpleMultiSelectRichObjects, DataTable, Dropzone},
        props: {
            entityId: {
                type: Text | String,
                required: true
            }
        },
        data() {
            return {
                editedId: null,
                columns: {
                    name: {
                        text: 'Name',
                    },
                    tag: {
                        text: 'Tag',
                    },
                    createdAt: {
                        text: 'Erstellt von',
                    },
                }
            }
        },
        computed: {
            params: function () {
                return {
                    entityId: this.entityId
                }
            },
            filters() {
                let tags = this.$store.getters.applicationSettings.model.attachment.tag;
                let filter = [
                    {
                        type: 'text',
                        model: 'name',
                        label: 'Name'
                    },
                ];
                filter.push({
                    label: 'Tag',
                    type: 'select',
                    options: tags,
                    model: 'tag',
                    callback: function (val) {
                        if (!val) {
                            return '-';
                        }
                        return tags[val];
                    }
                })

                return filter;
            }
        },
        methods: {
            editAttachmentName(attachment) {
                this.editedId = attachment.id;
            },
            updateAttachmentName(attachment) {
                this.$http.put('attachments/' + attachment['id'], {
                    name: attachment.name,
                    tag: attachment.tag,
                    shared: attachment.shared
                }).then(response => {
                    this.$refs.dataTable.reload();
                }, response => {
                    this.$notify({
                        type: 'warning',
                        text: 'Name konnte nicht geändert werden.'
                    });
                    this.$refs.dataTable.reload();
                });
            },
            download(attachment) {
                this.$http.get('attachments/' + attachment['id'] + '/download').then(response => {
                    window.location = response.data;
                });
            },
            complete() {
                this.$refs.dataTable.reload();
            }
        }
    }
</script>
