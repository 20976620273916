export default {
  data() {
    return {
      keyChange: false,
    }
  },
  created() {
    window.addEventListener('keyup', () => {
      this.keyChange = true;
    });
    window.addEventListener('beforeunload', this.confirm);
  },
  methods: {
    confirm(event) {
      if (this.keyChange) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
    resetKeyChange() {
      console.log("Reset key cahnge");
      this.keyChange = false;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.keyChange) {
      next();
    } else {
      return this.$dialog
        .confirm('Seite wirklich verlassen? Evtl. gibt es ungespeichert Änderungen!', {
          okText: 'Seite verlassen',
          cancelText: 'Abbrechen',
        })
        .then(function (dialog) {
          next();
        })
        .catch(function () {
          next(false);
        });
    }
  }
};
