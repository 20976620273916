<template>
    <div>
        <data-table
            :endpoint="endpoint"
            :columns="columns"
            :filters="filters"
            :filter-layout="filterLayout"
            :filter-large="true"
            ref="dataTable"
            :fixed-query="fixedQuery"
            updateRevealSize="large"
            :add-query-to-url="addQueryToUrl"
            :state-key="stateKey"
        >
            <template slot="updateForm" slot-scope="{data: person, errors: errors}">
                <person-form v-model="person" :errors="errors"/>
            </template>
            <template slot="dataRow" slot-scope="{singleData: person}">
                <td v-if="showInvoiceData">
                    {{ person.courseInvoiceNumber }}
                    <br>
                    {{ person.courseInvoiceLocation }}
                </td>
                <td>
                    <template v-if="person.customerNumber">
                        {{ person.customerNumber }}
                    </template>
                </td>
                <td>
                    <status-light :status="person.status"/>
                    {{ person.displayName }}
                    <div
                        class="badge"
                        v-if="person.bookingType"
                    >
                        {{ person.bookingType|dataTrans('model.touchBooking.bookingType') }}
                    </div>
                </td>
                <td>
                    <template v-if="person.street">{{ person.street }}</template>
                    <br v-if="person.street && (person.zip || person.city)">
                    <template v-if="person.zip">{{ person.zip }}</template>
                    <template v-if="person.city">{{ person.city }}</template>
                </td>
                <td>
                    <template v-if="person.email">
                        {{ person.email }}
                    </template>
                    <template v-if="person.phoneNumber">
                        {{ person.email }}
                    </template>
                </td>
                <td>
                    {{ person.lastEditedAt|formatDate('LLL') }}
                </td>
                <td class="text-right">
                    <a class="action --duplicate" title="Als Duplikat markieren" @click="addDuplicate(person.id)"></a>
                    <router-link :to="'/persons/' + person.id"
                                 class="action --show"></router-link>
                    <router-link :to="'/persons/' + person.id" v-if="person.userCanUpdate"
                                 class="action --edit"></router-link>
                    <a v-if="person.userCanDelete" class="action --delete"></a>
                </td>
            </template>
        </data-table>
    </div>
</template>

<script>
    import DataTable from "../components/DataTable";
    import CredibilitySelect from "../components/form/CredibilitySelect";
    import EducationTypeSelect from "../components/form/EducationTypeSelect";
    import PersonForm from "./PersonForm";
    import StatusLight from "./Person/StatusLight";

    export default {
        components: {
            StatusLight,
            PersonForm,
            EducationTypeSelect,
            CredibilitySelect,
            DataTable
        },
        props: {
            endpoint: {
                type: Text | String,
                default: 'people'
            },
            disableFilter: {
                type: Array,
                default: () => []
            },
            fixedQuery: {
                type: Object,
                default: () => {
                }
            },
            addQueryToUrl: {
                type: Boolean,
                default: () => false
            },
            stateKey: {
                type: String | null,
                default: () => null,
            }
        },
        methods: {
            reload() {
                this.$refs.dataTable.reload();
            },
            createData(data) {
                this.$refs.dataTable.createData(data);
            },
            addDuplicate(duplicate) {
                if (!this.duplicate) {
                    this.duplicate = duplicate;
                    this.$notify({
                        text: 'Markieren Sie einen zweiten Datensatz, um diese zusammenzuführen.',
                        type: 'info'
                    });
                } else {
                    let query = {
                        basePerson: this.duplicate,
                        matchPerson: duplicate
                    };
                    this.duplicate = null;
                    window.open(this.$router.resolve({
                        path: '/tools/personDuplicateResolver',
                        query: query
                    }).href);
                }
            }
        },
        computed: {
            filters() {
                let personStati = [];
                if (this.$store.getters.applicationSettings) {
                    personStati = this.$store.getters.applicationSettings.model.person.stati;
                }
                let filter = [
                    {
                        type: 'text',
                        model: 'firstName',
                        label: 'Vorname'
                    },
                    {
                        type: 'text',
                        model: 'lastName',
                        label: 'Nachname'
                    },
                    {
                        type: 'text',
                        model: 'street',
                        label: 'Straße'
                    },
                    {
                        type: 'text',
                        model: 'city',
                        label: 'Wohnort'
                    },
                    {
                        type: 'text',
                        model: 'phoneNumbers',
                        label: 'Primäre Telefonnummer'
                    },
                    {
                        type: 'text',
                        model: 'email',
                        label: 'Primäre E-Mail'
                    },
                    {
                        type: 'text',
                        model: 'company',
                        label: 'Unternehmen'
                    },
                    {
                        type: 'select',
                        model: 'status',
                        label: 'Status',
                        options: personStati,
                        getValidFilterValue: function (value) {
                            if (this.$store.getters.applicationSettings) {
                                return this.$store.getters.applicationSettings.model.person[value];
                            }
                            return value;
                        }
                    },
                    {
                        type: 'text',
                        model: 'invoiceNumber',
                        label: 'Rechnungsnummer'
                    },
                ];

                return filter.filter(item => {
                    return this.disableFilter.includes(item.model) === false;
                });
            },
            columns() {
                let columns = {
                    customerNumber: {
                        text: 'Kd-Nr.',
                        order: true,
                    },
                    lastName: {
                        text: 'Name',
                        order: true,
                    },
                    city: {
                        text: 'Adresse',
                    },
                    email: {
                        text: 'Kontakt',
                    },
                    lastEditedAt: {
                        text: 'Zuletzt bearbeitet',
                        order: true
                    }
                }

                if (this.showInvoiceData) {
                    columns = Object.assign({invoice: {text: 'Rechnungsdaten'}}, columns);
                }

                return columns;
            },
            showInvoiceData() {
                return typeof this.fixedQuery !== 'undefined' && this.fixedQuery.hasOwnProperty('course');
            }
        },
        data() {
            return {
                duplicate: null,
                filterLayout: [
                    ['firstName', 'lastName'],
                    ['street', 'city'],
                    ['email', 'phoneNumbers'],
                    ['company', 'status'],
                    ['invoiceNumber'],
                ],
            }
        }
    }
</script>
