import Vue from "vue";
import auth from "./auth";
import router from "./../router";
import VueResource from "vue-resource";

Vue.use(VueResource);

Vue.http.options.root = window.baseApiUrl + '/';

Vue.http.interceptors.push(function (request, next) {
    if(request.url.indexOf('tasks') >= 0) {
        request.timeout = 6000;
    } else {
        request.timeout = 30000;
    }
    next();
});

Vue.http.interceptors.push(function (request, next) {
    if (
        request.url !== "actions/passwordReset"
        && request.url !== "token"
    ) {
        request.headers.set("Authorization", auth.getTokenHeader());
    }

    if (
        request.url !== "token"
        && request.url !== "token/refresh"
    ) {
        next((response) => {
            if (response.status === 401) {
                return auth.refresh().then(response => {
                    auth.setAuth(response.data.token);
                    auth.setRefreshToken(response.data.refresh_token);
                    request.headers.set("Authorization", `Bearer ${response.data.token}`);

                    return Vue.http(request).then(data => {
                        return data;
                    });
                }, () => {
                    auth.clearAuth();
                    return router.push("/login");
                });
            } else {
                next();
            }
        });
    } else {
        next();
    }
});
